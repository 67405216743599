import React from "react";
import ColorHelper from "../../Helpers/ColorHelper";

const Sounds2 = (props) => {
    const { color } = props;
  return (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width={46} height={46} viewBox="0 0 46 46">
   
    <path  stroke={ColorHelper.BgBlue} class="cls-1" d="M27.24,9.57H1.8c-.39,0-.71,.32-.71,.71v25.44c0,.39,.32,.71,.71,.71H27.24c.39,0,.71-.32,.71-.71V10.28c0-.39-.32-.71-.71-.71Zm-4.95,1.41h1.41v1.41h-1.41v-1.41Zm-2.83,0h1.41v1.41h-1.41v-1.41Zm-2.83,0h1.41v1.41h-1.41v-1.41Zm-2.83,0h1.41v1.41h-1.41v-1.41Zm-2.83,0h1.41v1.41h-1.41v-1.41Zm-2.83,0h1.41v1.41h-1.41v-1.41Zm-2.83,0h1.41v1.41h-1.41v-1.41Zm-2.83,0h1.41v1.41h-1.41v-1.41Zm1.41,24.03h-1.41v-1.41h1.41v1.41Zm2.83,0h-1.41v-1.41h1.41v1.41Zm2.83,0h-1.41v-1.41h1.41v1.41Zm2.83,0h-1.41v-1.41h1.41v1.41Zm2.83,0h-1.41v-1.41h1.41v1.41Zm2.83,0h-1.41v-1.41h1.41v1.41Zm2.83,0h-1.41v-1.41h1.41v1.41Zm2.83,0h-1.41v-1.41h1.41v1.41Zm2.83,0h-1.41v-1.41h1.41v1.41Zm0-2.83H2.5V13.81H26.53v18.38Zm0-19.79h-1.41v-1.41h1.41v1.41Z"/>
    <path  stroke={ColorHelper.BgBlue} class="cls-1" d="M14.52,15.93c-3.9,0-7.07,3.16-7.07,7.07s3.16,7.07,7.07,7.07,7.07-3.16,7.07-7.07c0-3.9-3.17-7.06-7.07-7.07Zm0,12.72c-3.12,0-5.65-2.53-5.65-5.65s2.53-5.65,5.65-5.65,5.65,2.53,5.65,5.65c0,3.12-2.53,5.65-5.65,5.65Z"/>
    <path  stroke={ColorHelper.BgBlue} class="cls-1" d="M17.69,22.38l-4.95-2.74c-.34-.19-.77-.07-.96,.28-.06,.1-.09,.22-.09,.34v5.48c0,.39,.32,.71,.71,.71,.12,0,.24-.03,.34-.09l4.95-2.74c.34-.19,.47-.62,.28-.96-.06-.12-.16-.21-.28-.28Zm-4.58,2.16v-3.08l2.78,1.54-2.78,1.54Z"/>
    <path  stroke={ColorHelper.BgBlue} class="cls-1" d="M44.2,9.57h-14.84c-.39,0-.71,.32-.71,.71v25.44c0,.39,.32,.71,.71,.71h14.84c.39,0,.71-.32,.71-.71V10.28c0-.39-.32-.71-.71-.71Zm-.71,25.44h-13.43V10.99h13.43v24.03Z"/>
    <path  stroke={ColorHelper.BgBlue} class="cls-1" d="M32.19,20.88h3.66c.39,1.1,1.6,1.68,2.71,1.29,.6-.21,1.08-.69,1.29-1.29h1.54c.39,0,.71-.32,.71-.71s-.32-.71-.71-.71h-1.54c-.39-1.1-1.6-1.68-2.71-1.29-.6,.21-1.08,.69-1.29,1.29h-3.66c-.39,0-.71,.32-.71,.71s.32,.71,.71,.71h0Zm5.65-1.41c.39,0,.71,.32,.71,.71s-.32,.71-.71,.71-.71-.32-.71-.71c0-.39,.32-.71,.71-.71Z"/>
    <path  stroke={ColorHelper.BgBlue} class="cls-1" d="M32.19,15.23h.83c.39,1.1,1.6,1.68,2.71,1.29,.6-.21,1.08-.69,1.29-1.29h4.36c.39,0,.71-.32,.71-.71s-.32-.71-.71-.71h-4.36c-.39-1.1-1.6-1.68-2.71-1.29-.6,.21-1.08,.69-1.29,1.29h-.83c-.39,0-.71,.32-.71,.71s.32,.71,.71,.71h0Zm2.83-1.41c.39,0,.71,.32,.71,.71s-.32,.71-.71,.71-.71-.32-.71-.71c0-.39,.32-.71,.71-.71Z"/>
    <path  stroke={ColorHelper.BgBlue} class="cls-1" d="M32.19,26.53h.83c.39,1.1,1.6,1.68,2.71,1.29,.6-.21,1.08-.69,1.29-1.29h4.36c.39,0,.71-.32,.71-.71s-.32-.71-.71-.71h-4.36c-.39-1.1-1.6-1.68-2.71-1.29-.6,.21-1.08,.69-1.29,1.29h-.83c-.39,0-.71,.32-.71,.71s.32,.71,.71,.71h0Zm2.83-1.41c.39,0,.71,.32,.71,.71s-.32,.71-.71,.71-.71-.32-.71-.71c0-.39,.32-.71,.71-.71Z"/>
    <path  stroke={ColorHelper.BgBlue}  class="cls-1" d="M32.19,33.6h9.19c.39,0,.71-.32,.71-.71v-2.83c0-.39-.32-.71-.71-.71h-9.19c-.39,0-.71,.32-.71,.71v2.83c0,.39,.32,.71,.71,.71Zm.71-2.83h7.77v1.41h-7.77v-1.41Z"/>
  </svg>
  );
};

export default Sounds2;