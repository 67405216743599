import React from "react";
import ColorHelper from "../../Helpers/ColorHelper";

const HighlightIconV3_4 = () => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.9997 38.333C25.0134 38.333 27.0074 37.9364 28.8679 37.1658C30.7283 36.3951 32.4187 35.2656 33.8427 33.8417C35.2666 32.4178 36.3961 30.7273 37.1667 28.8669C37.9373 27.0065 38.334 25.0124 38.334 22.9987C38.334 20.985 37.9373 18.991 37.1667 17.1305C36.3961 15.2701 35.2666 13.5797 33.8427 12.1557C32.4187 10.7318 30.7283 9.6023 28.8679 8.83168C27.0074 8.06106 25.0134 7.66442 22.9997 7.66442C18.9328 7.66442 15.0324 9.28 12.1567 12.1557C9.28096 15.0315 7.66538 18.9318 7.66538 22.9987C7.66538 27.0656 9.28096 30.966 12.1567 33.8417C15.0324 36.7174 18.9328 38.333 22.9997 38.333ZM22.9997 42.1663C12.4139 42.1663 3.83301 33.5854 3.83301 22.9997C3.83301 12.4139 12.4139 3.83301 22.9997 3.83301C33.5854 3.83301 42.1663 12.4139 42.1663 22.9997C42.1663 33.5854 33.5854 42.1663 22.9997 42.1663ZM22.9997 26.833C24.0163 26.833 24.9914 26.4291 25.7102 25.7102C26.4291 24.9914 26.833 24.0163 26.833 22.9997C26.833 21.983 26.4291 21.008 25.7102 20.2891C24.9914 19.5702 24.0163 19.1663 22.9997 19.1663C21.983 19.1663 21.008 19.5702 20.2891 20.2891C19.5702 21.008 19.1663 21.983 19.1663 22.9997C19.1663 24.0163 19.5702 24.9914 20.2891 25.7102C21.008 26.4291 21.983 26.833 22.9997 26.833ZM22.9997 30.6663C20.9663 30.6663 19.0163 29.8586 17.5785 28.4208C16.1407 26.983 15.333 25.033 15.333 22.9997C15.333 20.9663 16.1407 19.0163 17.5785 17.5785C19.0163 16.1407 20.9663 15.333 22.9997 15.333C25.033 15.333 26.983 16.1407 28.4208 17.5785C29.8586 19.0163 30.6663 20.9663 30.6663 22.9997C30.6663 25.033 29.8586 26.983 28.4208 28.4208C26.983 29.8586 25.033 30.6663 22.9997 30.6663Z"
        fill={ColorHelper.BgBlue}
      />
    </svg>
  );
};

export default HighlightIconV3_4;
