import React from "react";

const NavbarIcon4 = (props) => {
  const { color, bgColor } = props;
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="24" fill={bgColor} />
      <path
        d="M33.21 27.89C32.5739 29.3945 31.5788 30.7202 30.3119 31.7513C29.045 32.7824 27.5448 33.4874 25.9425 33.8048C24.3401 34.1221 22.6845 34.0421 21.1202 33.5718C19.5559 33.1014 18.1307 32.2551 16.9691 31.1067C15.8075 29.9582 14.9449 28.5428 14.4567 26.9839C13.9685 25.4251 13.8696 23.7705 14.1686 22.1646C14.4677 20.5588 15.1555 19.0506 16.1721 17.772C17.1886 16.4934 18.5029 15.4833 20 14.83M34 24C34 22.6868 33.7414 21.3864 33.2388 20.1732C32.7363 18.9599 31.9997 17.8575 31.0711 16.9289C30.1425 16.0003 29.0401 15.2638 27.8269 14.7612C26.6136 14.2587 25.3133 14 24 14V24H34Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NavbarIcon4;
