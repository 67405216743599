import ColorHelper from "../../../Helpers/ColorHelper";
import FontHelper from "../../../Helpers/FontHelper";

const HighlightSectionOfServicesStyle = {
    main: {
        p: 1,
        textAlign: "center",
        pb: 6,
    },
    headings: {
        my: 3,
        backgroundcolor: "blue",
        pt: {xs:0, md: 12},
    },
    cardContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        maxWidth: '100%',
        maxheight: "50%",
        px: { xs: 4, md: 4, lg: 10 },

    },
    headlinetxt: {
        color: ColorHelper.white,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: { xs: 14, md: 14 },
        maxWidth: "100%",
        transform: { xs: `translateY(10%)`, md: `translateY(-50%)` },
      

    },
    title: {
        fontSize: 14,
        fontFamily: FontHelper.FontSemiBold,
    },


    card: {
        display: 'flex',
        justifyContent: { xs: 'center', md: 'center' },
        alignItems: 'center',
        flexDirection: 'column',
        alignSelf: 'stretch',
        p: 4,
        border: `2px solid ${ColorHelper.blue}`,
        borderRadius: 2,
        // width: {xs: '100%', md: '30%'},
        // minWidth: {xs: '100%', md: '30%'},
        aspectRatio: '1/1',

        mx: { xs: 0, md: 2 },
        my: { xs: 2, md: 2 },

        '&:hover': {
            //borderRadius: 9,
            backgroundcolor: "blue"
        },

    },

    cardTitle: {
        fontFamily: FontHelper.FontSemiBold,
        fontSize: 18,
        fontWeight: 800,
        color: ColorHelper.PriamryColor,
        mb: 1.5,
    },
    cardDesc: {
        fontSize: 15,
        color: ColorHelper.grey,
border: `2px solid ${ColorHelper.blue}`,
        margin:0,
    }
}

export default HighlightSectionOfServicesStyle;