import * as React from "react";
import ColorHelper from "../../Helpers/ColorHelper";

const ArrowDropDownCircle = ({ size = 46, color, ...props }) => (
  <svg
    width={size}
    height={size}
    fill={color}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M2 12C2 6.48 6.48 2 12 2s10 4.48 10 10-4.48 10-10 10S2 17.52 2 12Zm6-1 4 4 4-4H8Z"
      clipRule="evenodd"
    />
  </svg>
);

export default ArrowDropDownCircle;
