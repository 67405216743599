import React from "react";

const HLTvLiteMOOC02 = () => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3085_81664)">
        <path d="M0.0673828 0H0V0.0673828H0.0673828V0Z" fill="white" />
        <path
          d="M12.7129 14.5996H5.02011C4.13308 14.5996 3.41406 15.2211 3.41406 15.9878V35.623H42.4961V15.9878C42.4961 15.2211 41.7771 14.5996 40.89 14.5996H33.4668"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12.7129 16.8457H6.01263V33.377H9.74807"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12.4434 33.377H39.8975V16.8457H33.377"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M43.2602 39.7559H2.73978C1.59877 39.7559 0.673828 38.8309 0.673828 37.6899V36.2538C0.673828 35.9054 0.956209 35.623 1.30453 35.623H44.6956C45.0439 35.623 45.3262 35.9054 45.3262 36.2538V37.6899C45.3262 38.8309 44.4012 39.7559 43.2602 39.7559Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M29.873 20.0801H32.1759C32.8392 20.0801 33.377 19.5424 33.377 18.879V5.28892C33.377 4.62561 32.8392 4.08789 32.1759 4.08789H13.9139C13.2506 4.08789 12.7129 4.62561 12.7129 5.28892V18.879C12.7129 19.5424 13.2506 20.0801 13.9139 20.0801H27.1777"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12.7129 7.43311H33.377"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M19.4961 16.2507V10.972C19.4961 10.5542 19.9359 10.2823 20.3096 10.4692L25.5882 13.1085C26.0025 13.3157 26.0025 13.9069 25.5882 14.1141L20.3096 16.7534C19.9359 16.9403 19.4961 16.6684 19.4961 16.2507Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15.6779 7.43311L17.1847 4.08795"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M19.0021 7.43311L20.5089 4.08795"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M22.3263 7.43311L23.8332 4.08795"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M25.6505 7.43311L27.1574 4.08795"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M28.9747 7.43311L30.4816 4.08795"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13.459 25.3809H9.4512C9.21824 25.3809 9.0293 25.1919 9.0293 24.959V23.1972C9.0293 22.9643 9.21824 22.7754 9.4512 22.7754H13.459C13.692 22.7754 13.8809 22.9643 13.8809 23.1972V24.959C13.8809 25.1919 13.692 25.3809 13.459 25.3809Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13.459 30.2324H9.4512C9.21824 30.2324 9.0293 30.0435 9.0293 29.8106V28.0488C9.0293 27.8159 9.21824 27.627 9.4512 27.627H13.459C13.692 27.627 13.8809 27.8159 13.8809 28.0488V29.8106C13.8809 30.0435 13.692 30.2324 13.459 30.2324Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M16.7559 22.8652H18.6426"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M20.6191 22.8652H34.9941"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M34.9941 25.3809H31.4004"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M29.4238 25.3809H16.7559"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M16.7559 27.7168H18.6426"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M20.6191 27.7168H34.9941"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M34.9941 30.2324H31.4004"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M29.4238 30.2324H16.7559"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M29.6934 35.623H16.5762V36.7841C16.5762 37.2841 16.9815 37.6895 17.4815 37.6895H28.788C29.288 37.6895 29.6934 37.2841 29.6934 36.7841V35.623Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3085_81664">
          <rect width="46" height="46" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HLTvLiteMOOC02;
