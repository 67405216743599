import React from "react";

const HLTvSMS02 = () => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3086_82743)">
        <path
          d="M14.9142 34.2306C14.9142 34.9712 14.307 35.5783 13.5665 35.5783H2.02148C1.28099 35.5783 0.673828 34.9719 0.673828 34.2306V19.1816M0.673828 17.8339V10.4219H14.9142"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="22.9256"
        />
        <path
          d="M0.673828 14.4648H14.9142"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="22.9256"
        />
        <path
          d="M31.0858 10.4219H45.3261V34.2306C45.3261 34.9712 44.719 35.5783 43.9785 35.5783H40.2275M38.8799 35.5783H37.5322M36.1845 35.5783H32.4335C31.693 35.5783 31.0858 34.9719 31.0858 34.2306"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="22.9256"
        />
        <path
          d="M31.0858 14.4648H45.3262"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="22.9256"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14.9142 7.72656H31.0858V36.9259C31.0858 37.6669 30.4792 38.2735 29.7381 38.2735H16.2618C15.5208 38.2735 14.9142 37.6671 14.9142 36.9259V7.72656Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="22.9256"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.7168 32.8831H10.8713C11.6125 32.8831 12.2189 32.2766 12.2189 31.5354C12.2189 30.7942 11.6124 30.1877 10.8713 30.1877H4.7168C3.97568 30.1877 3.36914 30.7942 3.36914 31.5354C3.36914 32.2766 3.97559 32.8831 4.7168 32.8831Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="22.9256"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M35.1288 32.8831H41.2833C42.0245 32.8831 42.6309 32.2766 42.6309 31.5354C42.6309 30.7942 42.0244 30.1877 41.2833 30.1877H35.1288C34.3877 30.1877 33.7811 30.7942 33.7811 31.5354C33.7811 32.2766 34.3876 32.8831 35.1288 32.8831Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="22.9256"
        />
        <path
          d="M14.9142 11.7695H31.0858"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="22.9256"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M18.9571 35.5784H27.0429C27.7842 35.5784 28.3906 34.9719 28.3906 34.2307C28.3906 33.4895 27.7838 32.8831 27.0429 32.8831H18.9571C18.2162 32.8831 17.6094 33.4895 17.6094 34.2307C17.6095 34.9719 18.2159 35.5784 18.9571 35.5784Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="22.9256"
        />
        <path
          d="M20.4214 25.2052C20.755 25.7571 21.3611 26.1281 22.0496 26.1281H23.9505C24.996 26.1281 25.8514 25.2727 25.8514 24.2273C25.8514 23.1818 24.9959 22.3264 23.9505 22.3264H22.0495C21.0041 22.3264 20.1486 21.471 20.1486 20.4255C20.1486 19.38 21.004 18.5247 22.0495 18.5247H23.9504C24.6389 18.5247 25.2449 18.8957 25.5785 19.4476"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="22.9256"
        />
        <path
          d="M23.0001 18.5247V16.5032M23.0001 28.1496V26.1281"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="22.9256"
        />
        <path
          d="M28.3906 15.1387V13.791M28.3906 17.8341V16.4864"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="22.9256"
        />
      </g>
      <defs>
        <clipPath id="clip0_3086_82743">
          <rect width="46" height="46" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HLTvSMS02;
