import ColorHelper from "../../Helpers/ColorHelper";
import FontHelper from "../../Helpers/FontHelper";


const PartnerSectionStyle = {
    main: {
        p: 1,
        textAlign: "center",
    },
    headings: {
        my: 3,
    },
    cardContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
        maxWidth: '100%',
        // px: {xs: 0, md: 0, lg: 10},
    },
    card: {
        alignSelf: 'stretch',
        p: 4,
        border: `2px solid ${ColorHelper.blue}`,
        borderRadius: 2,
        // width: {xs: '100%', md: '30%'},
        // minWidth: {xs: '100%', md: '30%'},
        mx: { xs: 0, md: 2 },
        my: { xs: 2, md: 2 },

    },
    cardTitle: {
        fontFamily: FontHelper.FontSemiBold,
        fontSize: 18,
        fontWeight: 800,
        color: ColorHelper.grey1,
        mb: 1.5,
    },
    cardDesc: {
        fontSize: 15,
        color: ColorHelper.grey,
    },
    LogoContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        px: 5,
        pb: 2,
    },
    PartnerLogoWrapper: {
        border: `1px solid ${ColorHelper.grey2}`,
        borderRadius: '3px',
        width: { xs: '100%', md: '40%', lg: '13%' },
        minHeight: '100px',
        // aspectRatio: '2/1',
        mx: 2,
        my: 2,
        backgroundColor: ColorHelper.white,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }
}

export default PartnerSectionStyle;