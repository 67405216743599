import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import ColorHelper from '../../../Helpers/ColorHelper';
import ServicesListingStyle from './ServicesListingStyle';

const ServicesListing = (props) => {
  const { ListData, iconColor, space } = props;
  return (
    <Box >
      <Box sx={{ ...ServicesListingStyle.ServicesListingContainer }}>
        {ListData.map((Streamitem) => (
          <Box sx={{ ...ServicesListingStyle.singleItemWrapper, ...ServicesListingStyle.card }} key={Streamitem.id}>
            <Streamitem.icon color={iconColor} />
            <Typography
              className="shortdesc"
              sx={ServicesListingStyle.Itemtitle}
              variant="h6"
              component="h2"
            >
              {Streamitem.title}
            </Typography>
            <Typography
              sx={ServicesListingStyle.ItemtDesc}
              variant="h6"
              component="h2"
            >
              {Streamitem.desc}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>

  )
}

export default ServicesListing