import * as React from "react";
import ColorHelper from "../../Helpers/ColorHelper";

const OTT = ({ size = 46, color, ...props }) => (
  
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="48" height="48" rx="24" fill="#FFF3F5"/>
    <path d="M17.998 19L20.308 15H23.998L21.688 19H17.998V19ZM23.998 19L26.308 15H29.998L27.688 19H23.998ZM29.998 19L32.308 15H33.008C33.556 15 34 15.445 34 15.993V32.007C33.9982 32.2696 33.8931 32.521 33.7075 32.7068C33.5219 32.8926 33.2706 32.9979 33.008 33H14.992C14.7288 32.9997 14.4765 32.895 14.2905 32.7088C14.1045 32.5226 14 32.2702 14 32.007V15.993C14.0018 15.7304 14.1069 15.479 14.2925 15.2932C14.4781 15.1074 14.7294 15.0021 14.992 15H17.998L16 18.46V31H32V19H29.998Z" fill="#DA1A5D"/>
    </svg>
    

);

export default OTT;