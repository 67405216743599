import React from "react";
import {
  Route,
  Routes,
  BrowserRouter,
  Redirect,
  Navigate,
  useLocation,
} from "react-router-dom";
import { Helmet } from "react-helmet-async";
import "./App.css";
// import Home from "./Pages/Home";
// import ProductsPage from "./Pages/ProductsPage";
// import SolutionPage from "./Pages/SolutionPage";
// import ServicePage from "./Pages/ServicePage";
// import Contact from "./Pages/Contact";
import Partners from "./Pages/Partners";
// import About from "./Pages/About";
// import TeamLeaderShip from "./Pages/TeamLeaderShip";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
// import OTTPage from "./Pages/OTTPage";
// import Carrier from "./Pages/Carrier";
import PageNotFound from "./Pages/PageNotFound";
import Loader from "./Components/Ui-Components/Loader/Loader";
import CarrierDetails from "./Components/Carrier/CarrierDetails";
// import Blogs from "./Pages/Blogs";
import BlogDetailsContainer from "./Components/Blogs/BlogDetailsContainer";
import CaseStudiesContainer from ".//Components/CaseStudies/CaseStudiesDetailsContainer"

// Aamir

const Home = React.lazy(() => import("./Pages/Home"));
const ProductsPage = React.lazy(() => import("./Pages/ProductsPage"));
const About = React.lazy(() => import("./Pages/About"));
const SolutionPage = React.lazy(() => import("./Pages/SolutionPage"));
const ServicePage = React.lazy(() => import("./Pages/ServicePage"));
const Contact = React.lazy(() => import("./Pages/Contact"));
const TeamLeaderShip = React.lazy(() => import("./Pages/TeamLeaderShip"));
const OTTPage = React.lazy(() => import("./Pages/OTTPage"));
const Carrier = React.lazy(() => import("./Pages/Carrier"));
const TermsConditions = React.lazy(()=>import("./Pages/TermConditions"))

const Blogs = React.lazy(() => import("./Pages/Blogs"));
const CaseStudies = React.lazy(()=> import("./Pages/CaseStudies"))

// Tabrez
function App() {

  const search = useLocation();
  if (search?.search?.slice(0, 1) === "?" ) {
    Navigate("/");
  }
  const LowercaseRedirect=({ path }) =>{
    const lowercasePath = path.toLowerCase();
    return <Navigate replace to={lowercasePath} />;
  }
  return (
    <>
      <Helmet>
        <title>Best OTT Platform | Video on Demand | Build Your Own OTT</title>
        <meta
          name="description"
          content="Mobiotics is one of the best OTT platform solution providers. Build your own OTT platform & video-on-demand solution with a complete OTT SaaS suite & support."
        />
        <link rel="canonical" href="https://www.mobiotics.com" />
        <meta
          name="keywords"
          content="Best OTT Platform, Video on Demand, Build Your Own OTT"
        />
      </Helmet>

      <React.Suspense fallback={<Loader />}>
        <Routes>
        <Route path="https://www.mobiotics.com/tvbuddy2/website/index.php" element={<Navigate replace to="https://www.mobiotics.com/" />} />
        <Route path="https://www.mobiotics.com/index" element={<Navigate replace to="https://www.mobiotics.com/" />} />
        <Route path="https://www.mobiotics.com/?ref=cybrhome" element={<Navigate replace to="https://www.mobiotics.com/" />} />
        <Route path="https://www.mobiotics.com/_1" element={<Navigate replace to="https://www.mobiotics.com/" />} />
        <Route path="https://www.mobiotics.com/company/career/Technical-Writer" element={<Navigate replace to="https://www.mobiotics.com/company/career" />} />
        <Route path="https://www.mobiotics.com/?source=post_page---------------------------" element={<Navigate replace to="https://www.mobiotics.com/" />} />
        <Route path="https://www.mobiotics.com/team.php" element={<Navigate replace to="https://www.mobiotics.com/company/leadership" />} />
        <Route path="https://www.mobiotics.com/?ref=producthunt" element={<Navigate replace to="https://www.mobiotics.com/" />} />
        <Route path="https://www.mobiotics.com/solutions/digitalization-audio-video" element={<Navigate replace to="https://www.mobiotics.com/solutions/digitization" />} />
        <Route path="https://www.mobiotics.com/products" element={<Navigate replace to="https://www.mobiotics.com/" />} />
        <Route path="https://www.mobiotics.com/?ref=briolink" element={<Navigate replace to="https://www.mobiotics.com/" />} />
        <Route path="https://www.mobiotics.com/index.php" element={<Navigate replace to="https://www.mobiotics.com/" />} />
        <Route path="https://www.mobiotics.com/Company/career" element={<Navigate replace to="https://www.mobiotics.com/company/career" />} />
        <Route path="https://www.mobiotics.com/Company/about" element={<Navigate replace to="https://www.mobiotics.com/company/about" />} />
        <Route path="https://www.mobiotics.com/Company/contact" element={<Navigate replace to="https://www.mobiotics.com/company/contact" />} />
        <Route path="https://www.mobiotics.com/Company/leadership" element={<Navigate replace to="https://www.mobiotics.com/company/leadership" />} />
        <Route path="https://www.mobiotics.com/Company/partners" element={<Navigate replace to="https://www.mobiotics.com/company/partners" />} />
        <Route path="https://www.mobiotics.com/Products/sounds" element={<Navigate replace to="https://www.mobiotics.com/products/sounds" />} />
        <Route path="https://www.mobiotics.com/Products/vApps" element={<Navigate replace to="https://www.mobiotics.com/products/vApps" />} />
        <Route path="https://www.mobiotics.com/Products/vCMS" element={<Navigate replace to="https://www.mobiotics.com/products/vCMS" />} />
        {/* <Route path="/Products/vDRM" element={<Navigate replace to="/products/vDRM" />} /> */}
        {/* <Route path="/Products/vDRM" element={<LowercaseRedirect path="/products/vDRM" />} /> */}
        <Route path="http://3.144.118.25:8088/Products/vDRM" element={<Navigate replace to="http://3.144.118.25:8088/products/vDRM" />} />
        <Route path="https://www.mobiotics.com/Products/vlite-mooc" element={<Navigate replace to="https://www.mobiotics.com/products/vlite-mooc" />} />
        <Route path="https://www.mobiotics.com/Products/vLive" element={<Navigate replace to="https://www.mobiotics.com/products/vLive" />} />
        <Route path="https://www.mobiotics.com/Products/vSMS" element={<Navigate replace to="https://www.mobiotics.com/products/vSMS" />} />
        <Route path="https://www.mobiotics.com/Resources/blogs" element={<Navigate replace to="https://www.mobiotics.com/resources/blogs" />} />
        <Route path="https://www.mobiotics.com/Services/dev-ops" element={<Navigate replace to="https://www.mobiotics.com/services/dev-ops" />} />
        <Route path="https://www.mobiotics.com/Services/ott-consulting" element={<Navigate replace to="https://www.mobiotics.com/services/ott-consulting" />} />
        <Route path="https://www.mobiotics.com/Services/ott-apps" element={<Navigate replace to="https://www.mobiotics.com/services/ott-apps" />} />
        <Route path="https://www.mobiotics.com/Services/custom-development" element={<Navigate replace to="https://www.mobiotics.com/services/custom-development" />} />
        <Route path="https://www.mobiotics.com/Services/qa-automation" element={<Navigate replace to="https://www.mobiotics.com/services/qa-automation" />} />
        <Route path="https://www.mobiotics.com/Solutions/broadcast" element={<Navigate replace to="https://www.mobiotics.com/solutions/broadcast" />} />
        <Route path="https://www.mobiotics.com/Solutions/digitization" element={<Navigate replace to="https://www.mobiotics.com/solutions/digitization" />} />
        <Route path="https://www.mobiotics.com/Solutions/OTT" element={<Navigate replace to="https://www.mobiotics.com/solutions/OTT" />} />
        <Route path="https://www.mobiotics.com/Solutions/ott" element={<Navigate replace to="https://www.mobiotics.com/solutions/OTT" />} />
        <Route path="https://www.mobiotics.com/resources/blogs/undefined" element={<Navigate replace to="https://www.mobiotics.com/resources/blogs" />} />
        <Route path="https://www.mobiotics.com/services/ott-app-development" element={<Navigate replace to="https://www.mobiotics.com/services/ott-apps" />} />
        <Route path="https://www.mobiotics.com/company/career/Android-Developer" element={<Navigate replace to="https://www.mobiotics.com/company/career" />} />
        <Route path="https://www.mobiotics.com/company/career/DevOps-Engineer" element={<Navigate replace to="https://www.mobiotics.com/company/career" />} />
        <Route path="https://www.mobiotics.com/company/career/Flutter-Developer" element={<Navigate replace to="https://www.mobiotics.com/company/career" />} />
        <Route path="https://www.mobiotics.com/company/career/IOS-Developer" element={<Navigate replace to="https://www.mobiotics.com/company/career" />} />
        <Route path="https://www.mobiotics.com/company/career/Lead-Backend-Developer" element={<Navigate replace to="https://www.mobiotics.com/company/career" />} />
        <Route path="https://www.mobiotics.com/company/career/Lead-DevOps-Engineer" element={<Navigate replace to="https://www.mobiotics.com/company/career" />} />
        <Route path="https://www.mobiotics.com/company/career/Lead-QA-Engineer" element={<Navigate replace to="https://www.mobiotics.com/company/career" />} />
        <Route path="https://www.mobiotics.com/Resources/blogs/5-steps-to-take-before-you-launch-your-ott-platform" element={<Navigate replace to="https://www.mobiotics.com/resources/blogs/5-steps-to-take-before-you-launch-your-ott-platform" />} />
        <Route path="https://www.mobiotics.com/Resources/blogs/7-essential-ott-video-streaming-platforms-features-in-2023" element={<Navigate replace to="https://www.mobiotics.com/resources/blogs/7-essential-ott-video-streaming-platforms-features-in-2023" />} />
        <Route path="https://www.mobiotics.com/Resources/blogs/7-reasons-to-adopt-digitization-today" element={<Navigate replace to="https://www.mobiotics.com/resources/blogs/7-reasons-to-adopt-digitization-today" />} />
        <Route path="https://www.mobiotics.com/Resources/blogs/8-marketing-strategy-for-ott-video-platforms" element={<Navigate replace to="https://www.mobiotics.com/resources/blogs/8-marketing-strategy-for-ott-video-platforms" />} />
        <Route path="https://www.mobiotics.com/Resources/blogs/benefits-of-a-cloud-streaming-platform" element={<Navigate replace to="https://www.mobiotics.com/resources/blogs/benefits-of-a-cloud-streaming-platform" />} />
        <Route path="https://www.mobiotics.com/Resources/blogs/best-10-ott-platforms-around-the-world" element={<Navigate replace to="https://www.mobiotics.com/resources/blogs/best-10-ott-platforms-around-the-world" />} />
        <Route path="https://www.mobiotics.com/Resources/blogs/best-ott-platforms-built-for-ott-streaming-business" element={<Navigate replace to="https://www.mobiotics.com/resources/blogs/best-ott-platforms-built-for-ott-streaming-business" />} />
        <Route path="https://www.mobiotics.com/Resources/blogs/custom-ott-app-development-why-do-you-need-it" element={<Navigate replace to="https://www.mobiotics.com/resources/blogs/custom-ott-app-development-why-do-you-need-it" />} />
        <Route path="https://www.mobiotics.com/Resources/blogs/how-do-i-create-an-ott-app" element={<Navigate replace to="https://www.mobiotics.com/resources/blogs/how-do-i-create-an-ott-app" />} />
        <Route path="https://www.mobiotics.com/Resources/blogs/how-does-ott-subscription-work" element={<Navigate replace to="https://www.mobiotics.com/resources/blogs/how-does-ott-subscription-work" />} />
        <Route path="https://www.mobiotics.com/Resources/blogs/how-does-white-label-streaming-work" element={<Navigate replace to="https://www.mobiotics.com/resources/blogs/how-does-white-label-streaming-work" />} />
        <Route path="https://www.mobiotics.com/Resources/blogs/how-to-manage-your-content-archive-storage-requirements" element={<Navigate replace to="https://www.mobiotics.com/resources/blogs/how-to-manage-your-content-archive-storage-requirements" />} />
        <Route path="https://www.mobiotics.com/Resources/blogs/importance-of-e-learning-platforms-in-remote-education" element={<Navigate replace to="https://www.mobiotics.com/resources/blogs/importance-of-e-learning-platforms-in-remote-education" />} />
        <Route path="https://www.mobiotics.com/Resources/blogs/the-3-key-challenges-in-ott-content-delivery" element={<Navigate replace to="https://www.mobiotics.com/resources/blogs/the-3-key-challenges-in-ott-content-delivery" />} />
        <Route path="https://www.mobiotics.com/Resources/blogs/the-dos-and-don'ts-of-launching-an-ott-video-platform" element={<Navigate replace to="https://www.mobiotics.com/resources/blogs/the-dos-and-don'ts-of-launching-an-ott-video-platform" />} />
        <Route path="https://www.mobiotics.com/Resources/blogs/the-increasing-importance-of-programmatic-ott-advertising" element={<Navigate replace to="https://www.mobiotics.com/resources/blogs/the-increasing-importance-of-programmatic-ott-advertising" />} />
        <Route path="https://www.mobiotics.com/Resources/blogs/tips-for-a-successful-ott-platform-launch" element={<Navigate replace to="https://www.mobiotics.com/resources/blogs/tips-for-a-successful-ott-platform-launch" />} />
        <Route path="https://www.mobiotics.com/Resources/blogs/top-benefits-of-investing-in-ott-app-development-in-2023" element={<Navigate replace to="https://www.mobiotics.com/resources/blogs/top-benefits-of-investing-in-ott-app-development-in-2023" />} />
        <Route path="https://www.mobiotics.com/Resources/blogs/video-on-demand-definition-types-and-examples" element={<Navigate replace to="https://www.mobiotics.com/resources/blogs/video-on-demand-definition-types-and-examples" />} />
        <Route path="https://www.mobiotics.com/Resources/blogs/what-does-ott-in-broadcasting-mean" element={<Navigate replace to="https://www.mobiotics.com/resources/blogs/what-does-ott-in-broadcasting-mean" />} />
        <Route path="https://www.mobiotics.com/Resources/blogs/what-is-a-white-label-ott-platform" element={<Navigate replace to="https://www.mobiotics.com/resources/blogs/what-is-a-white-label-ott-platform" />} />
        <Route path="https://www.mobiotics.com/Resources/blogs/what-is-ott-advertising-benefits-and-challenges-of-ott" element={<Navigate replace to="https://www.mobiotics.com/resources/blogs/what-is-ott-advertising-benefits-and-challenges-of-ott" />} />
        <Route path="https://www.mobiotics.com/Resources/blogs/what-is-ott-streaming-and-how-does-an-ott-platform-work" element={<Navigate replace to="https://www.mobiotics.com/resources/blogs/what-is-ott-streaming-and-how-does-an-ott-platform-work" />} />
        <Route path="https://www.mobiotics.com/Resources/blogs/what-is-video-cms-and-how-does-it-work" element={<Navigate replace to="https://www.mobiotics.com/resources/blogs/what-is-video-cms-and-how-does-it-work" />} />
        <Route path="https://www.mobiotics.com/Resources/blogs/what-is-video-on-demand-streaming-and-how-does-it-work" element={<Navigate replace to="https://www.mobiotics.com/resources/blogs/what-is-video-on-demand-streaming-and-how-does-it-work" />} />
        <Route path="https://www.mobiotics.com/Resources/blogs/what-to-look-for-when-choosing-a-vod-streaming-platform-for-your-business" element={<Navigate replace to="https://www.mobiotics.com/resources/blogs/what-to-look-for-when-choosing-a-vod-streaming-platform-for-your-business" />} />
          {/* dummy urls for production */}
          {/* <Route exact path="*" element={<PageNotFound />} /> */}
          <Route exact path="*" element={<Navigate to="/" />} />
          <Route
            path="/company/career/Technical-Writer"
            element={<Navigate to="/company/career" />}
          />
          <Route path="/team.php" element={<Navigate to="/company/leadership" />} />
          <Route
            path="/solutions/digitalization-audio-video"
            element={<Navigate to="/solutions/digitization" />}
          />
          <Route exact path="/" element={<Home />}></Route>
          <Route exact path="products/:id" element={<ProductsPage />}></Route>
          <Route exact path="solutions/:id" element={<SolutionPage />}></Route>
          <Route exact path="services/:id" element={<ServicePage />}></Route>
          <Route exact path="OTT/:id" element={<OTTPage />}></Route>
          {/* <Route exact path="contact" element={<Contact />}></Route> */}
          <Route exact path="company/about" element={<About />}></Route>
          <Route
            exact
            path="company/leadership"
            element={<TeamLeaderShip />}
          ></Route>
          <Route
            exact
            path="company/partners"
            element={<TeamLeaderShip />}
          ></Route>
          <Route exact path="company/contact" element={<Contact />}></Route>
          <Route exact path="company/career" element={<Carrier />}></Route>
          <Route
            exact
            path="company/career/:slug"
            element={<CarrierDetails />}
          ></Route>
          <Route exact path="resources/blogs" element={<Blogs />}></Route>
          <Route exact path="company/privacy-policy" element={<TermsConditions />}></Route>
          <Route exact path="resources/case-studies" element={<CaseStudies />}></Route>
          <Route
            exact
            path="resources/blogs/:slug"
            element={<BlogDetailsContainer />}
          ></Route>
           <Route
            exact
            path="resources/case-studies/:slug"
            element={<CaseStudiesContainer />}
          ></Route>
        </Routes>
        {/* <TawkMessengerReact
                propertyId="638ddb39daff0e1306dafbeb"
                widgetId="1gjh20nab"/> */}
      </React.Suspense>
    </>
  );
}

export default App;
