import React from "react";

const NavbarIcon3 = (props) => {
  const { color, bgColor } = props;
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="24" fill={bgColor} />
      <path
        d="M20 33H28M24 29V33M16 15H32C33.1046 15 34 15.8954 34 17V27C34 28.1046 33.1046 29 32 29H16C14.8954 29 14 28.1046 14 27V17C14 15.8954 14.8954 15 16 15Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NavbarIcon3;
