import * as React from "react";
import ColorHelper from "../../Helpers/ColorHelper";

const Contact = ({ size = 46, color, ...props }) => (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="48" height="48" rx="24" fill="#FFF3F5"/>
    <path d="M14 20.994C13.9995 20.2062 14.1544 19.426 14.4558 18.6982C14.7572 17.9703 15.1992 17.3091 15.7565 16.7523C16.3139 16.1955 16.9756 15.7542 17.7037 15.4535C18.4319 15.1528 19.2122 14.9987 20 15H28C31.313 15 34 17.695 34 20.994V33H20C16.687 33 14 30.305 14 27.006V20.994ZM32 31V20.994C31.9974 19.9345 31.5749 18.9192 30.8251 18.1706C30.0754 17.422 29.0595 17.0011 28 17H20C19.4748 16.9987 18.9546 17.1011 18.4691 17.3012C17.9836 17.5014 17.5424 17.7955 17.1708 18.1665C16.7991 18.5376 16.5044 18.9784 16.3035 19.4636C16.1026 19.9488 15.9995 20.4689 16 20.994V27.006C16.0026 28.0655 16.4251 29.0808 17.1749 29.8294C17.9246 30.578 18.9405 30.999 20 31H32ZM26 23H28V25H26V23ZM20 23H22V25H20V23Z" fill="#DA1A5D"/>
    </svg>
    

);

export default Contact;