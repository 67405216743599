const ButtunGroup = {
  buttonGroup: {
    minWidth: "auto",
    pl: { xs: "25px", md: "110px" },
    pr: { xs: "0px", md: "80px" },
    mr: { xs: "-5px", md: 0 },
    display: { xs: "none", md: "flex" },
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    bottom: { xs: '30%', md: '14%' },
    left: { xs: '28%', md: '0.3%' },
  },
  BtnIconContainer: {
    bg: "transparent",
    border: "0px solid",
    fontSize: 40,
    cursor: "pointer",
    px: "2px",
    mx: 0.5,
    color: "#BBC7D7",
    transition: "all 0.25s",
    "&:hover": {
      color: "text",
    },
    "&:focus": {
      outline: 0,
    },
    svg: {
      width: { xs: "30px", md: "auto" },
    },
  },
}

export default ButtunGroup;