import ColorHelper from "../../../Helpers/ColorHelper";
import FontHelper from "../../../Helpers/FontHelper";

const HighlightSectionOfServicesStyle = {
  main: {
    p: 1,
    mt: 0,
    textAlign: "center",
    backgroundColor: "#F5F8FF",
    pb: 0,
    mt: { xs: 0, md: -3 },
  },
  mainLeader: {
    p: 1,
    textAlign: "center",
    pb: 6,
  },

  headings: {
    mt: 5,
  },
  cardContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    maxWidth: "100%",
    maxheight: "50%",
    px: { xs: 4, md: 4, lg: 10 },
  },
  cardContainerLeader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    maxWidth: "100%",
    maxheight: "50%",
    px: { xs: 4, md: 0, lg: 10 },
  },
  headlinetxt: {
    color: ColorHelper.white,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: { xs: 14, md: 14 },
    maxWidth: "100%",
    // transform: { xs: `translateY(10%)`, md: `translateY(-50%)` },
    // mt:-0.5,
  },
  title: {
    fontSize: 14,
    fontFamily: FontHelper.FontSemiBold,
  },

  card: {
    display: "flex",
    justifyContent: { xs: "center", md: "flex-start" },
    alignItems: "center",
    flexDirection: "column",

    alignSelf: "stretch",
    p: 5,
    border: `2px solid ${ColorHelper.blue}`,
    borderRadius: 2,
    // width: {xs: '100%', md: '30%'},
    // minWidth: {xs: '100%', md: '30%'},
    aspectRatio: { xs: "1/1", md: "unset", lg: "1/1" },

    mx: { xs: 0, md: 2 },
    my: { xs: 2, md: 2 },

    "&:hover": {
      //borderRadius: 9,
      // backgroundColor: ColorHelper.PriamryColor,
    },
  },

  cardTitle: {
    fontFamily: FontHelper.FontSemiBold,
    fontSize: 18,
    fontWeight: 800,
    color: ColorHelper.PriamryColor,
    mb: 1.5,
    mt: 2,
  },
  cardDesc: {
    fontSize: 15,
    color: ColorHelper.grey,
    margin: 0,
  },

  highlightwrap: {
    width: "auto",
    height: 200,
    display: "flex",
    justifyContent: { xs: "center", md: "center" },
    alignItems: "center",
    flexDirection: "column",
  },
};

export default HighlightSectionOfServicesStyle;
