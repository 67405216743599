import CircularArrowBtn from "../Assets/Icons/CircularArrowBtn";
import CircularArrowBtnDisabled from "../Assets/Icons/CircularArrowBtnDisabled";
import Whatsapp from "../Assets/Icons/Whatsapp";
import Mail from "../Assets/Icons/Mail";
import Plus from "../Assets/Icons/Plus";
import FbMessenger from "../Assets/Icons/FbMessenger";
import Headphones from "../Assets/Icons/Headphones";

// Vlive
import { ReactComponent as VliveIcons01 } from "../Assets/Icons/VliveIcons/VliveIcons01.svg";
import { ReactComponent as VLive02 } from "../Assets/Icons/VliveIcons/vLive02.svg";
import { ReactComponent as VLive03 } from "../Assets/Icons/VliveIcons/vLive03.svg";
import { ReactComponent as VLive04 } from "../Assets/Icons/VliveIcons/vLive04.svg";
import { ReactComponent as VLive05 } from "../Assets/Icons/VliveIcons/vLive05.svg";
import { ReactComponent as VLive06 } from "../Assets/Icons/VliveIcons/vLive06.svg";
import { ReactComponent as VLive07 } from "../Assets/Icons/VliveIcons/vLive07.svg";
import { ReactComponent as MS } from "../Assets/Icons/VliveIcons/MS.svg";

// Sounds
import { ReactComponent as Sounds01 } from "../Assets/Icons/SoundIcons/Sounds01.svg";
import { ReactComponent as Sounds02 } from "../Assets/Icons/SoundIcons/Sounds02.svg";
import { ReactComponent as Sounds03 } from "../Assets/Icons/SoundIcons/Sounds03.svg";
import { ReactComponent as Sounds04 } from "../Assets/Icons/SoundIcons/Sounds04.svg";
import { ReactComponent as Sounds05 } from "../Assets/Icons/SoundIcons/Sounds05.svg";
import { ReactComponent as Sounds06 } from "../Assets/Icons/SoundIcons/Sounds06.svg";

// vlite mooc

import { ReactComponent as vLiteMOOC01 } from "../Assets/Icons/vLiveMOOCIcons/vLite-MOOC01.svg";
import { ReactComponent as vLiteMOOC02 } from "../Assets/Icons/vLiveMOOCIcons/vLite-MOOC02.svg";
import { ReactComponent as vLiteMOOC03 } from "../Assets/Icons/vLiveMOOCIcons/vLite-MOOC03.svg";
import { ReactComponent as vLiteMOOC04 } from "../Assets/Icons/vLiveMOOCIcons/vLite-MOOC04.svg";
import { ReactComponent as vLiteMOOC05 } from "../Assets/Icons/vLiveMOOCIcons/vLite-MOOC05.svg";
import { ReactComponent as DRM } from "../Assets/Icons/vLiveMOOCIcons/DRM.svg";

// Vapps
import { ReactComponent as vApps01 } from "../Assets/Icons/vAppIcons/vApps01.svg";
import { ReactComponent as vApps02 } from "../Assets/Icons/vAppIcons/vApps02.svg";
import { ReactComponent as vApps03 } from "../Assets/Icons/vAppIcons/vApps03.svg";
import { ReactComponent as vApps04 } from "../Assets/Icons/vAppIcons/vApps04.svg";
import { ReactComponent as vApps05 } from "../Assets/Icons/vAppIcons/vApps05.svg";

// vDRM
import { ReactComponent as vDRM01 } from "../Assets/Icons/vDRMIcons/vDRM01.svg";
import { ReactComponent as vDRM02 } from "../Assets/Icons/vDRMIcons/vDRM02.svg";
import { ReactComponent as vDRM03 } from "../Assets/Icons/vDRMIcons/vDRM03.svg";
import { ReactComponent as vDRM04 } from "../Assets/Icons/vDRMIcons/vDRM04.svg";
import { ReactComponent as vDRM05 } from "../Assets/Icons/vDRMIcons/vDRM05.svg";

// vSMS
import { ReactComponent as vSMS01 } from "../Assets/Icons/vSMSIcons/vSMS01.svg";
import { ReactComponent as vSMS02 } from "../Assets/Icons/vSMSIcons/vSMS02.svg";
import { ReactComponent as vSMS03 } from "../Assets/Icons/vSMSIcons/vSMS03.svg";
import { ReactComponent as vSMS04 } from "../Assets/Icons/vSMSIcons/vSMS04.svg";
import { ReactComponent as vSMS05 } from "../Assets/Icons/vSMSIcons/vSMS05.svg";
import { ReactComponent as vSMS06 } from "../Assets/Icons/vSMSIcons/vSMS06.svg";

import StreamingTypeIcon1 from "../Assets/Icons/StreamingTypeIcon1";
import StreamingTypeIcon2 from "../Assets/Icons/StreamingTypeIcon2";
import StreamingTypeIcon3 from "../Assets/Icons/StreamingTypeIcon3";
import StreamingTypeIcon4 from "../Assets/Icons/StreamingTypeIcon4";
import StreamingTypeIcon5 from "../Assets/Icons/StreamingTypeIcon5";
import StreamingTypeIcon6 from "../Assets/Icons/StreamingTypeIcon6";
import StreamingTypeIcon7 from "../Assets/Icons/StreamingTypeIcon7";
import StreamingTypeIcon8 from "../Assets/Icons/StreamingTypeIcon8"

import { ReactComponent as vCMS01 } from "../Assets/Icons/vCMSFeatureIcons/vCMS01.svg";
import { ReactComponent as vCMS02 } from "../Assets/Icons/vCMSFeatureIcons/vCMS02.svg";
import { ReactComponent as vCMS03 } from "../Assets/Icons/vCMSFeatureIcons/vCMS03.svg";
import { ReactComponent as vCMS04 } from "../Assets/Icons/vCMSFeatureIcons/vCMS04.svg";
import { ReactComponent as vCMS05 } from "../Assets/Icons/vCMSFeatureIcons/vCMS05.svg";
import { ReactComponent as vCMS06 } from "../Assets/Icons/vCMSFeatureIcons/vCMS06.svg";


import Dribbble from "../Assets/Icons/SocialIcons/Dribbble.svg";
import Facebook from "../Assets/Icons/SocialIcons/Facebook.svg";
import GitHub from "../Assets/Icons/SocialIcons/GitHub.svg";
import LinkedIn from "../Assets/Icons/SocialIcons/LinkedIn.svg";
import Twitter from "../Assets/Icons/SocialIcons/Twitter.svg";
import Instagram from "../Assets/Icons/SocialIcons/Instagram.svg";
import Social_icon from "../Assets/Icons/SocialIcons/Social_icon.svg";
import arrowRight from "../Assets/Icons/arrowRight";
import ReviewNavBtn from "../Assets/Icons/ReviewNavBtn";
import PlayButton from "../Assets/Icons/PlayButton.png";
import NavbarIcon1 from "../Assets/Icons/NavbarIcons/NavbarIcon1";
import NavbarIcon2 from "../Assets/Icons/NavbarIcons/NavbarIcon2";
import NavbarIcon3 from "../Assets/Icons/NavbarIcons/NavbarIcon3";
import NavbarIcon4 from "../Assets/Icons/NavbarIcons/NavbarIcon4";
import NavbarIcon5 from "../Assets/Icons/NavbarIcons/NavbarIcon5";
import NavbarIcon6 from "../Assets/Icons/NavbarIcons/NavbarIcon6";
import NavbarIcon7 from "../Assets/Icons/NavbarIcons/NavbarIcon7";
import NavbarIcon8 from "../Assets/Icons/NavbarIcons/NavbarIcon8";
import ChevronDown from "../Assets/Icons/ChevronDown";
import ChevronUp from "../Assets/Icons/ChevronUp";
import close from "../Assets/Icons/close.svg";
import HighlightIcon1 from "../Assets/Icons/HighlightIcon1";
import HighlightIcon2 from "../Assets/Icons/HighlightIcon2";
import HighlightIcon3 from "../Assets/Icons/HighlightIcon3";
import HighlightIcon4 from "../Assets/Icons/HighlightIcon4";
import HighlightIcon5 from "../Assets/Icons/HighlightIcon5";
import HighlightIcon6 from "../Assets/Icons/HighlightIcon6";
import { ReactComponent as VCMSHighlight01 } from "../Assets/Icons/vCMSHighlightIcon/HLT-vCMS01.svg";
import { ReactComponent as VCMSHighlight02 } from "../Assets/Icons/vCMSHighlightIcon/HLT-vCMS02.svg";
import { ReactComponent as VCMSHighlight03 } from "../Assets/Icons/vCMSHighlightIcon/HLT-vCMS03.svg";
import { ReactComponent as VCMSHighlight04 } from "../Assets/Icons/vCMSHighlightIcon/HLT-vCMS04.svg";
import { ReactComponent as VCMSHighlight05 } from "../Assets/Icons/vCMSHighlightIcon/HLT-vCMS05.svg";


// OTT
import Otticon from "../Assets/Icons/Otticon";
import { ReactComponent as OTT01 } from "../Assets/Icons/OTTSolutionIcons/OTT01.svg";
import { ReactComponent as OTT02 } from "../Assets/Icons/OTTSolutionIcons/OTT02.svg";
import { ReactComponent as OTT03 } from "../Assets/Icons/OTTSolutionIcons/OTT03.svg";
import { ReactComponent as OTT04 } from "../Assets/Icons/OTTSolutionIcons/OTT04.svg";

// Broadcast
import { ReactComponent as Broadcast01 } from "../Assets/Icons/BroadcastSolutionIcons/Broadcast01.svg";
import { ReactComponent as Broadcast02 } from "../Assets/Icons/BroadcastSolutionIcons/Broadcast02.svg";
import { ReactComponent as Broadcast03 } from "../Assets/Icons/BroadcastSolutionIcons/Broadcast03.svg";
import { ReactComponent as Broadcast04 } from "../Assets/Icons/BroadcastSolutionIcons/Broadcast04.svg";

// digitization
import { ReactComponent as Digitization01 } from "../Assets/Icons/DigitizationSolutionsIcons/Digitization01.svg";
import { ReactComponent as Digitization02 } from "../Assets/Icons/DigitizationSolutionsIcons/Digitization02.svg";
import { ReactComponent as Digitization03 } from "../Assets/Icons/DigitizationSolutionsIcons/Digitization03.svg";
import { ReactComponent as Digitization04 } from "../Assets/Icons/DigitizationSolutionsIcons/Digitization04.svg";

//product section icons
import Vlive1 from "../Assets/Icons/Vlive1";
import Vlive2 from "../Assets/Icons/Vlive2";
import Vlive3 from "../Assets/Icons/Vlive3";
import Vlive4 from "../Assets/Icons/Vlive4";
import Vlive5 from "../Assets/Icons/Vlive5";
import Vlive6 from "../Assets/Icons/Vlive6";

//Nav section Icons
import BlogIcon from "../Assets/Icons/BlogIcon";
import About from "../Assets/Icons/About";
import Devops from "../Assets/Icons/Devops";
import LeaderShip from "../Assets/Icons/LeaderShip";
import OTTApps from "../Assets/Icons/OTTApps";
import OTTConsulting from "../Assets/Icons/OTTConsulting";
import OTT from "../Assets/Icons/OTT";
import QA from "../Assets/Icons/QA";
import Sound from "../Assets/Icons/Sounds";
import vApps from "../Assets/Icons/vApps";
import vCMS from "../Assets/Icons/vCMS";
import vLiteMooc from "../Assets/Icons/vLiteMooc";
import vSMS from "../Assets/Icons/vSMS";
import broadcast from "../Assets/Icons/Broadcast";
import CustomDevelopment from "../Assets/Icons/CustomDevelopment";
import Contact from "../Assets/Icons/Contact";
import CDN from "../Assets/Icons/CDN";

//social  icons
import Linkedin from "../Assets/Icons/Linkedin";
import Linkdin from "../Assets/Icons/Linkdin";

import Sounds1 from "../Assets/Icons/Sounds1";
import Sounds2 from "../Assets/Icons/Sounds2";
import Sounds3 from "../Assets/Icons/Sounds3";
import VDRM1 from "../Assets/Icons/VDRM1";

import HighlightIconV2_1 from "../Assets/Icons/HighlightIconV2_1";
import HighlightIconV2_2 from "../Assets/Icons/HighlightIconV2_2";
import HighlightIconV2_3 from "../Assets/Icons/HighlightIconV2_3";
import HighlightIconV2_4 from "../Assets/Icons/HighlightIconV2_4";
import HighlightIconV2_5 from "../Assets/Icons/HighlightIconV2_5";
import HighlightIconV2_6 from "../Assets/Icons/HighlightIconV2_6";
import HighlightIconV3_1 from "../Assets/Icons/HighlightIconV3_1";
import HighlightIconV3_2 from "../Assets/Icons/HighlightIconV3_2";
import HighlightIconV3_3 from "../Assets/Icons/HighlightIconV3_3";
import HighlightIconV3_4 from "../Assets/Icons/HighlightIconV3_4";
import HighlightIconV3_5 from "../Assets/Icons/HighlightIconV3_5";
import HighlightIconV3_6 from "../Assets/Icons/HighlightIconV3_6";
import HighlightIconV3_7 from "../Assets/Icons/HighlightIconV3_7";
import HighlightIconV3_8 from "../Assets/Icons/HighlightIconV3_8";

import IndustriesIcons1 from "../Assets/Icons/IndustriesIcons/IndustriesIcons1.svg";
import IndustriesIcons2 from "../Assets/Icons/IndustriesIcons/IndustriesIcons2.svg";
import IndustriesIcons3 from "../Assets/Icons/IndustriesIcons/IndustriesIcons3.svg";
import IndustriesIcons4 from "../Assets/Icons/IndustriesIcons/IndustriesIcons4.svg";
import IndustriesIcons5 from "../Assets/Icons/IndustriesIcons/IndustriesIcons5.svg";
import IndustriesIcons6 from "../Assets/Icons/IndustriesIcons/IndustriesIcons6.svg";
import IndustriesIcons7 from "../Assets/Icons/IndustriesIcons/IndustriesIcons7.svg";

import SliderBtn from "../Assets/Icons/SliderBtn";

import Call from "../Assets/Icons/Call";

import ArrowDropDownCircle from "../Assets/Icons/ArrowDropDownCircle";
import ArrowDropUpCircle from "../Assets/Icons/ArrowDropUpCircle";

//carrier icons
import Carriericon1 from "../Assets/Icons/Carriericon1";

import Upload from "../Assets/Icons/Upload";

// HighlightIcons Updated
import HLTvLive01 from "../Assets/Icons/HighlightsIcons/HLTvLive01";
import HLTvLive02 from "../Assets/Icons/HighlightsIcons/HLTvLive02";
import HLTvLive03 from "../Assets/Icons/HighlightsIcons/HLTvLive03";
import HLTvLive04 from "../Assets/Icons/HighlightsIcons/HLTvLive04";
import HLTvLive05 from "../Assets/Icons/HighlightsIcons/HLTvLive05";
import HLTvLive06 from "../Assets/Icons/HighlightsIcons/HLTvLive06";

import HLTSounds01 from "../Assets/Icons/HighlightsIcons/HLTSounds01";
import HLTSounds02 from "../Assets/Icons/HighlightsIcons/HLTSounds02";
import HLTSounds03 from "../Assets/Icons/HighlightsIcons/HLTSounds03";
import HLTSounds04 from "../Assets/Icons/HighlightsIcons/HLTSounds04";

import HLTvLiteMOOC01 from "../Assets/Icons/HighlightsIcons/HLTvLiteMOOC01";
import HLTvLiteMOOC02 from "../Assets/Icons/HighlightsIcons/HLTvLiteMOOC02";
import HLTvLiteMOOC03 from "../Assets/Icons/HighlightsIcons/HLTvLiteMOOC03";
import HLTvLiteMOOC04 from "../Assets/Icons/HighlightsIcons/HLTvLiteMOOC04";
import HLTvLiteMOOC05 from "../Assets/Icons/HighlightsIcons/HLTvLiteMOOC05";
import HLTvLiteMOOC06 from "../Assets/Icons/HighlightsIcons/HLTvLiteMOOC06";

import HLTvApps01 from "../Assets/Icons/HighlightsIcons/HLTvApps01";
import HLTvApps02 from "../Assets/Icons/HighlightsIcons/HLTvApps02";
import HLTvApps03 from "../Assets/Icons/HighlightsIcons/HLTvApps03";
import HLTvApps04 from "../Assets/Icons/HighlightsIcons/HLTvApps04";
import HLTvApps05 from "../Assets/Icons/HighlightsIcons/HLTvApps05";

import HLTvDRM01 from "../Assets/Icons/HighlightsIcons/HLTvDRM01";
import HLTvDRM02 from "../Assets/Icons/HighlightsIcons/HLTvDRM02";
import HLTvDRM03 from "../Assets/Icons/HighlightsIcons/HLTvDRM03";
import HLTvDRM04 from "../Assets/Icons/HighlightsIcons/HLTvDRM04";

import HLTvSMS01 from "../Assets/Icons/HighlightsIcons/HLTvSMS01";
import HLTvSMS02 from "../Assets/Icons/HighlightsIcons/HLTvSMS02";
import HLTvSMS03 from "../Assets/Icons/HighlightsIcons/HLTvSMS03";
import HLTvSMS04 from "../Assets/Icons/HighlightsIcons/HLTvSMS04";
import HLTvSMS05 from "../Assets/Icons/HighlightsIcons/HLTvSMS05";

const IconHelper = {
  //Navbar Icons
  NavbarIcon1,
  NavbarIcon2,
  NavbarIcon3,
  NavbarIcon4,
  NavbarIcon5,
  NavbarIcon6,
  NavbarIcon7,
  NavbarIcon8,
  ChevronDown,
  ChevronUp,
  vLiteMooc,
  Contact,
  Linkdin,
  // HeroSection Icons
  CircularArrowBtn,
  CircularArrowBtnDisabled,
  Whatsapp,
  Mail,
  Plus,
  FbMessenger,
  Headphones,
  close,

  // Card Highlight
  arrowRight,

  // vlive
  VliveIcons01,
  VLive02,
  VLive03,
  VLive04,
  VLive05,
  VLive06,
  VLive07,
  MS,

  // Sounds
  Sounds01,
  Sounds02,
  Sounds03,
  Sounds04,
  Sounds05,
  Sounds06,

  // Vlite MOOC
  vLiteMOOC01,
  vLiteMOOC02,
  vLiteMOOC03,
  vLiteMOOC04,
  vLiteMOOC05,
  DRM,

  // vapps
  vApps01,
  vApps02,
  vApps03,
  vApps04,
  vApps05,

  // vDRM
  vDRM01,
  vDRM02,
  vDRM03,
  vDRM04,
  vDRM05,

  // Vsms

  vSMS01,
  vSMS02,
  vSMS03,
  vSMS04,
  vSMS05,
  vSMS06,

  vCMS01,
  vCMS02,
  vCMS03,
  vCMS04,
  vCMS05,
  vCMS06,

  VCMSHighlight01,
  VCMSHighlight02,
  VCMSHighlight03,
  VCMSHighlight04,
  VCMSHighlight05,


  // StreamingTypeIcons
  StreamingTypeIcon1,
  StreamingTypeIcon2,
  StreamingTypeIcon3,
  StreamingTypeIcon4,
  StreamingTypeIcon5,
  StreamingTypeIcon6,
  StreamingTypeIcon7,
  StreamingTypeIcon8,
  Otticon,

  // OTT
  OTT01,
  OTT02,
  OTT03,
  OTT04,

  // Broadcast

  Broadcast01,
  Broadcast02,
  Broadcast03,
  Broadcast04,

  // Digitization

  Digitization01,
  Digitization02,
  Digitization03,
  Digitization04,

  // 3d Show Card Section
  PlayButton,

  // Testimonials
  ReviewNavBtn,

  //Foter Icons
  Dribbble,
  Facebook,
  GitHub,
  LinkedIn,
  Twitter,
  Social_icon,
  Instagram,

  // product service sections icons
  Vlive1,
  Vlive2,
  Vlive3,
  Vlive4,
  Vlive5,
  Vlive6,
  Sounds1,
  Sounds2,
  Sounds3,
  VDRM1,
  CDN,

  //carrier
  Carriericon1,

  //nav icons
  BlogIcon,
  About,
  Devops,
  LeaderShip,
  OTTApps,
  OTTConsulting,
  OTT,
  QA,
  Sound,
  vApps,
  vCMS,
  vSMS,
  broadcast,
  CustomDevelopment,

  Linkedin,

  // SERVICE PAGE
  // Hightlight Serives
  HighlightIcon1,
  HighlightIcon2,
  HighlightIcon3,
  HighlightIcon4,
  HighlightIcon5,
  HighlightIcon6,
  HighlightIconV2_1,
  HighlightIconV2_2,
  HighlightIconV2_3,
  HighlightIconV2_4,
  HighlightIconV2_5,
  HighlightIconV2_6,
  HighlightIconV3_1,
  HighlightIconV3_2,
  HighlightIconV3_3,
  HighlightIconV3_4,
  HighlightIconV3_5,
  HighlightIconV3_6,
  HighlightIconV3_7,
  HighlightIconV3_8,

  // Industries Icons
  IndustriesIcons1,
  IndustriesIcons2,
  IndustriesIcons3,
  IndustriesIcons4,
  IndustriesIcons5,
  IndustriesIcons6,
  IndustriesIcons7,

  SliderBtn,

  Call,
  Upload,

  ArrowDropDownCircle,
  ArrowDropUpCircle,

  //Highlight Updated Icons
  HLTvLive01,
  HLTvLive02,
  HLTvLive03,
  HLTvLive04,
  HLTvLive05,
  HLTvLive06,
  HLTSounds01,
  HLTSounds02,
  HLTSounds03,
  HLTSounds04,

  HLTvLiteMOOC01,
  HLTvLiteMOOC02,
  HLTvLiteMOOC03,
  HLTvLiteMOOC04,
  HLTvLiteMOOC05,
  HLTvLiteMOOC06,

  HLTvApps01,
  HLTvApps02,
  HLTvApps03,
  HLTvApps04,
  HLTvApps05,

  HLTvDRM01,
  HLTvDRM02,
  HLTvDRM03,
  HLTvDRM04,

  HLTvSMS01,
  HLTvSMS02,
  HLTvSMS03,
  HLTvSMS04,
  HLTvSMS05,
};

export default IconHelper;
