import React, { useState, useEffect } from "react";

// Custom Compoenent
import HeroSection from "../Components/HeroSection/HeroSection";
import StreamingType from "../Components/StreamingTypeSection/StreamingTypeSection";
import BrandSection from "../Components/BrandsSection/BrandSection";
import FooterSection from "../Components/FooterSection/FooterSection";
import HighlightCard from "../Components/HighlightCard/HighlightCard";
import CrossPlatormSection from "../Components/CrossPlatormSection/CrossPlatormSection";
import ShowBanner3d from "../Components/ShowBanner3d/ShowBanner3d";
import Categories from "../Components/Categories/Categories";
import TestimonialCard from "../Components/Testimonials/TestimonialCard";
import LeadingBrnadsCarousel from "../Components/LeadingBrandsCarousel/LeadingBrnadsCarousel";
import Header from "../Components/Header/Header";
import StickyCTABtn from "../Components/StickyCTABtn/StickyCTABtn";
import TalkToUsModal from "../Components/HeroSection/TalkToUsModal/TalkToUsModal";

import ServiceHeroSection from "../Components/ServicesSectionComps/ServiceHeroSection";
import HighlightSectionOfServices from "../Components/ServicesSectionComps/HighlightSectionOfServices/HighlightSectionOfServices";

import HighlightsData from "../Helpers/ProductsPagesHelpers/HighlightsData";
import ColorHelper from "../Helpers/ColorHelper";
import { useLocation } from "react-router-dom";

import ProductPagesHelper from "../Helpers/ProductsPagesHelpers/ProductPagesHelper";

import ContactSection from "../Components/ContactSection/ContactSection";
import ConstantHelper from "../Helpers/ConstantHelper";
import ImageHelper from "../Helpers/ImageHelper";
import TalkToUsModaldrp from "../Components/HeroSection/TalkToUsModal/TalkToUsModaldrp";

const Contact = () => {
  const [openCFModal, setOpenCFModal] = useState(false);

  const item = ProductPagesHelper[0];

  const ServiceHeroProps = {
    title: ConstantHelper.ContactBannertitle,
    caption: ConstantHelper.ContactBannercaption,
    img: ImageHelper.ContactPageBanner,
    btnName: ConstantHelper.ContactBannernavBtn2,
    btnLink: ConstantHelper.ContactBannerdemoBtnLink,
  };

  useEffect(() => {
    window.scrollTo(0, 100);
    window.scrollTo(0, 0);
  }, [item]);

  return (
    <>
      <Header />

      {/* <ServiceHeroSection ServiceHeroProps={ServiceHeroProps} /> */}

      <ContactSection />

      <FooterSection footerCardShow={false} />

      {/* Sticky Instant Chat Button */}
      {/* <StickyCTABtn /> */}

      {/* Talk to Us Modal */}
      <TalkToUsModaldrp open={openCFModal} onClose={() => setOpenCFModal(false)} />
    </>
  );
};

export default Contact;
