import ColorHelper from "../ColorHelper";
import IconHelper from "../IconHelper";
import ImageHelper from "../ImageHelper";

const ProductPagesHelper = {
  vLive: {
    id: "pp01",
    pageName: "vLive",
    menuName: "Products",
    title: "OTT Content Delivery, Engagement and Monetization Solution",
    caption:
      "Get a comprehensive live and on-demand multi-bitrate video/audio streaming platform to distribute and monetize your content globally ",
    demoBtnLink: "/",
    bannerImg: ImageHelper.Banner1,
    highlightsTitle:
      "Your go-to partner for all online video streaming services. ",
    highlightsTitle2:
      "Fully managed end-to-end platform for simplified operation",
    hightlights: 0,
    MediaCard: {
      id: "med01",
      MediaCardColor: ColorHelper.BgBlue,
      MediaCardTitle:
        "Multiscreen OTT Suite for Operators, Broadcasters and Content Owners",
      MediaCardDesc:
        "Mobiotics offers a Comprehensive OTT Tech Platform delivering End-to-end OTT solution to Large Enterprise, SMEs and Solopreneurs in Entertainment and eLearning verticals. Developed on a SAAS model, white-labelled platform enables Service Providers to launch Global OTT service with low TCO (Total Cost of Ownership) and faster Time-to-market. Products/Solutions range caters to all the verticals of an OTT platform– Entertainment-Video, Music/Podcast, Short Video/ UGC, Movie Premiering, eLearning/MOOC, Health & Fitness",
      MediaCardBtn: "/",
      MediaCardImg: ImageHelper.MediaCardImg1,
    },
    metaData: {
      id: "meta01",
      metaTitle: "Enterprise OTT Platform | Video Streaming & Transcoding Platform",
      description: "Looking for enterprise OTT solution provider? Live stream, distribute & monetize content with the best white-label OTT platform & video streaming solution provider.",
      canonical: "https://www.mobiotics.com/products/vLive",
      keywords: "Enterprise OTT Platform, Video Streaming Platform, Video Transcoding Platform"
    },
  },
  sounds: {
    id: "pp02",
    pageName: "Sounds",
    menuName: "Products",
    title: "Let’s revolutionize your audio streaming experience!",
    caption:
      "Fast to set up Audio Platform for Music Creators, Aggregators, Podcasters, and Online Radio Providers ",
    demoBtnLink: "/",
    bannerImg: ImageHelper.Banner2,
    highlightsTitle:
      "Enjoy the benefits of Sounds, a powerful audio streaming platform that is scalable and fully managed.",
    hightlights: 1,
    MediaCard: {
      id: "med01",
      MediaCardColor: ColorHelper.BgYellow,
      MediaCardTitle:
        "Get a flawless audio streaming experience with our SOUNDS",
      MediaCardDesc:
        "Are you an audio content owner, a music artist, or a podcaster? Here is a platform that can help you stream any content in real-time or on demand. We provide white-labeled audio streaming platforms globally. Monetize your audio content with our inbuilt subscription and purchase flows. Our powerful CMS allows you to manage all kinds of audio content i.e music, podcast, live radio etc. We encourage you to give your vision a try.",
      MediaCardBtn: "/",
      MediaCardImg: ImageHelper.MediaCardImg2,
    },
    metaData: {
      id: "meta02",
      metaTitle: "Best Audio Streaming Platform | OTT SaaS  Provider | OTT Platform",
      description: "Looking for Audio as a service platform? Mobiotics' Sounds is the best white-label OTT platform for streaming audio content with complete monetization solution.",
      canonical: "https://www.mobiotics.com/products/sounds",
      keywords: "Best Audio Streaming Platform, OTT SaaS Provider, OTT Platform"
    },
  },
  "vlite-mooc": {
    id: "pp03",
    pageName: "vLite MOOC",
    menuName: "Products",
    title: "Looking to launch an eLearning/Edtech service?",
    title1: "Let’s streamline the process for you!",
    caption:
      "vLite MOOC - The best eLearning solution for educational institutes and Online Learning providers",
    demoBtnLink: "/",
    bannerImg: ImageHelper.Banner3,
    highlightsTitle:
      "Your go-to learning management software for all online video streaming services.",
    hightlights: 2,
    MediaCard: {
      id: "med01",
      MediaCardColor: ColorHelper.PriamryColor,
      MediaCardTitle:
        "Here is a unified eLearning solution that helps institutes and trainers launch their eLearning services.",
      MediaCardDesc:
        "We guarantee an exceptional viewing and learning experience of on-demand and live educational content for students across the globe. Monetize the courses and Live classes with ease. Our learning management software transforms the world of education by powering educators with the digital tools and resources that help them become future-ready. Through vLite MOOC, build with us a robust e-learning app development company that benefits trainers and the student community.",
      MediaCardBtn: "/",
      MediaCardImg: ImageHelper.MediaCardImg3,
    },
    metaData: {
      id: "meta03",
      metaTitle: "eLearning Management System | Video Streaming | Best OTT Platform",
      description: "eLearning management system made easy with Mobiotics white-label OTT platform. Build your own education streaming platform. Get vLite MOOC OTT solution today.",
      canonical: "https://www.mobiotics.com/products/vlite-mooc",
      keywords: "eLearning Management System, Video Streaming, Best OTT Platform"
    },
  },
  vApps: {
    id: "pp04",
    pageName: "vApps",
    menuName: "Products",
    title: "An On-demand App store for Android AOSP devices.",
    caption:
      "Complete cloud-based SAAS app store for AOSP set-top boxes, TVs, and mobile devices",
    demoBtnLink: "/",
    bannerImg: ImageHelper.vAppBannerImage1,
    highlightsTitle:
      "Your go-to partner for all online video streaming services.",
    hightlights: 3,
    MediaCard: {
      id: "med01",
      MediaCardColor: ColorHelper.PriamryColor,
      MediaCardTitle: "Everything you need to know about the AppStore!",
      MediaCardDesc:
        "vApps helps to manage and store apps that stream video for operators/OEM’s, and ensure that your customers have a smooth and engaging time, watching content. Our OTT app store services include video streaming apps for various platforms, including Apple TV, Amazon Fire TV, and many more. Designed for operators looking to extend and monetize STB offerings, vApps is a custom app and game store for advanced 2-way set-top boxes. It comes with Provider and Publisher portals.",
      MediaCardBtn: "/",
      MediaCardImg: ImageHelper.MediaCardImg4,
    },
    metaData: {
      id: "meta04",
      metaTitle: "On-Demand App store for OTT | Best OTT Platform Provider",
      description: "OTT app store for custom OTT apps. vApps is a complete cloud-based OTT SaaS app store for AOSP set-top boxes, TVs, and mobile devices. Get on-demand streaming platform.",
      canonical: "https://www.mobiotics.com/products/vApps",
      keywords: "On-Demand App store for OTT, Best OTT Platform Provider"
    },
  },
  vDRM: {
    id: "pp05",
    pageName: "vDRM",
    menuName: "Products",
    title:
      "Multi DRM as service - Google Widevine, Apple Fairplay, Microsoft Playready",
    caption:
      "Scalable, Reliable, and Easy to use Digital Rights Management (DRM) as a service for OTT providers.",
    demoBtnLink: "/",
    bannerImg: ImageHelper.vAppBannerImage,
    highlightsTitle:
      "Your go-to partner for all online video streaming services.",
    hightlights: 4,
    MediaCard: {
      id: "med01",
      MediaCardColor: ColorHelper.BgBlue,
      MediaCardTitle:
        "Wish to have a Premium Content Security Service? vDRM is the solution that you are looking for!",
      MediaCardDesc:
        "Our product vDRM is a multi-DRM service to protect your digital media content (audio/video). Prevent unauthorized access and revenue leakage with our Multi DRM packaging and license service, which supports industry-leading DRMs i.e Google Widevine, Apple Fairplay and Microsoft Playready. ",
      MediaCardImg: ImageHelper.MediaCardImg5,
    },
    metaData: {
      id: "meta05",
      metaTitle: "Multi DRM Services | OTT Digital Rights Management | OTT Platform",
      description: "Mobiotics offers the best OTT Digital Rights Management in India. vDRM is a multi-DRM packaging & license service to build your own white-label OTT platform.",
      canonical: "https://www.mobiotics.com/products/vDRM",
      keywords: "Multi DRM Services, OTT Digital Rights Management, OTT Platform"
    },
  },
  "vCMS": {
    id: "pp06",
    pageName: "vCMS",
    menuName: "Products",
    title:
      "OTT Content Management System.",
    caption:
      "vCMS Offers you Rich, Extendable Content Meta Data Management for Audio, Video, LIVE and Event Broadcast OTT Platforms.",
    demoBtnLink: "/",
    bannerImg: ImageHelper.Banner6,
    highlightsTitle: "Your go-to partner for all online video streaming services.",
    hightlights: 5,
    MediaCard: {
      id: "med01",
      MediaCardColor: ColorHelper.PriamryColor,
      MediaCardTitle:
        "Organize your content with ease using vCMS.  ",
      MediaCardDesc:
        "vCMS is a centralized content management solution for ingesting, managing, publishing and distributing audio and video assets. Supports both Live and On-demand contents. Feature rich CMS offers individual and bulk content ingestion, video transcoding and DRM protection, Multi-lingual metadata management, posters, trailers, cast and crew management, and detailed dashboard and analytics.    ",
      MediaCardImg: ImageHelper.MediaCardImg6,
    },
    metaData: {
      id: "meta06",
      metaTitle: "OTT CMS | OTT Content Management System | OTT Platform",
      description: "Mobiotics vCMS is a user-friendly & fully customizable OTT content management system. get centralize OTT CMS solution for building your own OTT platform.",
      canonical: "https://www.mobiotics.com/products/vCMS",
      keywords: "OTT CMS, OTT Content Management System, OTT Platform"
    },
  },
  vSMS: {
    id: "pp07",
    pageName: "vSMS",
    menuName: "Products",
    title: "Subscriber Management System (SMS) purpose-built for OTT",
    caption:
      "Versatile User, Device, Subscription, Purchase and Discount Coupon Management service for OTT",
    demoBtnLink: "/",
    bannerImg: ImageHelper.vSMSBannerImage,
    highlightsTitle:
      "Your go-to partner for all online video streaming services.",
    hightlights: 6,
    MediaCard: {
      id: "med01",
      MediaCardColor: ColorHelper.PriamryColor,
      MediaCardTitle:
        "Manage your Users and Monetize using Subscription plans and purchases",
      MediaCardDesc:
        "Subscriber Management System (SMS) is a key module in a OTT service. Our vSMS is a comprehensive and advanced system to manage your users (login, signup, their profile), device management, launch subscription plans and promote using offers (discount coupons/vouchers). Simple to complex business models are supported. vSMS is integrated with multiple payment methods (domestic/international payment gateways, wallets, in-app payments and telco billing) for seamless payment experience.",
      MediaCardBtn: "/",
      MediaCardImg: ImageHelper.MediaCardImg7,
    },
    metaData: {
      id: "meta07",
      metaTitle: "OTT Billing System | Subscription Management for OTT | Mobiotics",
      description: "vSMS - OTT subscriber management system. Get billing, subscription management & monetization management from the best OTT solution provider. Build your own OTT.",
      canonical: "https://www.mobiotics.com/products/vSMS",
      keywords: "OTT Billing System, Subscription Management for OTT"
    },
  },
  vanalytics: {
    id: "pp08",
    pageName: "vAnalytics",
    menuName: "Products",
    title:
      "Scalable, reliable, customisable End-End OTT SAAS Suite for Enterprises",
    caption: "",
    demoBtnLink: "/",
    bannerImg: ImageHelper.Banner1,
    highlightsTitle:
      "Your go-to partner for all online video streaming services.",
    hightlights: 7,
    MediaCard: {
      id: "med01",
      MediaCardColor: ColorHelper.BgBlue,
      MediaCardTitle:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id.",
      MediaCardDesc:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et dignissim maecenas at neque. Enim sed fermentum ut et laoreet non ut tellus sit. Et accumsan morbi sit id eget placerat sit netus quis. Feugiat lectus lectus id.",
      MediaCardBtn: "/",
      MediaCardImg: ImageHelper.MediaCardImg1,
    },
  },
  vsuggest: {
    id: "pp09",
    pageName: "vSuggest",
    menuName: "Products",
    title:
      "Scalable, reliable, customisable End-End OTT SAAS Suite for Enterprises",
    caption: "",
    demoBtnLink: "/",
    bannerImg: ImageHelper.Banner2,
    highlightsTitle:
      "Your go-to partner for all online video streaming services.",
    hightlights: 8,
    MediaCard: {
      id: "med01",
      MediaCardColor: ColorHelper.BgYellow,
      MediaCardTitle:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id.",
      MediaCardDesc:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et dignissim maecenas at neque. Enim sed fermentum ut et laoreet non ut tellus sit. Et accumsan morbi sit id eget placerat sit netus quis. Feugiat lectus lectus id.",
      MediaCardBtn: "/",
      MediaCardImg: ImageHelper.MediaCardImg2,
    },
  },
};

export default ProductPagesHelper;