import React from "react";

const HLTvApps03 = () => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.375 12.5781H42.7656V37.375H3.23438V12.5781H8.625"
        stroke="#457ABD"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.0156 45.1016H31.9844"
        stroke="#457ABD"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M37.375 4.49219V0.89844H8.625V26.0547H37.375V4.49219Z"
        stroke="#457ABD"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.625 4.49219H37.375"
        stroke="#457ABD"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.2031 15.2734H14.0156V9.88281H21.2031V15.2734Z"
        stroke="#457ABD"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.0156 20.6641H31.9844"
        stroke="#457ABD"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M26.5938 15.2734H31.9844"
        stroke="#457ABD"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M26.5938 9.88281H31.9844"
        stroke="#457ABD"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M28.3906 37.375V45.1016"
        stroke="#457ABD"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.6094 45.1016V37.375"
        stroke="#457ABD"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M23.8984 31.4453C23.8984 31.9415 23.4962 32.3438 23 32.3438C22.5038 32.3438 22.1016 31.9415 22.1016 31.4453C22.1016 30.9491 22.5038 30.5469 23 30.5469C23.4962 30.5469 23.8984 30.9491 23.8984 31.4453Z"
        fill="#457ABD"
      />
      <path
        d="M3.23438 31.4453H18.957"
        stroke="#457ABD"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M27.043 31.4453H42.7656"
        stroke="#457ABD"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default HLTvApps03;
