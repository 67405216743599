import React, { memo, useState } from "react";
import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

// Custom Component
import BorderedButton from "../Ui-Components/Buttons/BorderedButton/BorderedButton";
import FilledButton from "../Ui-Components/Buttons/FilledButton/FilledButton";
import SliderCustomBtns from "../Ui-Components/Buttons/SliderCustomBtns/SliderCustomBtns";

// Helpers
import ConstantHelper from "../../Helpers/ConstantHelper";
import ColorHelper from "../../Helpers/ColorHelper";
import ImageHelper from "../../Helpers/ImageHelper";
import IconHelper from "../../Helpers/IconHelper";

// Custoom Styles
import HeroSectionStyle from "./HeroSectionStyle";
import TalkToUsModal from "./TalkToUsModal/TalkToUsModal";

import { useNavigate } from "react-router-dom";

import Lottie from "react-lottie";
// import animation1 from "../../Assets/Animation/animation1.json";
import AnimationPink from "../../Assets/Animation/AnimationPink.json";
import animation2 from "../../Assets/Animation/animation2.json";
import animation3 from "../../Assets/Animation/animation3.json";
import blueBanner from "../../Assets/Animation/blueBanner.json";
import greenBanner from "../../Assets/Animation/greenBanner.json";

//useref for custom button

import "./Carousel.css";
import HeroBtnGroup from "./HeroBtnGroup";
import FontHelper from "../../Helpers/FontHelper";
import TalkToUsModaldrp from "./TalkToUsModal/TalkToUsModaldrp";

const defaultOptions1 = {
  loop: true,
  autoplay: true,
  animationData: AnimationPink,
};
const defaultOptions2 = {
  loop: true,
  autoplay: true,
  animationData: blueBanner,
};
const defaultOptions3 = {
  loop: true,
  autoplay: true,
  animationData: greenBanner,
};

const data = [
  {
    id: 1,
    title1: ConstantHelper.heroText1,
    title2: ConstantHelper.heroText2,
    caption: ConstantHelper.heroContentA,
    image: <Lottie options={defaultOptions1} height={500} width={500} />,
    btn1: ConstantHelper.navBtn1,
    btn1Link: "/",
    btn2: ConstantHelper.navBtn2,
    btn2Link: "/",
    topPos: "150px",
    leftPos: "50%",
    topPosSmall: "10rem;",
    leftPosSmall: "0%",
    bgColor: ColorHelper.PriamryColor,
  },
  {
    id: 2,
    title1: ConstantHelper.heroText3,
    title2: ConstantHelper.heroText4,
    caption: ConstantHelper.heroContentB,
    image: <Lottie options={defaultOptions2} height={500} width={500} />,
    btn1: ConstantHelper.navBtn1,
    btn1Link: "/",
    btn2: ConstantHelper.navBtn2,
    btn2Link: "/",
    topPos: "100px",
    leftPos: "150px",
    topPosSmall: "10rem;",
    leftPosSmall: "0%",
    bgColor: ColorHelper.blue,
  },
  {
    id: 3,
    title1: ConstantHelper.heroText5,
    title2: ConstantHelper.heroText6,
    caption: ConstantHelper.heroContentC,
    btn1: ConstantHelper.navBtn1,
    btn1Link: "/",
    btn2: ConstantHelper.navBtn2,
    btn2Link: "/",
    image: <Lottie options={defaultOptions3} height={500} width={500} />,
    topPos: "150px",
    leftPos: "50%",
    topPosSmall: "10rem;",
    leftPosSmall: "0%",
    bgColor: ColorHelper.AppleGreen,
  },
];

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1619 },
    items: 1,
    slidesToSlide: 1,
  },
  laptop: {
    breakpoint: { max: 1619, min: 1024 },
    items: 1,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 640 },
    items: 1,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 639, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const carouselParams = {
  additionalTransfrom: 0,
  arrows: false,
  autoPlay: true,
  autoPlaySpeed: 3000,
  centerMode: false,
  className: "",
  containerClass: "carousel-container",
  dotListClass: "",
  draggable: true,
  focusOnSelect: false,
  infinite: true,
  itemClass: "",
  customButtonGroup: <HeroBtnGroup />,
  keyBoardControl: true,
  minimumTouchDrag: 80,
  renderButtonGroupOutside: true,
  renderDotsOutside: false,
  responsive: responsive,
  showDots: false,
  sliderClass: "",
  slidesToSlide: 1,
};

const HeroSection = memo(function HeroSection() {
  const [openCFModal, setOpenCFModal] = useState(false);

  const navigate = useNavigate();

  const handleCloseModal = () => setOpenCFModal(false);

  return (
    <div className="banner">
      <Carousel {...carouselParams}>
        {data.map((item) => (
          <Box
            sx={{
              ...HeroSectionStyle.container,
              backgroundColor: item.bgColor,
            }}
          >
            <Box key={item.id} sx={HeroSectionStyle.contents}>
              <Typography
                sx={HeroSectionStyle.title2}
                variant="h6"
                component="h2"
              >
                {item.title1}
              </Typography>
              <Typography
                sx={HeroSectionStyle.title2}
                variant="h6"
                component="h2"
              >
                {item.title2}
              </Typography>
              <Typography
                sx={HeroSectionStyle.heroContent}
                variant="h6"
                component="h2"
              >
                {item.caption}
              </Typography>
              <Box sx={HeroSectionStyle.btnContainer}>
                <Box
                  onClick={() => {
                    navigate(`/company/contact`);
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      color: ColorHelper.black,
                      backgroundColor: ColorHelper.white,
                      border: `1px solid ${ColorHelper.transparent}`,
                      mx: 1,
                      py: 1.2,
                      px: 3,
                      my: 2,
                      fontFamily: FontHelper.FontSemiBold,
                      fontWeight: 600,
                      borderRadius: "7px",
                      minWidth: "max-content",
                      maxHeight: "3.2rem",
                      minHeight: "3.2rem",
                      textTransform: "unset",
                      boxShadow: "none",
                      "&:hover": {
                        backgroundColor: ColorHelper.transparent,
                        border: `1px solid ${ColorHelper.white}`,
                        color: ColorHelper.white,
                        boxShadow: "none",
                      },
                    }}
                  >
                    {item.btn1}
                  </Button>
                </Box>
                <Box onClick={() => setOpenCFModal(true)}>
                  <Button
                    variant="outlined"
                    sx={{
                      borderColor: ColorHelper.white,
                      color: ColorHelper.white,
                      backgroundColor: ColorHelper.transparent,
                      border: `1px solid ${ColorHelper.white}!important`,

                      mx: 1,
                      py: 1.2,
                      px: 3,
                      my: 2,
                      fontFamily: FontHelper.FontSemiBold,
                      borderRadius: "7px",
                      maxHeight: "3.2rem",
                      minHeight: "3.2rem",
                      minWidth: "max-content",
                      textTransform: "unset",
                      fontWeight: 800,
                      "&:hover": {
                        backgroundColor: ColorHelper.white,
                        color: ColorHelper.black,
                        borderColor: ColorHelper.white,
                      },
                      "&:focus": {
                        borderColor: ColorHelper.white,
                      },
                    }}
                  >
                    {item.btn2}
                  </Button>
                </Box>
              </Box>

              {/* <Box sx={HeroSectionStyle.HeroNavbtns}>
                <Button disableRipple sx={HeroSectionStyle.NavBtns} onClick={() => slider.current.prev()}>
                  <IconHelper.CircularArrowBtnDisabled
                    color={ColorHelper.PriamryColor}
                  />
                </Button>
                <Button disableRipple sx={HeroSectionStyle.NavBtns} onClick={() => slider.current.next()}>
                  <IconHelper.CircularArrowBtn
                    color={ColorHelper.PriamryColor}
                  />
                </Button>
              </Box> */}
            </Box>

            <Box sx={HeroSectionStyle.img_container}>
              {/* <img
                src={solution3}
                style={HeroSectionStyle.image}
                alt="Hero"
            />   */}
              <Box style={HeroSectionStyle.image}>{item.image}</Box>
            </Box>
          </Box>
        ))}
      </Carousel>
      <TalkToUsModaldrp
        open={openCFModal}
        onClose={handleCloseModal}
        formName={ConstantHelper.modalName1}
      />
    </div>
  );
});

export default HeroSection;
