import { Box, Typography } from "@mui/material";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

// Custom Comps
import Rating from "./Comps/Rating";
import ButtonGroup from "./Comps/ButtonGroup";

// Custom Stlyes
import TestimonialCardStyle from "./TestimonialCardStyle";

// Helpers
import ConstantHelper from "../../Helpers/ConstantHelper";
import ImageHelper from "../../Helpers/ImageHelper";
import './Testimonial.css'

const data = [
  {
    id: 1,
    description:
      "Mobiotics team's dedication to learn and adapt systems to meet our changing needs has been great - they have almost built the plane while flying it with us!! The team's tenacity and willingness to learn and recommend new technologies is a core strength and I hope always continues to be.",
    avatar: ImageHelper.BrandCover4,
    name: "Karan Malhotra",
    designation: "CEO - Fastfilmz",
    review: 4,
  },
  {
    id: 2,
    description:
      "Mobiotics has developed Partner - Customer Self Service Portals and Mobile Applications for us making it extremely easy for us to extend services to our customer base. Skilled professionals of team Mobiotics always deliver quality solutions within reasonable timeframes. It's a pleasure to do business with Mobiotics due to their flexible approach and ability to deliver solutions on demand.",
    avatar: ImageHelper.BrandCover3,
    name: "Sheila Paul",
    designation: "Sr.VP-IMCL(Hinduja Ventures)",
    review: 5,
  },
  {
    id: 3,
    description:
      "Mobiotics team's dedication to learn and adapt systems to meet our changing needs has been great - they have almost built the plane while flying it with us!! The team's tenacity and willingness to learn and recommend new technologies is a core strength and I hope always continues to be.",
    avatar: ImageHelper.BrandCover4,
    name: "Karam Malhotra",
    designation: "CEO - Fastfilmz",
    review: 5,
  },
  {
    id: 4,
    description:
      "Mobiotics has developed Partner - Customer Self Service Portals and Mobile Applications for us making it extremely easy for us to extend services to our customer base. Skilled professionals of team Mobiotics always deliver quality solutions within reasonable timeframes. It's a pleasure to do business with Mobiotics due to their flexible approach and ability to deliver solutions on demand.",
    avatar: ImageHelper.BrandCover3,
    name: "Sheila Paul",
    designation: "Sr.VP-IMCL(Hinduja Ventures)",
    review: 4,
  },
];

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1619 },
    items: 4,
    slidesToSlide: 1,
  },
  laptop: {
    breakpoint: { max: 1619, min: 1024 },
    items: 3,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 640 },
    items: 1,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 639, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const carouselParams = {
  additionalTransfrom: 0,
  arrows: false,
  autoPlay: true,
  autoPlaySpeed: 3000,
  centerMode: false,
  className: "",
  containerClass: "carousel-container",
  customButtonGroup: <ButtonGroup />,
  dotListClass: "",
  draggable: true,
  focusOnSelect: false,
  infinite: true,
  itemClass: "",
  keyBoardControl: true,
  minimumTouchDrag: 80,
  renderButtonGroupOutside: true,
  renderDotsOutside: false,
  responsive: responsive,
  showDots: false,
  sliderClass: "",
  slidesToSlide: 1,
};

const TestimonialCard = () => {
  return (
    <section
      id="testimonial"
      className="testimonialCards"
      style={TestimonialCardStyle.TestimonialWrapper}
    >
      <Box sx={TestimonialCardStyle.TestimonialTitleWrapper}>
        <Typography sx={TestimonialCardStyle.TestimonialTitle}>
          {ConstantHelper.TestimonialTitle}
        </Typography>
      </Box>
      <Box sx={TestimonialCardStyle.carouselWrapper}>
        <Carousel {...carouselParams}>
          {data.map((item) => (
            <Box sx={TestimonialCardStyle.reviewCard} key={item.id}>
              <Typography sx={TestimonialCardStyle.description}>
                {item.description}
              </Typography>
              <div className="card-footer">
                <Rating rating={item.review} />
                <div className="reviewer-info">
                  <Typography as="h4" sx={TestimonialCardStyle.heading}>
                    {item.name}
                  </Typography>
                  <Typography sx={TestimonialCardStyle.designation}>
                    {item.designation}
                  </Typography>
                </div>
                <Box className="AvatarImage">
                  <img style={{ transform: 'translateX(15%)', }} src={item.avatar} alt="User's Pic" />
                </Box>
              </div>
            </Box>
          ))}
        </Carousel>
      </Box>
    </section>
  );
};

export default TestimonialCard;
