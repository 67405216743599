import React, { useState, useEffect } from "react";
import { Box, Button, Dialog, Slide, Typography } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import useMediaQuery from "@mui/material/useMediaQuery";
import BorderedButton from "../../Ui-Components/Buttons/BorderedButton/BorderedButton";

// Helpers
import ImageHelper from "../../../Helpers/ImageHelper";
import ConstantHelper from "../../../Helpers/ConstantHelper";
import IconHelper from "../../../Helpers/IconHelper";
import ColorHelper from "../../../Helpers/ColorHelper";

// Custom Styles
// import ShowBanner3dStyle from "../../ShowBanner3dStyle";
import TalkToUsModalStyle from "./TalkToUsModalStyle";
import ShowBanner3dStyle from "../../ShowBanner3d/ShowBanner3dStyle";
import TalkToUsForm from "./TalkToUsForm";
import TalkTousFormdrp from "./TalkTousFormdrp";
import LeaderModal from "./LeaderModal";


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement,
  },
  ref: React.Ref<unknown>
) {
  return <Slide ref={ref} {...props} />;
});


let formprops = {
  name: "sending props",

};

export default function TalkToUsModaldrp(props) {
  const { modalprops, onClose, formName } = props;
  // formprops = props;
  //   const [renderVideo, setRenderVideo] = useState(0);

  //   const [videoContainer, setVideoContainer] = useState(500);

  //   const GalaxyFold = useMediaQuery("(min-width:280px) and (max-width: 359px)");
  //   const GalaxyS8 = useMediaQuery("(min-width:360px) and (max-width: 374px)");
  //   const iphoneSE = useMediaQuery("(min-width:375px) and (max-width: 389px)");
  //   const iphone12Pro = useMediaQuery("(min-width:390px) and (max-width: 392px)");
  //   const Pixel5 = useMediaQuery("(min-width:393px) and (max-width: 411px)");
  //   const GalaxyS20 = useMediaQuery("(min-width:412px) and (max-width: 413px)");
  //   const iPhoneXR = useMediaQuery("(min-width:414px) and (max-width: 539px)");
  //   const aboveMobile = useMediaQuery(
  //     "(min-width:540px) and (max-width: 2000px)"
  //   );

  //   useEffect(() => {
  //     if (GalaxyFold === true) {
  //       setVideoContainer(180);
  //       // alert(`GalaxyS8::${videoContainer}`);
  //     } else if (GalaxyS8 === true) {
  //       setVideoContainer(260);
  //       // alert(`iphoneSE::${videoContainer}`);
  //     } else if (iphoneSE === true) {
  //       setVideoContainer(280);
  //       // alert(`iphoneSE::${videoContainer}`);
  //     } else if (iphone12Pro === true) {
  //       setVideoContainer(280);
  //       // alert(`iphoneSE::${videoContainer}`);
  //     } else if (Pixel5 === true) {
  //       setVideoContainer(280);
  //       // alert(`iphoneSE::${videoContainer}`);
  //     } else if (GalaxyS20 === true) {
  //       setVideoContainer(300);
  //       // alert(`iphoneSE::${videoContainer}`);
  //     } else if (iPhoneXR === true) {
  //       setVideoContainer(320);
  //       // alert(`iphoneSE::${videoContainer}`);
  //     } else if (aboveMobile === true) {
  //       setVideoContainer(440);
  //       // alert(`iphoneSE::${videoContainer}`);
  //     }
  //   }, [
  //     GalaxyFold,
  //     GalaxyS8,
  //     iphoneSE,
  //     iphone12Pro,
  //     Pixel5,
  //     GalaxyS20,
  //     iPhoneXR,
  //     aboveMobile,
  //   ]);

  const closeDialog = () => {
    props?.onClose();
  };

  return (
    <div style={{ position: "relative" }}>
      <Dialog
        TransitionComponent={Transition}
        open={props?.open}
        onClose={closeDialog}
        sx={{ 'div': { width: '100%' } }}
      >
        <Box>
          <Box sx={TalkToUsModalStyle.modalWrapper}>
            <Box sx={TalkToUsModalStyle.modalHeader}>

              <Typography sx={TalkToUsModalStyle.ModalTitle}> Request a demo and discover why Mobiotics is right for you!</Typography>
              <Button onClick={closeDialog} className='cf-close' >
                <img src={IconHelper.close} alt="close icon" />
              </Button>
            </Box>
            <Box sx={TalkToUsModalStyle.modalContent}>
              <Box sx={TalkToUsModalStyle.main}>
                {modalprops == undefined ? (<TalkTousFormdrp onClose={onClose} />) : (<TalkToUsForm modalprops={modalprops} onClose={onClose} />)}
              </Box>
            </Box>
            {/* <Box sx={TalkToUsModalStyle.moalFooter}>
              <Box
                sx={{
                  display: { xs: "none", md: "flex" },
                  justifyContent: "space-around",
                }}
                onClick={closeDialog}
              >
                <BorderedButton
                  btnText={ConstantHelper.streamingModalBtn2}
                  color={ColorHelper.grey1}
                  bgColor={ColorHelper.transparent}
                  bordercolor={ColorHelper.lightGrey}
                  onClick={closeDialog}
                />
              </Box>
            </Box> */}
          </Box>
        </Box>
      </Dialog>
    </div>
  );
}
