import React from "react";
import ColorHelper from "../../Helpers/ColorHelper";

const HighlightIcon1 = () => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23 1.91675C21.475 1.91675 20.0125 2.52255 18.9341 3.60088C17.8558 4.67922 17.25 6.14175 17.25 7.66675V23.0001C17.25 24.5251 17.8558 25.9876 18.9341 27.0659C20.0125 28.1443 21.475 28.7501 23 28.7501C24.525 28.7501 25.9875 28.1443 27.0659 27.0659C28.1442 25.9876 28.75 24.5251 28.75 23.0001V7.66675C28.75 6.14175 28.1442 4.67922 27.0659 3.60088C25.9875 2.52255 24.525 1.91675 23 1.91675V1.91675Z"
        stroke={ColorHelper.BgBlue}
        stroke-width="3.83333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M36.4163 19.1667V23.0001C36.4163 26.5584 35.0028 29.971 32.4867 32.4871C29.9706 35.0032 26.558 36.4167 22.9997 36.4167C19.4414 36.4167 16.0288 35.0032 13.5127 32.4871C10.9965 29.971 9.58301 26.5584 9.58301 23.0001V19.1667"
        stroke={ColorHelper.BgBlue}
        stroke-width="3.83333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M23 36.4167V44.0834"
        stroke={ColorHelper.BgBlue}
        stroke-width="3.83333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.333 44.0833H30.6663"
        stroke={ColorHelper.BgBlue}
        stroke-width="3.83333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default HighlightIcon1;
