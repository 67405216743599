// Helpers
import ColorHelper from "../../Helpers/ColorHelper";
import FontHelper from "../../Helpers/FontHelper";

const ShowBanner3dStyle = {
  Wrapper: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: ColorHelper.PriamryColor,
  },
  Container: {
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    px: 2,
    pt: 7,
    pb: 9,
    minWidth: { xs: "100vw", md: "900", lg: "1200px" },
  },
  SectionTitle: {
    fontFamily: FontHelper.FontSemiBold,
    fontSize: 30,
    fontWeight: 700,
    color: ColorHelper.white,
    mt: 4,
    textAlign: "center",
    px: { xs: 3, md: 0 },
  },
  card: {
    position: "relative",
  },
  showCard: {
    borderRadius: 10,
  },
  activeCard: {
    position: "absolute",
    left: { xs: "18%", md: "22%" },
    right: { xs: "18%", md: "22%" },
    zIndex: "3",
  },
  BannerImage: {
    borderRadius: 10,
  },
  buttonGroup: {
    minWidth: "100%",
    pl: { xs: "5px", md: "0px", lg: "150px" },
    pr: { xs: "5px", md: "0px", lg: "150px" },
    mr: { xs: "0px", md: 0 },
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "absolute",
    top: { xs: "55%", md: "50%" },
  },
  BtnIconContainer: {
    bg: "transparent",
    border: "0px solid",
    fontSize: 40,
    cursor: "pointer",
    px: "2px",
    color: "#BBC7D7",
    transition: "all 0.25s",
    zIndex: 5,
    "&:hover": {
      color: "text",
    },
    "&:focus": {
      outline: 0,
    },
    svg: {
      width: { xs: "30px", md: "auto" },
    },
  },
  PlayButton: {
    position: "absolute",
    top: "35%",
    bottom: "35%",
    left: "35%",
    right: "35%",
    maxWidth: "70px",
    height: "min-content",
    borderRadius: "50%",
    zIndex: 2,
    padding: 0,
    overflow: "hidden",
  },
  BtnImg: {
    width: "100%",
  },
  PlayButtonActive: {
    position: "absolute",
    top: "35%",
    bottom: "35%",
    left: "35%",
    right: "35%",
    width: "fit-content",
    height: "min-content",
    borderRadius: "50%",
    zIndex: 4,
    padding: 0,
    overflow: "hidden",
  },
};

export default ShowBanner3dStyle;
