import React from "react";

const HLTSounds03 = () => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3085_81251)">
        <path
          d="M45.2116 29.595L42.6073 26.9932C42.4546 26.8404 42.2076 26.8404 42.0549 26.9932L31.8413 37.2044L29.5323 42.6729L34.9981 40.3613L45.2116 30.15C45.3643 29.9972 45.3643 29.7478 45.2116 29.595Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M38.7708 30.2747V5.509C38.7708 4.30994 37.7882 3.32723 36.5892 3.32723H2.85553C1.65648 3.32723 0.673767 4.30994 0.673767 5.509V35.0227C0.673767 36.2217 1.65648 37.2043 2.85553 37.2043H31.8414"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M42.4189 32.9402L39.2648 29.7834"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M21.2001 12.1841H38.7708M0.673767 12.1841H18.0893"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M5.93331 9.53662C6.91342 9.53662 7.71285 8.73468 7.71285 7.75457C7.71285 6.77438 6.91342 5.97504 5.93331 5.97504C4.95321 5.97504 4.15387 6.77438 4.15387 7.75457C4.15387 8.73468 4.95321 9.53662 5.93331 9.53662Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12.9494 9.53662C13.9295 9.53662 14.7289 8.73468 14.7289 7.75457C14.7289 6.77438 13.9295 5.97504 12.9494 5.97504C11.9668 5.97504 11.1674 6.77438 11.1674 7.75457C11.1674 8.73468 11.9668 9.53662 12.9494 9.53662Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M33.5114 9.53662H21.7169C20.7343 9.53662 19.9375 8.73728 19.9375 7.75457C19.9375 6.77186 20.7343 5.97504 21.7169 5.97504H33.5114C34.4941 5.97504 35.2934 6.77186 35.2934 7.75457C35.2934 8.73728 34.4941 9.53662 33.5114 9.53662Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12.3435 20.1425H9.74934C9.06715 20.1425 8.50958 20.7001 8.50958 21.3823V28.3553C8.50958 29.035 9.06715 29.5925 9.74934 29.5925H12.3435V20.1425Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M20.5942 24.8674V17.6399C20.5942 17.0187 20.1742 16.6724 19.5708 16.8812L12.3435 20.1424V29.5924L19.5708 32.8537C20.1742 33.0624 20.5942 32.7187 20.5942 32.0975V24.8674Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M29.4916 19.1444C32.7832 22.4362 32.7832 27.7772 29.4916 31.0715"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M26.7778 20.4631C29.3439 23.0292 29.3439 27.1866 26.7778 29.7502"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M24.0667 21.7844C25.9022 23.6199 25.9022 26.596 24.0667 28.429"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17.4477 26.6699V31.8965M17.4477 17.8411V23.559"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M34.9981 40.3613L31.8413 37.2045"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3085_81251">
          <rect width="46" height="46" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HLTSounds03;
