// Helper
import ColorHelper from "../../Helpers/ColorHelper";
import FontHelper from "../../Helpers/FontHelper";

const LeadingBrnadsCarouselStyle = {
  LeadingBrandsWrapper: {
    backgroundColor: ColorHelper.BgBlue,
    paddingBottom: { xs: "0px", md: "80px" },
    width: "100%",
    height: "auto",
    position: "relative",
  },
  LeadingBrandsTitleWrapper: {
    textAlign: "center",
    // margin: '0 auto',
    pt: { xs: '50px', md: "40px" },
    pb: { xs: "20px", md: "60px" },
    px: { xs: 3, md: 3 },
  },
  LeadingBrandsTitle: {
    fontFamily: FontHelper.FontSemiBold,
    fontSize: 30,
    fontWeight: 100,
    color: ColorHelper.white,
  },
  carouselWrapper: {
    // display: "flex",
    // justifyContent: "flex-start",
    // flexDirection: "row",
    // alignItems: "flex-start",
    height: { xs: "220px", md: 'auto' },
    mt: { xs: '10px', md: "-30px" },
    px: "35px",
    pb: { xs: '10px', md: '40px' },
    ".carousel-container": {
      width: "100%",
      ul: {
        mb: "20px",
        // display: 'inline-flex',
        // listStyleType: 'none'
      },
      maxWidth: [
        "100%",
        "90%",
        "90%",
        "88%",
        "88%",
        "1180px",
        "80%",
        "calc(50% + 865px)",
      ],
      mr: ["auto", null, null, null, null, null, null, "-220px"],
      ml: "auto",
      ".react-multi-carousel-item": {
        transition: "all 0.25s",
        'div': {
          my: 0,
        }
      },
      ".react-multi-carousel-item--active:nth-of-type(4n)": {
        opacity: "0.5",
        "@media screen and (max-width: 1620px)": {
          opacity: 1,
        },
      },
    },
  },
  card: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "column",
    m: 10,
    "&:hover": {
      boxShadow: "0px 6px 30px rgba(38, 78, 118, 0.1)",
    },
  },
  cardImg: {
    borderRadius: 10,
    overflow: "hidden",
  },
  title: {
    fontFamily: FontHelper.FontMedium,
    fontSize: 18,
    fontWeight: 500,
    mb: 1,
    color: ColorHelper.white,
    lineHeight: 1.6,
  },
  caption: {
    fontFamily: FontHelper.FontRegular,
    color: ColorHelper.white,
    fontWeight: "500",
    fontSize: 16,
    lineHeight: 1.4,
  },
};

export default LeadingBrnadsCarouselStyle;
