const meta = {
    title: "End-to-End OTT Solutions Provider | Video Streaming Platform | Mobiotics",
    description: "Mobiotics has equipped brands with end-to-end OTT solutions. Get custom OTT video streaming platform & complete OTT SaaS suite for your business.",
    url: "https://www.mobiotics.com/company/about",
    keywords: "End-to-End OTT Solutions Provider, Video Streaming Platform, Mobiotics"
};

const careerMeta = {
    title: "Careers | Best OTT Platform Solution Provider | White-Label OTT",
    description: "Grow your career with the best OTT platform solution provider in India & US. Grab your dream job at the best white-label OTT platform service provider like Mobiotics.",
    url: "https://www.mobiotics.com/company/career",
    keywords: "Best OTT Platform Solution Provider, White-Label OTT",
    canonical: "https://www.mobiotics.com/company/career"
};

const contactUsMeta = {
    title: "Contact Us | Video Streaming Platform | OTT Solution Providers in India",
    description: "Transform your video content into a full-scale subscription service with Mobiotics OTT. Contact us or visit website to know more about our OTT services.",
    url: "https://www.mobiotics.com/contact",
    keywords: "Video Streaming Platform, OTT Solution Providers in India",
    canonical: "https://www.mobiotics.com/company/contact"
}

const leadershipSkillMeta = {
    title: "Leaders | Mobiotics",
    description: "Leaders of Mobiotics",
    url: "https://www.mobiotics.com/leadership",
    canonical: "https://www.mobiotics.com/company/leadership"
}

const OTTPageMeta = {
    title: "OTT | Mobiotics",
    description: "OTT page of Mobiotics",
    url: "https://www.mobiotics.com/ott-apps"
}


export {
    meta,
    careerMeta,
    contactUsMeta,
    leadershipSkillMeta,
    OTTPageMeta,
};