





import React from "react";
import ColorHelper from "../../Helpers/ColorHelper";

const Otticon = () => {
  return (
    <svg width="27" height="39" viewBox="0 0 27 39" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.75065 36.7497C7.75065 37.8038 8.61315 38.6663 9.66732 38.6663H17.334C18.3882 38.6663 19.2507 37.8038 19.2507 36.7497V34.833H7.75065V36.7497ZM13.5007 0.333008C6.10232 0.333008 0.0839844 6.35134 0.0839844 13.7497C0.0839844 18.3113 2.36482 22.3172 5.83398 24.7513V29.083C5.83398 30.1372 6.69648 30.9997 7.75065 30.9997H19.2507C20.3048 30.9997 21.1673 30.1372 21.1673 29.083V24.7513C24.6365 22.3172 26.9173 18.3113 26.9173 13.7497C26.9173 6.35134 20.899 0.333008 13.5007 0.333008ZM18.9632 21.608L17.334 22.758V27.1663H9.66732V22.758L8.03815 21.608C6.76834 20.7277 5.73036 19.5532 5.01279 18.1848C4.29523 16.8165 3.91938 15.2948 3.91732 13.7497C3.91732 8.45967 8.21065 4.16634 13.5007 4.16634C18.7907 4.16634 23.084 8.45967 23.084 13.7497C23.084 16.8738 21.5507 19.8063 18.9632 21.608Z" fill="#457ABD"/>
    </svg>
  );
};

export default Otticon;