import * as React from "react";
import ColorHelper from "../../Helpers/ColorHelper";

const vSMS = ({ size = 46, color, ...props }) => (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="48" height="48" rx="24" fill="#FFF3F5"/>
    <path d="M34 24C34 29.523 29.523 34 24 34H14V24C14 18.477 18.477 14 24 14C29.523 14 34 18.477 34 24Z" stroke="#DA1A5D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M19 21H28" stroke="#DA1A5D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M19 25H28" stroke="#DA1A5D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M19 29H24" stroke="#DA1A5D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
);

export default vSMS;