import { Box } from "@mui/system";
import React from "react";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Link } from "react-router-dom";

// Custom Components
import BorderedButton from "../Ui-Components/Buttons/BorderedButton/BorderedButton";
import FilledButton from "../Ui-Components/Buttons/FilledButton/FilledButton";

// Custom Styling
import FooterSectionStyle from "./FooterSectionStyle";

// Helpers
import ConstantHelper from "../../Helpers/ConstantHelper";
import ColorHelper from "../../Helpers/ColorHelper";
import IconHelper from "../../Helpers/IconHelper";
import ImageHelper from "../../Helpers/ImageHelper";

// Data
import { FooterMenuData1 } from "./FooterMenuData";
import { FooterMenuData2 } from "./FooterMenuData";
import { FooterMenuData3 } from "./FooterMenuData";
import { FooterMenuData4 } from "./FooterMenuData";
import { FooterMenuData5 } from "./FooterMenuData";
import { memo } from "react";

const FooterSection = memo(function FooterSection(props) {
  const GalaxyFold = useMediaQuery("(width: 280px)");

  const navigate = useNavigate();

  const handleProductsPage = (url, productsIndex) => {
    console.log(url, productsIndex);
    navigate(`${url}`, { state: { index: productsIndex } });
  };

  // const laptop = useMediaQuery('(min-width:1280px)');

  return (
    <>
      {props.footerCardShow && (
        <Box sx={FooterSectionStyle.FooterCard}>
          <Box sx={FooterSectionStyle.textContainer}>
            <Typography sx={FooterSectionStyle.cardTitle}>
              {ConstantHelper.FooterCardTitle}
            </Typography>
            {/* <Typography sx={FooterSectionStyle.cardCaption}>
            {ConstantHelper.FooterCardCaption}
          </Typography> */}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
              minHeight: "auto",
              ...(GalaxyFold && {
                justifyContent: "space-around",
                flexDirection: "column",
                minHeight: "150px",
              }),
            }}
          >
            <Box
              onClick={() => navigate("/contact")}
              sx={{ cursor: "pointer" }}
            >
              <FilledButton
                btnText={ConstantHelper.FooterCardBtn2}
                color={ColorHelper.white}
                bgColor={ColorHelper.PriamryColor}
              />
            </Box>
          </Box>
        </Box>
      )}

      <Box sx={FooterSectionStyle.footerMain}>
        <Box sx={FooterSectionStyle.footerHead}>
          <Link to="/">
            <Box
              sx={FooterSectionStyle.imageBackground}
              className="imageBackgroundMobile"
            >
              <img src={ImageHelper.Logo} alt="logo" />
            </Box>
          </Link>

          <Typography sx={FooterSectionStyle.FootershortDesc}>
            {ConstantHelper.FootershortDesc}
          </Typography>
          <Box sx={FooterSectionStyle.socialIconsContainer}>
            <Box
              onClick={() => window.open(ConstantHelper.fb)}
              style={FooterSectionStyle.Link}
            >
              <Box sx={FooterSectionStyle.FooterIcons}>
                <img src={IconHelper.Facebook} alt="BanrdsLogo" />
              </Box>
            </Box>
            <Box
              onClick={() => window.open(ConstantHelper.LinkedIn)}
              style={FooterSectionStyle.Link}
            >
              <Box sx={FooterSectionStyle.FooterIcons}>
                <img src={IconHelper.LinkedIn} alt="BanrdsLogo" />
              </Box>
            </Box>
            <Box
              onClick={() => window.open(ConstantHelper.Twitter)}
              style={FooterSectionStyle.Link}
            >
              <Box sx={FooterSectionStyle.FooterIcons}>
                <img src={IconHelper.Twitter} alt="BanrdsLogo" />
              </Box>
            </Box>
            <Box
              onClick={() => window.open(ConstantHelper.Instagram)}
              style={FooterSectionStyle.Link}
            >
              <Box sx={FooterSectionStyle.FooterIcons}>
                <img src={IconHelper.Instagram} alt="BanrdsLogo" height={26} />
              </Box>
            </Box>
          </Box>
        </Box>

        <Box sx={FooterSectionStyle.MenuListingContainer}>
          <Box
            sx={{
              ...FooterSectionStyle.MenuListing,
              // ...laptop && {
              //   ml: 0,
              // }
            }}
          >
            <Typography sx={FooterSectionStyle.MenuTitle}>
              {ConstantHelper.FooterMenu1}
            </Typography>
            {FooterMenuData1.map((item, index) => (
              <Box
                onClick={() =>
                  handleProductsPage(
                    `/${ConstantHelper?.FooterMenu1?.toLowerCase()}` + item.url,
                    index
                  )
                }
                style={FooterSectionStyle.Link}
                key={item.id}
              >
                <Typography sx={FooterSectionStyle.MenuLink}>
                  {item.title}
                </Typography>
                {item.IsItnew && (
                  <Typography sx={FooterSectionStyle.MenuLinkBadge}>
                    New
                  </Typography>
                )}
              </Box>
            ))}
          </Box>

          <Box sx={FooterSectionStyle.MenuListing}>
            <Typography sx={FooterSectionStyle.MenuTitle}>
              {ConstantHelper.FooterMenu3}
            </Typography>
            {FooterMenuData3.map((item, index) => (
              <Box
                onClick={() =>
                  handleProductsPage(
                    `/${ConstantHelper.FooterMenu3?.toLowerCase()}` + item.url,
                    index
                  )
                }
                style={FooterSectionStyle.Link}
                key={item.id}
              >
                <Typography sx={FooterSectionStyle.MenuLink}>
                  {item.title}
                </Typography>
                {item.IsItnew && (
                  <Typography sx={FooterSectionStyle.MenuLinkBadge}>
                    New
                  </Typography>
                )}
              </Box>
            ))}
          </Box>

          <Box sx={FooterSectionStyle.MenuListing}>
            <Typography sx={FooterSectionStyle.MenuTitle}>
              {ConstantHelper.FooterMenu4}
            </Typography>
            {FooterMenuData5.map((item, index) => (
              <Box
                onClick={() =>
                  handleProductsPage(
                    `/${ConstantHelper.FooterMenu4?.toLowerCase()}` + item.url,
                    index
                  )
                }
                style={FooterSectionStyle.Link}
                key={item.id}
              >
                {item?.title ? (
                  <Typography sx={FooterSectionStyle.MenuLink}>
                    {item.title}
                    <br />
                    {item?.title ? item.title2 : null}
                  </Typography>
                ) : null}

                {item.IsItnew && (
                  <Typography sx={FooterSectionStyle.MenuLinkBadge}>
                    New
                  </Typography>
                )}
              </Box>
            ))}
          </Box>

          <Box sx={FooterSectionStyle.MenuListing}>
            <Typography sx={FooterSectionStyle.MenuTitle}>
              {ConstantHelper.FooterMenu2}
            </Typography>
            {FooterMenuData2.map((item, index) => (
              <Box
                onClick={() => handleProductsPage(item.url, index)}
                style={FooterSectionStyle.Link}
                key={item.id}
              >
                <Typography sx={FooterSectionStyle.MenuLink}>
                  {item.title}
                </Typography>
                {item.IsItnew && (
                  <Typography sx={FooterSectionStyle.MenuLinkBadge}>
                    New
                  </Typography>
                )}
              </Box>
            ))}
          </Box>

          {/** */}
          <Box sx={FooterSectionStyle.MenuListing}>
            <Typography sx={FooterSectionStyle.MenuTitle}>
              {ConstantHelper.FooterMenu5}
            </Typography>
            {FooterMenuData4.map((item, index) => (
              <Box
                onClick={() => handleProductsPage(item.url, index)}
                style={FooterSectionStyle.Link}
                key={item.id}
              >
                <Typography sx={FooterSectionStyle.MenuLink}>
                  {item.title}
                </Typography>
                {item.IsItnew && (
                  <Typography sx={FooterSectionStyle.MenuLinkBadge}>
                    New
                  </Typography>
                )}
              </Box>
            ))}
          </Box>

          {/** */}
        </Box>
      </Box>

      <Box sx={FooterSectionStyle.creditsContainer}>
        <Box sx={FooterSectionStyle.copyrightWrapper}>
          <Typography sx={FooterSectionStyle.Copyright}>
            {ConstantHelper.Copyright}
          </Typography>
        </Box>
        {/* <Box sx={FooterSectionStyle.socialIconsContainer}>
          <Box
            onClick={() => window.open(ConstantHelper.fb)}
            style={FooterSectionStyle.Link}
          >
            <Box sx={FooterSectionStyle.FooterIcons}>
              <img src={IconHelper.Facebook} alt="BanrdsLogo" />
            </Box>
          </Box>
          <Box
            onClick={() => window.open(ConstantHelper.LinkedIn)}
            style={FooterSectionStyle.Link}
          >
            <Box sx={FooterSectionStyle.FooterIcons}>
              <img src={IconHelper.LinkedIn} alt="BanrdsLogo" />
            </Box>
          </Box>
          <Box
            onClick={() => window.open(ConstantHelper.Twitter)}
            style={FooterSectionStyle.Link}
          >
            <Box sx={FooterSectionStyle.FooterIcons}>
              <img src={IconHelper.Twitter} alt="BanrdsLogo" />
            </Box>
          </Box>
        </Box> */}
      </Box>
      {props.ChatBot && <Box id="ChatBot"></Box>}
    </>
  );
});

export default FooterSection;
