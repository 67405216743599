const ColorHelper = {

  PriamryColor: "#da1a5d",

  blue: "#457abd",

  lighBlue: "#F5F8FF",

  lighBlue1: "#A9BAD9",

  white: "#fff",

  white1: "#F5F8FF",

  white2: "#FFF3F5",

  whiteOp: "rgba(249, 245, 255, 0.2)",

  black: "#101828",

  grey: "#000",

  grey1: "#344054",

  grey2: "#E4E7EC",

  grey3: "#98A2B3",

  grey4: "#475467",

  lightGrey: "#D0D5DD",

  transparent: "transparent",

  BgBlue: "#457ABD",

  BgDarkBlue: "#00008B",

  green: "#027A48",

  lightGreen: "#ECFDF3",

  softBlue: "#1E3654",

  BgYellow: "#FFFB05",

  AppleGreen: "#AACF37",

  SafronLight: "#FFF3F5",


};



export default ColorHelper;