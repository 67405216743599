import React from "react";
import ColorHelper from "../../Helpers/ColorHelper";

const HighlightIcon3 = () => {
  return (
    <svg
      width="32"
      height="40"
      viewBox="0 0 32 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.58301 2.75L29.4163 20L2.58301 37.25V2.75Z"
        stroke={ColorHelper.BgBlue}
        stroke-width="3.83333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default HighlightIcon3;
