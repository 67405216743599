import React from "react";

const ChevronUp = (props) => {
  const { color } = props;
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15 12.5L10 7.5L5 12.5" stroke="white" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    
  );
};

export default ChevronUp;