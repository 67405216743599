import React from "react";
import ColorHelper from "../../Helpers/ColorHelper";

const HighlightIconV3_1 = () => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.2502 40.2497C17.2502 41.3038 18.1127 42.1663 19.1668 42.1663H26.8335C27.8877 42.1663 28.7502 41.3038 28.7502 40.2497V38.333H17.2502V40.2497ZM23.0002 3.83301C15.6018 3.83301 9.5835 9.85134 9.5835 17.2497C9.5835 21.8113 11.8643 25.8172 15.3335 28.2513V32.583C15.3335 33.6372 16.196 34.4997 17.2502 34.4997H28.7502C29.8043 34.4997 30.6668 33.6372 30.6668 32.583V28.2513C34.136 25.8172 36.4168 21.8113 36.4168 17.2497C36.4168 9.85134 30.3985 3.83301 23.0002 3.83301ZM28.4627 25.108L26.8335 26.258V30.6663H19.1668V26.258L17.5377 25.108C16.2678 24.2277 15.2299 23.0532 14.5123 21.6848C13.7947 20.3165 13.4189 18.7948 13.4168 17.2497C13.4168 11.9597 17.7102 7.66634 23.0002 7.66634C28.2902 7.66634 32.5835 11.9597 32.5835 17.2497C32.5835 20.3738 31.0502 23.3063 28.4627 25.108Z"
        fill={ColorHelper.BgBlue}
      />
    </svg>
  );
};

export default HighlightIconV3_1;
