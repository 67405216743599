const TalkToUsListingData = [
  {
    option: "products",
    optionData: [
      { name: "vLive - End to End OTT SaaS Suite", value: "vLive" },
      { name: "Sounds - Audio platform as a Service", value: "Sounds" },
      { name: "vLite MOOC - eLearning Managment Service", value: "vLite MOOC" },
      { name: "vApps - On Demand App Store", value: "vApps" },
      {
        name: "vDRM - Multi-DRM Packaging and license Service",
        value: "vDRM",
      },
      { name: "vSMS - Enhanced OTT Subscriber Managment", value: "vSMS" },
      { name: "vCMS - Centralized Video Content Management", value: "vCMS" },
      {
        name: "CDN & Cloud Networking - Accelarate delivery of content to worldwide",
        value: "CDN & Cloud Networking",
      },
    ],
  },
  {
    option: "Solutions",
    optionData: [
      { name: "OTT", value: "OTT" },
      { name: "Broadcast", value: "Broadcast" },
      { name: "Digitization", value: "Digitization" },
    ],
  },
    {
          option: "Services",
          optionData: [
             {name: "OTT Apps",value:"OTT Apps"},
             {name: "OTT Consulting",value:"OTT Consulting"},
            //  {name: "DevOps",value:"DevOps"},
              {name:"Custom Development",value:"Custom Development"},
              // {name:"QA Automation",value:"QA Automation"},
          ]
      },  
];

export default TalkToUsListingData;
