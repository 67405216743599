import ColorHelper from "../../../Helpers/ColorHelper";
import FontHelper from "../../../Helpers/FontHelper";
const ServicesListingStyle = {
  ServicesListingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    flexWrap: "wrap",
    px: { xs: 3, md: 20 },
    my: { xs: 2, md: 1 },
    minHeight: { xs: "50vmax", md: "25vmax", lg: "30vmax" },
    pt: 10,
  },
  singleItemWrapper: {
    width: { xs: "100%", md: "25%" },
    my: { xs: 2, md: 5 },
    px: { xs: 0, md: 2 },
  },
  Itemtitle: {
    fontSize: "19px",
    fontWeight: "bolder",
    color: ColorHelper.blue,
    fontFamily: FontHelper.FontSemiBold,

  },

  /* 
color: #366eb4;
font-size: 19px;
font-weight: bolder; */
  ItemtDesc: {
    fontSize: "0.8rem",
    fontWeight: "100",
    color: ColorHelper.grey,
    fontFamily: FontHelper.FontRegular,
  },
  ServicesListingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    flexWrap: "wrap",
    px: { xs: 3, md: 20 },
    my: { xs: 2, md: 1 },
    minHeight: { xs: "50vmax", md: "25vmax", lg: "30vmax" },
    pt: 5,
  },
  singleItemWrapper: {
    width: { xs: "100%", md: "25%" },
    my: { xs: 2, md: 5 },
    px: { xs: 0, md: 2 },
  },
  Itemtitle: {
    fontSize: "1rem",
    fontWeight: "800",
    color: ColorHelper.grey1,
  },
  ItemtDesc: {
    fontSize: "1rem",
    fontWeight: "100",
    color: ColorHelper.grey,
  },
  card: {
    alignSelf: 'stretch',
    p: 4,
    border: `2px solid ${ColorHelper.blue}`,
    borderRadius: 2,
    width: { xs: '100%', md: '30%' },
    minWidth: { xs: '100%', md: '30%' },
    // aspectRatio: '1/1',

    mx: { xs: 0, md: 2 },
    my: { xs: 2, md: 2 },

    '&:hover': {
      //borderRadius: 9,
      backgroundcolor: "blue"
    },

  },

  cardTitle: {
    fontFamily: FontHelper.FontSemiBold,
    fontSize: 18,
    fontWeight: 800,
    color: ColorHelper.grey1,
    mb: 1.5,
  },
  cardDesc: {
    fontSize: 15,
    color: ColorHelper.grey,
  }
}

export default ServicesListingStyle;