import React from "react";
import { Box } from "@mui/system";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
// Custom Style
import BrandSectionStyle from "./BrandSectionStyle";

// Helper
import ImageHelper from "../../Helpers/ImageHelper";
import { memo } from "react";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1619 },
    items: 7,
    slidesToSlide: 1,
  },
  laptop: {
    breakpoint: { max: 1619, min: 1024 },
    items: 7,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 640 },
    items: 3,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 639, min: 0 },
    items: 2,
    slidesToSlide: 1,
  },
};

const carouselParams = {
  additionalTransfrom: 0,
  arrows: false,
  autoPlay: true,
  autoPlaySpeed: 1000,
  centerMode: false,
  Height: "50px",
  className: "",
  containerClass: "carousel-container",
  dotListClass: "",
  draggable: true,
  focusOnSelect: false,
  infinite: true,
  itemClass: "",
  keyBoardControl: true,
  minimumTouchDrag: 80,
  renderButtonGroupOutside: true,
  renderDotsOutside: false,
  responsive: responsive,
  showDots: false,
  sliderClass: "",
  slidesToSlide: 1,
  width: "100%"
};

const BrandSection = memo(function BrandSection() {
  return (
    <div className="container-fluid">
      <Box sx={BrandSectionStyle.container} id="BrandsContianer">
        <Carousel {...carouselParams}>
          <Box sx={BrandSectionStyle.brandLogoImgs}>
            <LazyLoadImage src={ImageHelper.BanrdsLogo1} alt="BanrdsLogo" effect="blur"/>
          </Box>
          <Box sx={BrandSectionStyle.brandLogoImgs}>
            <LazyLoadImage src={ImageHelper.BanrdsLogo2} alt="BanrdsLogo" effect="blur"/>
          </Box>
          <Box sx={BrandSectionStyle.brandLogoImgs}>
            <LazyLoadImage src={ImageHelper.BanrdsLogo3} alt="BanrdsLogo" effect="blur"/>
          </Box>
          <Box sx={BrandSectionStyle.brandLogoImgs}>
            <LazyLoadImage src={ImageHelper.BanrdsLogo4} alt="BanrdsLogo" effect="blur"/>
          </Box>
          <Box sx={BrandSectionStyle.brandLogoImgs}>
            <LazyLoadImage src={ImageHelper.BanrdsLogo5} alt="BanrdsLogo" effect="blur"/>
          </Box>
          <Box sx={BrandSectionStyle.brandLogoImgs}>
            <LazyLoadImage src={ImageHelper.BanrdsLogo6} alt="BanrdsLogo" effect="blur"/>
          </Box>
          <Box sx={BrandSectionStyle.brandLogoImgs}>
            <LazyLoadImage src={ImageHelper.BanrdsLogo7} alt="BanrdsLogo" effect="blur"/>
          </Box>
          <Box sx={BrandSectionStyle.brandLogoImgs}>
            <LazyLoadImage src={ImageHelper.BanrdsLogo8} alt="BanrdsLogo" effect="blur"/>
          </Box>
          <Box sx={BrandSectionStyle.brandLogoImgs}>
            <LazyLoadImage src={ImageHelper.BanrdsLogo9} alt="BanrdsLogo" effect="blur"/>
          </Box>
          <Box sx={BrandSectionStyle.brandLogoImgs}>
            <LazyLoadImage src={ImageHelper.BanrdsLogo10} alt="BanrdsLogo" effect="blur"/>
          </Box>

          {/* <Box sx={BrandSectionStyle.brandLogoImgs}>
            <img src={ImageHelper.BanrdsLogo12} alt="BanrdsLogo" />
          </Box><Box sx={BrandSectionStyle.brandLogoImgs}>
            <img src={ImageHelper.BanrdsLogo13} alt="BanrdsLogo" />
          </Box>
          <Box sx={BrandSectionStyle.brandLogoImgs}>
            <img src={ImageHelper.BanrdsLogo1} alt="BanrdsLogo" />
          </Box>
          <Box sx={BrandSectionStyle.brandLogoImgs}>
            <img src={ImageHelper.BanrdsLogo14} alt="BanrdsLogo" />
          </Box> */}
        </Carousel>
      </Box>
    </div>
  );
});

export default BrandSection;
