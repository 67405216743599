import React from "react";

const StreamingTypeIcon7 = (props) => {
  const { color } = props;
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23 3.83331V11.5"
        stroke={color}
        strokeWidth="3.83333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23 34.5V42.1667"
        stroke={color}
        strokeWidth="3.83333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.44922 9.44916L14.8734 14.8733"
        stroke={color}
        strokeWidth="3.83333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.1267 31.1266L36.5509 36.5508"
        stroke={color}
        strokeWidth="3.83333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.83325 23H11.4999"
        stroke={color}
        strokeWidth="3.83333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.5 23H42.1667"
        stroke={color}
        strokeWidth="3.83333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.44922 36.5508L14.8734 31.1266"
        stroke={color}
        strokeWidth="3.83333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.1267 14.8733L36.5509 9.44916"
        stroke={color}
        strokeWidth="3.83333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default StreamingTypeIcon7;
