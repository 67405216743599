import React from "react";
import ColorHelper from "../../Helpers/ColorHelper";

const HighlightIconV2_4 = () => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.9375 5.75H10.0625C9.68125 5.75 9.31562 5.59855 9.04603 5.32897C8.77645 5.05938 8.625 4.69375 8.625 4.3125C8.625 3.93125 8.77645 3.56562 9.04603 3.29603C9.31562 3.02645 9.68125 2.875 10.0625 2.875H35.9375C36.3187 2.875 36.6844 3.02645 36.954 3.29603C37.2235 3.56562 37.375 3.93125 37.375 4.3125C37.375 4.69375 37.2235 5.05938 36.954 5.32897C36.6844 5.59855 36.3187 5.75 35.9375 5.75ZM40.25 10.0625C40.25 9.68125 40.0985 9.31562 39.829 9.04603C39.5594 8.77645 39.1937 8.625 38.8125 8.625H7.1875C6.80625 8.625 6.44062 8.77645 6.17103 9.04603C5.90145 9.31562 5.75 9.68125 5.75 10.0625C5.75 10.4437 5.90145 10.8094 6.17103 11.079C6.44062 11.3485 6.80625 11.5 7.1875 11.5H38.8125C39.1937 11.5 39.5594 11.3485 39.829 11.079C40.0985 10.8094 40.25 10.4437 40.25 10.0625ZM18.6875 32.1712L25.53 28.75L18.6875 25.3288V32.1712ZM43.125 18.6875V38.8125C43.125 39.9562 42.6707 41.0531 41.8619 41.8619C41.0531 42.6707 39.9562 43.125 38.8125 43.125H7.1875C6.04375 43.125 4.94685 42.6707 4.1381 41.8619C3.32935 41.0531 2.875 39.9562 2.875 38.8125V18.6875C2.875 17.5438 3.32935 16.4469 4.1381 15.6381C4.94685 14.8294 6.04375 14.375 7.1875 14.375H38.8125C39.9562 14.375 41.0531 14.8294 41.8619 15.6381C42.6707 16.4469 43.125 17.5438 43.125 18.6875ZM30.1875 28.75C30.1867 28.4845 30.1124 28.2245 29.9728 27.9987C29.8333 27.7728 29.6339 27.5901 29.3969 27.4706L17.8969 21.7206C17.678 21.6104 17.4347 21.5579 17.1898 21.5681C16.945 21.5784 16.7069 21.651 16.498 21.7792C16.2892 21.9074 16.1166 22.0869 15.9966 22.3005C15.8766 22.5142 15.8132 22.755 15.8125 23V34.5C15.812 34.7443 15.8738 34.9847 15.992 35.1985C16.1102 35.4123 16.281 35.5924 16.4881 35.7219C16.7169 35.8637 16.9809 35.9384 17.25 35.9375C17.475 35.9361 17.6966 35.882 17.8969 35.7794L29.3969 30.0294C29.6339 29.9099 29.8333 29.7272 29.9728 29.5013C30.1124 29.2755 30.1867 29.0155 30.1875 28.75Z"
        fill={ColorHelper.BgBlue}
      />
    </svg>
  );
};

export default HighlightIconV2_4;
