import React from "react";

const BlogIcon = ({ size = 46, color, ...props }) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="48" height="48" rx="24" fill="#FFF7F8" />
    <g clip-path="url(#clip0_3537_81621)">
      <g clip-path="url(#clip1_3537_81621)">
        <path
          d="M24.1239 26.6157H30.6253L34.5771 30.8566L34.6035 19.6745C34.6116 15.8273 31.4951 12.7042 27.6478 12.7042H21.8022C17.9606 12.7042 14.8464 15.8184 14.8464 19.66C14.8464 22.7812 16.9023 25.4223 19.7337 26.3029"
          stroke="#DA1A5D"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13.9339 34.7585C13.2173 34.0418 13.2173 32.88 13.9339 32.1634L21.3356 24.7617L24.6241 24.0668L23.9307 27.3567L16.5289 34.7585C15.8124 35.4751 14.6506 35.4751 13.9339 34.7585Z"
          stroke="#DA1A5D"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15.1602 30.9371L17.7552 33.5321"
          stroke="#DA1A5D"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_3537_81621">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(12 12)"
        />
      </clipPath>
      <clipPath id="clip1_3537_81621">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(12 12)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default BlogIcon;