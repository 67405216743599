import React from "react";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

// Helper
import CategoriesData from "./CategoriesData";
import ConstantHelper from "../../Helpers/ConstantHelper";

// Custom Style
import CategoriesStyle from "./CategoriesStyle";
import "./CategoriesStyle.css";
import { memo } from "react";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1399 },
    items: 5,
    slidesToSlide: 1,
  },
  laptop: {
    breakpoint: { max: 1399, min: 1024 },
    items: 5,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 640 },
    items: 3,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 639, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const carouselParams = {
  additionalTransfrom: 0,
  arrows: false,
  autoPlay: true,
  autoPlaySpeed: 1000,
  centerMode: false,
  className: "",
  containerClass: "carousel-container",
  dotListClass: "",
  draggable: true,
  focusOnSelect: false,
  infinite: true,
  itemClass: "",
  keyBoardControl: true,
  minimumTouchDrag: 80,
  renderButtonGroupOutside: true,
  renderDotsOutside: false,
  responsive: responsive,
  showDots: false,
  sliderClass: "",
  slidesToSlide: 1,
};

const Categories = memo(function Categories() {
  return (
    <Box sx={CategoriesStyle.CategoriesWrapper}>
      <Typography sx={CategoriesStyle.caption} variant="h6" component="h2">
        {ConstantHelper.CatergoriesCaption}
      </Typography>
      <Typography
        sx={CategoriesStyle.CatergoriesTitle}
        variant="h6"
        component="h2"
      >
        {ConstantHelper.CatergoriesTitle}
      </Typography>

      <Box sx={CategoriesStyle.categoriesContianer} id="categoriesContianer">
        <Carousel {...carouselParams}>
          {CategoriesData.map((item) => (
            <Box
              sx={{
                // backgroundImage: `url(${item.image})`,
                ...CategoriesStyle.categoryBlock,
              }}
              key={item.id}
              id="category"
            >
              <img src={item.image} alt="Catgories Images" />
              <Box id="overlay" sx={CategoriesStyle.overlay}></Box>
              <Box sx={CategoriesStyle.categorytexts}>
                <Typography
                  sx={CategoriesStyle.categorytTitle}
                  variant="h6"
                  component="h2"
                >
                  {item.title}
                </Typography>
                <Typography
                  sx={CategoriesStyle.categoryDesc}
                  variant="h6"
                  component="h2"
                  id="categoryDesc"
                >
                  {item.desc}
                </Typography>
              </Box>
            </Box>
          ))}
        </Carousel>
      </Box>
    </Box>
  );
});

export default Categories;