import React from "react";

const NavbarIcon5 = (props) => {
  const { color, bgColor } = props;
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="24" fill={bgColor} />
      <path
        d="M35 18L25.5 27.5L20.5 22.5L13 30M35 18H29M35 18V24"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NavbarIcon5;
