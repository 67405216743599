import React from "react";

const FbMessenger = (props) => {
  const { color } = props;
  return (
    <svg
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 19.8732C15.936 19.8732 19.9375 15.998 19.9375 11.2178C19.9375 6.43762 15.936 2.5625 11 2.5625C6.06396 2.5625 2.0625 6.43762 2.0625 11.2178C2.0625 13.8177 3.24616 16.1498 5.12007 17.7364V19.4879C5.12007 20.1725 5.81735 20.6316 6.43904 20.3564L8.38268 19.4961C9.21047 19.7413 10.0893 19.8732 11 19.8732Z"
        fill={color}
      />
      <path
        d="M8.85982 9.37791L6.2681 12.9224C5.99022 13.3024 6.48996 13.759 6.87042 13.4727L9.08344 11.8072C9.3087 11.6377 9.62447 11.6354 9.85235 11.8017L11.8205 13.2375C12.198 13.5128 12.7374 13.4336 13.0123 13.0625L15.7303 9.39217C16.0122 9.01152 15.5086 8.55127 15.1276 8.84133L12.7879 10.6225C12.5626 10.794 12.2452 10.7971 12.0163 10.6301L10.0544 9.19901C9.67542 8.92251 9.13346 9.00367 8.85982 9.37791Z"
        fill="#457ABD"
      />
    </svg>
  );
};

export default FbMessenger;
