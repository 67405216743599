// Helper
import ColorHelper from "../../../Helpers/ColorHelper";

const CustomNavbarStyle = {

  MainMenu: {
    backgroundColor: "#dc0053",
    color: "#fff"
  },
  liOfMenu: {
    width: { xs: '100vw', md: 'unset' },
    justifyContent: 'center',
    mr: 1,
    backgroundColor: "#dc0053",
    '&: hover': {
      backgroundColor: '#dc0053',
      color: "#fff",
    },
    '&: focusVisible': {
      backgroundColor: '#dc0053',
      color: "#fff"
    },
  },
  MainMenuText: {
    color: ColorHelper.black,
    mr: 0.8,
  },
  MenuItem: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "300px",
    my: 1,
    px: 3,
    py: 1.5,


  },
  SubMenuText: {
    ml: 0,
    'a':{
      width: '100%',
      'p':{
        width: '100%',
      }
    }
  },
  subMenuTitle: {
    fontSize: 14,
    fontWeight: 800,
    color: ColorHelper.black,
    mb: 0.5,
  },
  subMenuCaption: {
    fontSize: 13,
    fontWeight: 500,
    color: ColorHelper.black,
  },
  logoContainer: {
    cursor: 'pointer',
  }
};

export default CustomNavbarStyle;
