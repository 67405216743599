import ColorHelper from "../../../Helpers/ColorHelper";
import FontHelper from "../../../Helpers/FontHelper";

const LeadeinfoStyle = {
    main: {
        p: 0,
        textAlign: "center",
        pb: 0,
    },
    mainLeader: {
        p: 1,
        textAlign: "center",
        mt: {xs: 0, md: 6},
        pb: {xs: 0, md: 6},
    },

    headings: {
        my: 3,
      
    },
    cardContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        maxWidth: '100%',
        maxheight: "50%",
        px: { xs: 4, md: 4, lg: 10 },

    },
    cardContainerLeader: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        maxWidth: '100%',
        maxheight: "50%",
        px: { xs: 4, md: 0, lg: 10 },

    },
    headlinetxt: {
        color: ColorHelper.white,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: { xs: 14, md: 14 },
        maxWidth: "100%",
        // transform: { xs: `translateY(10%)`, md: `translateY(-50%)` },
        // mt:-0.5,
      

    },
    title: {
        fontSize: 14,
        fontFamily: FontHelper.FontSemiBold,
    },


    card: {
        display: 'flex',
        justifyContent: { xs: 'center', md: 'center' },
        alignItems: 'center',
        flexDirection: 'column',
         
        alignSelf: 'stretch',
        p: 5,
       // border: `2px solid ${ColorHelper.blue}`,
        borderRadius: 2,
        // width: {xs: '100%', md: '30%'},
        // minWidth: {xs: '100%', md: '30%'},
        aspectRatio: '1/1',

        mx: { xs: 0, md: 2 },
        my: { xs: 2, md: 2 },

        '&:hover': {
            //borderRadius: 9,
            backgroundcolor: "blue"
        },

    },
    heading: {
        fontfamily: FontHelper.FontRegular,
        fontSize: 14,
        fontWeight: 400,
        color: ColorHelper.black,
        mb: 1.5,
    
    },
    blue: {
        color: ColorHelper.blue,
     
    },
    social: {
        color: ColorHelper.blue,
       
    },
    readcontainer: {
        width: {sx:0, md: "400px"}
    },
    Read: {
        fontfamily: FontHelper.FontRegular,
        fontSize: "14px",
        fontWeight: 400,
        color: ColorHelper.PriamryColor,
        mb: 1.5,
    
    },

    cardTitle: {
        fontfamily: FontHelper.FontRegular,
        fontSize: 14,
        fontWeight: 400,
        color: ColorHelper.blue,
        mb: 1.5,
    
    },
    imgc: {
       // backgoundcolor: ColorHelper.blue,
        borderRadius: '100px',
        width: "100%",
        height: "100%"
   
      //  mb: 1.5,
    
    },
    cardDesc: {
        fontSize: "14px",
        color: ColorHelper.grey,
        fontfamily: FontHelper.FontRegular,
        margin:0,
        fontWeight: 400,
        height: "30%",
     
        mb: {md: 2}
    }

  
}

export default LeadeinfoStyle;