import React, { useState, useEffect } from "react";
import { Box } from '@mui/system'
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";


import ColorHelper from '../../Helpers/ColorHelper'
import IconHelper from '../../Helpers/IconHelper'
import FilledButton from '../Ui-Components/Buttons/FilledButton/FilledButton'
import ConstantHelper from "../../Helpers/ConstantHelper";

const StickyCTABtn = () => {
    const [ctaBtnText, setctaBtnText] = useState("");

    const theme = useTheme();
    const aboveMobile = useMediaQuery(theme.breakpoints.up("sm"));
  
    useEffect(() => {
      if (aboveMobile === true) {
        setctaBtnText(ConstantHelper.CTA_btn);
      } else {
        setctaBtnText("");
      }
    }, [aboveMobile]);

  return (  
    <Box sx={{
        position: "fixed",
        bottom: "0.3rem",
        right: "2rem",
    }}>
    {/* <FilledButton
      btnText={ctaBtnText}
      color={ColorHelper.white}
      bgColor={ColorHelper.blue}
      icon={<IconHelper.FbMessenger color={ColorHelper.white} />}
    /> */}
  </Box>
  )
}

export default StickyCTABtn