import * as React from "react";
import ColorHelper from "../../Helpers/ColorHelper";

const OTTApps = ({ size = 46, color, ...props }) => (
  
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="48" height="48" rx="24" fill="#FFF3F5"/>
<path d="M29 14H19V34H29V14Z" stroke="#DA1A5D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M34 16H29V32H34V16Z" stroke="#DA1A5D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M19 16H14V32H19V16Z" stroke="#DA1A5D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

);

export default OTTApps;