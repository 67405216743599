import React from "react";

const HLTvLive06 = () => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3086_83104)">
        <path
          d="M31.4241 12.8476C31.9453 15.488 33.9471 17.596 36.5291 18.2707C36.8024 18.3421 37.0261 18.5382 37.1394 18.7968L38.0515 20.8789C38.1495 21.1027 38.4668 21.1027 38.5649 20.8789L39.4769 18.7968C39.5901 18.5383 39.8134 18.3421 40.0864 18.2709C43.1011 17.4835 45.3261 14.7428 45.3261 11.4814C45.3261 7.54892 42.0918 4.37186 38.1382 4.46539C34.967 4.54041 32.2998 6.75398 31.5207 9.7031"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M40.4403 12.4585C40.4403 13.636 39.4857 14.5906 38.3082 14.5906C37.1307 14.5906 36.1761 13.636 36.1761 12.4585"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M36.0215 9.2605V9.97116"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M40.595 9.2605V9.97116"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M28.3022 13.4883V10.1784C28.3022 5.23456 24.2945 1.22681 19.3507 1.22681H17.4555C15.6095 1.22681 13.894 1.78581 12.469 2.74346"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10.2058 4.92725C9.13551 6.4023 8.50391 8.21643 8.50391 10.1783V13.4883"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12.3568 27.8459L7.20728 29.5324"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1.47546 38.9011V43.3721C1.47546 44.146 2.10284 44.7733 2.87667 44.7733H17.9418"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10.667 18.7574V13.1133H9.87687C8.38421 13.1133 7.17419 14.3233 7.17419 15.816V16.6298C7.17419 18.1224 8.38421 19.3324 9.87687 19.3324H10.6953C10.6771 19.1431 10.667 18.9514 10.667 18.7574Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M26.9294 13.1133H26.1393V18.7574C26.1393 18.9514 26.1292 19.1431 26.1111 19.3324H26.9294C28.422 19.3324 29.6321 18.1223 29.6321 16.6297V15.8159C29.632 14.3233 28.422 13.1133 26.9294 13.1133Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14.4931 24.3115L12.9673 25.4232C12.5837 25.7027 12.3568 26.1487 12.3568 26.6233V33.1421C12.3568 33.5143 12.7961 33.7121 13.0748 33.4655L17.9418 29.1592"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14.7285 24.3955V26.5431C14.7285 26.6642 14.7794 26.7798 14.8686 26.8615L17.9418 29.6778V24.7936C17.9418 24.7625 17.9435 24.7319 17.9465 24.7017"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M18.8805 23.8548H23.2535C24.9823 22.8153 26.1393 20.9218 26.1393 18.7575V12.6011C26.1393 11.2343 25.0312 10.1262 23.6644 10.1262H13.1418C11.775 10.1262 10.667 11.2343 10.667 12.6011V18.7575C10.667 22.0404 13.3283 24.7017 16.6111 24.7017H17.9463C17.9926 24.2263 18.393 23.8548 18.8805 23.8548Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M21.3041 19.2756C21.3041 20.5636 20.2599 21.6078 18.9719 21.6078H17.8342C16.5462 21.6078 15.5021 20.5636 15.5021 19.2756"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14.4384 13.4253V14.8758"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M22.3678 13.4253V14.8758"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M18.4031 14.4753C17.7889 14.4753 17.291 14.9733 17.291 15.5874C17.291 16.2016 17.7889 16.6995 18.4031 16.6995"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10.3168 41.5381C9.53916 41.5381 8.90881 42.1685 8.90881 42.9462V44.7734H17.9418V41.5381H10.3168Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17.9418 38.3632V44.7733H43.918C44.6957 44.7733 45.3261 44.1429 45.3261 43.3652V24.7934C45.3261 24.2749 44.9059 23.8547 44.3875 23.8547H18.8806C18.3621 23.8547 17.9419 24.275 17.9419 24.7934V35.2187"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M34.1062 35.9304H29.1619C28.2691 35.9304 27.5454 35.2068 27.5454 34.314C27.5454 33.4212 28.2691 32.6975 29.1619 32.6975H34.1062C34.999 32.6975 35.7227 33.4212 35.7227 34.314C35.7226 35.2068 34.9989 35.9304 34.1062 35.9304Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M3.13156 28.374H5.06428V25.5396C5.06428 24.2327 4.00484 23.1733 2.69807 23.1733C2.10204 23.1733 1.61877 23.6565 1.61877 24.2526V28.8955C2.03592 28.5692 2.56079 28.374 3.13156 28.374Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.237 32.5767C7.237 31.9209 6.79794 31.3685 6.19796 31.195C6.80548 31.0193 7.24806 30.4552 7.23682 29.7884C7.22344 28.9952 6.54314 28.374 5.74982 28.374H3.13159C1.77423 28.374 0.673828 29.4744 0.673828 30.8318V37.0852C0.673828 38.4425 1.77423 39.5429 3.13159 39.5429H5.74973C6.54305 39.5429 7.22335 38.9217 7.23673 38.1285C7.24796 37.4618 6.80539 36.8977 6.19787 36.7219C6.79785 36.5484 7.23691 35.9961 7.23691 35.3402C7.23691 34.6844 6.79785 34.132 6.19787 33.9585C6.79794 33.7849 7.237 33.2325 7.237 32.5767Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3086_83104">
          <rect width="46" height="46" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HLTvLive06;
