import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";
// import CustomNavbar from "../Elements/Navbar/CustomNavbar";
import CustomNavbar from "../Elements/CustomNavbar/CustomNavbar";
import CustomNavbarOld from "../Elements/Navbar/CustomNavbarOld";
import TopStripe from "../Elements/TopStripe/TopStripe";
import useMediaQuery from "@mui/material/useMediaQuery";

const Header = () => {
  // Sticky Menu Area

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  /* Method that will fix header after a specific scrollable */
  const isSticky = (e) => {
    const header = document.querySelector(".header-section");
    const scrollTop = window.scrollY;
    scrollTop >= 0
      ? header.classList.add("is-sticky")
      : header.classList.remove("is-sticky");
  };

  const isMobile = useMediaQuery('(max-width:899px)');

  return (
    <Box
      className="header-section d-none d-xl-block"
      sx={{
        minWidth: "90vw",
      }}
    >
      <TopStripe />
      {isMobile == true ? <CustomNavbarOld /> : <CustomNavbar />}
    </Box>
  );
};

export default Header;
