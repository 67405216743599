import React from "react";

const HLTvLive01 = () => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3086_82846)">
        <path
          d="M21.9646 28.8729C21.9646 30.2137 20.8776 31.3005 19.5369 31.3005C18.1961 31.3005 17.1091 30.2137 17.1091 28.8729C17.1091 27.532 18.1961 26.4451 19.5369 26.4451C20.8776 26.4451 21.9646 27.532 21.9646 28.8729Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M21.9647 26.2127V31.5332"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M29.0423 28.8729C29.0423 30.2137 27.9554 31.3005 26.6147 31.3005C25.2738 31.3005 24.1868 30.2137 24.1868 28.8729C24.1868 27.532 25.2738 26.4451 26.6147 26.4451C27.9554 26.4451 29.0423 27.532 29.0423 28.8729Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M29.0423 26.2127V31.5332"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M33.6501 27.4269C34.8706 27.4269 35.8601 28.3461 35.8601 29.4801C35.8601 30.614 34.8706 31.5332 33.6501 31.5332C32.4295 31.5332 31.44 30.614 31.44 29.4801"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M33.6501 27.4268C32.4295 27.4268 31.44 26.5077 31.44 25.3736C31.44 24.2397 32.4295 23.3205 33.6501 23.3205C34.8706 23.3205 35.8601 24.2397 35.8601 25.3736"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12.6519 27.4269C13.8725 27.4269 14.862 28.3461 14.862 29.4801C14.862 30.614 13.8725 31.5332 12.6519 31.5332C11.4314 31.5332 10.4418 30.614 10.4418 29.4801"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12.6519 27.4268C11.4314 27.4268 10.4418 26.5077 10.4418 25.3736C10.4418 24.2397 11.4314 23.3205 12.6519 23.3205C13.8725 23.3205 14.862 24.2397 14.862 25.3736"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M24.5723 36.1389H39.2829C42.5515 36.1389 45.2788 33.542 45.3255 30.2736C45.3674 27.3465 43.2863 24.8654 40.484 24.3396C39.8324 24.2174 39.3796 23.6311 39.4112 22.9692C39.604 18.9423 35.6504 15.8958 31.832 17.0031C31.1748 17.1937 30.4799 16.8686 30.2245 16.2338C29.7865 15.1448 29.1642 14.15 28.398 13.2858"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M25.9732 11.2804C24.4559 10.3795 22.6848 9.86133 20.7922 9.86133C15.3414 9.86133 10.9391 14.1385 10.6451 19.4478C10.6037 20.1957 9.93938 20.7525 9.19421 20.6762C4.72745 20.2189 0.631837 23.7443 0.674153 28.4623C0.712337 32.7277 4.24293 36.1389 8.50844 36.1389H21.4277"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3086_82846">
          <rect width="46" height="46" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HLTvLive01;
