// Helpers
import ConstantHelper from "../../Helpers/ConstantHelper";
import ImageHelper from "../../Helpers/ImageHelper";

const StreamingTypeData2 = [
    {
        id: "stream15",
        name: ConstantHelper.StreamingType15,
        bannerImg: ImageHelper.Banner3,
        url: "/services/ott-apps",
      },
      {
        id: "stream16",
        name: ConstantHelper.StreamingType16,
        bannerImg: ImageHelper.Banner7,
        url: "/services/ott-consulting",
      },
      {
        id: "stream17",
        name: ConstantHelper.StreamingType17,
        bannerImg: ImageHelper.Banner6,
        url: "/services/devops",
      },
      {
        id: "stream18",
        name: ConstantHelper.StreamingType18,
        bannerImg: ImageHelper.Banner1,
        url: "/services/custom-dev",
      },
      {
        id: "stream19",
        name: ConstantHelper.StreamingType19,
        bannerImg: ImageHelper.Banner5,
        url: "/services/qa-automation",
      }
    
];



export default StreamingTypeData2;