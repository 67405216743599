import ColorHelper from "../../Helpers/ColorHelper";
import FontHelper from "../../Helpers/FontHelper";

const ContentMediaSectionStyle = {
  cardWrapper: {
    backgroundColor: ColorHelper.white,
    display: "flex",
    justifyCotent: "center",
    alignItems: "center",
  },
  img: {
    height: "fit-content"
  },
  card: {
    backgroundColor: ColorHelper.white,
    width: "100vw",
    display: "flex",
    alignItems: "center",
    flexDirection: { xs: "column", md: "row", lg: "row" },
    justifyContent: 'space-between',
    px: { xs: 4, md: 27 },
    py: { xs: 4, md: 7 },
    mx: { xs: 2, md: 0 },
  },
  ImageWapper: {
    maxWidth: { xs: '100vw', md: '35%' },
    order: 2,
    img: {
      borderRadius: "10px",
      maxWidth: "100%",
    }
  },
  content: {
    // mx: { xs: 1, md: 1, lg: 5 },
    mt: { xs: 0, md: 4, lg: 0 },
    maxWidth: { xs: '100vw', md: '55%' },
    // order: 1,
    'img': {
      width: '100%',
    }
  },
  CardTitle: {
    fontSize: { xs: 18, md: 28 },
    fontFamily: FontHelper.FontSemiBold,
    color: ColorHelper.BgBlue,
    maxWidth: "100%",
  },
  CardCaption: {
    fontSize: { xs: 14, md: 16 },
    color: ColorHelper.grey4,
    maxWidth: "100%",
    // my: { xs: 2, md: 3 },
  },
  CardLink: {
    fontSize: { xs: 14, md: 16 },
    color: ColorHelper.PriamryColor,
    maxWidth: "100%",
    my: { xs: 2, md: 3 },
    "&:hover": {
      color: ColorHelper.blue,
    },
  },
  Link: {
    textDecoration: "none",
    color: ColorHelper.white,
    display: "flex",
    justifyCotent: "center",
    alignItems: "center",
    mb: 2,
    cursor: 'pointer',
    transition: "0.3s ease-in-out 0s",
    "&:hover": {
      fontWeight: 800,
      transition: "0.3s ease-in-out 0s",
    },
    "&:hover svg": {
      ml: 1,
      transition: "0.3s ease-in-out 0s",
    },
    "&:hover path": {
      stroke: ColorHelper.blue,
      transition: "0.3s ease-in-out 0s",
    }
  },
  arrowWrapper: {
    pt: 0.7,
    ml: 1.2,
    transition: "0.3s ease-in-out 0s",
  },
};

export default ContentMediaSectionStyle;
