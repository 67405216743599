import React from "react";

const StreamingTypeIcon2 = (props) => {
  const { color } = props;
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23 42.1666C23 42.1666 38.3333 34.5 38.3333 23V9.58331L23 3.83331L7.66663 9.58331V23C7.66663 34.5 23 42.1666 23 42.1666Z"
        stroke={color}
        strokeWidth="3.83333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default StreamingTypeIcon2;
