import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import ColorHelper from '../../Helpers/ColorHelper'
import ConstantHelper from '../../Helpers/ConstantHelper'
import FontHelper from '../../Helpers/FontHelper'
import ImageHelper from '../../Helpers/ImageHelper'
import PartnerSectionStyle from './PartnerSectionStyle'

const PartnersData = [
    {
        id: 1,
        partnersImage: ImageHelper.PartnersLogo1
    },
    {
        id: 2,
        partnersImage: ImageHelper.PartnersLogo2
    },
    {
        id: 3,
        partnersImage: ImageHelper.PartnersLogo3
    },
    {
        id: 4,
        partnersImage: ImageHelper.PartnersLogo4
    },
    {
        id: 5,
        partnersImage: ImageHelper.PartnersLogo5
    },
    {
        id: 6,
        partnersImage: ImageHelper.PartnersLogo6
    },
    {
        id: 7,
        partnersImage: ImageHelper.PartnersLogo7
    },
    {
        id: 8,
        partnersImage: ImageHelper.PartnersLogo8
    },
    {
        id: 9,
        partnersImage: ImageHelper.PartnersLogo9
    },
    {
        id: 10,
        partnersImage: ImageHelper.PartnersLogo10
    },
    {
        id: 11,
        partnersImage: ImageHelper.PartnersLogo11
    },
    {
        id: 12,
        partnersImage: ImageHelper.PartnersLogo12
    },
]


const PartnerSection = () => {
    return (
        <Box sx={{ ...PartnerSectionStyle.main, backgroundColor: ColorHelper.lighBlue }}>
            <Box sx={PartnerSectionStyle.headings}>
                <Typography
                    sx={{
                        fontSize: { xs: "0.85rem", md: "1.2rem" },
                        fontWeight: 400,
                        color: ColorHelper.blue,
                    }}
                    variant="h6"
                    component="h2"
                >
                    {ConstantHelper.PartnerCaption}
                </Typography>
                <Typography
                    sx={{
                        fontFamily: FontHelper.FontSemiBold,
                        fontSize: { xs: "1rem", md: "2rem" },
                        fontWeight: 800,
                        color: ColorHelper.black,
                    }}
                    variant="h6"
                    component="h2"
                >
                    {ConstantHelper.ParnterTitle}
                </Typography>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                }}>
                    <Typography
                        sx={{
                            fontSize: { xs: "0.85rem", md: 15 },
                            fontWeight: 100,
                            maxWidth: { xs: '90%', md: '60%' },
                            color: ColorHelper.black,
                        }}
                        variant="h6"
                        component="h2"
                    >
                        {ConstantHelper.PartnerDescription}
                    </Typography>
                </Box>
            </Box>
            <Box sx={PartnerSectionStyle.LogoContainer}>
                {PartnersData.map((item, index) => (
                    <Box sx={PartnerSectionStyle.PartnerLogoWrapper}>
                        <img src={item.partnersImage} alt="Partner Logo" />

                    </Box>
                ))}

            </Box>

        </Box>
    )
}

export default PartnerSection