import * as React from "react";
import ColorHelper from "../../Helpers/ColorHelper";

const About = ({ size = 46, color, ...props }) => (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="48" height="48" rx="24" fill="#FFF3F5"/>
    <path d="M33 31H35V33H13V31H15V16C15 15.7348 15.1054 15.4804 15.2929 15.2929C15.4804 15.1054 15.7348 15 16 15H26C26.2652 15 26.5196 15.1054 26.7071 15.2929C26.8946 15.4804 27 15.7348 27 16V31H31V23H29V21H32C32.2652 21 32.5196 21.1054 32.7071 21.2929C32.8946 21.4804 33 21.7348 33 22V31ZM17 17V31H25V17H17ZM19 23H23V25H19V23ZM19 19H23V21H19V19Z" fill="#DA1A5D"/>
    </svg>
    

);

export default About;