import React, { useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import JobForm from "../HeroSection/TalkToUsModal/JobForm";
import "./CarrierDetails.css";
import { Divider, Typography } from "@mui/material";
import { Grid } from "@mui/material";
import { BsFillBagFill } from "react-icons/bs";
import { RiMapPin2Fill } from "react-icons/ri";
import { FaShareAlt } from "react-icons/fa";
import ColorHelper from "../../Helpers/ColorHelper";
import FontHelper from "../../Helpers/FontHelper";

import CarrierData from "../../Helpers/OperatorPageHelpers/CarrierData";
import { Box } from "@mui/system";
import Header from "../Header/Header";
import FooterSection from "../FooterSection/FooterSection";
import SeoComponent from "../SeoComponent/SeoComponent";

export default function CarrierDetails() {
  let CarrierDataArr = {
    HighlightsData: CarrierData[1]?.hightlights,
  };
  let { slug } = useParams();

  let item = CarrierDataArr?.HighlightsData?.find((ele) => {
    return ele.slug == slug;
  });

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Header />
      
      {item ? (
        <>
        <SeoComponent
        canonical={item?.canonical}
      />
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            justifyContent="space-between"
            gap={2}
            p={3}
            mt={16}
          >
            <Box display="flex" flexDirection="column" justifyContent="center">
              <h4>
                {item.jobName}&nbsp;-&nbsp;
                <span style={{ color: "#da1a5d" }}>{item.stream}</span>
              </h4>
              <Box fontWeight="Bold">
                <span style={{ color: "#5B5B5B" }}>{item.location}</span>
              </Box>
            </Box>
            <div>
              <Box className="button button-primary">Apply Now </Box>
            </div>
          </Box>
          <Divider />

          <Box
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            mt={4}
            className="CarrierDetails_Container"
          >
            <div className="CarrierDetails_Box1">
              <Grid xs={7} md={7}>
                <Typography
                  sx={{ color: ColorHelper.black, mb: 1.5 }}
                  fontWeight="Bold"
                >
                  <span style={{ color: "#da1a5d", mb: 1.5 }}>
                    {item?.stream}
                  </span>
                </Typography>

                <Typography sx={{ color: ColorHelper.black, mb: 1.5 }}>
                  <span>
                    <Typography
                      sx={{
                        color: ColorHelper.black,
                        mb: 1.5,
                        fontFamily: FontHelper.FontBold,
                      }}
                      fontSize={20}
                    >
                      {item?.jobName}
                    </Typography>
                    <Typography sx={{ color: ColorHelper.black, mb: 1.5 }}>
                      <BsFillBagFill />
                      &nbsp;
                      <span style={{ fontSize: "14px", color: "#5B5B5B" }}>
                        {item?.mode}
                      </span>
                      &nbsp;
                      <RiMapPin2Fill />
                      &nbsp;
                      <span style={{ fontSize: "14px", color: "#5B5B5B" }}>
                        {item?.location}
                      </span>
                      &nbsp;
                      {/* <FaShareAlt />
                      &nbsp;
                      <span style={{ fontSize: "14px", color: "#5B5B5B" }}>
                        Share This Job
                      </span>
                      &nbsp; */}
                    </Typography>
                  </span>
                </Typography>
                <Typography
                  sx={{
                    color: ColorHelper.black,
                    mb: 1,
                    fontFamily: FontHelper.FontBold,
                    //   textDecoration: "underline",
                    fontSize: 20,
                  }}
                >
                  {item?.AboutMobiotics}
                </Typography>
                <Typography sx={{ color: ColorHelper.black, mb: 1.5 }}>
                  <span style={{ fontSize: "15px" }}>{item?.company}</span>
                </Typography>

                <Typography
                  sx={{
                    color: ColorHelper.black,
                    mb: 1,
                    fontFamily: FontHelper.FontBold,
                    //   textDecoration: "underline",
                    fontSize: 20,
                  }}
                >
                  {item?.responsibility}
                </Typography>
                <ul style={{ marginLeft: "15px" }}>
                  {item?.roles?.map((data) => {
                    return (
                      <li key={data.id}>
                        <li>{data.text}</li>
                      </li>
                    );
                  })}
                </ul>
                <Typography
                  sx={{
                    color: ColorHelper.black,
                    mb: 1.5,
                    mt: 1.5,
                    fontFamily: FontHelper.FontBold,
                    //   textDecoration: "underline",
                    fontSize: 20,
                  }}
                >
                  {item?.skillHeading}
                </Typography>
                <ul style={{ marginLeft: "15px" }}>
                  {item?.skills?.map((data) => {
                    return (
                      <li key={data?.id}>
                        <li>{data?.skills}</li>
                      </li>
                    );
                  })}
                </ul>

                <Typography
                  sx={{
                    color: ColorHelper.black,
                    mb: 1,
                    mt: 1,
                    fontFamily: FontHelper.FontBold,
                    //   textDecoration: "underline",
                    fontSize: 20,
                  }}
                >
                  {item?.greatToHaveHeading}
                </Typography>
                <ul style={{ marginLeft: "15px" }}>
                  {item?.greatToHaveSkills?.map((data) => {
                    return (
                      <li key={data?.id}>
                        <li>{data?.skills}</li>
                      </li>
                    );
                  })}
                </ul>

                <Typography
                  sx={{
                    color: ColorHelper.black,
                    mb: 1,
                    mt: 1,
                    fontFamily: FontHelper.FontBold,
                    //   textDecoration: "underline",
                    fontSize: 20,
                  }}
                >
                  {item?.profileHeading}
                </Typography>
                <ul style={{ marginLeft: "15px" }}>
                  {item?.candidateProfile?.map((data) => {
                    return (
                      <li key={data?.id}>
                        <li>{data?.text}</li>
                      </li>
                    );
                  })}
                </ul>
                <Typography
                  sx={{
                    color: ColorHelper.black,
                    mb: 1,
                    mt: 1,
                    fontFamily: FontHelper.FontBold,
                    //   textDecoration: "underline",
                    fontSize: 20,
                  }}
                >
                  {item?.placeToEnhanceSkillsHeading}
                </Typography>
                <ul style={{ marginLeft: "15px" }}>
                  {item?.placeToEnhanceSkill?.map((data) => {
                    return (
                      <li key={data.id}>
                        <li>{data.text}</li>
                      </li>
                    );
                  })}
                </ul>

                <Typography
                  sx={{
                    color: ColorHelper.black,
                    mb: 1,
                    mt: 1,
                    fontFamily: FontHelper.FontBold,
                    //   textDecoration: "underline",
                    fontSize: 20,
                  }}
                >
                  {item?.perksHeading}
                </Typography>
                {/* <ul style={{marginLeft:"15px",marginBottom:"15px"}}>
                                        {item.perks.map(data => {
                                            return <li key={data.id}><li>{data.text}</li></li>
                                        })}
                                        </ul> */}
                <ul style={{ marginLeft: "15px", marginBottom: "15px" }}>
                  {item?.perks}
                </ul>
                {/* <Typography sx={{ color: ColorHelper.black }}>
                                        <span>About This Position</span><br />
                                       
                                    </Typography>

                                    <Typography sx={{ color: ColorHelper.black, mb: 1.5 }}> <span style={{ fontSize: "15px" }}>{item.about}</span></Typography>
                                    <Typography sx={{ color: ColorHelper.black }}>
                                        <span>What will you do as a {item.jobName} ?</span><br />
                                        <span style={{ fontSize: "15px" }}>{item.desc}</span>
                                    </Typography> */}
              </Grid>
            </div>
            <div class="CarrierDetails_Box2">
              <JobForm jobName={item?.jobName} />
            </div>
          </Box>
        </>
      ) : (
        <span>No Data Found</span>
      )}

      <FooterSection footerCardShow={true} />
    </>
  );
}
