// Helper
import ImageHelper from "../../Helpers/ImageHelper";

const CategoriesData = [
  {
    id: 1,
    title: "Media and Broadcast",
    desc: "Deliver and monetize your Live and On-demand content through our end-to-end OTT platform that offers efficient content delivery, engagement and monetization. ",
    image: ImageHelper.CategoreisImages2,
  },
  {
    id: 2,
    title: "Operators",
    desc: "If you are a Telco, ISP or Pay TV operators, launch TV Everywhere service for your subscribers through our white-labeled, fully managed OTT platform.",
    image: ImageHelper.CategoreisImages3,
  },
  {
    id: 3,
    title: "E-Learning and Education",
    desc: "Launch and monetize online course or deliver Live class with our eLearning OTT solution. Suitable for Trainers, Coaches, Training institutes, schools and colleges.",
    image: ImageHelper.CategoreisImages4,
  },
  {
    id: 4,
    title: "Youtube",
    desc: "A platform for YouTubers and other content creators to widen their reach and audience by disseminating quality content through branded Apps.",
    image: ImageHelper.CategoreisImages5,
  },
  {
    id: 5,
    title: "Filmmakers",
    desc: "An OTT streaming platform that supports the best streaming technology and content security for filmmakers to showcase their content to global audiences.",
    image: ImageHelper.CategoreisImages9,
  },
  {
    id: 6,
    title: "Content Producers",
    desc: "Connect with your audience directly through your own OTT service like Netflix with our enterprise grade, feature rich and technologically advance  OTT platform.",
    image: ImageHelper.CategoreisImages10,
  },
  {
    id: 7,
    title: "Enterprises",
    desc: "Engage with your employees, customers and investors in a new way. Deliver training programs, marketing video, product announcements, live events, internal communications through our OTT platform for enterprises.",
    image: ImageHelper.CategoreisImages7,
  },
  {
    id: 8,
    title: "Health and Fitness",
    desc: "Monetize your passion for health and fitness by creating technologically sound and personalized programs with remarkable Online Fitness Studio.",
    image: ImageHelper.CategoreisImages6,
  },
  {
    id: 9,
    title: "Entertainment",
    desc: "Get end-to-end OTT platform for your entertainment content. Secure delivery, buffer-free audio and video streaming, real-time analytics and flexibility in monetization.",
    image: ImageHelper.CategoreisImages8,
  },
];

export default CategoriesData;
