import React from "react";

const HLTvDRM01 = () => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3086_82396)">
        <path
          d="M36.2175 23.0001C36.2175 30.2999 30.2998 36.2175 23 36.2175C15.7002 36.2175 9.78259 30.2999 9.78259 23.0001C9.78259 15.7003 15.7002 9.78263 23 9.78263C30.2998 9.78263 36.2175 15.7003 36.2175 23.0001Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M24.2953 24.7677C24.2953 25.483 23.7154 26.063 23 26.063C22.2845 26.063 21.7046 25.483 21.7046 24.7677C21.7046 24.0524 22.2845 23.4724 23 23.4724C23.7154 23.4724 24.2953 24.0524 24.2953 24.7677Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M26.2626 21.3608V19.4358C26.2626 17.6415 24.7944 16.1733 23 16.1733C21.2056 16.1733 19.7374 17.6415 19.7374 19.4358V21.3608"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M23 26.0631V27.2007"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M20.9805 2.78724C20.9805 3.95449 21.8846 4.90063 23 4.90063C24.1154 4.90063 25.0195 3.95449 25.0195 2.78724C25.0195 1.61999 24.1154 0.673846 23 0.673846C21.8846 0.673846 20.9805 1.61999 20.9805 2.78724Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M23 9.4104V4.9006"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M20.9805 43.213C20.9805 42.0459 21.8846 41.0996 23 41.0996C24.1154 41.0996 25.0195 42.0459 25.0195 43.213C25.0195 44.3803 24.1154 45.3264 23 45.3264C21.8846 45.3264 20.9805 44.3803 20.9805 43.213Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M23 36.463V41.0996"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M4.48534 14.6425C5.49617 15.2261 6.76773 14.9162 7.3253 13.9503C7.88296 12.9844 7.51568 11.7283 6.50485 11.1447C5.49401 10.5612 4.22246 10.871 3.6648 11.8369C3.10714 12.8028 3.47451 14.0589 4.48534 14.6425Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.2595 16.2217L7.32532 13.9503"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M39.4952 34.8554C38.4843 34.2718 38.117 33.0157 38.6746 32.0497C39.2323 31.0839 40.5038 30.7741 41.5147 31.3576C42.5255 31.9412 42.8928 33.1973 42.3351 34.1632C41.7775 35.1291 40.506 35.439 39.4952 34.8554Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M34.7479 29.7827L38.6747 32.0498"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.50478 34.8554C7.51561 34.2718 7.88298 33.0157 7.32532 32.0497C6.76775 31.0839 5.4961 30.7741 4.48527 31.3576C3.47444 31.9412 3.10716 33.1973 3.66482 34.1632C4.22248 35.1291 5.49395 35.439 6.50478 34.8554Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.2954 29.7577L7.32538 32.0498"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M41.5147 14.6425C40.5038 15.2261 39.2324 14.9162 38.6747 13.9503C38.117 12.9844 38.4843 11.7283 39.4952 11.1447C40.506 10.5612 41.7775 10.871 42.3352 11.8369C42.8929 12.8028 42.5255 14.0589 41.5147 14.6425Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M34.6279 16.2866L38.6747 13.9502"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M24.5722 29.2473H26.8679C27.4685 29.2473 27.96 28.7559 27.96 28.1552V22.453C27.96 21.8523 27.4685 21.3608 26.8679 21.3608H19.132C18.5314 21.3608 18.04 21.8523 18.04 22.453V28.1552C18.04 28.7559 18.5314 29.2473 19.132 29.2473H21.4277"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M33.2683 21.4278C32.5105 16.4375 28.2019 12.6134 23 12.6134C17.2636 12.6134 12.6133 17.2636 12.6133 23.0001C12.6133 28.7365 17.2636 33.3867 23 33.3867C28.2019 33.3867 32.5105 29.5626 33.2683 24.5723"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3086_82396">
          <rect width="46" height="46" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HLTvDRM01;
