import React from "react";

const HLTvLiteMOOC04 = () => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3085_82013)">
        <path
          d="M11.8465 45.3262H1.53168C1.06854 45.3262 0.692993 44.9506 0.692993 44.4875V43.9817C0.692993 40.9018 3.18975 38.405 6.26977 38.405C9.34971 38.405 11.8465 40.9018 11.8465 43.9817V45.3262Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M22.9999 45.3262H11.8464V43.9817C11.8464 40.9018 14.3432 38.405 17.4232 38.405C20.5031 38.405 22.9999 40.9018 22.9999 43.9817V45.3262Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M34.1535 45.3262H23V43.9817C23 40.9018 25.4968 38.405 28.5767 38.405C31.6566 38.405 34.1535 40.9018 34.1535 43.9817V45.3262Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M44.4682 45.3262H34.1534V43.9817C34.1534 40.9018 36.6502 38.405 39.7302 38.405C42.8102 38.405 45.3069 40.9018 45.3069 43.9817V44.4875C45.3069 44.9506 44.9314 45.3262 44.4682 45.3262Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.124 38.4702C8.38406 38.1001 9.30712 36.9342 9.30712 35.556C9.30712 33.8813 7.94455 32.5187 6.26977 32.5187C4.59499 32.5187 3.23242 33.8813 3.23242 35.556C3.23242 36.9342 4.15539 38.1002 5.41553 38.4702"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M18.2774 38.4702C19.5375 38.1002 20.4606 36.9342 20.4606 35.556C20.4606 33.8813 19.098 32.5187 17.4232 32.5187C15.7484 32.5187 14.3859 33.8813 14.3859 35.556C14.3859 36.9342 15.3088 38.1002 16.569 38.4702"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M29.4309 38.4702C30.6909 38.1002 31.614 36.9342 31.614 35.556C31.614 33.8813 30.2514 32.5187 28.5767 32.5187C26.9019 32.5187 25.5393 33.8813 25.5393 35.556C25.5393 36.9342 26.4623 38.1002 27.7224 38.4702"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M40.5843 38.4702C41.8444 38.1002 42.7674 36.9342 42.7674 35.556C42.7674 33.8813 41.4049 32.5187 39.7301 32.5187C38.0553 32.5187 36.6927 33.8813 36.6927 35.556C36.6927 36.9342 37.6157 38.1002 38.8759 38.4702"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M35.103 28.594H10.9844C10.4193 28.594 9.96118 28.1359 9.96118 27.5708V26.5151C9.96118 25.95 10.4193 25.4919 10.9844 25.4919H35.103C35.6681 25.4919 36.1262 25.95 36.1262 26.5151V27.5708C36.1262 28.1359 35.6681 28.594 35.103 28.594Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M29.3444 21.5562H38.851C39.7992 21.5562 40.5679 20.7874 40.5679 19.8392V2.39068C40.5679 1.44247 39.7992 0.673681 38.851 0.673681H7.23645C6.28823 0.673681 5.51953 1.44247 5.51953 2.39068V19.8392C5.51953 20.7874 6.28823 21.5562 7.23645 21.5562H16.743"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.2146 8.97816V18.4027C8.2146 18.656 8.41989 18.8613 8.67316 18.8613H18.469"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M27.6184 18.8613H37.4143C37.6675 18.8613 37.8728 18.656 37.8728 18.4028V3.82759C37.8728 3.57441 37.6675 3.36903 37.4143 3.36903H8.67316C8.41989 3.36903 8.2146 3.57441 8.2146 3.82759V5.82823"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M23.701 17.0684C25.5068 16.7564 26.8807 15.1835 26.8807 13.2885C26.8807 11.1695 25.1628 9.45159 23.0437 9.45159C20.9246 9.45159 19.2068 11.1695 19.2068 13.2885C19.2068 15.1835 20.5807 16.7564 22.3864 17.0684"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M29.6951 25.4919V23.6872C29.6951 20.0136 26.7171 17.0358 23.0437 17.0358H23.0436C19.3702 17.0358 16.3922 20.0136 16.3922 23.6872V25.4919"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M34.1534 30.5747V28.5939"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M34.1534 44.0378V33.7249"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.8466 28.5941V44.0378"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3085_82013">
          <rect width="46" height="46" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HLTvLiteMOOC04;
