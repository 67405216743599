import React from "react";
import ColorHelper from "../../Helpers/ColorHelper";

const Vlive6 = (props) => {
    const { color } = props;
  return (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" height={46} width={46} viewBox="0 0 46 46">
   
    <g id="Page-1">
      <g id="_017---Self-Star-Rating" data-name="017---Self-Star-Rating">
        <path stroke={ColorHelper.BgBlue} id="Shape" class="cls-1" d="M26.84,25.29c-.25,.09-.43,.3-.48,.55s.04,.52,.24,.69,.47,.22,.72,.14c6.09-2.11,9.76-8.3,8.69-14.65-1.07-6.35-6.57-11-13.01-11S11.07,5.67,10,12.02c-1.07,6.35,2.6,12.55,8.69,14.65,.38,.13,.8-.07,.93-.45,.13-.38-.07-.8-.45-.93-1.27-.44-2.45-1.1-3.49-1.94v-.8c0-2.29,1.4-4.34,3.54-5.15,2.27,1.61,5.31,1.61,7.58,0,2.13,.82,3.54,2.87,3.54,5.15v.8c-1.04,.84-2.22,1.5-3.49,1.94h0Zm-8.97-13.28c0-2.83,2.3-5.13,5.13-5.13s5.13,2.3,5.13,5.13-2.3,5.13-5.13,5.13c-2.83,0-5.13-2.3-5.13-5.13Zm10.11,4.3c2.3-2.66,2.11-6.66-.44-9.09-2.55-2.43-6.55-2.43-9.1,0-2.55,2.43-2.74,6.43-.44,9.09-2.16,1.1-3.59,3.25-3.78,5.66-4.18-4.71-3.89-11.87,.65-16.23,4.54-4.36,11.71-4.36,16.25,0,4.54,4.36,4.82,11.53,.65,16.23-.19-2.42-1.63-4.56-3.79-5.66Z"/>
        <path stroke={ColorHelper.BgBlue} id="Shape-2" data-name="Shape" class="cls-1" d="M16.7,44.69c.45,.33,1.05,.38,1.54,.12l4.77-2.48,4.77,2.48c.49,.26,1.09,.21,1.54-.12s.67-.88,.58-1.43l-.91-5.23,3.83-3.69c.4-.39,.55-.97,.38-1.5s-.63-.92-1.18-1l-5.32-.76-2.37-4.77c-.25-.5-.76-.81-1.31-.81-.56,0-1.06,.31-1.31,.81l-2.39,4.77-5.31,.76c-.55,.08-1.01,.47-1.18,1s-.02,1.12,.38,1.5l3.83,3.69-.91,5.23c-.1,.55,.13,1.1,.58,1.43h0Zm-2.49-11.41l5.69-.82c.24-.03,.44-.18,.55-.4l2.56-5.12,2.55,5.12c.11,.22,.31,.36,.55,.4l5.7,.82-4.11,3.96c-.17,.17-.25,.41-.21,.65l.97,5.61-5.1-2.65c-.21-.11-.46-.11-.68,0l-5.11,2.65,.97-5.61c.04-.24-.04-.48-.21-.65l-4.11-3.96Z"/>
        <path stroke={ColorHelper.BgBlue} id="Shape-3" data-name="Shape" class="cls-1" d="M4.47,33.42l-.72,4.14c-.07,.39,.04,.78,.29,1.08,.25,.3,.62,.48,1.01,.48,.21,0,.42-.05,.61-.15l3.78-1.97,3.78,1.97c.23,.12,.51,.11,.73-.03s.35-.39,.34-.65-.16-.5-.39-.62l-4.12-2.15c-.21-.11-.46-.11-.68,0l-3.87,2.01,.73-4.25c.04-.24-.04-.48-.21-.65l-3.11-3,4.31-.62c.24-.03,.44-.18,.55-.4l1.94-3.88,1.93,3.88c.11,.22,.31,.36,.55,.4l4.6,.66c.4,.06,.77-.22,.83-.62s-.22-.77-.62-.83l-4.22-.6-1.89-3.79c-.22-.45-.68-.74-1.19-.73h0c-.5,0-.95,.28-1.17,.73l-1.9,3.79-4.21,.6c-.5,.07-.92,.42-1.08,.9-.16,.48-.02,1.01,.35,1.36l3.04,2.93Z"/>
        <path stroke={ColorHelper.BgBlue} id="Shape-4" data-name="Shape" class="cls-1" d="M44.92,29.12c-.15-.48-.57-.83-1.07-.9l-4.21-.6-1.89-3.79c-.22-.45-.67-.73-1.17-.73h0c-.5,0-.96,.28-1.19,.73l-1.89,3.79-4.22,.6c-.26,.04-.48,.21-.58,.45s-.06,.52,.1,.73,.42,.31,.68,.27l4.6-.66c.24-.03,.44-.18,.55-.4l1.93-3.87,1.94,3.88c.11,.21,.31,.36,.55,.4l4.3,.62-3.11,3c-.17,.17-.25,.41-.21,.65l.74,4.25-3.87-2.02c-.21-.11-.46-.11-.68,0l-4.12,2.15c-.23,.12-.38,.36-.39,.62s.12,.51,.34,.65,.5,.15,.73,.03l3.78-1.97,3.78,1.97c.45,.23,.99,.19,1.39-.11s.61-.8,.52-1.3l-.71-4.15,3.04-2.93c.37-.35,.5-.88,.34-1.37h0Z"/>
        <path stroke={ColorHelper.BgBlue} id="Shape-5" data-name="Shape" class="cls-1" d="M18.97,35.56s.07,0,.11,0l2.76-.4c.24-.03,.44-.18,.55-.4l1.27-2.55c.18-.36,.04-.8-.33-.99s-.8-.04-.99,.33l-1.1,2.21-2.38,.34c-.38,.05-.65,.39-.63,.78s.35,.68,.73,.68h0Z"/>
      </g>
    </g>
  </svg>
  );
};

export default Vlive6;