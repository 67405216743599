import HeroImage from "../Assets/Images/hero_img.png";
import Logo from "../Assets/Images/MobioticsLogo.svg";
import StreamTypeCover from "../Assets/Images/StreamTypeCover.png";
import vLive_Logo from "../Assets/Images/vLive_Logo.svg";

import Sounds from "../Assets/Images/productsLogos/Sounds.png";
import vApps from "../Assets/Images/productsLogos/vApps.png";
import vCMS from "../Assets/Images/productsLogos/vCMS.png";
import vCRM from "../Assets/Images/productsLogos/vCRM.png";
import vDRM from "../Assets/Images/productsLogos/vDRM.png";
import vLite_MOOC from "../Assets/Images/productsLogos/vLite_MOOC.png";
import vSMS from "../Assets/Images/productsLogos/vSMS.png";

// import BanrdsLogo1 from "../Assets/Images/BrandsLogos/BanrdsLogo1.svg";
// import BanrdsLogo2 from "../Assets/Images/BrandsLogos/BanrdsLogo2.svg";
// import BanrdsLogo3 from "../Assets/Images/BrandsLogos/BanrdsLogo3.svg";
// import BanrdsLogo4 from "../Assets/Images/BrandsLogos/BanrdsLogo4.svg";
// import BanrdsLogo5 from "../Assets/Images/BrandsLogos/BanrdsLogo5.svg";
// import BanrdsLogo6 from "../Assets/Images/BrandsLogos/BanrdsLogo6.svg";

// import BanrdsLogo1 from "../Assets/Images/BrandsLogos/BanrdsLogo1.png";
// import BanrdsLogo2 from "../Assets/Images/BrandsLogos/BanrdsLogo2.png";
// import BanrdsLogo3 from "../Assets/Images/BrandsLogos/BanrdsLogo3.png";
// import BanrdsLogo4 from "../Assets/Images/BrandsLogos/BanrdsLogo4.png";
// import BanrdsLogo5 from "../Assets/Images/BrandsLogos/BanrdsLogo5.png";
// import BanrdsLogo6 from "../Assets/Images/BrandsLogos/BanrdsLogo6.png";
// import BanrdsLogo7 from "../Assets/Images/BrandsLogos/BanrdsLogo7.png";
// import BanrdsLogo8 from "../Assets/Images/BrandsLogos/BanrdsLogo8.png";
// import BanrdsLogo9 from "../Assets/Images/BrandsLogos/BanrdsLogo9.png";
// import BanrdsLogo10 from "../Assets/Images/BrandsLogos/BanrdsLogo10.png";
//import BanrdsLogo11 from "../Assets/Images/BrandsLogos/BanrdsLogo11.png";
//import BanrdsLogo12 from "../Assets/Images/BrandsLogos/BanrdsLogo12.png";
//import BanrdsLogo13 from "../Assets/Images/BrandsLogos/BanrdsLogo13.png";
//import BanrdsLogo14 from "../Assets/Images/BrandsLogos/BanrdsLogo14.png";

import BanrdsLogo1 from "../Assets/Images/BrandsLogos/android.webp";
import BanrdsLogo2 from "../Assets/Images/BrandsLogos/ios.webp";
import BanrdsLogo3 from "../Assets/Images/BrandsLogos/appleTv.webp";
import BanrdsLogo4 from "../Assets/Images/BrandsLogos/roku.webp";
import BanrdsLogo5 from "../Assets/Images/BrandsLogos/androidtv.webp";
import BanrdsLogo6 from "../Assets/Images/BrandsLogos/firetv.webp";
import BanrdsLogo7 from "../Assets/Images/BrandsLogos/mi.webp";
import BanrdsLogo8 from "../Assets/Images/BrandsLogos/samsung.webp";
import BanrdsLogo9 from "../Assets/Images/BrandsLogos/LGwebOS.webp";
import BanrdsLogo10 from "../Assets/Images/BrandsLogos/Netrange.webp";
import BanrdsLogo11 from "../Assets/Images/BrandsLogos/Vewd.webp";
import BanrdsLogo12 from "../Assets/Images/BrandsLogos/dishtv.webp";
import BanrdsLogo13 from "../Assets/Images/BrandsLogos/Metrological.webp";

import cardImage from "../Assets/Images/cardImage.webp";
import iPhone12Promockup from "../Assets/Images/iPhone12Promockup.webp";
import Blob from "../Assets/Shapes/Blob.svg";

import CategoreisImages1 from "../Assets/Images/CategoriesImages/CategoreisImages1.webp";
import CategoreisImages2 from "../Assets/Images/CategoriesImages/CategoreisImages2.webp";
import CategoreisImages3 from "../Assets/Images/CategoriesImages/CategoreisImages3.webp";
import CategoreisImages4 from "../Assets/Images/CategoriesImages/CategoreisImages4.webp";
import CategoreisImages5 from "../Assets/Images/CategoriesImages/CategoreisImages5.webp";
import CategoreisImages6 from "../Assets/Images/CategoriesImages/CategoreisImages6.webp";
import CategoreisImages7 from "../Assets/Images/CategoriesImages/CategoreisImages7.webp";
import CategoreisImages8 from "../Assets/Images/CategoriesImages/CategoreisImages8.webp";
import CategoreisImages9 from "../Assets/Images/CategoriesImages/CategoreisImages9.webp";
import CategoreisImages10 from "../Assets/Images/CategoriesImages/CategoreisImages10.webp";

import Avatar1 from "../Assets/Images/Avatars/Avatar-1.png";
import Avatar2 from "../Assets/Images/Avatars/Avatar-2.png";
import Avatar3 from "../Assets/Images/Avatars/Avatar-3.png";
import Avatar4 from "../Assets/Images/Avatars/Avatar-4.png";

import BrandCover from "../Assets/Images/BrandCover.png";

//our customer Base
// import BrandCover1 from "../Assets/Images/BrandCover/altbalaji.png";
// import BrandCover2 from "../Assets/Images/BrandCover/Noor play.png";
// import BrandCover3 from "../Assets/Images/BrandCover/nxtdigital.png";
// import BrandCover4 from "../Assets/Images/BrandCover/fastway.png";
// import BrandCover5 from "../Assets/Images/BrandCover/lattukids.png";
// import BrandCover6 from "../Assets/Images/BrandCover/cave.png";
// import BrandCover7 from "../Assets/Images/BrandCover/InDigital.png";
// import BrandCover8 from "../Assets/Images/BrandCover/fastfilmz.png";
// import BrandCover9 from "../Assets/Images/BrandCover/NammaFlix.png";
// import BrandCover10 from "../Assets/Images/BrandCover/mi.png";
// import BrandCover11 from "../Assets/Images/BrandCover/purplestream.png";
// import BrandCover12 from "../Assets/Images/BrandCover/onlykannada.png";
// import BrandCover13 from "../Assets/Images/BrandCover/net5.png";
// import BrandCover14 from "../Assets/Images/BrandCover/Epic.png";
// import BrandCover15 from "../Assets/Images/BrandCover/Docubay.png";
// import BrandCover16 from "../Assets/Images/BrandCover/knowDiabitice.png";
// import BrandCover17 from "../Assets/Images/BrandCover/7salai.png";
// import BrandCover18 from "../Assets/Images/BrandCover/myTvapp.png";
// import BrandCover19 from "../Assets/Images/BrandCover/onechannel.png";
//import BrandCover20 from "../Assets/Images/BrandCover/BrandCover5.png";
//import BrandCover21 from "../Assets/Images/BrandCover/BrandCover1.png";
//import BrandCover22 from "../Assets/Images/BrandCover/BrandCover2.png";
//import BrandCover23 from "../Assets/Images/BrandCover/BrandCover3.png";
//import BrandCover24 from "../Assets/Images/BrandCover/BrandCover4.png";
//import BrandCover25 from "../Assets/Images/BrandCover/BrandCover5.png";
//import BrandCover26 from "../Assets/Images/BrandCover/BrandCover5.png";

// New Brands imgs
import BrandCover1 from "../Assets/Images/BrandCover/1.webp";
import BrandCover2 from "../Assets/Images/BrandCover/2.webp";
import BrandCover3 from "../Assets/Images/BrandCover/3.webp";
import BrandCover4 from "../Assets/Images/BrandCover/4.webp";
import BrandCover5 from "../Assets/Images/BrandCover/5.webp";
import BrandCover6 from "../Assets/Images/BrandCover/6.webp";
import BrandCover7 from "../Assets/Images/BrandCover/7.webp";
import BrandCover8 from "../Assets/Images/BrandCover/8.webp";
import BrandCover9 from "../Assets/Images/BrandCover/9.webp";
import BrandCover10 from "../Assets/Images/BrandCover/10.webp";
import BrandCover11 from "../Assets/Images/BrandCover/11.webp";
import BrandCover12 from "../Assets/Images/BrandCover/12.webp";
import BrandCover13 from "../Assets/Images/BrandCover/13.webp";
import BrandCover14 from "../Assets/Images/BrandCover/14.webp";
import BrandCover15 from "../Assets/Images/BrandCover/15.webp";
import BrandCover16 from "../Assets/Images/BrandCover/16.webp";
import BrandCover17 from "../Assets/Images/BrandCover/17.webp";
import BrandCover18 from "../Assets/Images/BrandCover/18.webp";
import BrandCover19 from "../Assets/Images/BrandCover/19.webp";
import BrandCover20 from "../Assets/Images/BrandCover/20.webp";
import BrandCover21 from "../Assets/Images/BrandCover/21.webp";
import BrandCover22 from "../Assets/Images/BrandCover/22.webp";
import BrandCover23 from "../Assets/Images/BrandCover/23.webp";

//About Us iamges
import about1 from "../Assets/Images/About/Rectangle 111.webp";
import about2 from "../Assets/Images/About/Rectangle 112.webp";
import about3 from "../Assets/Images/About/Rectangle 113.webp";

import ShowBanner1 from "../Assets/Images/ShowBanners/ShowBanner1.png";
import ShowBanner2 from "../Assets/Images/ShowBanners/ShowBanner2.png";
import ShowBanner3 from "../Assets/Images/ShowBanners/ShowBanner3.png";
import MediaCardImg1 from "../Assets/Images/MediaCardImgs/MediaCardImg1.gif";
import MediaCardImg2 from "../Assets/Images/MediaCardImgs/MediaCardImg2.webp";
import MediaCardImg3 from "../Assets/Images/MediaCardImgs/MediaCardImg3.webp";
import MediaCardImg4 from "../Assets/Images/MediaCardImgs/MediaCardImg4.webp";
import MediaCardImg5 from "../Assets/Images/MediaCardImgs/MediaCardImg5.webp";
import MediaCardImg6 from "../Assets/Images/MediaCardImgs/MediaCardImg6.webp";
import MediaCardImg7 from "../Assets/Images/MediaCardImgs/MediaCardImg7.webp";
import ContentMedia1 from "../Assets/Images/ContentMedia/ContentMedia1.jpg";
import education from "../Assets/Images/ContentMedia/education.webp";
import media__entertainment from "../Assets/Images/ContentMedia/media__entertainment.webp";
import operators from "../Assets/Images/ContentMedia/operators.webp";

import ContentMedia2 from "../Assets/Images/ContentMedia/ContentMedia2.jpg";
import ContentMedia3 from "../Assets/Images/ContentMedia/ContentMedia3.jpg";
import ContentMedia4 from "../Assets/Images/ContentMedia/ContentMedia4.jpg";
import ContentMedia5 from "../Assets/Images/ContentMedia/ContentMedia5.jpg";
import ContentMedia6 from "../Assets/Images/ContentMedia/ContentMedia6.png";
import ContentMedia7 from "../Assets/Images/ContentMedia/ContentMedia7.png";

//broadcaster
import broadcast1 from "../Assets/Images/ServiceImages/solution3/broadcast1.png";
import broadcast2 from "../Assets/Images/ServiceImages/solution3/broadcast2.png";
import broadcast3 from "../Assets/Images/ServiceImages/solution3/broadcast3.png";
import broadcast4 from "../Assets/Images/ServiceImages/solution3/broadcast4.png";
import broadcast5 from "../Assets/Images/ServiceImages/solution3/broadcast5.png";
import studio from "../Assets/Images/ServiceImages/solution3/studio.webp";
import graphics from "../Assets/Images/ServiceImages/solution3/graphics.webp";
import end_to_end from "../Assets/Images/ServiceImages/solution3/end_to_end.webp";
import efficient_it from "../Assets/Images/ServiceImages/solution3/efficient_it.webp";
import centralized from "../Assets/Images/ServiceImages/solution3/centralized.webp";
import broadcastImage01 from "../Assets/Images/ServiceImages/solution3/broadcastImage01.png";
import broadcastImage02 from "../Assets/Images/ServiceImages/solution3/broadcastImage02.png";
import broadcastImage03 from "../Assets/Images/ServiceImages/solution3/broadcastImage03.png";
import broadcastImage04 from "../Assets/Images/ServiceImages/solution3/broadcastImage04.png";
import broadcastImage05 from "../Assets/Images/ServiceImages/solution3/broadcastImage05.png";

//operators solution
import solution3 from "../Assets/Images/ServiceImages/solution3/Operators01.jpg";
import solution4 from "../Assets/Images/ServiceImages/solution3/Operators02.jpg";
import solution5 from "../Assets/Images/ServiceImages/solution3/Operators03.jpg";

//education e learning
import solution6 from "../Assets/Images/ServiceImages/solution1/Edu01.jpg";
import solution7 from "../Assets/Images/ServiceImages/solution1/Edu02.jpg";
import solution8 from "../Assets/Images/ServiceImages/solution1/Edu03.jpg";

//Health & fitness
import solution56 from "../Assets/Images/ServiceImages/solution5/Health01.jpg";
import solution57 from "../Assets/Images/ServiceImages/solution5/Health02.jpg";
import solution58 from "../Assets/Images/ServiceImages/solution5/Health03.jpg";

//OTT for Enterprise
import solution9 from "../Assets/Images/ServiceImages/solution4/Enterprise01.jpg";
import solution10 from "../Assets/Images/ServiceImages/solution4/Enterprise02.jpg";
import solution111 from "../Assets/Images/ServiceImages/solution4/Enterprise03.jpg";
import solution112 from "../Assets/Images/ServiceImages/solution4/Rectangle 129.jpg";
import ottImage01 from "../Assets/Images/ServiceImages/solution4/ottImage01.png";
import ottImage02 from "../Assets/Images/ServiceImages/solution4/ottImage02.png";
import ottImage03 from "../Assets/Images/ServiceImages/solution4/ottImage03.png";
import ottImage04 from "../Assets/Images/ServiceImages/solution4/ottImage4.png";

import solution113 from "../Assets/Images/ServiceImages/solution4/Music&Podcast.png";

import broadcaststudio from "../Assets/Images/ServiceImages/solution3/studio.png";

import ContentMedia8 from "../Assets/Images/ContentMedia/ContentMedia8.png";
import ContentMedia9 from "../Assets/Images/ContentMedia/ContentMedia9.png";
import ContentMedia10 from "../Assets/Images/ContentMedia/ContentMedia10.png";
import ContentMedia11 from "../Assets/Images/ContentMedia/ContentMedia11.png";
import ContentMedia12 from "../Assets/Images/ContentMedia/ContentMedia12.png";

import Banner1 from "../Assets/Images/BannerImgs/Banner1.jpeg";
import Banner2 from "../Assets/Images/BannerImgs/Banner2.jpeg";
import Banner3 from "../Assets/Images/BannerImgs/Banner3.jpeg";
import Banner4 from "../Assets/Images/BannerImgs/Banner4.jpeg";
import vAppBannerImage1 from "../Assets/Images/BannerImgs/vAppBannerImage1.png";
import vAppBannerImage from "../Assets/Images/BannerImgs/vApps1.png";
import Banner5 from "../Assets/Images/BannerImgs/Banner5.jpeg";
import vDRMBannerImage from "../Assets/Images/BannerImgs/vDRM.jpg";
import vSMSBannerImage from "../Assets/Images/BannerImgs/vSMS.jpg";

import Banner6 from "../Assets/Images/BannerImgs/Banner6.jpeg";
import Banner4Old from "../Assets/Images/BannerImgs/Banner4Old.jpeg";
import Banner5Old from "../Assets/Images/BannerImgs/Banner5Old.jpeg";
import Banner6Old from "../Assets/Images/BannerImgs/Banner6Old.jpeg";
import Banner7 from "../Assets/Images/BannerImgs/Banner7.jpeg";

import StreamingBanner1 from "../Assets/Images/StreamingSectionBanner/BannerA.webp";
import StreamingBanner2 from "../Assets/Images/StreamingSectionBanner/BannerB.webp";
import StreamingBanner3 from "../Assets/Images/StreamingSectionBanner/BannerC.webp";
import StreamingBanner4 from "../Assets/Images/StreamingSectionBanner/BannerD.webp";
import StreamingBanner5 from "../Assets/Images/StreamingSectionBanner/BannerE.webp";
import StreamingBanner6 from "../Assets/Images/StreamingSectionBanner/BannerF.webp";
import StreamingBanner7 from "../Assets/Images/StreamingSectionBanner/BannerG.webp";
import StreamingBanner8 from "../Assets/Images/StreamingSectionBanner/BannerH.webp";

import ServiceImg1 from "../Assets/Images/ServiceImages/ServiceImgpic1.jpg";
import ServiceImg2 from "../Assets/Images/ServiceImages/ServiceImgpic2.jpg";
import ServiceImg3 from "../Assets/Images/ServiceImages/ServiceImgpic3.jpg";
import ServiceImg4 from "../Assets/Images/ServiceImages/ServiceImgpic4.jpg";
import ServiceImg5 from "../Assets/Images/ServiceImages/ServiceImgpic5.jpg";

import Spic1 from "../Assets/Images/ServiceImages/ServicePics/Serv1.jpg";
import Spic2 from "../Assets/Images/ServiceImages/ServicePics/Serv2.jpg";
import Spic3 from "../Assets/Images/ServiceImages/ServicePics/Serv3.jpg";
import Spic4 from "../Assets/Images/ServiceImages/ServicePics/Serv4.jpg";
import Spic5 from "../Assets/Images/ServiceImages/ServicePics/Serv5.jpg";
import Spic6 from "../Assets/Images/ServiceImages/ServicePics/Serv6.jpg";

//solution2 Media and Entatenment
import solution11 from "../Assets/Images/ServiceImages/solution2/MediaEnt1.png";
import solution12 from "../Assets/Images/ServiceImages/solution2/MediaEnt2.png";
import solution13 from "../Assets/Images/ServiceImages/solution2/MediaEnt3.png";

// services images 1
import service11 from "../Assets/Images/ServiceImages/service 1/Service1.webp";
import service12 from "../Assets/Images/ServiceImages/service 1/Service2.webp";
import service13 from "../Assets/Images/ServiceImages/service 1/Service3.webp";
import service14 from "../Assets/Images/ServiceImages/service 1/Service4.webp";
import service15 from "../Assets/Images/ServiceImages/service 1/Personalized Streaming Experience.webp";
import service16 from "../Assets/Images/ServiceImages/service 1/Video sneak peeks.webp";

// services images 2
import service21 from "../Assets/Images/ServiceImages/service 2/Rectangle 100.webp";
import service22 from "../Assets/Images/ServiceImages/service 2/Rectangle 101.webp";
import service23 from "../Assets/Images/ServiceImages/service 2/Rectangle 102.webp";
import service24 from "../Assets/Images/ServiceImages/service 2/Rectangle 103.webp";
import service25 from "../Assets/Images/ServiceImages/service 2/Rectangle 104.webp";
import service26 from "../Assets/Images/ServiceImages/service 2/26.webp";

import services1 from "../Assets/Images/ServiceImages/service 2/ott-consulting/service1.webp";
import services2 from "../Assets/Images/ServiceImages/service 2/ott-consulting/service2.webp";
import services3 from "../Assets/Images/ServiceImages/service 2/ott-consulting/service3.webp";
import services4 from "../Assets/Images/ServiceImages/service 2/ott-consulting/service4.webp";
import services5 from "../Assets/Images/ServiceImages/service 2/ott-consulting/service5.webp";

// services images 3
import service31 from "../Assets/Images/ServiceImages/service 3/Rectangle 105.webp";
import service32 from "../Assets/Images/ServiceImages/service 3/Rectangle 106.webp";
import service33 from "../Assets/Images/ServiceImages/service 3/Rectangle 107.webp";
import service34 from "../Assets/Images/ServiceImages/service 3/Rectangle 108.webp";
import service35 from "../Assets/Images/ServiceImages/service 3/Rectangle 109.webp";
import service36 from "../Assets/Images/ServiceImages/service 3/Rectangle 110.webp";

// services images 4
import service41 from "../Assets/Images/ServiceImages/service 4/41.webp";
import service42 from "../Assets/Images/ServiceImages/service 4/42.webp";
import service43 from "../Assets/Images/ServiceImages/service 4/43.webp";
import service44 from "../Assets/Images/ServiceImages/service 4/44.webp";
import service45 from "../Assets/Images/ServiceImages/service 4/45.webp";
import service46 from "../Assets/Images/ServiceImages/service 4/46.webp";
import service47 from "../Assets/Images/ServiceImages/service 4/47.png";
import full_automation from "../Assets/Images/ServiceImages/service 4/full_automation.webp";
import end_to_end_digital from "../Assets/Images/ServiceImages/service 4/end_to_end_digital.webp";
import media_asset from "../Assets/Images/ServiceImages/service 4/media_asset.webp";
import audio_streaming from "../Assets/Images/ServiceImages/service 4/audio_streaming.webp";
import digitizationImage01 from "../Assets/Images/ServiceImages/service 4/digitizationImage01.png";
import digitizationImage03 from "../Assets/Images/ServiceImages/service 4/digitizationImage03.png";
import digitizationImage05 from "../Assets/Images/ServiceImages/service 4/digitizationImage05.png";


// Partners Logo
import PartnersLogo1 from "../Assets/Images/PartnersLogo/PartnersLogo1.png";
import PartnersLogo2 from "../Assets/Images/PartnersLogo/PartnersLogo2.png";
import PartnersLogo3 from "../Assets/Images/PartnersLogo/PartnersLogo3.png";
import PartnersLogo4 from "../Assets/Images/PartnersLogo/PartnersLogo4.png";
import PartnersLogo5 from "../Assets/Images/PartnersLogo/PartnersLogo5.png";
import PartnersLogo6 from "../Assets/Images/PartnersLogo/PartnersLogo6.png";
import PartnersLogo7 from "../Assets/Images/PartnersLogo/PartnersLogo7.png";
import PartnersLogo8 from "../Assets/Images/PartnersLogo/PartnersLogo8.png";
import PartnersLogo9 from "../Assets/Images/PartnersLogo/PartnersLogo9.png";
import PartnersLogo10 from "../Assets/Images/PartnersLogo/PartnersLogo10.png";
import PartnersLogo11 from "../Assets/Images/PartnersLogo/PartnersLogo11.png";
import PartnersLogo12 from "../Assets/Images/PartnersLogo/PartnersLogo12.png";

import AboutImage1 from "../Assets/Images/AboutImages/AboutImage1.png";
import AboutImage2 from "../Assets/Images/AboutImages/AboutImage2.png";
import AboutImage3 from "../Assets/Images/AboutImages/AboutImage3.png";

import AboutPageBanner from "../Assets/Images/AboutBannerImages/AboutPageBanner.webp";
import LeadershipBanner from "../Assets/Images/AboutBannerImages/LeadershipBanner.webp";
import PartnersPageBanner from "../Assets/Images/AboutBannerImages/PartnersPageBanner.webp";

import TrustedBrand1 from "../Assets/Images/TrusrtedBrands/TrustedBrand1.png";

import TeamMember1 from "../Assets/Images/TeamMembers/TeamMember1.png";
import ContactPageBanner from "../Assets/Images/ContactPageBanner.jpg";
import CEO from "../Assets/Images/TeamMembers/CEO.webp";
import sangram from "../Assets/Images/TeamMembers/sangram.webp";
import praneeth from "../Assets/Images/TeamMembers/praneeth.webp";
import rajesh from "../Assets/Images/TeamMembers/rajesh.webp";

import Rajeshbig from "../Assets/Images/TeamMembers/Rajeshbig.png";
import praneethbig from "../Assets/Images/TeamMembers/praneethbig.png";
import subramanyam from "../Assets/Images/TeamMembers/subramanyam.webp";
import sajesh from "../Assets/Images/TeamMembers/sajesh.webp";
import rishav from "../Assets/Images/TeamMembers/rishav.png";
import rishavBig from "../Assets/Images/TeamMembers/rishavBig.jpeg";


import rajeshBhatia from "../Assets/Images/TeamMembers/rajeshBhatia.webp";
import subranyabig from "../Assets/Images/TeamMembers/subramanyabig.png";
import sajeshBig from "../Assets/Images/TeamMembers/sajeshBig.png";
import Tej from "../Assets/Images/TeamMembers/Tej.webp";
import Tejbig from "../Assets/Images/TeamMembers/Tejbig.png";

import carrier2 from "../Assets/Images/Carrier/carrier.webp";
import career from "../Assets/Images/Carrier/careerBanner.png";

const ImageHelper = {
  HeroImage,
  Logo,
  StreamTypeCover,
  vLive_Logo,
  Sounds,
  vApps,
  vCMS,
  vCRM,
  vDRM,
  vLite_MOOC,
  vSMS,

  //leaders
  praneeth,
  sangram,
  rajesh,
  Rajeshbig,
  praneethbig,
  subramanyam,
  sajesh,
  rajeshBhatia,

  BanrdsLogo1,
  BanrdsLogo2,
  BanrdsLogo3,
  BanrdsLogo4,
  BanrdsLogo5,
  BanrdsLogo6,
  BanrdsLogo7,
  BanrdsLogo8,
  BanrdsLogo9,
  BanrdsLogo10,
  BanrdsLogo11,
  BanrdsLogo12,
  BanrdsLogo13,
  //BanrdsLogo14,

  cardImage,
  iPhone12Promockup,
  Blob,
  CategoreisImages1,
  CategoreisImages2,
  CategoreisImages3,
  CategoreisImages4,
  CategoreisImages5,
  CategoreisImages6,
  CategoreisImages7,
  CategoreisImages8,
  CategoreisImages9,
  CategoreisImages10,

  Avatar1,
  Avatar2,
  Avatar3,
  Avatar4,

  BrandCover,
  BrandCover1,
  BrandCover2,
  BrandCover3,
  BrandCover4,
  BrandCover5,
  BrandCover6,
  BrandCover7,
  BrandCover8,
  BrandCover9,
  // BrandCover10,
  BrandCover11,
  BrandCover12,
  BrandCover13,
  BrandCover14,
  BrandCover15,
  BrandCover16,
  BrandCover17,
  BrandCover18,
  BrandCover19,
  BrandCover20,
  BrandCover21,
  BrandCover22,
  BrandCover23,

  ShowBanner1,
  ShowBanner2,
  ShowBanner3,
  MediaCardImg1,
  MediaCardImg2,
  MediaCardImg3,
  MediaCardImg4,
  MediaCardImg5,
  vAppBannerImage,
  vDRMBannerImage,
  vSMSBannerImage,
  MediaCardImg6,
  MediaCardImg7,
  Banner1,
  Banner2,
  Banner3,
  Banner4,
  Banner5,
  Banner6,
  Banner4Old,
  Banner5Old,
  Banner6Old,
  Banner7,

  StreamingBanner1,
  StreamingBanner2,
  StreamingBanner3,
  StreamingBanner4,
  vAppBannerImage1,
  StreamingBanner5,
  StreamingBanner6,
  StreamingBanner7,
  StreamingBanner8,

  ContentMedia1,
  ContentMedia2,
  ContentMedia3,
  ContentMedia4,
  ContentMedia5,
  ContentMedia6,
  ContentMedia7,
  ContentMedia8,
  ContentMedia9,
  ContentMedia10,
  ContentMedia11,
  ContentMedia12,
  education,
  operators,
  media__entertainment,

  //solution
  solution3,
  solution4,
  solution5,

  solution6,
  solution7,
  solution8,

  solution9,
  solution10,
  solution111,
  solution112,
  solution113,

  about1,
  about2,
  about3,

  solution11,
  solution12,
  solution13,
  ottImage01,
  ottImage02,
  ottImage03,
  ottImage04,

  //services
  service11,
  service12,
  service13,
  service14,
  service15,
  service16,

  services1,
  services2,
  services3,
  services4,
  services5,

  service21,
  service22,
  service23,
  service24,
  service25,
  service26,

  service31,
  service32,
  service33,
  service34,
  service35,
  service36,

  service41,
  service42,
  service43,
  service44,
  service45,
  service46,
  service47,
  full_automation,
  end_to_end_digital,
  media_asset,
  audio_streaming,
  digitizationImage01,
  digitizationImage03,
  digitizationImage05,

  ServiceImg1,
  ServiceImg2,
  ServiceImg3,
  ServiceImg4,
  ServiceImg5,

  Spic1,
  Spic2,
  Spic3,
  Spic4,
  Spic5,
  Spic6,

  PartnersLogo1,
  PartnersLogo2,
  PartnersLogo3,
  PartnersLogo4,
  PartnersLogo5,
  PartnersLogo6,
  PartnersLogo7,
  PartnersLogo8,
  PartnersLogo9,
  PartnersLogo10,
  PartnersLogo11,
  PartnersLogo12,

  AboutImage1,
  AboutImage2,
  AboutImage3,

  AboutPageBanner,
  LeadershipBanner,
  PartnersPageBanner,

  TrustedBrand1,

  TeamMember1,

  ContactPageBanner,
  rishav,
  rishavBig,
  sajeshBig,
  subranyabig,
  CEO,
  Tej,
  Tejbig,

  carrier2,
  career,

  broadcast1,
  broadcast2,
  broadcast3,
  broadcast4,
  broadcast5,
  broadcaststudio,
  studio,
  graphics,
  end_to_end,
  centralized,
  efficient_it,
  broadcastImage01,
  broadcastImage02,
  broadcastImage03,
  broadcastImage04,
  broadcastImage05,
};

export default ImageHelper;
