import React from "react";
import ColorHelper from "../../Helpers/ColorHelper";

const Sounds3 = (props) => {
    const { color } = props;
  return (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width={46} height={46} viewBox="0 0 46 46">
   
    <path stroke={ColorHelper.BgBlue} class="cls-1" d="M18.45,31.67c-.16,0-.33-.06-.46-.17l-4.61-3.93c-.16-.14-.25-.33-.25-.54s.09-.41,.25-.54l4.61-3.93c.3-.26,.75-.22,1.01,.08,.26,.3,.22,.75-.08,1.01l-3.98,3.39,3.98,3.39c.3,.26,.34,.71,.08,1.01-.14,.17-.34,.25-.54,.25Z"/>
    <path stroke={ColorHelper.BgBlue} class="cls-1" d="M27.55,31.67c-.2,0-.4-.09-.54-.25-.26-.3-.22-.75,.08-1.01l3.98-3.39-3.98-3.39c-.3-.26-.34-.71-.08-1.01,.26-.3,.71-.34,1-.08l4.61,3.93c.16,.14,.25,.33,.25,.54s-.09,.41-.25,.54l-4.61,3.93c-.13,.11-.3,.17-.46,.17Z"/>
    <path stroke={ColorHelper.BgBlue} class="cls-1" d="M21.37,34.61s-.1,0-.15-.02c-.39-.08-.63-.46-.55-.84l2.88-13.65c.08-.39,.46-.63,.84-.55,.39,.08,.63,.46,.55,.84l-2.88,13.65c-.07,.34-.37,.57-.7,.57Z"/>
    <path stroke={ColorHelper.BgBlue} class="cls-1" d="M39.81,42.05H6.19c-2.64,0-4.79-2.15-4.79-4.79V8.73C1.4,6.09,3.55,3.95,6.19,3.95H39.81c2.64,0,4.79,2.15,4.79,4.79v28.53c0,2.64-2.15,4.79-4.79,4.79ZM6.19,5.37c-1.85,0-3.36,1.51-3.36,3.36v28.53c0,1.85,1.51,3.36,3.36,3.36H39.81c1.85,0,3.36-1.51,3.36-3.36V8.73c0-1.85-1.51-3.36-3.36-3.36H6.19Z"/>
    <path stroke={ColorHelper.BgBlue} class="cls-1" d="M43.88,15.36H2.12c-.39,0-.71-.32-.71-.71s.32-.71,.71-.71H43.88c.39,0,.71,.32,.71,.71s-.32,.71-.71,.71Z"/>
    <path stroke={ColorHelper.BgBlue} class="cls-1" d="M13.59,11.95c-1.24,0-2.26-1.01-2.26-2.26s1.01-2.26,2.26-2.26,2.26,1.01,2.26,2.26-1.01,2.26-2.26,2.26Zm0-3.09c-.46,0-.83,.37-.83,.83s.37,.83,.83,.83,.83-.37,.83-.83-.37-.83-.83-.83Z"/>
    <path stroke={ColorHelper.BgBlue} class="cls-1" d="M6.65,11.93c-1.24,0-2.26-1.01-2.26-2.26s1.01-2.26,2.26-2.26,2.26,1.01,2.26,2.26-1.01,2.26-2.26,2.26Zm0-3.09c-.46,0-.83,.37-.83,.83s.37,.83,.83,.83,.83-.37,.83-.83-.37-.83-.83-.83Z"/>
    <path stroke={ColorHelper.BgBlue} class="cls-1" d="M20.52,11.97c-1.24,0-2.26-1.01-2.26-2.26s1.01-2.26,2.26-2.26,2.26,1.01,2.26,2.26-1.01,2.26-2.26,2.26Zm0-3.09c-.46,0-.83,.37-.83,.83s.37,.83,.83,.83,.83-.37,.83-.83-.37-.83-.83-.83Z"/>
  </svg>
  );
};

export default Sounds3;