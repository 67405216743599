import React from "react";
import ColorHelper from "../../Helpers/ColorHelper";

const HighlightIcon4 = () => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.9997 3.83325C17.9164 3.83325 13.0412 5.85259 9.44679 9.44704C5.85235 13.0415 3.83301 17.9166 3.83301 22.9999C3.83301 28.0832 5.85235 32.9584 9.44679 36.5528C13.0412 40.1472 17.9164 42.1666 22.9997 42.1666C28.083 42.1666 32.9581 40.1472 36.5526 36.5528C40.147 32.9584 42.1663 28.0832 42.1663 22.9999C42.1663 17.9166 40.147 13.0415 36.5526 9.44704C32.9581 5.85259 28.083 3.83325 22.9997 3.83325V3.83325Z"
        stroke={ColorHelper.BgBlue}
        stroke-width="3.83333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.167 15.3333L30.667 22.9999L19.167 30.6666V15.3333Z"
        stroke={ColorHelper.BgBlue}
        stroke-width="3.83333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default HighlightIcon4;
