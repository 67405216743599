import IconHelper from "../IconHelper";
import ImageHelper from "../ImageHelper";

const CarrierData = [
  {
    id: "hl1",
    hightlights: [
      {
        id: "cm1",
        title: "Scalable and secure",
        description:
          "Ensure that your content seamlessly reaches your audience as your company grows. Enlarge and retain your streaming audience on a platform that broadcasts your content in the best way possible. ",
        image: ImageHelper.solution11,
      },
      {
        id: "cm2",
        title: "Top-rated streaming infrastructure",
        description:
          "We help you build a secure streaming platform supported by effective distribution methods. Enjoy the freedom to stream your content through any cloud-based server or host your videos on an on-premises server within your online video platform. ",
        image: ImageHelper.solution12,
      },
      {
        id: "cm3",
        title: "Limitless video delivery",
        description:
          "Create and deliver excellent video experiences on mobile, the web, smart TVs, and linked TVs. Get the finest rated and quick support that enables your target viewers to stream your content without interruptions at unprecedented scales. ",
        image: ImageHelper.solution13,
      },
      
      /*  {
                      id: "cm5",
                      title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et dignissim maecenas at neque. Enim sed fermentum ut et laoreet non ut tellus sit. Et accumsan morbi sit id eget placerat sit netus quis. Feugiat lectus lectus id.",
                      image: ImageHelper.ContentMedia1,
                  } */
    ],
  },
  {
    id: "hl2",
      canonical: "https://www.mobiotics.com/company/career",
    hightlights: [
      {
        id: "cm1",
        jobName: "iOS Developer (1-5 Years)",
        slug: "iOS-developer",
        location: "Cochin and Bangalore",
        stream: "Engineering",
        ApplyId: 1,
        mode: "FullTime",
        AboutMobiotics: "",
        responsibility: "Job Description",
        // skillHeading:"Job Description",
        profileHeading: "Required candidate profile",
        perksHeading: "Perks And Benefits",
        company:
          "If you have an interesting portfolio of apps and have excellent communication and team skills and you fit the description below - you are the one we are looking for",
        // roles: [
        //   { id: 1, text: "Good Experience in Vue.js or React.js" },
        //   {
        //     id: 2,
        //     text: "Excellent in core front-end web technologies (JavaScript, HTML5, SCSS, CSS3)",
        //   },
        // ],
        // roles1:"Good Experience in Vue.js or React.js",
        // roles2:"Excellent in core front-end web technologies (JavaScript, HTML5, SCSS, CSS3)",
        skills: [
          {
            id: 1,
            skills:
              "Strong understanding of Swift & Objective-C, MVC/MVVM, and Cocoa APIs/frameworks",
          },
          {
            id: 2,
            skills:
              "Experience with AVFoundation, CoreData,Core location, CoreAnimation, CoreGraphics, NSURLSession, iOS background modes, tvOS, JSON, CocoaPods,3D touch.",
          },
          {
            id: 3,
            skills:
              "Familiarity with RESTful APIs to connect iOS applications to back-end services.",
          },
          {
            id: 4,
            skills:
              "Strong understanding of Apple's design principles and interface guidelines. ",
          },
          {
            id: 5,
            skills:
              "Strong understanding of auto layout and UI implementation.",
          },
          { id: 6, skills: "Strong understanding of Git." },
          {
            id: 7,
            skills: "Understanding of the full mobile development life cycle.",
          },
          { id: 8, skills: "Experience with third-party libraries and APIs." },
          {
            id: 9,
            skills:
              "Establish unit-test code for robustness, including edge cases, usability, and general reliability.",
          },
          {
            id: 10,
            skills:
              "Knowledge in making network calls using Alamofile(swift), AFNetworking(Objective - C), and using native NSURLSession.",
          },

          {
            id: 11,
            skills:
              "Good debugging skills and knowledge to use Instruments App.",
          },
          {
            id: 12,
            skills:
              "App store deployment and certificate management knowledge.",
          },
          {
            id: 13,
            skills:
              "Knowledge in Firebase Real-Time dB and FCM will be a plus. ",
          },
          {
            id: 14,
            skills: "Experience in implementing universal linking in iOS.",
          },
        ],
        candidateProfile: [
          {
            id: 1,
            text: "Minimum Bachelor’s degree or equivalent experience in computer science.",
          },
          { id: 2, text: "1-5 years of relevant experience." },
          { id: 3, text: "We are preferring immediate to 30 days joiners." },
          {
            id: 4,
            text: "Ability to multi task, organize and prioritize work.",
          },
          {
            id: 5,
            text: "Good oral and written communication skills in English.",
          },
        ],
        // candidateProfile1:"Bachelor's degree or post-graduation in computer science or related subjects",
        // candidateProfile2:"3-5 years of experience as a front end developer",
        // candidateProfile3:"Ability to multi-task, organize, and prioritize work",
        // candidateProfile4:"Good oral and written communication skills in English",
        // candidateProfile5:"You are a go-getter with excellent learning, communication, and team skills. You revel in working with cutting-edge technology and have an eye for detail.",
        perks: "Health insurance, Daily lunch, Stock option, Referral bonuses",
        about:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id nibh nunc, arcu diam erat sit morbi. Vel amet arcu donec aliquet vitae. Feugiat mi risus tristique facilisi egestas diam in in. Dolor purus auctor sit eu consectetur adipiscing ultrices. Dui dignissim mi etiam amet, egestas sociis congue id molestie. Ornare ornare massa volutpat sed nec vitae sit duis etiam. Ultrices libero non rutrum et urna, pharetra. Nisi, tempus purus in habitant et morbi pharetra. Urna ipsum velit nisi eu, consequat curabitur feugiat maecenas. Enim nibh diam leo condimentum accumsan sit facilisi euismod ut. Tortor eget egestas nunc vel eget. Iaculis sed donec at tellus at dictumst. Ac sit risus enim lorem. Consectetur amet sed eget id amet urna libero. Vulputate sapien semper viverra vitae faucibus pretium bibendum blandit imperdiet. Pulvinar pretium nisl at scelerisque at. At ac ornare duis magna egestas nulla. Potenti et, tortor est convallis scelerisque tincidunt nulla tellus imperdiet.",
        desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id nibh nunc, arcu diam erat sit morbi. Vel amet arcu donec aliquet vitae. Feugiat mi risus tristique facilisi egestas diam in in. Dolor purus auctor sit eu consectetur adipiscing ultrices. Dui dignissim mi etiam amet, egestas sociis congue id molestie Ornare ornare massa volutpat sed nec vitae sit duis etiam. Ultrices libero non rutrum et urna, pharetra. Nisi, tempus purus in habitant et morbi pharetra. Urna ipsum velit nisi eu, consequat curabitur feugiat maecenas. Enim nibh diam leo condimentum accumsan sit facilisi euismod ut. Tortor eget egestas nunc vel eget. Iaculis sed donec at tellus at dictumst. Ac sit risus enim lorem. Consectetur amet sed eget id amet urna libero. Vulputate sapien semper viverra vitae faucibus pretium bibendum blandit imperdiet. Pulvinar pretium nisl at scelerisque at. At ac ornare duis magna egestas nulla. Potenti et, tortor est convallis scelerisque tincidunt nulla tellus imperdiet.",
      },
      // {
      //   id: "cm15",
      //   jobName: "QA Engineer (2-5 years)",
      //   slug: "QA-Engineer",
      //   location: "Cochin",
      //   stream: "Engineering",
      //   ApplyId: 2,
      //   mode: "FullTime",
      //   AboutMobiotics: "",
      //   responsibility: "Roles and Responsibilities",
      //   skillHeading: "Key Skills",
      //   greatToHaveHeading: "Great to have",
      //   profileHeading: " Required candidate profile",
      //   perksHeading: "Perks And Benefits",
      //   company:
      //     "",
      //   roles: [
      //     { id: 1, text: "Review of software requirements" },
      //     { id: 2, text: "Preparation of test cases" },
      //     { id: 3, text: "Execution of tests" },
      //     { id: 4, text: "Automation" },
      //     { id: 5, text: "Reporting of defects" },
      //     { id: 6, text: "Preparation of test reports" },
      //     { id: 7, text: "Communicating with Remote/Dev/DevOps team" },
      //     { id: 8, text: "Software Release and Configuration Support" },
      //     { id: 9, text: "Customer Issue Management" },
      //   ],
      //   // roles1:"Good Experience in Vue.js or React.js",
      //   // roles2:"Excellent in core front-end web technologies (JavaScript, HTML5, SCSS, CSS3)",
      //   skills: [
      //     { id: 1, skills: "A good understanding of SDLC" },
      //     { id: 2, skills: "A good understanding of bug life cycle" },
      //     { id: 3, skills: "Good knowledge in SQL" },
      //     { id: 4, skills: "Good knowledge in Linux" },
      //     {
      //       id: 5,
      //       skills:
      //         "A good understanding of selenium automation for Android and Web",
      //     },
      //     {
      //       id: 6,
      //       skills: "Good writing skills (for documenting the process)",
      //     },
      //     { id: 7, skills: "Good communications skills" },
      //     { id: 8, skills: "Good presentation skills" },
      //   ],
      //   greatToHaveSkills: [
      //     { id: 1, skills: "knowledge of Github/bitbucket" },
      //     {
      //       id: 2,
      //       skills:
      //         "Knowledge of tailing server error logs and console logs using firebug.",
      //     },
      //     { id: 3, skills: "Knowledge of Security testing" },
      //     { id: 4, skills: "Knowledge of tools like JMeter, Postman, Putty" },
      //   ],
      //   candidateProfile: [
      //     {
      //       id: 1,
      //       text: "Minimum Bachelor’s degree or equivalent experience in computer science",
      //     },
      //     { id: 2, text: "2-5 years of relevant experience" },
      //     { id: 3, text: "We are preferring immediate to 30 days joiners" },
      //     { id: 4, text: "Proficient in malayalam" },
      //     {
      //       id: 5,
      //       text: "Ability to multi task, organize and prioritize work",
      //     },
      //     {
      //       id: 6,
      //       text: "Good oral and written communication skills in English",
      //     },
      //   ],
      //   // candidateProfile1:"Bachelor's degree or post-graduation in computer science or related subjects",
      //   // candidateProfile2:"3-5 years of experience as a front end developer",
      //   // candidateProfile3:"Ability to multi-task, organize, and prioritize work",
      //   // candidateProfile4:"Good oral and written communication skills in English",
      //   // candidateProfile5:"You are a go-getter with excellent learning, communication, and team skills. You revel in working with cutting-edge technology and have an eye for detail.",
      //   perks: "As per the industry standards",
      //   about:
      //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id nibh nunc, arcu diam erat sit morbi. Vel amet arcu donec aliquet vitae. Feugiat mi risus tristique facilisi egestas diam in in. Dolor purus auctor sit eu consectetur adipiscing ultrices. Dui dignissim mi etiam amet, egestas sociis congue id molestie. Ornare ornare massa volutpat sed nec vitae sit duis etiam. Ultrices libero non rutrum et urna, pharetra. Nisi, tempus purus in habitant et morbi pharetra. Urna ipsum velit nisi eu, consequat curabitur feugiat maecenas. Enim nibh diam leo condimentum accumsan sit facilisi euismod ut. Tortor eget egestas nunc vel eget. Iaculis sed donec at tellus at dictumst. Ac sit risus enim lorem. Consectetur amet sed eget id amet urna libero. Vulputate sapien semper viverra vitae faucibus pretium bibendum blandit imperdiet. Pulvinar pretium nisl at scelerisque at. At ac ornare duis magna egestas nulla. Potenti et, tortor est convallis scelerisque tincidunt nulla tellus imperdiet.",
      //   desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id nibh nunc, arcu diam erat sit morbi. Vel amet arcu donec aliquet vitae. Feugiat mi risus tristique facilisi egestas diam in in. Dolor purus auctor sit eu consectetur adipiscing ultrices. Dui dignissim mi etiam amet, egestas sociis congue id molestie Ornare ornare massa volutpat sed nec vitae sit duis etiam. Ultrices libero non rutrum et urna, pharetra. Nisi, tempus purus in habitant et morbi pharetra. Urna ipsum velit nisi eu, consequat curabitur feugiat maecenas. Enim nibh diam leo condimentum accumsan sit facilisi euismod ut. Tortor eget egestas nunc vel eget. Iaculis sed donec at tellus at dictumst. Ac sit risus enim lorem. Consectetur amet sed eget id amet urna libero. Vulputate sapien semper viverra vitae faucibus pretium bibendum blandit imperdiet. Pulvinar pretium nisl at scelerisque at. At ac ornare duis magna egestas nulla. Potenti et, tortor est convallis scelerisque tincidunt nulla tellus imperdiet.",
      // },
      // {
      //   id: "cmB15",
      //   jobName: "Lead QA Engineer(4-6 Years)",
      //   slug: "Lead-QA-Engineer-4-6",
      //   location: "Bangalore",
      //   stream: "Engineering",
      //   ApplyId: 3,
      //   mode: "FullTime",
      //   AboutMobiotics: "",
      //   responsibility: "Roles and Responsibilities",
      //   skillHeading: "Key Skills",
      //   greatToHaveHeading: "Great to have",
      //   profileHeading: "Required candidate profile",
      //   perksHeading: "Perks And Benefits",
      //   company: "",
      //   roles: [
      //     { id: 1, text: "Review of software requirements" },
      //     { id: 2, text: "Preparation of test cases" },
      //     { id: 3, text: "Execution of tests" },
      //     { id: 4, text: "Automation" },
      //     { id: 5, text: "Reporting of defects" },
      //     { id: 6, text: "Preparation of test reports" },
      //     { id: 7, text: "Communicating with Remote/Dev/DevOps team" },
      //     { id: 8, text: "Software Release and Configuration Support" },
      //     { id: 9, text: "Customer Issue Management" },
      //     { id: 10, text: "Team mentoring and leading" },
      //   ],
      //   // roles1:"Good Experience in Vue.js or React.js",
      //   // roles2:"Excellent in core front-end web technologies (JavaScript, HTML5, SCSS, CSS3)",
      //   skills: [
      //     { id: 1, skills: "A good understanding of SDLC" },
      //     { id: 2, skills: "A good understanding of bug life cycle" },
      //     { id: 3, skills: "Good knowledge in SQL" },
      //     { id: 4, skills: "Good knowledge in Linux" },
      //     {
      //       id: 5,
      //       skills:
      //         "A good understanding of selenium automation for Android and Web",
      //     },
      //     {
      //       id: 6,
      //       skills: "Good writing skills (for documenting the process)",
      //     },
      //     { id: 7, skills: "Good communications skills" },
      //     { id: 8, skills: "Good presentation skills" },
      //     { id: 9, skills: "Should have team leading experience" },
      //   ],
      //   greatToHaveSkills: [
      //     { id: 1, skills: "knowledge of Github/bitbucket" },
      //     {
      //       id: 2,
      //       skills:
      //         "Knowledge of tailing server error logs and console logs using firebug.",
      //     },
      //     { id: 3, skills: "Knowledge of Security testing" },
      //     { id: 4, skills: "Knowledge of tools like JMeter, Postman, Putty" },
      //     { id: 5, skills: "Any experience with customer support" },
      //   ],
      //   candidateProfile: [
      //     {
      //       id: 1,
      //       text: "Minimum Bachelor’s degree or equivalent experience in computer science",
      //     },
      //     { id: 2, text: "4-6 years of relevant experience" },
      //     { id: 3, text: "We are preferring immediate to 30 days joiners" },
      //     { id: 4, text: "Proficient in malayalam" },
      //     {
      //       id: 5,
      //       text: "Ability to multi task, organize and prioritize work",
      //     },
      //     {
      //       id: 6,
      //       text: "Good oral and written communication skills in English",
      //     },
      //   ],
      //   // candidateProfile1:"Bachelor's degree or post-graduation in computer science or related subjects",
      //   // candidateProfile2:"3-5 years of experience as a front end developer",
      //   // candidateProfile3:"Ability to multi-task, organize, and prioritize work",
      //   // candidateProfile4:"Good oral and written communication skills in English",
      //   // candidateProfile5:"You are a go-getter with excellent learning, communication, and team skills. You revel in working with cutting-edge technology and have an eye for detail.",
      //   perks: "As per the industry standards",
      //   about:
      //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id nibh nunc, arcu diam erat sit morbi. Vel amet arcu donec aliquet vitae. Feugiat mi risus tristique facilisi egestas diam in in. Dolor purus auctor sit eu consectetur adipiscing ultrices. Dui dignissim mi etiam amet, egestas sociis congue id molestie. Ornare ornare massa volutpat sed nec vitae sit duis etiam. Ultrices libero non rutrum et urna, pharetra. Nisi, tempus purus in habitant et morbi pharetra. Urna ipsum velit nisi eu, consequat curabitur feugiat maecenas. Enim nibh diam leo condimentum accumsan sit facilisi euismod ut. Tortor eget egestas nunc vel eget. Iaculis sed donec at tellus at dictumst. Ac sit risus enim lorem. Consectetur amet sed eget id amet urna libero. Vulputate sapien semper viverra vitae faucibus pretium bibendum blandit imperdiet. Pulvinar pretium nisl at scelerisque at. At ac ornare duis magna egestas nulla. Potenti et, tortor est convallis scelerisque tincidunt nulla tellus imperdiet.",
      //   desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id nibh nunc, arcu diam erat sit morbi. Vel amet arcu donec aliquet vitae. Feugiat mi risus tristique facilisi egestas diam in in. Dolor purus auctor sit eu consectetur adipiscing ultrices. Dui dignissim mi etiam amet, egestas sociis congue id molestie Ornare ornare massa volutpat sed nec vitae sit duis etiam. Ultrices libero non rutrum et urna, pharetra. Nisi, tempus purus in habitant et morbi pharetra. Urna ipsum velit nisi eu, consequat curabitur feugiat maecenas. Enim nibh diam leo condimentum accumsan sit facilisi euismod ut. Tortor eget egestas nunc vel eget. Iaculis sed donec at tellus at dictumst. Ac sit risus enim lorem. Consectetur amet sed eget id amet urna libero. Vulputate sapien semper viverra vitae faucibus pretium bibendum blandit imperdiet. Pulvinar pretium nisl at scelerisque at. At ac ornare duis magna egestas nulla. Potenti et, tortor est convallis scelerisque tincidunt nulla tellus imperdiet.",
      // },
      {
        id: "cmB16",
        jobName: "Lead Backend Developer (5-7 Years)",
        slug: "lead-backend-developer",
        location: "Bangalore",
        stream: "Engineering",
        ApplyId: 4,
        mode: "FullTime",
        AboutMobiotics: "",
        responsibility: "Job Description",
        skillHeading: "Good To have skills",
        // greatToHaveHeading: "Great to have",
        profileHeading: "Qualifications",
        perksHeading: "Perks And Benefits",
        company:
          "The ideal candidate is a creative problem solver who will work in coordination with cross-functional teams to design, develop, and maintain our OTT Video SAAS Products. You must be comfortable working as part of a team while taking the initiative to take lead on new innovations and projects.",
        roles: [
          { id: 1, text: "5-7 years of experience" },
          { id: 2, text: "Create/Maintain OTT Video SAAS Products" },
          {
            id: 3,
            text: "Work cross-functionally to enhance overall user experience of our platforms",
          },
          {
            id: 4,
            text: "Own various design tasks involved in the product development life cycle from start to finish-",
          },
          {
            id: 5,
            text: "Ability to mentor and coach junior engineers on processes and technologies.-",
          },
          {
            id: 6,
            text: "Managing and developing microservices that include various Payment gateways integration.",
          },
          { id: 7, text: "Managing and creating Serverless Applications." },
          { id: 8, text: "Good understanding of Microservices architecture." },
          { id: 9, text: "Excellent NodeJS skills." },
          { id: 10, text: "Excellent skills on retaliation DBs like MySql." },
          {
            id: 11,
            text: "At least 2+ Experience working on Serverless technologies. (AWS Lambda)",
          },
          {
            id: 12,
            text: "Good understanding of DevOps process and ability to manage services on the cloud.",
          },
          {
            id: 13,
            text: "At least 2+ years of experience working on AWS cloud such as CloudFormation, Lambda, API gateway, RDS, SQS, Serverless computing, AWS Batch, docker, etc.",
          },
          {
            id: 14,
            text: "Ability to undertake an existing software product, understand the current development, and maintain and execute further development.",
          },
          {
            id: 15,
            text: "Relevant Experience in developing/leading and managing large scalable applications involving multiple teams.",
          },
          {
            id: 16,
            text: "Debugging and troubleshooting skills and ability to generate RCA.",
          },
          { id: 17, text: "Experience with In memory DBS like Redis" },
        ],
        // roles1:"Good Experience in Vue.js or React.js",
        // roles2:"Excellent in core front-end web technologies (JavaScript, HTML5, SCSS, CSS3)",
        skills: [
          { id: 1, skills: "Good Understanding of Python/Go/Dot.Net" },
          { id: 2, skills: "AWS Certification" },
          {
            id: 3,
            skills:
              "Experience in working on various Payment gateways including Telcos.",
          },
          {
            id: 4,
            skills: "Previously worked on video streaming platforms/products.",
          },
          {
            id: 5,
            skills:
              "Have a good understanding of the Analytics systems and hands-on experience in working with services like AWS Kinesis, Redshift, etc.",
          },
        ],
        // greatToHaveSkills: [
        //   { id: 1, skills: "knowledge of Github/bitbucket" },
        //   {
        //     id: 2,
        //     skills:
        //       "Knowledge of tailing server error logs and console logs using firebug.",
        //   },
        //   { id: 3, skills: "Knowledge of Security testing" },
        //   { id: 4, skills: "Knowledge of tools like JMeter, Postman, Putty" },
        //   { id: 5, skills: "Any experience with customer support" },
        // ],
        candidateProfile: [
          {
            id: 1,
            text: "Minimum Bachelor's degree or equivalent experience computer science",
          },
          {
            id: 2,
            text: "5-7 years' of experience using LAMP, Node.js, express.js and AWS Lambda",
          },
          {
            id: 3,
            text: "Proficiency in at least one Javascript Framework (Vue.js, React.js)",
          },
          { id: 4, text: "Proficiency in AWS" },
          {
            id: 5,
            text: "Ability to multi-task, organize, and prioritize work",
          },
          {
            id: 6,
            text: "Good oral and written communication skills in English",
          },
        ],
        // candidateProfile1:"Bachelor's degree or post-graduation in computer science or related subjects",
        // candidateProfile2:"3-5 years of experience as a front end developer",
        // candidateProfile3:"Ability to multi-task, organize, and prioritize work",
        // candidateProfile4:"Good oral and written communication skills in English",
        // candidateProfile5:"You are a go-getter with excellent learning, communication, and team skills. You revel in working with cutting-edge technology and have an eye for detail.",
        perks: "Health insurance, Daily lunch, Stock option, Referral bonuses",
        about:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id nibh nunc, arcu diam erat sit morbi. Vel amet arcu donec aliquet vitae. Feugiat mi risus tristique facilisi egestas diam in in. Dolor purus auctor sit eu consectetur adipiscing ultrices. Dui dignissim mi etiam amet, egestas sociis congue id molestie. Ornare ornare massa volutpat sed nec vitae sit duis etiam. Ultrices libero non rutrum et urna, pharetra. Nisi, tempus purus in habitant et morbi pharetra. Urna ipsum velit nisi eu, consequat curabitur feugiat maecenas. Enim nibh diam leo condimentum accumsan sit facilisi euismod ut. Tortor eget egestas nunc vel eget. Iaculis sed donec at tellus at dictumst. Ac sit risus enim lorem. Consectetur amet sed eget id amet urna libero. Vulputate sapien semper viverra vitae faucibus pretium bibendum blandit imperdiet. Pulvinar pretium nisl at scelerisque at. At ac ornare duis magna egestas nulla. Potenti et, tortor est convallis scelerisque tincidunt nulla tellus imperdiet.",
        desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id nibh nunc, arcu diam erat sit morbi. Vel amet arcu donec aliquet vitae. Feugiat mi risus tristique facilisi egestas diam in in. Dolor purus auctor sit eu consectetur adipiscing ultrices. Dui dignissim mi etiam amet, egestas sociis congue id molestie Ornare ornare massa volutpat sed nec vitae sit duis etiam. Ultrices libero non rutrum et urna, pharetra. Nisi, tempus purus in habitant et morbi pharetra. Urna ipsum velit nisi eu, consequat curabitur feugiat maecenas. Enim nibh diam leo condimentum accumsan sit facilisi euismod ut. Tortor eget egestas nunc vel eget. Iaculis sed donec at tellus at dictumst. Ac sit risus enim lorem. Consectetur amet sed eget id amet urna libero. Vulputate sapien semper viverra vitae faucibus pretium bibendum blandit imperdiet. Pulvinar pretium nisl at scelerisque at. At ac ornare duis magna egestas nulla. Potenti et, tortor est convallis scelerisque tincidunt nulla tellus imperdiet.",
      },
      // {
      //   id: "cmB17",
      //   jobName: "Technical Writer (3-5 Years)",
      //   slug: "Technical-Writer",
      //   location: "Bangalore",
      //   stream: "Technical",
      //   ApplyId: 5,
      //   mode: "FullTime",
      //   AboutMobiotics: "",
      //   responsibility: "Job Description",
      //   // skillHeading: "Good To have skills",
      //   // greatToHaveHeading: "Great to have",
      //   profileHeading: "Qualifications",
      //   perksHeading: "Perks And Benefits",
      //   company:
      //     "",
      //   roles: [
      //     {
      //       id: 1,
      //       text: "Curate, edit, and write supporting documents - User guide for products Determine requirements of end-users.",
      //     },
      //     {
      //       id: 2,
      //       text: "Identify ways to minimize the use of technical instructions.",
      //     },
      //     {
      //       id: 3,
      //       text: "Employ diagrams, drawings, and/or other visual media to enhance the user’s understanding.",
      //     },
      //     {
      //       id: 4,
      //       text: "Pick and choose the appropriate medium to communicate processes or instructions.",
      //     },
      //     {
      //       id: 5,
      //       text: "Gather feedback and work to implement it in the process.",
      //     },
      //     {
      //       id: 6,
      //       text: "Knowledge of preparation of Short Video Tutorials.",
      //     },
      //     {
      //       id: 7,
      //       text: "Experience in preparation of API documentation using the tools - Swagger/PostMan is a plus.",
      //     },
      //     {
      //       id: 8,
      //       text: "Knowledge/experience on preparation of software requirements specification is a plus.",
      //     },
      //   ],
      //   // roles1:"Good Experience in Vue.js or React.js",
      //   // roles2:"Excellent in core front-end web technologies (JavaScript, HTML5, SCSS, CSS3)",
      //   // skills: [
      //   //   { id: 1, skills: "Good Understanding of Python/Go/Dot.Net" },
      //   //   { id: 2, skills: "AWS Certification" },
      //   //   {
      //   //     id: 3,
      //   //     skills:
      //   //       "Experience in working on various Payment gateways including Telcos.",
      //   //   },
      //   //   {
      //   //     id: 4,
      //   //     skills: "Previously worked on video streaming platforms/products.",
      //   //   },
      //   //   {
      //   //     id: 5,
      //   //     skills:
      //   //       "Have a good understanding of the Analytics systems and hands-on experience in working with services like AWS Kinesis, Redshift, etc.",
      //   //   },
      //   // ],
      //   // greatToHaveSkills: [
      //   //   { id: 1, skills: "knowledge of Github/bitbucket" },
      //   //   {
      //   //     id: 2,
      //   //     skills:
      //   //       "Knowledge of tailing server error logs and console logs using firebug.",
      //   //   },
      //   //   { id: 3, skills: "Knowledge of Security testing" },
      //   //   { id: 4, skills: "Knowledge of tools like JMeter, Postman, Putty" },
      //   //   { id: 5, skills: "Any experience with customer support" },
      //   // ],
      //   candidateProfile: [
      //     {
      //       id: 1,
      //       text: "Minimum Bachelor’s degree or equivalent experience computer science",
      //     },
      //     { id: 2, text: "3-5 years of relevant experience" },
      //     { id: 3, text: "We are preferring immediate to 30 days of joiners" },
      //     {
      //       id: 4,
      //       text: "Ability to multi-task, organize, and prioritize work",
      //     },
      //     {
      //       id: 5,
      //       text: "Good oral and written communication skills in English",
      //     },
      //   ],
      //   // candidateProfile1:"Bachelor's degree or post-graduation in computer science or related subjects",
      //   // candidateProfile2:"3-5 years of experience as a front end developer",
      //   // candidateProfile3:"Ability to multi-task, organize, and prioritize work",
      //   // candidateProfile4:"Good oral and written communication skills in English",
      //   // candidateProfile5:"You are a go-getter with excellent learning, communication, and team skills. You revel in working with cutting-edge technology and have an eye for detail.",
      //   perks: "As per the industry standards",
      //   about:
      //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id nibh nunc, arcu diam erat sit morbi. Vel amet arcu donec aliquet vitae. Feugiat mi risus tristique facilisi egestas diam in in. Dolor purus auctor sit eu consectetur adipiscing ultrices. Dui dignissim mi etiam amet, egestas sociis congue id molestie. Ornare ornare massa volutpat sed nec vitae sit duis etiam. Ultrices libero non rutrum et urna, pharetra. Nisi, tempus purus in habitant et morbi pharetra. Urna ipsum velit nisi eu, consequat curabitur feugiat maecenas. Enim nibh diam leo condimentum accumsan sit facilisi euismod ut. Tortor eget egestas nunc vel eget. Iaculis sed donec at tellus at dictumst. Ac sit risus enim lorem. Consectetur amet sed eget id amet urna libero. Vulputate sapien semper viverra vitae faucibus pretium bibendum blandit imperdiet. Pulvinar pretium nisl at scelerisque at. At ac ornare duis magna egestas nulla. Potenti et, tortor est convallis scelerisque tincidunt nulla tellus imperdiet.",
      //   desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id nibh nunc, arcu diam erat sit morbi. Vel amet arcu donec aliquet vitae. Feugiat mi risus tristique facilisi egestas diam in in. Dolor purus auctor sit eu consectetur adipiscing ultrices. Dui dignissim mi etiam amet, egestas sociis congue id molestie Ornare ornare massa volutpat sed nec vitae sit duis etiam. Ultrices libero non rutrum et urna, pharetra. Nisi, tempus purus in habitant et morbi pharetra. Urna ipsum velit nisi eu, consequat curabitur feugiat maecenas. Enim nibh diam leo condimentum accumsan sit facilisi euismod ut. Tortor eget egestas nunc vel eget. Iaculis sed donec at tellus at dictumst. Ac sit risus enim lorem. Consectetur amet sed eget id amet urna libero. Vulputate sapien semper viverra vitae faucibus pretium bibendum blandit imperdiet. Pulvinar pretium nisl at scelerisque at. At ac ornare duis magna egestas nulla. Potenti et, tortor est convallis scelerisque tincidunt nulla tellus imperdiet.",
      // },
      // {
      //   id: "cmB18",
      //   jobName: "Lead DevOps Engineer (6-9 years)",
      //   slug: "Lead-DevOps-Engineer",
      //   location: "Bangalore",
      //   stream: "Engineering",
      //   ApplyId: 6,
      //   mode: "FullTime",
      //   AboutMobiotics: "",
      //   responsibility: "Job Description",
      //   // skillHeading: "Good To have skills",
      //   // greatToHaveHeading: "Great to have",
      //   profileHeading: "Certification",
      //   perksHeading: "Perks And Benefits",
      //   company:
      //     "If you are a hands-on DevOps engineer with excellent Linux/Unix credentials along with a good understanding of AWS cloud and can manage and scale cloud/on-premise  infrastructure and has grip on CI/CD - you are the candidate we are looking for:",
      //   roles: [
      //     {
      //       id: 1,
      //       text: "Strong hands-on experience in Linux System Administration with Centos, RHEL and Amazon Linux.",
      //     },
      //     {
      //       id: 2,
      //       text: "Strong hands-on experience in managing AWS services like -  EC2,  S3, ELB, RDS, Lambda, cloud watch, API Gateway, Route53, SNS, SES, CloudFront & etc.",
      //     },
      //     {
      //       id: 3,
      //       text: "Ability to do Architecture for new implementation with AWS/On-Premises.",
      //     },
      //     {
      //       id: 4,
      //       text: "Experience in configuring and managing databases - MySQL, AWS RDS (Aurora MySQL).",
      //     },
      //     {
      //       id: 5,
      //       text: "Must have a working knowledge of automation/scripting languages such as Bash or Python.",
      //     },
      //     {
      //       id: 6,
      //       text: "Knowledge of caching solutions like Redis or AWS Elastic cache.",
      //     },
      //     {
      //       id: 7,
      //       text: "Experience in version control systems git, AWS CodeCommit, and AWS CodeBuild including branching and merging strategies.",
      //     },
      //     {
      //       id: 8,
      //       text: "Experience in CI/CD pipelines using Jenkins or with AWS Code Pipelines.",
      //     },

      //     {
      //       id: 9,
      //       text: "Experience in Configuration Management - Ansible.",
      //     },
      //     {
      //       id: 10,
      //       text: "Experience in delivering Infrastructure as Code utilising tools such as AWS and CloudFormation or Terraform.",
      //     },
      //     {
      //       id: 11,
      //       text: "Exposure to working on container services like Docker, Kubernetes, ECS, and EKS.",
      //     },
      //     { id: 12, text: "Monitoring experiences with Cloud Watch / Nagios." },
      //   ],
      //   // roles1:"Good Experience in Vue.js or React.js",
      //   // roles2:"Excellent in core front-end web technologies (JavaScript, HTML5, SCSS, CSS3)",
      //   // skills: [
      //   //   { id: 1, skills: "Good Understanding of Python/Go/Dot.Net" },
      //   //   { id: 2, skills: "AWS Certification" },
      //   //   {
      //   //     id: 3,
      //   //     skills:
      //   //       "Experience in working on various Payment gateways including Telcos.",
      //   //   },
      //   //   {
      //   //     id: 4,
      //   //     skills: "Previously worked on video streaming platforms/products.",
      //   //   },
      //   //   {
      //   //     id: 5,
      //   //     skills:
      //   //       "Have a good understanding of the Analytics systems and hands-on experience in working with services like AWS Kinesis, Redshift, etc.",
      //   //   },
      //   // ],
      //   // greatToHaveSkills: [
      //   //   { id: 1, skills: "knowledge of Github/bitbucket" },
      //   //   {
      //   //     id: 2,
      //   //     skills:
      //   //       "Knowledge of tailing server error logs and console logs using firebug.",
      //   //   },
      //   //   { id: 3, skills: "Knowledge of Security testing" },
      //   //   { id: 4, skills: "Knowledge of tools like JMeter, Postman, Putty" },
      //   //   { id: 5, skills: "Any experience with customer support" },
      //   // ],
      //   candidateProfile: [
      //     {
      //       id: 1,
      //       text: "AWS Solutions Architect Professional or Associate Level Certificate. Any DevOps certification.",
      //     },
      //   ],
      //   // candidateProfile1:"Bachelor's degree or post-graduation in computer science or related subjects",
      //   // candidateProfile2:"3-5 years of experience as a front end developer",
      //   // candidateProfile3:"Ability to multi-task, organize, and prioritize work",
      //   // candidateProfile4:"Good oral and written communication skills in English",
      //   // candidateProfile5:"You are a go-getter with excellent learning, communication, and team skills. You revel in working with cutting-edge technology and have an eye for detail.",
      //   perks: "As per the industry standards",
      //   about:
      //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id nibh nunc, arcu diam erat sit morbi. Vel amet arcu donec aliquet vitae. Feugiat mi risus tristique facilisi egestas diam in in. Dolor purus auctor sit eu consectetur adipiscing ultrices. Dui dignissim mi etiam amet, egestas sociis congue id molestie. Ornare ornare massa volutpat sed nec vitae sit duis etiam. Ultrices libero non rutrum et urna, pharetra. Nisi, tempus purus in habitant et morbi pharetra. Urna ipsum velit nisi eu, consequat curabitur feugiat maecenas. Enim nibh diam leo condimentum accumsan sit facilisi euismod ut. Tortor eget egestas nunc vel eget. Iaculis sed donec at tellus at dictumst. Ac sit risus enim lorem. Consectetur amet sed eget id amet urna libero. Vulputate sapien semper viverra vitae faucibus pretium bibendum blandit imperdiet. Pulvinar pretium nisl at scelerisque at. At ac ornare duis magna egestas nulla. Potenti et, tortor est convallis scelerisque tincidunt nulla tellus imperdiet.",
      //   desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id nibh nunc, arcu diam erat sit morbi. Vel amet arcu donec aliquet vitae. Feugiat mi risus tristique facilisi egestas diam in in. Dolor purus auctor sit eu consectetur adipiscing ultrices. Dui dignissim mi etiam amet, egestas sociis congue id molestie Ornare ornare massa volutpat sed nec vitae sit duis etiam. Ultrices libero non rutrum et urna, pharetra. Nisi, tempus purus in habitant et morbi pharetra. Urna ipsum velit nisi eu, consequat curabitur feugiat maecenas. Enim nibh diam leo condimentum accumsan sit facilisi euismod ut. Tortor eget egestas nunc vel eget. Iaculis sed donec at tellus at dictumst. Ac sit risus enim lorem. Consectetur amet sed eget id amet urna libero. Vulputate sapien semper viverra vitae faucibus pretium bibendum blandit imperdiet. Pulvinar pretium nisl at scelerisque at. At ac ornare duis magna egestas nulla. Potenti et, tortor est convallis scelerisque tincidunt nulla tellus imperdiet.",
      // },
      // {
      //   id: "cmB19",
      //   jobName: "DevOps Engineer (4+ years)",
      //   slug: "DevOps-Engineer",
      //   location: "Bangalore",
      //   stream: "Engineering",
      //   ApplyId: 7,
      //   mode: "FullTime",
      //   AboutMobiotics: "",
      //   responsibility: "Job Description",
      //   skillHeading: "Qualifications",
      //   // greatToHaveHeading: "Great to have",
      //   profileHeading: "Certification",
      //   perksHeading: "Perks And Benefits",
      //   company:
      //     "",
      //   roles: [
      //     {
      //       id: 1,
      //       text: "Expertise in deploying and managing Linux (Redhat/Amazon Linux) servers on the cloud and on-premise.",
      //     },
      //     {
      //       id: 2,
      //       text: "Experience in managing and scaling AWS cloud services - EC2, IAM, VPC, ELB, S3, Lambda, RDS, Cloud Front.",
      //     },
      //     {
      //       id: 3,
      //       text: "Experience in configuring, managing and troubleshooting LAMP stack.",
      //     },
      //     {
      //       id: 4,
      //       text: "Good expertise in HTTP - Apache/Nginx, MySQL, PHP, Redis.",
      //     },
      //     {
      //       id: 5,
      //       text: "Monitoring tools - Nagios.",
      //     },
      //     {
      //       id: 6,
      //       text: "Experience with version control system - Git / Bitbucket.",
      //     },
      //     { id: 7, text: "Experience with CI/CD using Jenkins" },
      //     { id: 8, text: "Knowledge of shell / Python script." },

      //     {
      //       id: 9,
      //       text: "Hands-on with  Ansible, Docker, EKS and Kubernetes.",
      //     },
      //   ],
      //   // roles1:"Good Experience in Vue.js or React.js",
      //   // roles2:"Excellent in core front-end web technologies (JavaScript, HTML5, SCSS, CSS3)",
      //   skills: [
      //     {
      //       id: 1,
      //       skills:
      //         "Minimum Bachelor’s degree or equivalent experience computer science.",
      //     },
      //     { id: 2, skills: "4+ years of relevant experience." },
      //     {
      //       id: 3,
      //       skills: "We are preferring immediate to 30 days of joiners.",
      //     },
      //     {
      //       id: 4,
      //       skills: "Good oral and written communication skills in English.",
      //     },
      //     {
      //       id: 5,
      //       skills: "Ability to multi-task, organize, and prioritize work.",
      //     },
      //   ],
      //   // greatToHaveSkills: [
      //   //   { id: 1, skills: "knowledge of Github/bitbucket" },
      //   //   {
      //   //     id: 2,
      //   //     skills:
      //   //       "Knowledge of tailing server error logs and console logs using firebug.",
      //   //   },
      //   //   { id: 3, skills: "Knowledge of Security testing" },
      //   //   { id: 4, skills: "Knowledge of tools like JMeter, Postman, Putty" },
      //   //   { id: 5, skills: "Any experience with customer support" },
      //   // ],
      //   candidateProfile: [
      //     {
      //       id: 1,
      //       text: "Red hat certification (RHCSE/RHCSA).",
      //     },
      //     {
      //       id: 2,
      //       text: "Amazon SysOps Certification or Associate Level Certificate.",
      //     },
      //   ],
      //   // candidateProfile1:"Bachelor's degree or post-graduation in computer science or related subjects",
      //   // candidateProfile2:"3-5 years of experience as a front end developer",
      //   // candidateProfile3:"Ability to multi-task, organize, and prioritize work",
      //   // candidateProfile4:"Good oral and written communication skills in English",
      //   // candidateProfile5:"You are a go-getter with excellent learning, communication, and team skills. You revel in working with cutting-edge technology and have an eye for detail.",
      //   perks: "As per the industry standards",
      //   about:
      //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id nibh nunc, arcu diam erat sit morbi. Vel amet arcu donec aliquet vitae. Feugiat mi risus tristique facilisi egestas diam in in. Dolor purus auctor sit eu consectetur adipiscing ultrices. Dui dignissim mi etiam amet, egestas sociis congue id molestie. Ornare ornare massa volutpat sed nec vitae sit duis etiam. Ultrices libero non rutrum et urna, pharetra. Nisi, tempus purus in habitant et morbi pharetra. Urna ipsum velit nisi eu, consequat curabitur feugiat maecenas. Enim nibh diam leo condimentum accumsan sit facilisi euismod ut. Tortor eget egestas nunc vel eget. Iaculis sed donec at tellus at dictumst. Ac sit risus enim lorem. Consectetur amet sed eget id amet urna libero. Vulputate sapien semper viverra vitae faucibus pretium bibendum blandit imperdiet. Pulvinar pretium nisl at scelerisque at. At ac ornare duis magna egestas nulla. Potenti et, tortor est convallis scelerisque tincidunt nulla tellus imperdiet.",
      //   desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id nibh nunc, arcu diam erat sit morbi. Vel amet arcu donec aliquet vitae. Feugiat mi risus tristique facilisi egestas diam in in. Dolor purus auctor sit eu consectetur adipiscing ultrices. Dui dignissim mi etiam amet, egestas sociis congue id molestie Ornare ornare massa volutpat sed nec vitae sit duis etiam. Ultrices libero non rutrum et urna, pharetra. Nisi, tempus purus in habitant et morbi pharetra. Urna ipsum velit nisi eu, consequat curabitur feugiat maecenas. Enim nibh diam leo condimentum accumsan sit facilisi euismod ut. Tortor eget egestas nunc vel eget. Iaculis sed donec at tellus at dictumst. Ac sit risus enim lorem. Consectetur amet sed eget id amet urna libero. Vulputate sapien semper viverra vitae faucibus pretium bibendum blandit imperdiet. Pulvinar pretium nisl at scelerisque at. At ac ornare duis magna egestas nulla. Potenti et, tortor est convallis scelerisque tincidunt nulla tellus imperdiet.",
      // },
      {
        id: "cmB20",
        jobName: "Flutter Developer (3-5 Years)",
        slug: "flutter-developer",
        location: "Bangalore",
        stream: "Engineering",
        ApplyId: 8,
        canonical: 'https://www.mobiotics.com/company/career',
        mode: "FullTime",
        AboutMobiotics: "",
        responsibility: "Job Description",
        skillHeading: "Qualifications",
        // greatToHaveHeading: "Great to have",
        // profileHeading: "Certification",
        perksHeading: "Perks And Benefits",
        company: "",
        roles: [
          {
            id: 1,
            text: "Should have 3-5 years of experience in the Industry.",
          },
          {
            id: 2,
            text: "Should have worked on Flutter preferably in the Media domain.",
          },
          {
            id: 3,
            text: "Candidate should have a good knowledge of Dart Programming Language.",
          },
          {
            id: 4,
            text: "Knowledge on Android and (or) IOS would be an added advantage.",
          },
          {
            id: 5,
            text: "Knowledge of AWS Cloud would be preferable.",
          },
          {
            id: 6,
            text: "Knowledge of Android Studio would be an added advantage.",
          },
          { id: 7, text: "Experience on REST APIs would be desirable." },
          {
            id: 8,
            text: "Experience on working in Scrum/Agile environment would be beneficial.",
          },
        ],
        // roles1:"Good Experience in Vue.js or React.js",
        // roles2:"Excellent in core front-end web technologies (JavaScript, HTML5, SCSS, CSS3)",
        skills: [
          {
            id: 1,
            skills:
              "Minimum Bachelor’s degree or equivalent experience computer science.",
          },
          { id: 2, skills: "3-5 years of relevant experience." },
          {
            id: 3,
            skills: "We are preferring immediate to 30 days of joiners.",
          },
          {
            id: 4,
            skills: "Ability to multi-task, organize, and prioritize work.",
          },
          {
            id: 5,
            skills: "Good oral and written communication skills in English.",
          },
        ],
        // greatToHaveSkills: [
        //   { id: 1, skills: "knowledge of Github/bitbucket" },
        //   {
        //     id: 2,
        //     skills:
        //       "Knowledge of tailing server error logs and console logs using firebug.",
        //   },
        //   { id: 3, skills: "Knowledge of Security testing" },
        //   { id: 4, skills: "Knowledge of tools like JMeter, Postman, Putty" },
        //   { id: 5, skills: "Any experience with customer support" },
        // ],
        // candidateProfile: [
        //   {
        //     id: 1,
        //     text: "Red hat certification (RHCSE/RHCSA).",
        //   },
        //   {
        //     id: 2,
        //     text: "Amazon SysOps Certification or Associate Level Certificate.",
        //   },
        // ],
        // candidateProfile1:"Bachelor's degree or post-graduation in computer science or related subjects",
        // candidateProfile2:"3-5 years of experience as a front end developer",
        // candidateProfile3:"Ability to multi-task, organize, and prioritize work",
        // candidateProfile4:"Good oral and written communication skills in English",
        // candidateProfile5:"You are a go-getter with excellent learning, communication, and team skills. You revel in working with cutting-edge technology and have an eye for detail.",
        perks: "Health insurance, Daily lunch, Stock option, Referral bonuses",
        about:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id nibh nunc, arcu diam erat sit morbi. Vel amet arcu donec aliquet vitae. Feugiat mi risus tristique facilisi egestas diam in in. Dolor purus auctor sit eu consectetur adipiscing ultrices. Dui dignissim mi etiam amet, egestas sociis congue id molestie. Ornare ornare massa volutpat sed nec vitae sit duis etiam. Ultrices libero non rutrum et urna, pharetra. Nisi, tempus purus in habitant et morbi pharetra. Urna ipsum velit nisi eu, consequat curabitur feugiat maecenas. Enim nibh diam leo condimentum accumsan sit facilisi euismod ut. Tortor eget egestas nunc vel eget. Iaculis sed donec at tellus at dictumst. Ac sit risus enim lorem. Consectetur amet sed eget id amet urna libero. Vulputate sapien semper viverra vitae faucibus pretium bibendum blandit imperdiet. Pulvinar pretium nisl at scelerisque at. At ac ornare duis magna egestas nulla. Potenti et, tortor est convallis scelerisque tincidunt nulla tellus imperdiet.",
        desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id nibh nunc, arcu diam erat sit morbi. Vel amet arcu donec aliquet vitae. Feugiat mi risus tristique facilisi egestas diam in in. Dolor purus auctor sit eu consectetur adipiscing ultrices. Dui dignissim mi etiam amet, egestas sociis congue id molestie Ornare ornare massa volutpat sed nec vitae sit duis etiam. Ultrices libero non rutrum et urna, pharetra. Nisi, tempus purus in habitant et morbi pharetra. Urna ipsum velit nisi eu, consequat curabitur feugiat maecenas. Enim nibh diam leo condimentum accumsan sit facilisi euismod ut. Tortor eget egestas nunc vel eget. Iaculis sed donec at tellus at dictumst. Ac sit risus enim lorem. Consectetur amet sed eget id amet urna libero. Vulputate sapien semper viverra vitae faucibus pretium bibendum blandit imperdiet. Pulvinar pretium nisl at scelerisque at. At ac ornare duis magna egestas nulla. Potenti et, tortor est convallis scelerisque tincidunt nulla tellus imperdiet.",
      },
      {
        id: "cmB21",
        jobName: "Android Developer - OTT/Player (3-6 years)",
        slug: "android-developer-ott-player",
        location: "Bangalore",
        stream: "Engineering",
        ApplyId: 9,
        mode: "FullTime",
        AboutMobiotics: "",
        responsibility: "Job Description",
        skillHeading: "Qualifications",
        // greatToHaveHeading: "Great to have",
        // profileHeading: "Certification",
        perksHeading: "Perks And Benefits",
          canonical: "https://www.mobiotics.com/company/career",
        company:
          "If you have an interesting portfolio of apps and have excellent communication and team skills and you fit the description below - you are the one we are looking for",
        roles: [
          {
            id: 1,
            text: "Knowledge of Leanback, Dagger, Retrofit, RxJava, Butterknife, Glide/Picasso.",
          },
          {
            id: 2,
            text: "Excellent working knowledge of Android Studio.",
          },
          {
            id: 3,
            text: "Expertise in code profiling, debugging, and optimization.",
          },
          {
            id: 4,
            text: "Familiar with Unit Testing and Automation.",
          },
          {
            id: 5,
            text: "Excellent in Java and Knowledge of Kotlin language.",
          },
          {
            id: 6,
            text: "Experience in Exoplayer.",
          },
          { id: 7, text: "Working experience in OTT platform." },
          {
            id: 8,
            text: "Should have worked or knowledge of creating Libraries/SDK.",
          },

          {
            id: 9,
            text: "Cares about testing and writing testable code eg. Unit Testing.",
          },
          {
            id: 10,
            text: "Familiar with MVP.",
          },
          {
            id: 11,
            text: "Should have worked or familiar with Firebase, FCM, IMA, Google Services and Facebook SDK.",
          },
          {
            id: 12,
            text: "Experience in working with Android AOSP or Android TV Application is a plus.",
          },
          {
            id: 13,
            text: "NDK Knowledge or experience is a plus.",
          },
        ],
        // roles1:"Good Experience in Vue.js or React.js",
        // roles2:"Excellent in core front-end web technologies (JavaScript, HTML5, SCSS, CSS3)",
        skills: [
          {
            id: 1,
            skills:
              "Minimum Bachelor’s degree or equivalent experience computer science.",
          },
          { id: 2, skills: "3-6 years of relevant experience." },
          {
            id: 3,
            skills: "We are preferring immediate to 30 days of joiners.",
          },
          {
            id: 4,
            skills: "Ability to multi-task, organize, and prioritize work.",
          },
          {
            id: 5,
            skills: "Good oral and written communication skills in English.",
          },
        ],
        // greatToHaveSkills: [
        //   { id: 1, skills: "knowledge of Github/bitbucket" },
        //   {
        //     id: 2,
        //     skills:
        //       "Knowledge of tailing server error logs and console logs using firebug.",
        //   },
        //   { id: 3, skills: "Knowledge of Security testing" },
        //   { id: 4, skills: "Knowledge of tools like JMeter, Postman, Putty" },
        //   { id: 5, skills: "Any experience with customer support" },
        // ],
        // candidateProfile: [
        //   {
        //     id: 1,
        //     text: "Red hat certification (RHCSE/RHCSA).",
        //   },
        //   {
        //     id: 2,
        //     text: "Amazon SysOps Certification or Associate Level Certificate.",
        //   },
        // ],
        // candidateProfile1:"Bachelor's degree or post-graduation in computer science or related subjects",
        // candidateProfile2:"3-5 years of experience as a front end developer",
        // candidateProfile3:"Ability to multi-task, organize, and prioritize work",
        // candidateProfile4:"Good oral and written communication skills in English",
        // candidateProfile5:"You are a go-getter with excellent learning, communication, and team skills. You revel in working with cutting-edge technology and have an eye for detail.",
        perks: "Health insurance, Daily lunch, Stock option, Referral bonuses",
        about:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id nibh nunc, arcu diam erat sit morbi. Vel amet arcu donec aliquet vitae. Feugiat mi risus tristique facilisi egestas diam in in. Dolor purus auctor sit eu consectetur adipiscing ultrices. Dui dignissim mi etiam amet, egestas sociis congue id molestie. Ornare ornare massa volutpat sed nec vitae sit duis etiam. Ultrices libero non rutrum et urna, pharetra. Nisi, tempus purus in habitant et morbi pharetra. Urna ipsum velit nisi eu, consequat curabitur feugiat maecenas. Enim nibh diam leo condimentum accumsan sit facilisi euismod ut. Tortor eget egestas nunc vel eget. Iaculis sed donec at tellus at dictumst. Ac sit risus enim lorem. Consectetur amet sed eget id amet urna libero. Vulputate sapien semper viverra vitae faucibus pretium bibendum blandit imperdiet. Pulvinar pretium nisl at scelerisque at. At ac ornare duis magna egestas nulla. Potenti et, tortor est convallis scelerisque tincidunt nulla tellus imperdiet.",
        desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id nibh nunc, arcu diam erat sit morbi. Vel amet arcu donec aliquet vitae. Feugiat mi risus tristique facilisi egestas diam in in. Dolor purus auctor sit eu consectetur adipiscing ultrices. Dui dignissim mi etiam amet, egestas sociis congue id molestie Ornare ornare massa volutpat sed nec vitae sit duis etiam. Ultrices libero non rutrum et urna, pharetra. Nisi, tempus purus in habitant et morbi pharetra. Urna ipsum velit nisi eu, consequat curabitur feugiat maecenas. Enim nibh diam leo condimentum accumsan sit facilisi euismod ut. Tortor eget egestas nunc vel eget. Iaculis sed donec at tellus at dictumst. Ac sit risus enim lorem. Consectetur amet sed eget id amet urna libero. Vulputate sapien semper viverra vitae faucibus pretium bibendum blandit imperdiet. Pulvinar pretium nisl at scelerisque at. At ac ornare duis magna egestas nulla. Potenti et, tortor est convallis scelerisque tincidunt nulla tellus imperdiet.",
      },
      // {
      //   id: "cmB22",
      //   jobName: "AI/ML Engineer (3-7 years)",
      //   slug: "AI&ML-Engineer",
      //   location: "Bangalore",
      //   stream: "Engineering",
      //   ApplyId: 10,
      //   mode: "FullTime",
      //   AboutMobiotics: "",
      //   responsibility: "Job Description",
      //   skillHeading: "Qualifications",
      //   // greatToHaveHeading: "Great to have",
      //   // profileHeading: "Certification",
      //   perksHeading: "Perks And Benefits",
      //   company:
      //     "",
      //   roles: [
      //     {
      //       id: 1,
      //       text: "Should have worked on Pandas,Numpy,Scikit Learn, Keras, Tensor Flow.",
      //     },
      //     {
      //       id: 2,
      //       text: "Should have good knowledge on basic ML Technologies like Linear Regression, Logistics Regression, Decision Trees, Ensemble Learning Techniques.",
      //     },
      //     {
      //       id: 3,
      //       text: "Should have worked on both Supervised and Unsupervised Learning.",
      //     },
      //     {
      //       id: 4,
      //       text: "Knowledge on Recommender Systems would an added advantage.",
      //     },
      //     {
      //       id: 5,
      //       text: "Knowledge of Neural Network, Convolutional Neural Network, Deep Neural Network and Object Detection Techniques would be an advantage.",
      //     },
      //     {
      //       id: 6,
      //       text: "Knowledge of AWS Stack would be an added advantage.",
      //     },
      //     {
      //       id: 7,
      //       text: "Knowledge of SQL, No SQL Databases and creating Data Pipelines would be an Advantage.",
      //     },
      //   ],
      //   // roles1:"Good Experience in Vue.js or React.js",
      //   // roles2:"Excellent in core front-end web technologies (JavaScript, HTML5, SCSS, CSS3)",
      //   skills: [
      //     {
      //       id: 1,
      //       skills:
      //         "Minimum Bachelor’s degree or equivalent experience computer science.",
      //     },
      //     { id: 2, skills: "3-7 years of relevant experience." },
      //     {
      //       id: 3,
      //       skills: "We are preferring immediate to 30 days of joiners.",
      //     },
      //     {
      //       id: 4,
      //       skills: "Ability to multi-task, organize, and prioritize work.",
      //     },
      //     {
      //       id: 5,
      //       skills: "Good oral and written communication skills in English.",
      //     },
      //   ],
      //   // greatToHaveSkills: [
      //   //   { id: 1, skills: "knowledge of Github/bitbucket" },
      //   //   {
      //   //     id: 2,
      //   //     skills:
      //   //       "Knowledge of tailing server error logs and console logs using firebug.",
      //   //   },
      //   //   { id: 3, skills: "Knowledge of Security testing" },
      //   //   { id: 4, skills: "Knowledge of tools like JMeter, Postman, Putty" },
      //   //   { id: 5, skills: "Any experience with customer support" },
      //   // ],
      //   // candidateProfile: [
      //   //   {
      //   //     id: 1,
      //   //     text: "Red hat certification (RHCSE/RHCSA).",
      //   //   },
      //   //   {
      //   //     id: 2,
      //   //     text: "Amazon SysOps Certification or Associate Level Certificate.",
      //   //   },
      //   // ],
      //   // candidateProfile1:"Bachelor's degree or post-graduation in computer science or related subjects",
      //   // candidateProfile2:"3-5 years of experience as a front end developer",
      //   // candidateProfile3:"Ability to multi-task, organize, and prioritize work",
      //   // candidateProfile4:"Good oral and written communication skills in English",
      //   // candidateProfile5:"You are a go-getter with excellent learning, communication, and team skills. You revel in working with cutting-edge technology and have an eye for detail.",
      //   perks: "As per the industry standards",
      //   about:
      //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id nibh nunc, arcu diam erat sit morbi. Vel amet arcu donec aliquet vitae. Feugiat mi risus tristique facilisi egestas diam in in. Dolor purus auctor sit eu consectetur adipiscing ultrices. Dui dignissim mi etiam amet, egestas sociis congue id molestie. Ornare ornare massa volutpat sed nec vitae sit duis etiam. Ultrices libero non rutrum et urna, pharetra. Nisi, tempus purus in habitant et morbi pharetra. Urna ipsum velit nisi eu, consequat curabitur feugiat maecenas. Enim nibh diam leo condimentum accumsan sit facilisi euismod ut. Tortor eget egestas nunc vel eget. Iaculis sed donec at tellus at dictumst. Ac sit risus enim lorem. Consectetur amet sed eget id amet urna libero. Vulputate sapien semper viverra vitae faucibus pretium bibendum blandit imperdiet. Pulvinar pretium nisl at scelerisque at. At ac ornare duis magna egestas nulla. Potenti et, tortor est convallis scelerisque tincidunt nulla tellus imperdiet.",
      //   desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id nibh nunc, arcu diam erat sit morbi. Vel amet arcu donec aliquet vitae. Feugiat mi risus tristique facilisi egestas diam in in. Dolor purus auctor sit eu consectetur adipiscing ultrices. Dui dignissim mi etiam amet, egestas sociis congue id molestie Ornare ornare massa volutpat sed nec vitae sit duis etiam. Ultrices libero non rutrum et urna, pharetra. Nisi, tempus purus in habitant et morbi pharetra. Urna ipsum velit nisi eu, consequat curabitur feugiat maecenas. Enim nibh diam leo condimentum accumsan sit facilisi euismod ut. Tortor eget egestas nunc vel eget. Iaculis sed donec at tellus at dictumst. Ac sit risus enim lorem. Consectetur amet sed eget id amet urna libero. Vulputate sapien semper viverra vitae faucibus pretium bibendum blandit imperdiet. Pulvinar pretium nisl at scelerisque at. At ac ornare duis magna egestas nulla. Potenti et, tortor est convallis scelerisque tincidunt nulla tellus imperdiet.",
      // },
      {
        id: "cmB23",
        jobName: "Solution Architect (7-10 years)",
        slug: "solution-architect",
        location: "Bangalore",
        stream: "Engineering",
        ApplyId: 11,
        mode: "FullTime",
        AboutMobiotics: "",
        responsibility: "Responsibility",
        skillHeading: "Required Skills",
        greatToHaveHeading: "Great to have skills",
        profileHeading: "Qualifications",
        perksHeading: "Perks And Benefits",
        company: "",
        roles: [
          {
            id: 1,
            text: "End to End ownership of various applications and microservices. Design, Architect, develop and deploy services that serve millions of users.",
          },
          {
            id: 2,
            text: "Design and Architect and develop technical solutions for the business problems.",
          },
          {
            id: 3,
            text: "Ability to mentor and coach junior engineers on processes and technologies.",
          },
          {
            id: 4,
            text: "Managing and developing microservices that include various Payment gateways integration.",
          },
          {
            id: 5,
            text: "Managing and creating Serverless Applications.",
          },
        ],
        // roles1:"Good Experience in Vue.js or React.js",
        // roles2:"Excellent in core front-end web technologies (JavaScript, HTML5, SCSS, CSS3)",
        skills: [
          {
            id: 1,
            skills: "7+ years of experience.",
          },
          {
            id: 2,
            skills: "Good understanding of Microservices architecture.",
          },
          {
            id: 3,
            skills: "Excellent NodeJS skills.",
          },
          {
            id: 4,
            skills: "Excellent skills on retaliation DBs like MySql.",
          },
          {
            id: 5,
            skills:
              "At least 2+ Experience working on Serverless technologies. (AWS Lambda)",
          },

          {
            id: 6,
            skills:
              "Good understanding of DevOps process and ability to manage services on the cloud.",
          },
          {
            id: 7,
            skills:
              "At least 2+ years of experience working on AWS cloud such as CloudFormation, Lambda, API gateway, RDS, SQS, Serverless computing, AWS Batch, docker, etc.",
          },
          {
            id: 8,
            skills:
              "Ability to undertake an existing software product, understand the current development, and maintain and execute further development.",
          },
          {
            id: 9,
            skills:
              "Relevant Experience in developing/leading and managing large scalable applications involving multiple teams.",
          },
          {
            id: 10,
            skills:
              "Debugging and troubleshooting skills and ability to generate RCA.",
          },
          {
            id: 11,
            skills: "Experience with In memory DBS like Redis.",
          },
        ],
        greatToHaveSkills: [
          { id: 1, skills: "Good Understanding of Python/Go/dotNet." },
          {
            id: 2,
            skills: "AWS Certified Solution Architect.",
          },
          {
            id: 3,
            skills:
              "Experience in working on various Payment gateways including Telcos.",
          },
          {
            id: 4,
            skills: "Previously worked on video streaming platforms/products.",
          },
          {
            id: 5,
            skills:
              "Have a good understanding of the Analytics systems and hands-on experience in working with services like AWS Kinesis, Redshift, etc.",
          },
        ],
        candidateProfile: [
          {
            id: 1,
            text: "Minimum Bachelor’s degree or equivalent experience computer science.",
          },
          {
            id: 2,
            text: "7-10 years of relevant experience.",
          },
          {
            id: 3,
            text: "We are preferring immediate to 30 days of joiners.",
          },
          {
            id: 4,
            text: "Ability to multi-task, organize, and prioritize work.",
          },
          {
            id: 5,
            text: "Good oral and written communication skills in English.",
          },
        ],
        // candidateProfile1:"Bachelor's degree or post-graduation in computer science or related subjects",
        // candidateProfile2:"3-5 years of experience as a front end developer",
        // candidateProfile3:"Ability to multi-task, organize, and prioritize work",
        // candidateProfile4:"Good oral and written communication skills in English",
        // candidateProfile5:"You are a go-getter with excellent learning, communication, and team skills. You revel in working with cutting-edge technology and have an eye for detail.",
        perks: "As per the industry standards",
        about:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id nibh nunc, arcu diam erat sit morbi. Vel amet arcu donec aliquet vitae. Feugiat mi risus tristique facilisi egestas diam in in. Dolor purus auctor sit eu consectetur adipiscing ultrices. Dui dignissim mi etiam amet, egestas sociis congue id molestie. Ornare ornare massa volutpat sed nec vitae sit duis etiam. Ultrices libero non rutrum et urna, pharetra. Nisi, tempus purus in habitant et morbi pharetra. Urna ipsum velit nisi eu, consequat curabitur feugiat maecenas. Enim nibh diam leo condimentum accumsan sit facilisi euismod ut. Tortor eget egestas nunc vel eget. Iaculis sed donec at tellus at dictumst. Ac sit risus enim lorem. Consectetur amet sed eget id amet urna libero. Vulputate sapien semper viverra vitae faucibus pretium bibendum blandit imperdiet. Pulvinar pretium nisl at scelerisque at. At ac ornare duis magna egestas nulla. Potenti et, tortor est convallis scelerisque tincidunt nulla tellus imperdiet.",
        desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id nibh nunc, arcu diam erat sit morbi. Vel amet arcu donec aliquet vitae. Feugiat mi risus tristique facilisi egestas diam in in. Dolor purus auctor sit eu consectetur adipiscing ultrices. Dui dignissim mi etiam amet, egestas sociis congue id molestie Ornare ornare massa volutpat sed nec vitae sit duis etiam. Ultrices libero non rutrum et urna, pharetra. Nisi, tempus purus in habitant et morbi pharetra. Urna ipsum velit nisi eu, consequat curabitur feugiat maecenas. Enim nibh diam leo condimentum accumsan sit facilisi euismod ut. Tortor eget egestas nunc vel eget. Iaculis sed donec at tellus at dictumst. Ac sit risus enim lorem. Consectetur amet sed eget id amet urna libero. Vulputate sapien semper viverra vitae faucibus pretium bibendum blandit imperdiet. Pulvinar pretium nisl at scelerisque at. At ac ornare duis magna egestas nulla. Potenti et, tortor est convallis scelerisque tincidunt nulla tellus imperdiet.",
      },
      //   {
      //       id: "cm2",
      //       jobName: "Senior Software Engineer-Backend",
      //       location: "Banglore",
      //       stream: "Engineering",
      //       ApplyId: 2,
      //       mode: "FullTime",
      //       about: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id nibh nunc, arcu diam erat sit morbi. Vel amet arcu donec aliquet vitae. Feugiat mi risus tristique facilisi egestas diam in in. Dolor purus auctor sit eu consectetur adipiscing ultrices. Dui dignissim mi etiam amet, egestas sociis congue id molestie. Ornare ornare massa volutpat sed nec vitae sit duis etiam. Ultrices libero non rutrum et urna, pharetra. Nisi, tempus purus in habitant et morbi pharetra. Urna ipsum velit nisi eu, consequat curabitur feugiat maecenas. Enim nibh diam leo condimentum accumsan sit facilisi euismod ut. Tortor eget egestas nunc vel eget. Iaculis sed donec at tellus at dictumst. Ac sit risus enim lorem. Consectetur amet sed eget id amet urna libero. Vulputate sapien semper viverra vitae faucibus pretium bibendum blandit imperdiet. Pulvinar pretium nisl at scelerisque at. At ac ornare duis magna egestas nulla. Potenti et, tortor est convallis scelerisque tincidunt nulla tellus imperdiet.",
      //       desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id nibh nunc, arcu diam erat sit morbi. Vel amet arcu donec aliquet vitae. Feugiat mi risus tristique facilisi egestas diam in in. Dolor purus auctor sit eu consectetur adipiscing ultrices. Dui dignissim mi etiam amet, egestas sociis congue id molestie Ornare ornare massa volutpat sed nec vitae sit duis etiam. Ultrices libero non rutrum et urna, pharetra. Nisi, tempus purus in habitant et morbi pharetra. Urna ipsum velit nisi eu, consequat curabitur feugiat maecenas. Enim nibh diam leo condimentum accumsan sit facilisi euismod ut. Tortor eget egestas nunc vel eget. Iaculis sed donec at tellus at dictumst. Ac sit risus enim lorem. Consectetur amet sed eget id amet urna libero. Vulputate sapien semper viverra vitae faucibus pretium bibendum blandit imperdiet. Pulvinar pretium nisl at scelerisque at. At ac ornare duis magna egestas nulla. Potenti et, tortor est convallis scelerisque tincidunt nulla tellus imperdiet."
      //   },
      // {
      //     id: "cm3",
      //     jobName: "Data Analyst",
      //     location: "Banglore",
      //     stream: "Data Analyst",
      //     ApplyId: 3,
      //     mode: "FullTime",
      //     about: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id nibh nunc, arcu diam erat sit morbi. Vel amet arcu donec aliquet vitae. Feugiat mi risus tristique facilisi egestas diam in in. Dolor purus auctor sit eu consectetur adipiscing ultrices. Dui dignissim mi etiam amet, egestas sociis congue id molestie. Ornare ornare massa volutpat sed nec vitae sit duis etiam. Ultrices libero non rutrum et urna, pharetra. Nisi, tempus purus in habitant et morbi pharetra. Urna ipsum velit nisi eu, consequat curabitur feugiat maecenas. Enim nibh diam leo condimentum accumsan sit facilisi euismod ut. Tortor eget egestas nunc vel eget. Iaculis sed donec at tellus at dictumst. Ac sit risus enim lorem. Consectetur amet sed eget id amet urna libero. Vulputate sapien semper viverra vitae faucibus pretium bibendum blandit imperdiet. Pulvinar pretium nisl at scelerisque at. At ac ornare duis magna egestas nulla. Potenti et, tortor est convallis scelerisque tincidunt nulla tellus imperdiet.",
      //     desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id nibh nunc, arcu diam erat sit morbi. Vel amet arcu donec aliquet vitae. Feugiat mi risus tristique facilisi egestas diam in in. Dolor purus auctor sit eu consectetur adipiscing ultrices. Dui dignissim mi etiam amet, egestas sociis congue id molestie Ornare ornare massa volutpat sed nec vitae sit duis etiam. Ultrices libero non rutrum et urna, pharetra. Nisi, tempus purus in habitant et morbi pharetra. Urna ipsum velit nisi eu, consequat curabitur feugiat maecenas. Enim nibh diam leo condimentum accumsan sit facilisi euismod ut. Tortor eget egestas nunc vel eget. Iaculis sed donec at tellus at dictumst. Ac sit risus enim lorem. Consectetur amet sed eget id amet urna libero. Vulputate sapien semper viverra vitae faucibus pretium bibendum blandit imperdiet. Pulvinar pretium nisl at scelerisque at. At ac ornare duis magna egestas nulla. Potenti et, tortor est convallis scelerisque tincidunt nulla tellus imperdiet."
      // },
      // {
      //     id: "cm4",
      //     jobName: "Business Development Specialization",
      //     location: "Banglore",
      //     stream: "Business Development & sales",
      //     ApplyId: 4,
      //     mode: "FullTime",
      //     about: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id nibh nunc, arcu diam erat sit morbi. Vel amet arcu donec aliquet vitae. Feugiat mi risus tristique facilisi egestas diam in in. Dolor purus auctor sit eu consectetur adipiscing ultrices. Dui dignissim mi etiam amet, egestas sociis congue id molestie. Ornare ornare massa volutpat sed nec vitae sit duis etiam. Ultrices libero non rutrum et urna, pharetra. Nisi, tempus purus in habitant et morbi pharetra. Urna ipsum velit nisi eu, consequat curabitur feugiat maecenas. Enim nibh diam leo condimentum accumsan sit facilisi euismod ut. Tortor eget egestas nunc vel eget. Iaculis sed donec at tellus at dictumst. Ac sit risus enim lorem. Consectetur amet sed eget id amet urna libero. Vulputate sapien semper viverra vitae faucibus pretium bibendum blandit imperdiet. Pulvinar pretium nisl at scelerisque at. At ac ornare duis magna egestas nulla. Potenti et, tortor est convallis scelerisque tincidunt nulla tellus imperdiet.",
      //     desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id nibh nunc, arcu diam erat sit morbi. Vel amet arcu donec aliquet vitae. Feugiat mi risus tristique facilisi egestas diam in in. Dolor purus auctor sit eu consectetur adipiscing ultrices. Dui dignissim mi etiam amet, egestas sociis congue id molestie Ornare ornare massa volutpat sed nec vitae sit duis etiam. Ultrices libero non rutrum et urna, pharetra. Nisi, tempus purus in habitant et morbi pharetra. Urna ipsum velit nisi eu, consequat curabitur feugiat maecenas. Enim nibh diam leo condimentum accumsan sit facilisi euismod ut. Tortor eget egestas nunc vel eget. Iaculis sed donec at tellus at dictumst. Ac sit risus enim lorem. Consectetur amet sed eget id amet urna libero. Vulputate sapien semper viverra vitae faucibus pretium bibendum blandit imperdiet. Pulvinar pretium nisl at scelerisque at. At ac ornare duis magna egestas nulla. Potenti et, tortor est convallis scelerisque tincidunt nulla tellus imperdiet."
      // },
      // {
      //     id: "cm5",
      //     jobName: "Leade Devops Enginner-2",
      //     location: "Banglore",
      //     stream: "Devops Engineering",
      //     ApplyId: 5,
      //     mode: "FullTime",
      //     about: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id nibh nunc, arcu diam erat sit morbi. Vel amet arcu donec aliquet vitae. Feugiat mi risus tristique facilisi egestas diam in in. Dolor purus auctor sit eu consectetur adipiscing ultrices. Dui dignissim mi etiam amet, egestas sociis congue id molestie. Ornare ornare massa volutpat sed nec vitae sit duis etiam. Ultrices libero non rutrum et urna, pharetra. Nisi, tempus purus in habitant et morbi pharetra. Urna ipsum velit nisi eu, consequat curabitur feugiat maecenas. Enim nibh diam leo condimentum accumsan sit facilisi euismod ut. Tortor eget egestas nunc vel eget. Iaculis sed donec at tellus at dictumst. Ac sit risus enim lorem. Consectetur amet sed eget id amet urna libero. Vulputate sapien semper viverra vitae faucibus pretium bibendum blandit imperdiet. Pulvinar pretium nisl at scelerisque at. At ac ornare duis magna egestas nulla. Potenti et, tortor est convallis scelerisque tincidunt nulla tellus imperdiet.",
      //     desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id nibh nunc, arcu diam erat sit morbi. Vel amet arcu donec aliquet vitae. Feugiat mi risus tristique facilisi egestas diam in in. Dolor purus auctor sit eu consectetur adipiscing ultrices. Dui dignissim mi etiam amet, egestas sociis congue id molestie Ornare ornare massa volutpat sed nec vitae sit duis etiam. Ultrices libero non rutrum et urna, pharetra. Nisi, tempus purus in habitant et morbi pharetra. Urna ipsum velit nisi eu, consequat curabitur feugiat maecenas. Enim nibh diam leo condimentum accumsan sit facilisi euismod ut. Tortor eget egestas nunc vel eget. Iaculis sed donec at tellus at dictumst. Ac sit risus enim lorem. Consectetur amet sed eget id amet urna libero. Vulputate sapien semper viverra vitae faucibus pretium bibendum blandit imperdiet. Pulvinar pretium nisl at scelerisque at. At ac ornare duis magna egestas nulla. Potenti et, tortor est convallis scelerisque tincidunt nulla tellus imperdiet."
      // },

      // {
      //     id: "cm6",
      //     jobName: "FrontEnd Engineering",
      //     location: "Banglore",
      //     stream: "FrontEnd Engineering",
      //     ApplyId: 6,
      //     mode: "FullTime",
      //     about: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id nibh nunc, arcu diam erat sit morbi. Vel amet arcu donec aliquet vitae. Feugiat mi risus tristique facilisi egestas diam in in. Dolor purus auctor sit eu consectetur adipiscing ultrices. Dui dignissim mi etiam amet, egestas sociis congue id molestie. Ornare ornare massa volutpat sed nec vitae sit duis etiam. Ultrices libero non rutrum et urna, pharetra. Nisi, tempus purus in habitant et morbi pharetra. Urna ipsum velit nisi eu, consequat curabitur feugiat maecenas. Enim nibh diam leo condimentum accumsan sit facilisi euismod ut. Tortor eget egestas nunc vel eget. Iaculis sed donec at tellus at dictumst. Ac sit risus enim lorem. Consectetur amet sed eget id amet urna libero. Vulputate sapien semper viverra vitae faucibus pretium bibendum blandit imperdiet. Pulvinar pretium nisl at scelerisque at. At ac ornare duis magna egestas nulla. Potenti et, tortor est convallis scelerisque tincidunt nulla tellus imperdiet.",
      //     desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id nibh nunc, arcu diam erat sit morbi. Vel amet arcu donec aliquet vitae. Feugiat mi risus tristique facilisi egestas diam in in. Dolor purus auctor sit eu consectetur adipiscing ultrices. Dui dignissim mi etiam amet, egestas sociis congue id molestie Ornare ornare massa volutpat sed nec vitae sit duis etiam. Ultrices libero non rutrum et urna, pharetra. Nisi, tempus purus in habitant et morbi pharetra. Urna ipsum velit nisi eu, consequat curabitur feugiat maecenas. Enim nibh diam leo condimentum accumsan sit facilisi euismod ut. Tortor eget egestas nunc vel eget. Iaculis sed donec at tellus at dictumst. Ac sit risus enim lorem. Consectetur amet sed eget id amet urna libero. Vulputate sapien semper viverra vitae faucibus pretium bibendum blandit imperdiet. Pulvinar pretium nisl at scelerisque at. At ac ornare duis magna egestas nulla. Potenti et, tortor est convallis scelerisque tincidunt nulla tellus imperdiet."
      // },

      // {
      //     id: "cm7",
      //     jobName: "Application Security Engineer",
      //     location: "Banglore",
      //     stream: "information and Security",
      //     ApplyId: 7,
      //     mode: "FullTime",
      //     about: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id nibh nunc, arcu diam erat sit morbi. Vel amet arcu donec aliquet vitae. Feugiat mi risus tristique facilisi egestas diam in in. Dolor purus auctor sit eu consectetur adipiscing ultrices. Dui dignissim mi etiam amet, egestas sociis congue id molestie. Ornare ornare massa volutpat sed nec vitae sit duis etiam. Ultrices libero non rutrum et urna, pharetra. Nisi, tempus purus in habitant et morbi pharetra. Urna ipsum velit nisi eu, consequat curabitur feugiat maecenas. Enim nibh diam leo condimentum accumsan sit facilisi euismod ut. Tortor eget egestas nunc vel eget. Iaculis sed donec at tellus at dictumst. Ac sit risus enim lorem. Consectetur amet sed eget id amet urna libero. Vulputate sapien semper viverra vitae faucibus pretium bibendum blandit imperdiet. Pulvinar pretium nisl at scelerisque at. At ac ornare duis magna egestas nulla. Potenti et, tortor est convallis scelerisque tincidunt nulla tellus imperdiet.",
      //     desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id nibh nunc, arcu diam erat sit morbi. Vel amet arcu donec aliquet vitae. Feugiat mi risus tristique facilisi egestas diam in in. Dolor purus auctor sit eu consectetur adipiscing ultrices. Dui dignissim mi etiam amet, egestas sociis congue id molestie Ornare ornare massa volutpat sed nec vitae sit duis etiam. Ultrices libero non rutrum et urna, pharetra. Nisi, tempus purus in habitant et morbi pharetra. Urna ipsum velit nisi eu, consequat curabitur feugiat maecenas. Enim nibh diam leo condimentum accumsan sit facilisi euismod ut. Tortor eget egestas nunc vel eget. Iaculis sed donec at tellus at dictumst. Ac sit risus enim lorem. Consectetur amet sed eget id amet urna libero. Vulputate sapien semper viverra vitae faucibus pretium bibendum blandit imperdiet. Pulvinar pretium nisl at scelerisque at. At ac ornare duis magna egestas nulla. Potenti et, tortor est convallis scelerisque tincidunt nulla tellus imperdiet."
      // },

      // {
      //     id: "cm8",
      //     jobName: "Security Engineer Level 2",
      //     location: "Banglore",
      //     stream: "Business Development & sales",
      //     ApplyId: 8,
      //     mode: "FullTime",
      //     about: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id nibh nunc, arcu diam erat sit morbi. Vel amet arcu donec aliquet vitae. Feugiat mi risus tristique facilisi egestas diam in in. Dolor purus auctor sit eu consectetur adipiscing ultrices. Dui dignissim mi etiam amet, egestas sociis congue id molestie. Ornare ornare massa volutpat sed nec vitae sit duis etiam. Ultrices libero non rutrum et urna, pharetra. Nisi, tempus purus in habitant et morbi pharetra. Urna ipsum velit nisi eu, consequat curabitur feugiat maecenas. Enim nibh diam leo condimentum accumsan sit facilisi euismod ut. Tortor eget egestas nunc vel eget. Iaculis sed donec at tellus at dictumst. Ac sit risus enim lorem. Consectetur amet sed eget id amet urna libero. Vulputate sapien semper viverra vitae faucibus pretium bibendum blandit imperdiet. Pulvinar pretium nisl at scelerisque at. At ac ornare duis magna egestas nulla. Potenti et, tortor est convallis scelerisque tincidunt nulla tellus imperdiet.",
      //     desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id nibh nunc, arcu diam erat sit morbi. Vel amet arcu donec aliquet vitae. Feugiat mi risus tristique facilisi egestas diam in in. Dolor purus auctor sit eu consectetur adipiscing ultrices. Dui dignissim mi etiam amet, egestas sociis congue id molestie Ornare ornare massa volutpat sed nec vitae sit duis etiam. Ultrices libero non rutrum et urna, pharetra. Nisi, tempus purus in habitant et morbi pharetra. Urna ipsum velit nisi eu, consequat curabitur feugiat maecenas. Enim nibh diam leo condimentum accumsan sit facilisi euismod ut. Tortor eget egestas nunc vel eget. Iaculis sed donec at tellus at dictumst. Ac sit risus enim lorem. Consectetur amet sed eget id amet urna libero. Vulputate sapien semper viverra vitae faucibus pretium bibendum blandit imperdiet. Pulvinar pretium nisl at scelerisque at. At ac ornare duis magna egestas nulla. Potenti et, tortor est convallis scelerisque tincidunt nulla tellus imperdiet."
      // },

      {
        id: "cmB24",
        jobName: "QA Engineer (3-5 years)",
        slug: "qa-engineer-3-5",
        location: "Bangalore",
        stream: "Engineering",
        ApplyId: 12,
        mode: "FullTime",
        AboutMobiotics: "",
        responsibility: "Responsibility",
        skillHeading: "Required Skills",
        greatToHaveHeading: "Great to have skills",
        profileHeading: "Qualifications",
        perksHeading: "Perks And Benefits",
        company: "",
        roles: [
          {
            id: 1,
            text: "Review of software requirements",
          },
          {
            id: 2,
            text: "Preparation of test cases",
          },
          {
            id: 3,
            text: "Execution of tests",
          },
          {
            id: 4,
            text: "Automation",
          },
          {
            id: 5,
            text: "Reporting of defects",
          },
          {
            id: 6,
            text: "Preparation of test reports",
          },
          {
            id: 7,
            text: "Communicating with Remote/Dev/DevOps team",
          },
          {
            id: 8,
            text: "Software Release and Configuration Support",
          },
          {
            id: 9,
            text: "Customer Issue Management",
          },
        ],
        // roles1:"Good Experience in Vue.js or React.js",
        // roles2:"Excellent in core front-end web technologies (JavaScript, HTML5, SCSS, CSS3)",
        skills: [
          {
            id: 1,
            skills: "A good understanding of SDLC",
          },
          {
            id: 2,
            skills: "A good understanding of bug life cycle",
          },
          {
            id: 3,
            skills: "Good knowledge in SQL",
          },
          {
            id: 4,
            skills: "Good knowledge in Linux",
          },
          {
            id: 5,
            skills:
              "A good understanding of selenium automation for Android and Web",
          },

          {
            id: 6,
            skills:
              "Good writing skills (for documenting the process)",
          },
          {
            id: 7,
            skills:
              "Good communications skills",
          },
          {
            id: 8,
            skills:
              "Good presentation skills",
          },
        ],
        greatToHaveSkills: [
          { id: 1, skills: "knowledge of Github/bitbucket" },
          {
            id: 2,
            skills: "Knowledge of tailing server error logs and console logs using firebug.",
          },
          {
            id: 3,
            skills:
              "Knowledge of Security testing",
          },
          {
            id: 4,
            skills: "Knowledge of tools like JMeter, Postman, Putty",
          },
          {
            id: 5,
            skills:
              "Any experience with customer support",
          },
        ],
        candidateProfile: [
          {
            id: 1,
            text: "Minimum Bachelor’s degree or equivalent experience in computer science",
          },
          {
            id: 2,
            text: "2-5 years of relevant experience",
          },
          {
            id: 3,
            text: "We are preferring immediate to 30 days joiners",
          },
          {
            id: 4,
            text: "Proficient in malayalam",
          },
          {
            id: 5,
            text: "Ability to multi task, organize and prioritize work",
          },  {
            id: 6,
            text: "Good oral and written communication skills in English",
          },
        ],
        // candidateProfile1:"Bachelor's degree or post-graduation in computer science or related subjects",
        // candidateProfile2:"3-5 years of experience as a front end developer",
        // candidateProfile3:"Ability to multi-task, organize, and prioritize work",
        // candidateProfile4:"Good oral and written communication skills in English",
        // candidateProfile5:"You are a go-getter with excellent learning, communication, and team skills. You revel in working with cutting-edge technology and have an eye for detail.",
        perks: "Health insurance, Daily lunch, Stock option, Referral bonuses, Parental leave",
        about:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id nibh nunc, arcu diam erat sit morbi. Vel amet arcu donec aliquet vitae. Feugiat mi risus tristique facilisi egestas diam in in. Dolor purus auctor sit eu consectetur adipiscing ultrices. Dui dignissim mi etiam amet, egestas sociis congue id molestie. Ornare ornare massa volutpat sed nec vitae sit duis etiam. Ultrices libero non rutrum et urna, pharetra. Nisi, tempus purus in habitant et morbi pharetra. Urna ipsum velit nisi eu, consequat curabitur feugiat maecenas. Enim nibh diam leo condimentum accumsan sit facilisi euismod ut. Tortor eget egestas nunc vel eget. Iaculis sed donec at tellus at dictumst. Ac sit risus enim lorem. Consectetur amet sed eget id amet urna libero. Vulputate sapien semper viverra vitae faucibus pretium bibendum blandit imperdiet. Pulvinar pretium nisl at scelerisque at. At ac ornare duis magna egestas nulla. Potenti et, tortor est convallis scelerisque tincidunt nulla tellus imperdiet.",
        desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id nibh nunc, arcu diam erat sit morbi. Vel amet arcu donec aliquet vitae. Feugiat mi risus tristique facilisi egestas diam in in. Dolor purus auctor sit eu consectetur adipiscing ultrices. Dui dignissim mi etiam amet, egestas sociis congue id molestie Ornare ornare massa volutpat sed nec vitae sit duis etiam. Ultrices libero non rutrum et urna, pharetra. Nisi, tempus purus in habitant et morbi pharetra. Urna ipsum velit nisi eu, consequat curabitur feugiat maecenas. Enim nibh diam leo condimentum accumsan sit facilisi euismod ut. Tortor eget egestas nunc vel eget. Iaculis sed donec at tellus at dictumst. Ac sit risus enim lorem. Consectetur amet sed eget id amet urna libero. Vulputate sapien semper viverra vitae faucibus pretium bibendum blandit imperdiet. Pulvinar pretium nisl at scelerisque at. At ac ornare duis magna egestas nulla. Potenti et, tortor est convallis scelerisque tincidunt nulla tellus imperdiet.",
      },
      {
        id: "cmB25",
        jobName: "Lead QA Engineer (5+Years)",
        slug: "lead-qa-engineer-5",
        location: "Bangalore",
        stream: "Engineering",
        ApplyId: 13,
        mode: "FullTime",
        canonical:'https://www.mobiotics.com/company/career',
        AboutMobiotics: "",
        responsibility: "Responsibility",
        skillHeading: "Required Skills",
        greatToHaveHeading: "Great to have skills",
        profileHeading: "Qualifications",
        perksHeading: "Perks And Benefits",
        company: "",
        roles: [
          {
            id: 1,
            text: "Review of software requirements",
          },
          {
            id: 2,
            text: "Preparation of test cases",
          },
          {
            id: 3,
            text: "Execution of tests",
          },
          {
            id: 4,
            text: "Automation",
          },
          {
            id: 5,
            text: "Reporting of defects",
          },
          {
            id: 6,
            text: "Preparation of test reports",
          },
          {
            id: 7,
            text: "Communicating with Remote/Dev/DevOps team",
          },
          {
            id: 8,
            text: "Software Release and Configuration Support",
          },
          {
            id: 9,
            text: "Customer Issue Management",
          },
          {
            id: 10,
            text: "Team mentoring and leading",
          },
        ],
        // roles1:"Good Experience in Vue.js or React.js",
        // roles2:"Excellent in core front-end web technologies (JavaScript, HTML5, SCSS, CSS3)",
        skills: [
          {
            id: 1,
            skills: "A good understanding of SDLC",
          },
          {
            id: 2,
            skills: "A good understanding of bug life cycle",
          },
          {
            id: 3,
            skills: "Good knowledge in SQL",
          },
          {
            id: 4,
            skills: "Good knowledge in Linux",
          },
          {
            id: 5,
            skills:
              "Should have team leading experience",
          },

          {
            id: 6,
            skills:
              "Good writing skills (for documenting the process)",
          },
          {
            id: 7,
            skills:
              "Good communications skills",
          },
          {
            id: 8,
            skills:
              "Good presentation skills",
          },
          {
            id: 9,
            skills:
              "A good understanding of selenium automation for Android and Web",
          },
          {
            id: 10,
            skills:
              "Leadership skills",
          },
        ],
        greatToHaveSkills: [
          { id: 1, skills: "knowledge of Github/bitbucket" },
          {
            id: 2,
            skills: "Knowledge of tailing server error logs and console logs using firebug.",
          },
          {
            id: 3,
            skills:
              "Knowledge of Security testing",
          },
          {
            id: 4,
            skills: "Knowledge of tools like JMeter, Postman, Putty",
          },
          {
            id: 5,
            skills:
              "Any experience with customer support",
          },
        ],
        candidateProfile: [
          {
            id: 1,
            text: "Minimum Bachelor’s degree or equivalent experience in computer science",
          },
          {
            id: 2,
            text: "5+ years of relevant experience",
          },
          {
            id: 3,
            text: "We are preferring immediate to 30 days joiners",
          },
          {
            id: 4,
            text: "Ability to multi task, organize and prioritize work",
          },  {
            id: 5,
            text: "Good oral and written communication skills in English",
          },
        ],
        // candidateProfile1:"Bachelor's degree or post-graduation in computer science or related subjects",
        // candidateProfile2:"3-5 years of experience as a front end developer",
        // candidateProfile3:"Ability to multi-task, organize, and prioritize work",
        // candidateProfile4:"Good oral and written communication skills in English",
        // candidateProfile5:"You are a go-getter with excellent learning, communication, and team skills. You revel in working with cutting-edge technology and have an eye for detail.",
        perks: "Health insurance, Daily lunch, Stock option, Referral bonuses, Parental leave",
        about:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id nibh nunc, arcu diam erat sit morbi. Vel amet arcu donec aliquet vitae. Feugiat mi risus tristique facilisi egestas diam in in. Dolor purus auctor sit eu consectetur adipiscing ultrices. Dui dignissim mi etiam amet, egestas sociis congue id molestie. Ornare ornare massa volutpat sed nec vitae sit duis etiam. Ultrices libero non rutrum et urna, pharetra. Nisi, tempus purus in habitant et morbi pharetra. Urna ipsum velit nisi eu, consequat curabitur feugiat maecenas. Enim nibh diam leo condimentum accumsan sit facilisi euismod ut. Tortor eget egestas nunc vel eget. Iaculis sed donec at tellus at dictumst. Ac sit risus enim lorem. Consectetur amet sed eget id amet urna libero. Vulputate sapien semper viverra vitae faucibus pretium bibendum blandit imperdiet. Pulvinar pretium nisl at scelerisque at. At ac ornare duis magna egestas nulla. Potenti et, tortor est convallis scelerisque tincidunt nulla tellus imperdiet.",
        desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id nibh nunc, arcu diam erat sit morbi. Vel amet arcu donec aliquet vitae. Feugiat mi risus tristique facilisi egestas diam in in. Dolor purus auctor sit eu consectetur adipiscing ultrices. Dui dignissim mi etiam amet, egestas sociis congue id molestie Ornare ornare massa volutpat sed nec vitae sit duis etiam. Ultrices libero non rutrum et urna, pharetra. Nisi, tempus purus in habitant et morbi pharetra. Urna ipsum velit nisi eu, consequat curabitur feugiat maecenas. Enim nibh diam leo condimentum accumsan sit facilisi euismod ut. Tortor eget egestas nunc vel eget. Iaculis sed donec at tellus at dictumst. Ac sit risus enim lorem. Consectetur amet sed eget id amet urna libero. Vulputate sapien semper viverra vitae faucibus pretium bibendum blandit imperdiet. Pulvinar pretium nisl at scelerisque at. At ac ornare duis magna egestas nulla. Potenti et, tortor est convallis scelerisque tincidunt nulla tellus imperdiet.",
      },
      {
        id: "cmB28",
        jobName: "Full Stack Developer (3-5 years)",
        slug: "full-stack-developer-3-5",
        location: "Bangalore",
        stream: "Engineering",
        ApplyId: 16,
        mode: "FullTime",
        AboutMobiotics: "",
        responsibility: "Responsibility",
        skillHeading: "Required Skills",
        // greatToHaveHeading: "Great to have skills",
        // profileHeading: "Qualifications",
        perksHeading: "Perks And Benefits",
        // company:
        //   "If you have an interesting portfolio of apps and have excellent communication and team skills and you fit the description below - you are the candidate we are looking for",
        roles: [
          {
            id: 1,
            text: "Design, develop, and maintain the architecture of the application.",
          },
          {
            id: 2,
            text: "Write clean, maintainable, and efficient code.",
          },
          {
            id: 3,
            text: "Collaborate with other team members to identify and prioritize features.",
          },
          {
            id: 4,
            text: "Ensure the performance, quality, and responsiveness of the application.",
          },
          {
            id: 5,
            text: "Troubleshoot and debug any issues that may arise.",
          },
          {
            id: 6,
            text: "Participate in code reviews to maintain code quality and ensure team members are adhering to best practices.",
          },
          {
            id: 7,
            text: "Stay up-to-date with new technology trends and make recommendations for improving the application.",
          },
        ],
        // roles1:"Good Experience in Vue.js or React.js",
        // roles2:"Excellent in core front-end web technologies (JavaScript, HTML5, SCSS, CSS3)",
        skills: [
          {
            id: 1,
            skills: "Strong experience with back-end languages such as Node and Python.",
          },
          {
            id: 2,
            skills: "Experience with back-end frameworks such as Express js.",
          },
          {
            id: 3,
            skills: "Strong Experience in AWS is mandatory.",
          },
          {
            id: 4,
            skills: "Strong experience with web development technologies such as HTML, CSS, JavaScript, and jQuery.",
          },
          {
            id: 5,
            skills:
              "Experience with front-end frameworks such as React, Angular, or Vue.js.",
          },

          {
            id: 6,
            skills:
              "Knowledge of Flutter would be an added advantage.",
          },
          {
            id: 7,
            skills:
              "Strong experience with databases such as MySQL, MongoDB.",
          },
          {
            id: 8,
            skills:
              "Experience with Git or other version control systems.",
          },
          {
            id: 9,
            skills:
              "Strong problem-solving and debugging skills.",
          },
          {
            id: 10,
            skills:
              "Good communication and teamwork skills.",
          },
        ],
        // greatToHaveSkills: [
        //   { id: 1, skills: "knowledge of Github/bitbucket" },
        //   {
        //     id: 2,
        //     skills: "Knowledge of tailing server error logs and console logs using firebug.",
        //   },
        //   {
        //     id: 3,
        //     skills:
        //       "Knowledge of Security testing",
        //   },
        //   {
        //     id: 4,
        //     skills: "Knowledge of tools like JMeter, Postman, Putty",
        //   },
        //   {
        //     id: 5,
        //     skills:
        //       "Any experience with customer support",
        //   },
        // ],
        // candidateProfile: [
        //   {
        //     id: 1,
        //     text: "Minimum Bachelor’s degree or equivalent experience in computer science",
        //   },
        //   {
        //     id: 2,
        //     text: "5+ years of relevant experience",
        //   },
        //   {
        //     id: 3,
        //     text: "We are preferring immediate to 30 days joiners",
        //   },
        //   {
        //     id: 4,
        //     text: "Ability to multi task, organize and prioritize work",
        //   },  {
        //     id: 5,
        //     text: "Good oral and written communication skills in English",
        //   },
        // ],
        // candidateProfile1:"Bachelor's degree or post-graduation in computer science or related subjects",
        // candidateProfile2:"3-5 years of experience as a front end developer",
        // candidateProfile3:"Ability to multi-task, organize, and prioritize work",
        // candidateProfile4:"Good oral and written communication skills in English",
        // candidateProfile5:"You are a go-getter with excellent learning, communication, and team skills. You revel in working with cutting-edge technology and have an eye for detail.",
        perks: "Health insurance, Daily lunch, Stock option, Referral bonuses, Parental leave",
        about:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id nibh nunc, arcu diam erat sit morbi. Vel amet arcu donec aliquet vitae. Feugiat mi risus tristique facilisi egestas diam in in. Dolor purus auctor sit eu consectetur adipiscing ultrices. Dui dignissim mi etiam amet, egestas sociis congue id molestie. Ornare ornare massa volutpat sed nec vitae sit duis etiam. Ultrices libero non rutrum et urna, pharetra. Nisi, tempus purus in habitant et morbi pharetra. Urna ipsum velit nisi eu, consequat curabitur feugiat maecenas. Enim nibh diam leo condimentum accumsan sit facilisi euismod ut. Tortor eget egestas nunc vel eget. Iaculis sed donec at tellus at dictumst. Ac sit risus enim lorem. Consectetur amet sed eget id amet urna libero. Vulputate sapien semper viverra vitae faucibus pretium bibendum blandit imperdiet. Pulvinar pretium nisl at scelerisque at. At ac ornare duis magna egestas nulla. Potenti et, tortor est convallis scelerisque tincidunt nulla tellus imperdiet.",
        desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id nibh nunc, arcu diam erat sit morbi. Vel amet arcu donec aliquet vitae. Feugiat mi risus tristique facilisi egestas diam in in. Dolor purus auctor sit eu consectetur adipiscing ultrices. Dui dignissim mi etiam amet, egestas sociis congue id molestie Ornare ornare massa volutpat sed nec vitae sit duis etiam. Ultrices libero non rutrum et urna, pharetra. Nisi, tempus purus in habitant et morbi pharetra. Urna ipsum velit nisi eu, consequat curabitur feugiat maecenas. Enim nibh diam leo condimentum accumsan sit facilisi euismod ut. Tortor eget egestas nunc vel eget. Iaculis sed donec at tellus at dictumst. Ac sit risus enim lorem. Consectetur amet sed eget id amet urna libero. Vulputate sapien semper viverra vitae faucibus pretium bibendum blandit imperdiet. Pulvinar pretium nisl at scelerisque at. At ac ornare duis magna egestas nulla. Potenti et, tortor est convallis scelerisque tincidunt nulla tellus imperdiet.",
      },
      {
        id: "cmB29",
        jobName: "Frontend Developer (3-5 years)",
        slug: "front-end-developer-3-5",
        location: "Bangalore",
        stream: "Engineering",
        ApplyId: 16,
        mode: "FullTime",
        AboutMobiotics: "",
        // responsibility: "Responsibility",
        skillHeading: "Required Skills",
        // greatToHaveHeading: "Great to have skills",
        // profileHeading: "Qualifications",
        perksHeading: "Perks And Benefits",
        company:
          "If you have an interesting portfolio of apps and have excellent communication and team skills and you fit the description below - you are the candidate we are looking for",
        // roles1:"Good Experience in Vue.js or React.js",
        // roles2:"Excellent in core front-end web technologies (JavaScript, HTML5, SCSS, CSS3)",
        skills: [
          {
            id: 1,
            skills: "Good Experience in Vue.js or React.js or Angular.",
          },
          {
            id: 2,
            skills:
              "Excellent in core front-end web technologies (JavaScript, HTML5, SCSS, CSS3)",
          },
          {
            id: 3,
            skills:
              "Strong JavaScript development skills including: Unit Testing (mocha, jasmine, chai)Packaging (Webpack or gulp) echnologies like npm, babel",
          },
          {
            id: 4,
            skills:
              "Code optimization including CSS minifying and script bundling.",
          },
          {
            id: 5,
            skills: "Strong understanding of web services (RESTful) and JSON.",
          },
          {
            id: 6,
            skills:
              "Experience with any server-side languages (PHP, Node.js) is a plus.",
          },
          {
            id: 7,
            skills: "Good understanding of responsive web design",
          },
          {
            id: 8,
            skills: "Understanding SEO and SMM is a Plus",
          },
          {
            id: 9,
            skills:
              "Understanding of PWA and lighthouse is a Plus 6-14 for 2-5 years.14-20 5-7 years",
          },
          {
            id: 10,
            skills: "Experience with HTML5 based TV apps is a Plus",
          },
        ],
        // greatToHaveSkills: [
        //   { id: 1, skills: "knowledge of Github/bitbucket" },
        //   {
        //     id: 2,
        //     skills: "Knowledge of tailing server error logs and console logs using firebug.",
        //   },
        //   {
        //     id: 3,
        //     skills:
        //       "Knowledge of Security testing",
        //   },
        //   {
        //     id: 4,
        //     skills: "Knowledge of tools like JMeter, Postman, Putty",
        //   },
        //   {
        //     id: 5,
        //     skills:
        //       "Any experience with customer support",
        //   },
        // ],
        // candidateProfile: [
        //   {
        //     id: 1,
        //     text: "Minimum Bachelor’s degree or equivalent experience in computer science",
        //   },
        //   {
        //     id: 2,
        //     text: "5+ years of relevant experience",
        //   },
        //   {
        //     id: 3,
        //     text: "We are preferring immediate to 30 days joiners",
        //   },
        //   {
        //     id: 4,
        //     text: "Ability to multi task, organize and prioritize work",
        //   },  {
        //     id: 5,
        //     text: "Good oral and written communication skills in English",
        //   },
        // ],
        // candidateProfile1:"Bachelor's degree or post-graduation in computer science or related subjects",
        // candidateProfile2:"3-5 years of experience as a front end developer",
        // candidateProfile3:"Ability to multi-task, organize, and prioritize work",
        // candidateProfile4:"Good oral and written communication skills in English",
        // candidateProfile5:"You are a go-getter with excellent learning, communication, and team skills. You revel in working with cutting-edge technology and have an eye for detail.",
        perks:
          "Health insurance, Daily lunch, Stock option, Referral bonuses, Parental leave",
        about:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id nibh nunc, arcu diam erat sit morbi. Vel amet arcu donec aliquet vitae. Feugiat mi risus tristique facilisi egestas diam in in. Dolor purus auctor sit eu consectetur adipiscing ultrices. Dui dignissim mi etiam amet, egestas sociis congue id molestie. Ornare ornare massa volutpat sed nec vitae sit duis etiam. Ultrices libero non rutrum et urna, pharetra. Nisi, tempus purus in habitant et morbi pharetra. Urna ipsum velit nisi eu, consequat curabitur feugiat maecenas. Enim nibh diam leo condimentum accumsan sit facilisi euismod ut. Tortor eget egestas nunc vel eget. Iaculis sed donec at tellus at dictumst. Ac sit risus enim lorem. Consectetur amet sed eget id amet urna libero. Vulputate sapien semper viverra vitae faucibus pretium bibendum blandit imperdiet. Pulvinar pretium nisl at scelerisque at. At ac ornare duis magna egestas nulla. Potenti et, tortor est convallis scelerisque tincidunt nulla tellus imperdiet.",
        desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id nibh nunc, arcu diam erat sit morbi. Vel amet arcu donec aliquet vitae. Feugiat mi risus tristique facilisi egestas diam in in. Dolor purus auctor sit eu consectetur adipiscing ultrices. Dui dignissim mi etiam amet, egestas sociis congue id molestie Ornare ornare massa volutpat sed nec vitae sit duis etiam. Ultrices libero non rutrum et urna, pharetra. Nisi, tempus purus in habitant et morbi pharetra. Urna ipsum velit nisi eu, consequat curabitur feugiat maecenas. Enim nibh diam leo condimentum accumsan sit facilisi euismod ut. Tortor eget egestas nunc vel eget. Iaculis sed donec at tellus at dictumst. Ac sit risus enim lorem. Consectetur amet sed eget id amet urna libero. Vulputate sapien semper viverra vitae faucibus pretium bibendum blandit imperdiet. Pulvinar pretium nisl at scelerisque at. At ac ornare duis magna egestas nulla. Potenti et, tortor est convallis scelerisque tincidunt nulla tellus imperdiet.",
      },
    ],
  },

  {
    id: "hl3",
    hightlights: [
      {
        id: "cm1",
        title: "Blended Learning",
        description:
          "Universities and educational institutions can explore the mixed-mode environment trend to harness personalisation and gain greater control over streaming",
        image: ImageHelper.solution8,
      },
      {
        id: "cm2",
        title: "Customization",
        description:
          "Universities and educational institutions can explore the mixed-mode environment trend to harness personalisation and gain greater control over streaming",
        image: ImageHelper.solution7,
      },
      {
        id: "cm3",
        title: "Multi-Screen Platform",
        description:
          "Let your students use the screen or device of their choice to attend online courses. Our educational video streaming services are designed to work on any device. ",
        image: ImageHelper.solution6,
      },
    ],
  },
  {
    id: "hl4",
    hightlights: [
      {
        id: "cm1",
        title: "Mobile streaming of videos",
        description:
          "Connect, engage, and expand your active customer base in real time. Our products, vLite, and Health+, help with mobile streaming networks so that users can practise exercise routines simultaneously.",
        image: ImageHelper.solution58,
      },
      {
        id: "cm2",
        title: "Boost Video Engagement",
        description:
          "Use fitness app builders coupled with AI-powered recommendation engines, live polls, and Q&A to reach a wider audience for your gym. ",
        image: ImageHelper.solution57,
      },
      {
        id: "cm3",
        title: " Integration with 3rd-Party Apps",
        description:
          "Profit from your customers with helpful, cooperative apps like Apple Health and Google Fit that guarantee streaming fitness on demand with various options.",
        image: ImageHelper.solution56,
      },
    ],
  },
  {
    id: "hl5",
    hightlights: [
      {
        id: "cm1",
        title: "Media and Entertainment",
        description:
          "Comprehensive OTT Platform delivering End-to-end OTT solutions to Broadcasters and Content owners. Launch your OTT service like Netflix and Amazon Prime, and Movie service like MUBI. Playout solution for broadcasters to launch their cloud based Live channels. End-to-end fully managed OTT platform that includes Content management, subscriber and subscription management, monetization, content processing/protection/delivery and analytics.",
        image: ImageHelper.solution111,
      },
      {
        id: "cm2",
        title: "Operators (Telco/PayTV/ISPs) ",
        description:
          "TV Everywhere solution for operators. Launch OTT service with both Live TV channels and On-demand audio/video content. Integration with Operator’s SMS and billing system for easy monetization. AppStore for AOSP Set Top Boxes for managing own App ecosystem.  The white-labeled platform enables Operators to launch OTT services with low TCO (Total Cost of Ownership) and faster Time-to-market.",
        image: ImageHelper.solution10,
      },
      {
        id: "cm3",
        title: "Education and E-learning ",
        description:
          "We offer E-Learning platform for educational institutes and trainers/coaches to launch their courses/e-Learning services. We guarantee an exceptional viewing and learning experience of on-demand and live educational content for students across the globe. Monetize the courses and Live classes with ease. We are into transforming the world of education by powering educators with the digital tools and resources that help them become future-ready. Through vLite MOOC, build with us a robust online teaching and learning platform that benefits trainers and the student community.  ",
        image: ImageHelper.solution9,
      },
      {
        id: "cm4",
        title: "Music/Podcast",
        description:
          "Get a flawless audio streaming experience with our SOUNDS. Are you an audio content owner, a music artist, or a podcaster? Here is a platform that can help you stream any content in real-time or on demand. We provide white-labeled audio streaming platforms globally. Monetize your audio content with our inbuilt subscription and purchase flows. Our powerful CMS allows you to manage all kinds of audio content i.e music, podcast, live radio etc. We encourage you to give your vision a try.",
        image: ImageHelper.solution113,
      },
      /* {
                     id: "cm5",
                     title: "Audio streaming",
                     description: "Stream audio content efficiently with the highest clarity using a platform that enables you to monetize your offerings.",
                     image: ImageHelper.service43,
                 },  */
      //   {
      //       id: "cm4",
      //       title: "End-to-End Digitalization Solutions  ",
      //       description: "Optimize your business processes through the enhanced digital transformation services incorporated into the platform.  ",
      //       image: ImageHelper.service44,
      //   },
      //   {
      //       id: "cm5",
      //       title: " Video streaming",
      //       description: "TGet a flawless audio streaming experience with our SOUNDS. Are you an audio content owner, a music artist, or a podcaster? Here is a platform that can help you stream any content in real-time or on demand. We provide white-labeled audio streaming platforms globally. Monetize your audio content with our inbuilt subscription and purchase flows. Our powerful CMS allows you to manage all kinds of audio content i.e music, podcast, live radio etc. We encourage you to give your vision a try.",
      //       image: ImageHelper.service45,
      //   },
      //   {
      //     id: "cm6",
      //     title: " Audio streaming",
      //     description: "Stream audio content efficiently with the highest clarity using a platform that enables you to monetize your offerings.",
      //     image: ImageHelper.service45,
      // },
    ],
  },
  {
    id: "hl6",
    hightlights: [
      {
        id: "cm1",
        title: "Turnkey End-to-End System Integration ",
        description:
          "We offer turnkey End-to-End System Integration for Broadcasters, Media, Production, Government sectors that can be easily implemented into your current business process wit with advance technology design and better commercial management.",
        image: ImageHelper.broadcast4,
      },
      {
        id: "cm2",
        title: "Centralized automation",
        description:
          "This platform allows your business to orchestrate its processes, integrate flexible architecture, and scale as required. An advance centralized automation for the entire broadcast station for Newsroom, Ingestion, Playout, Graphics, On-Air, and Content management and distribution. ",
        image: ImageHelper.broadcast5,
      },
      {
        id: "cm3",
        title: "Efficient IT Infrastructure ",
        description:
          "An effective IT infrastructure for the benchmark Broadcasting setup on-premises and cloud- based, that ensures video is broadcast with the best adequate ensuring quality. The infrastructure we provide helps to distribute your content seamlessly.  ",
        image: ImageHelper.broadcast1,
      },
      {
        id: "cm4",
        title: "Graphics Solution with AR/VR  ",
        description:
          "Prioritising the end-user experience, we offer a graphic solution with Augmented Reality. This ensures that the users experience visualization in the best possible way with Live & offline Graphics systems.   ",
        image: ImageHelper.broadcast3,
      },
      {
        id: "cm5",
        title: "Studio setup",
        description:
          "Mobiotics Studio setup for TV Network, Production, Live events, with latest infrastructure, technology and lightings",
        image: ImageHelper.broadcaststudio,
      },
    ],
  },
  {
    id: "hl7",
    hightlights: [
      {
        id: "cm1",
        title: "End-to-End Digitalization Solutions ",
        description:
          " Optimize your business processes through the enhanced digital transformation services incorporated into the platform. for Preservation of AV heritage, Metadata management & enrichment, a solution where Digitalization meets Utility, turnkey Digitization solution for Audio, Video, Documents, and Negatives.   ",
        image: ImageHelper.service41,
      },
      {
        id: "cm2",
        title: "Media Asset Management (MAM) ",
        description:
          "A platform that provides a single repository for storing and managing all your video and multimedia files. we facilitate the storage, management, and retrieval of media files throughout the content lifecycle and MAM with a centralized Archive automation workflow. ",
        image: ImageHelper.service42,
      },
      {
        id: "cm3",

        title: "Full automation",
        description:
          "A platform with an open, flexible architecture that can easily integrate your technology stack to implement and maintain. Robust & scalable centralized solution with advance technology. ",
        image: ImageHelper.service43,
      },
      {
        id: "cm4",
        title: "Video streaming ",
        description:
          "Get a flawless audio streaming experience with our SOUNDS. Are you an audio content owner, a music artist, or a podcaster? Here is a platform that can help you stream any content in real-time or on demand. We provide white-labeled audio streaming platforms globally. Monetize your audio content with our inbuilt subscription and purchase flows. Our powerful CMS allows you to manage all kinds of audio content i.e music, podcast, live radio etc. We encourage you to give your vision a try.  ",
        image: ImageHelper.service44,
      },
      {
        id: "cm5",
        title: "Audio streaming",
        description:
          "Stream audio content efficiently with the highest clarity using a platform that enables you to monetize your offerings. ",
        image: ImageHelper.service45,
      },
    ],
  },
];

export default CarrierData;
