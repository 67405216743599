import React from "react";

const ReviewNavBtn2 = (props) => {
  const { color, bgColor, sx } = props;
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={sx}
    >
      <circle
        r="22.681"
        transform="matrix(-1 0 0 1 22.6809 22.681)"
        fill={bgColor}
      />
      <path
        d="M18.6662 21.8756L25.0849 15.4683C25.1903 15.362 25.3158 15.2776 25.4539 15.22C25.5921 15.1625 25.7404 15.1328 25.8901 15.1328C26.0398 15.1328 26.188 15.1625 26.3262 15.22C26.4644 15.2776 26.5898 15.362 26.6953 15.4683C26.9065 15.6807 27.025 15.9682 27.025 16.2678C27.025 16.5674 26.9065 16.8548 26.6953 17.0673L21.0817 22.7375L26.6953 28.351C26.9065 28.5635 27.025 28.851 27.025 29.1506C27.025 29.4502 26.9065 29.7376 26.6953 29.9501C26.5902 30.0572 26.465 30.1425 26.3268 30.2009C26.1885 30.2593 26.0401 30.2896 25.8901 30.2903C25.74 30.2896 25.5916 30.2593 25.4534 30.2009C25.3152 30.1425 25.1899 30.0572 25.0849 29.9501L18.6662 23.5427C18.5511 23.4365 18.4592 23.3076 18.3964 23.1641C18.3335 23.0207 18.3011 22.8658 18.3011 22.7092C18.3011 22.5525 18.3335 22.3976 18.3964 22.2542C18.4592 22.1107 18.5511 21.9818 18.6662 21.8756V21.8756Z"
        fill={color}
      />
    </svg>
  );
};

export default ReviewNavBtn2;
