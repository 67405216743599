import React, { useRef, useState } from "react";
import {
  Grid,
  CardContent,
  MenuItem,
  InputLabel,
  Select,
  CardActions,
  Button,
  FormControl,
  Typography,
  Box,
} from "@mui/material";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "formik-material-ui";
import FilledButton from "../../Ui-Components/Buttons/FilledButton/FilledButton";
import ConstantHelper from "../../../Helpers/ConstantHelper";
import ColorHelper from "../../../Helpers/ColorHelper";
import TalkToUsListingData from "./TalkToUsListingData";

import EmailJsConfig from "../../../Helpers/EmailJsConfig";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";
import HighlightSectionOfServicesStyle2 from "../../../Components/ServicesSectionComps/HighlightSectionOfServices/HighlightSectionOfService2";
import ImageHelper from "../../../Helpers/ImageHelper";
import LeadeinfoStyle from "./LeadeinfoStyle";
import { BsLinkedin } from "react-icons/bs";

import TeamData from "../../../Helpers/ProductsPagesHelpers/TeamData";

const initialValues = {
  firstName: "",
  lastName: "",
  servicesOrProducts: "",
  menuListing: "",
  email: "",
  PhoneNo: "",
  Message: "",
};

// const options = [
//   { label: "Website Development", value: "website-development" },
//   { label: "Software Development", value: "software-development" },
//   {
//     label: "Mobile Application Development",
//     value: "mobile-application-development",
//   },
//   { label: "Digital Marketing", value: "digital-marketing" },
//   { label: "Careers", value: "careers" },
// ];

//PhoneNo validation
const numericRegEx = /(?=.[0-9])/;
const lengthRegEx = /(?=.{10,})/;

//validation schema
let validationSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  PhoneNo: Yup.string()
    .matches(numericRegEx, "Must contain only Numbers!")
    .matches(lengthRegEx, "Must contain 10 Digits!")
    .required("Required!"),
});

const Leaderinfo = (props) => {
  const {
    open,
    onClose,
    formprops,
    HighlightServicesProps,
    itemsInRow,
    bgColor,
    TeamLeaderprops,
    modalpropsinfo,
  } = props;

  const form = useRef();

  function SendEmail(e) {
    emailjs
      .sendForm(
        EmailJsConfig.SERVICE_ID,
        EmailJsConfig.TEMPLATE_ID,
        form.current,
        EmailJsConfig.USER_ID
      )
      .then(
        (result) => {
          onClose();
          console.log(result.text);
          Swal.fire({
            icon: "success",
            title:
              "Thank you for getting in touch with us. We will get back to you shortly.",
            confirmButtonColor: ColorHelper.PriamryColor,
          });
        },
        (error) => {
          onClose();
          console.log(error.text);
          Swal.fire({
            icon: "error",
            title: "Ooops, something went wrong",
            text: error.text,
            confirmButtonColor: ColorHelper.Red,
          });
        }
      );
  }

  const onSubmit = (values, formicHelpers) => {
    console.log("Form Values being sent in mail: ", values);
    SendEmail(values);
    formicHelpers.resetForm();
  };

  const [servicesOrProducts, setServicesOrProducts] =
    useState(TalkToUsListingData);
  const [MenuListing, setMenuListing] = useState({});
  const selectValue = (e) => {
    console.log(e);
    const selectedState = servicesOrProducts.filter(
      (v) => v.option === e.target.value
    );
    setMenuListing(selectedState[0]);
  };

  return (
    <>
      {TeamData.map((item) =>
        modalpropsinfo.modalId == item.id ? (
          <Grid
            container
            sx={{
              //  width:'900px !important',
              display: { md: "flex", xs: "flex" },
              justifyContent: { md: "center", xs: "center" },
              alignItems: { md: "center", xs: "center" },
              flexWrap: { md: "center", xs: "wrap" },
              flexDirection: { md: "row", xs: "column" },
            }}
          >
            <Grid
              xs={12}
              md={3}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <img
                src={item.teamImg}
                alt="Team Member"
                style={
                  {
                    // maxheight: "50%",
                    // px: { xs: 4, md: 4, lg: 10 },
                    //  order: 0,
                    // alignself: "stretch",
                    //  flexgrow: 1
                  }
                }
              />
            </Grid>
            <Grid
              xs={12}
              md={9}
              sx={{ textAlign: { xs: "center", md: "left" } }}
            >
              <Typography sx={LeadeinfoStyle.heading}>
                {item.Detail_description}
              </Typography>

              <Typography sx={LeadeinfoStyle.heading}>{item.name}</Typography>
              <Typography sx={LeadeinfoStyle.cardTitle}>
                {item.designation}
              </Typography>
              <Typography sx={LeadeinfoStyle.cardTitle}>
                <Box
                  onClick={() => window.open(item.url)}
                  sx={{
                    "&:hover svg path": {
                      fill: ColorHelper.grey1,
                    },
                  }}
                >
                  <BsLinkedin sx={{ color: "#DA1A5D" }} />
                </Box>
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <></>
        )
      )}
    </>
  );
};

export default Leaderinfo;
