import React from "react";
import ColorHelper from "../../Helpers/ColorHelper";

const HighlightIconV2_6 = () => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_690_54069)">
        <path
          d="M44.6523 0H1.34766C0.60375 0 0 0.60375 0 1.34766V9.28266H46V1.34766C46 0.60375 45.3971 0 44.6523 0ZM15.5744 5.98899H6.73828C5.99438 5.98899 5.39062 5.38523 5.39062 4.64133C5.39062 3.89742 5.99438 3.29367 6.73828 3.29367H15.5744C16.3183 3.29367 16.9221 3.89742 16.9221 4.64133C16.9221 5.38523 16.3183 5.98899 15.5744 5.98899ZM28.4805 5.98899C27.7366 5.98899 27.1328 5.38523 27.1328 4.64133C27.1328 3.89742 27.7366 3.29367 28.4805 3.29367C29.2253 3.29367 29.8281 3.89742 29.8281 4.64133C29.8281 5.38523 29.2253 5.98899 28.4805 5.98899ZM33.8711 5.98899C33.1272 5.98899 32.5234 5.38523 32.5234 4.64133C32.5234 3.89742 33.1272 3.29367 33.8711 3.29367C34.6159 3.29367 35.2188 3.89742 35.2188 4.64133C35.2188 5.38523 34.6159 5.98899 33.8711 5.98899ZM39.2617 5.98899C38.5178 5.98899 37.9141 5.38523 37.9141 4.64133C37.9141 3.89742 38.5178 3.29367 39.2617 3.29367C40.0065 3.29367 40.6094 3.89742 40.6094 4.64133C40.6094 5.38523 40.0065 5.98899 39.2617 5.98899Z"
          fill={ColorHelper.BgBlue}
        />
        <path
          d="M22.9998 29.5881C21.6369 29.5881 20.5273 30.6968 20.5273 32.0597C20.5273 33.4236 21.6369 34.5322 22.9998 34.5322C24.3628 34.5322 25.4723 33.4236 25.4723 32.0597C25.4723 30.6968 24.3628 29.5881 22.9998 29.5881Z"
          fill={ColorHelper.BgBlue}
        />
        <path
          d="M0 38.3633C0 39.1072 0.60375 39.711 1.34766 39.711H8.43363C8.38602 39.1423 8.46238 38.5628 8.66453 38.0174C7.29621 37.393 6.36453 36.0148 6.36453 34.4345V29.6853C6.36453 28.1238 7.27645 26.7582 8.6223 26.1239C8.11559 24.7269 8.43273 23.116 9.53512 22.0073L12.8854 18.6426C13.6293 17.8942 14.6212 17.4819 15.6768 17.4819C16.1449 17.4819 16.6076 17.5663 17.0425 17.7271C17.666 16.357 19.0442 15.4253 20.6254 15.4253H25.3746C26.9387 15.4253 28.3053 16.3372 28.9378 17.684C29.3645 17.5295 29.8173 17.4486 30.2755 17.4486C31.324 17.4486 32.3105 17.8565 33.0526 18.595L36.4218 21.9489C37.5385 23.0638 37.8575 24.6945 37.3355 26.1042C38.7038 26.7286 39.6355 28.1068 39.6355 29.6853V34.4345C39.6355 35.9987 38.7236 37.3643 37.3786 37.9977C37.5789 38.5502 37.6499 39.136 37.5951 39.711H44.6523C45.3971 39.711 46 39.1072 46 38.3633V11.978H0V38.3633Z"
          fill={ColorHelper.BgBlue}
        />
        <path
          d="M35.8349 28.4509L32.5603 28.0882L34.6136 25.5107C35.0078 25.0155 34.9664 24.3034 34.5184 23.8564L31.1519 20.5056C30.7039 20.0593 29.991 20.0212 29.4976 20.4178L26.9673 22.4521L26.6096 19.2255C26.5401 18.5962 26.0085 18.1201 25.3751 18.1201H20.6258C19.9924 18.1201 19.4608 18.5962 19.3912 19.2255L19.0277 22.5001L16.4511 20.4475C15.956 20.0526 15.2431 20.094 14.7968 20.5428L11.446 23.9085C10.9997 24.3573 10.9616 25.0694 11.3582 25.5628L13.3925 28.093L10.1659 28.4507C9.53663 28.5211 9.06055 29.0527 9.06055 29.6852V34.4345C9.06055 35.0679 9.53663 35.5995 10.1659 35.669L13.4405 36.0325L11.3871 38.6093C10.993 39.1045 11.0344 39.8174 11.4824 40.2636L14.8489 43.6144C15.2969 44.0608 16.0098 44.0988 16.5032 43.7022L19.0335 41.6679L19.3911 44.8945C19.4607 45.5238 19.9923 45.9999 20.6257 45.9999H25.375C26.0084 45.9999 26.54 45.5238 26.6095 44.8945L26.9731 41.6199L29.5498 43.6733C30.0449 44.0674 30.7578 44.0268 31.2041 43.5781L34.5549 40.2123C35.0011 39.7636 35.0392 39.0507 34.6427 38.5571L32.6083 36.0269L35.835 35.6692C36.4642 35.5997 36.9403 35.0681 36.9403 34.4347V29.6853C36.9402 29.0528 36.4641 28.5212 35.8349 28.4509ZM23.0003 37.2275C20.1513 37.2275 17.8329 34.9091 17.8329 32.06C17.8329 29.2109 20.1512 26.8925 23.0003 26.8925C25.8494 26.8925 28.1678 29.2109 28.1678 32.06C28.1678 34.9091 25.8494 37.2275 23.0003 37.2275Z"
          fill={ColorHelper.BgBlue}
        />
      </g>
      <defs>
        <clipPath id="clip0_690_54069">
          <rect width="46" height="46" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HighlightIconV2_6;
