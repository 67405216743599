import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { React, useState } from "react";

import ColorHelper from "../../../Helpers/ColorHelper";
import ConstantHelper from "../../../Helpers/ConstantHelper";
import FontHelper from "../../../Helpers/FontHelper";
import HighlightSectionOfServicesStyle from "./HighlightSectionOfServicesStyle";
import HighlightSectionOfServicesStyle1 from "./HighlightSectionOfServicesStyle1";
import HighlightServicesData from "./HighlightServicesData";
import style from './style';
import OTTData from "../../../Helpers/ProductsPagesHelpers/OTTData";

const HighlightSectionOfServices2 = (props) => {
  const { HighlightServicesProps, itemsInRow, bgColor } = props;

  //usestae for Hovering the cards
  const [hover, setHover] = useState({ value: false, index: 0 });
  const [itemone, setitemone] = useState(0);

  return (
    <Box sx={{ ...HighlightSectionOfServicesStyle.main, backgroundColor: bgColor }}>

    
      <Box sx={HighlightSectionOfServicesStyle.headings}>
        <Typography
          sx={{
            fontfamily: FontHelper.FontRegular,
            fontSize: { xs: "0.85rem", md: "18px" },
            fontWeight: 500,
            color: ColorHelper.BgBlue,
          }}
          variant="h7"
          component="h2"
        >

          {HighlightServicesProps.blueLine}
         
        </Typography>
        <Typography
          sx={{
            fontfamily: FontHelper.FontRegular,
            fontSize: { xs: "1rem", md: "30px" },
            fontWeight: 600,
            color: ColorHelper.black,
          }}
          variant="h7"
          component="h2"
        >
          {/* {ConstantHelper.ServicesListTitle} */}

          {HighlightServicesProps.blackLine}
        </Typography>
        <Typography
          sx={{
            fontfamily: FontHelper.FontRegular,
            fontSize: { xs: "1rem", md: "15px" },
            fontWeight: 400,
            color: ColorHelper.black,
          }}
          variant="h7"
          component="h2"
        >
         

          {HighlightServicesProps.yellowLine}
        </Typography>
      </Box>



       

   
    </Box>
  
  );
};

export default HighlightSectionOfServices2;