import { Box } from "@mui/system";
import React from "react";
import { Divider, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";

// Custom Components
import BorderedButton from "../Ui-Components/Buttons/BorderedButton/BorderedButton";
import FilledButton from "../Ui-Components/Buttons/FilledButton/FilledButton";

// Custom Styling
import FooterSectionStyle from "./ContactSectionStyle";

// Helpers
import ConstantHelper from "../../Helpers/ConstantHelper";
import ColorHelper from "../../Helpers/ColorHelper";
import IconHelper from "../../Helpers/IconHelper";
import ImageHelper from "../../Helpers/ImageHelper";

// Data
import { FooterMenuData1 } from "./ContactData";
import { FooterMenuData2 } from "./ContactData";
import { FooterMenuData3 } from "./ContactData";
import { FooterMenuData4 } from "./ContactData";
import TalkToUsForm from "../HeroSection/TalkToUsModal/TalkToUsForm";

import ContactSectionStyle from "../../Components/ContactSection/ContactSectionStyle";

import map from "../../Assets/Images/map2.png";
import TaktoUs from "../../Components/ContactSection/TaktoUs";

import email from "../../Assets/Images/customicons/email.png";
import map1 from "../../Assets/Images/customicons/map.png";
import phone from "../../Assets/Images/customicons/phone.png";
import ContentMediaSectionStyle from "../ContentMediaSection/ContentMediaSectionStyle";
import { contactUsMeta } from "../../Meta_Content";
import SeoComponent from "../SeoComponent/SeoComponent";

const ContactSection = () => {
  const GalaxyFold = useMediaQuery("(width: 280px)");

  return (
    <>
      <SeoComponent
        title={contactUsMeta.title}
        description={contactUsMeta.description}
        canonical={contactUsMeta.canonical}
        keywords={contactUsMeta.keywords}
      />
      <Box sx={ContactSectionStyle.SectionContainer}>
        <Box sx={ContactSectionStyle.sectionWrapper}>
          <br />
          <Box
            sx={{
              justifyContent: "center",
              maxWidth: { xs: "100%", md: "50%" },
            }}
          >
            <Box sx={{ pl: 2, maxWidth: "100vw" }}>
              <Typography sx={ContactSectionStyle.getinTouch}>
                {ConstantHelper.ContactFormTitle}
              </Typography>
              <Typography
                sx={{
                  ...ContactSectionStyle.friendlytxt,
                  fontSize: { xs: 20, md: 24 },
                }}
              >
                {ConstantHelper.ContactFormCaption}
              </Typography>
              <Divider sx={ContactSectionStyle.divider} />
            </Box>
            <Typography sx={ContactSectionStyle.FootershortDesc}>
              <TaktoUs />
            </Typography>
          </Box>

          <Box
            sx={ContactSectionStyle.mapImageContainer}
            onClick={() => window.open("https://goo.gl/maps/AamDq9ccmqkt6Do4A")}
          >
            <img
              src={map}
              alt="Mapping Icon"
              style={ContactSectionStyle.mappingicon}
            />
          </Box>
        </Box>

        <Box
          sx={{ ...ContactSectionStyle.FooterCard, mb: { xs: 0, md: "unset" } }}
        >
          <Box
            sx={{
              ...ContactSectionStyle.textContainer,
              margin: { xs: "unset", md: "unset" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                margin:"unset",
                columnGap: 2,
              }}
            >
              <Typography>
                <img
                  src={email}
                  alt="Email Icon"
                  sx={ContactSectionStyle.emailicon}
                />
              </Typography>
              <Typography sx={ContactSectionStyle.emailName}>
                {ConstantHelper.email}
              </Typography>
            </Box>
            <Typography sx={ContactSectionStyle.email_content1}>
              {ConstantHelper.email_content1}
            </Typography>
            <Typography sx={ContactSectionStyle.email_content2}>
              {ConstantHelper.email_content2}
            </Typography>
          </Box>

          <Box
            sx={{
              ...ContactSectionStyle.textContainer,
              margin: "unset",
              mt: { xs: 3, md: "unset" },
              mb: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                columnGap: 2,
              }}
            >
              <Typography>
                <img
                  src={phone}
                  alt="Phone Icon"
                  sx={ContactSectionStyle.phoneicon}
                />
              </Typography>
            </Box>
            <Typography sx={ContactSectionStyle.email_content1}>
              {ConstantHelper.callUs}
            </Typography>
            <Typography
              sx={ContactSectionStyle.office_content2}
              onClick={() =>
                window.location.replace(`tel:${ConstantHelper.phone_content2}`)
              }
            >
              {ConstantHelper.phone_content2}
            </Typography>
            <Typography
              sx={{
                ...ContactSectionStyle.phone_content2,
                visibility: "hidden",
              }}
              onClick={() =>
                window.location.replace(`tel:${ConstantHelper.phone_content2}`)
              }
            >
              {ConstantHelper.phone_content2}
            </Typography>
          </Box>
          <Box sx={ContactSectionStyle.textContainer}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                columnGap: 2,
              }}
            >
              <Typography>
                <img
                  src={map1}
                  alt="Map Icon"
                  sx={ContactSectionStyle.mapicon}
                />
              </Typography>
              <Typography sx={ContactSectionStyle.office}>
                {ConstantHelper.office1}
              </Typography>
            </Box>
            <Typography sx={ContactSectionStyle.office_content2}>
              {ConstantHelper.office_content1}
            </Typography>
          </Box>
          <Box sx={ContactSectionStyle.textContainer}></Box>
        </Box>

        {/* <Box sx={ContactSectionStyle.FooterCard}>
           <Box sx={ContactSectionStyle.textContainer}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                columnGap: 2,
              }}
            >
              <Typography>
                <img
                  src={map1}
                  alt="Map Icon"
                  sx={ContactSectionStyle.mapicon}
                />
              </Typography>
              <Typography sx={ContactSectionStyle.office}>
                {ConstantHelper.office1}
              </Typography>
            </Box>
            <Typography sx={ContactSectionStyle.office_content2}>
              {ConstantHelper.office_content1}
            </Typography>
          </Box> 
           <Box sx={ContactSectionStyle.textContainer}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                columnGap: 2,
              }}
            >
              <Typography>
                <img
                  src={map1}
                  alt="Map Icon"
                  sx={ContactSectionStyle.mapicon}
                />
              </Typography>
              <Typography sx={ContactSectionStyle.office}>
                {ConstantHelper.office2}
              </Typography>
            </Box>
            <Typography sx={ContactSectionStyle.office_content2}>
              {ConstantHelper.office_content2}
            </Typography>
          </Box> 
           <Box sx={ContactSectionStyle.textContainer}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                columnGap: 2,
              }}
            >
              <Typography>
                <img
                  src={map1}
                  alt="Map icon"
                  sx={ContactSectionStyle.mapicon}
                />
              </Typography>
              <Typography sx={ContactSectionStyle.office}>
                {ConstantHelper.office3}
              </Typography>
            </Box>
            <Typography sx={ContactSectionStyle.office_content2}>
              {ConstantHelper.office_content3}
            </Typography>
          </Box> 
        </Box> */}
      </Box>
    </>
  );
};

export default ContactSection;
