import * as React from "react";
import ColorHelper from "../../Helpers/ColorHelper";

const vApps = ({ size = 46, color, ...props }) => (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="48" height="48" rx="24" fill="#FFF3F5"/>
    <path d="M21 15H16C15.7348 15 15.4804 15.1054 15.2929 15.2929C15.1054 15.4804 15 15.7348 15 16V21C15 21.2652 15.1054 21.5196 15.2929 21.7071C15.4804 21.8946 15.7348 22 16 22H21C21.2652 22 21.5196 21.8946 21.7071 21.7071C21.8946 21.5196 22 21.2652 22 21V16C22 15.7348 21.8946 15.4804 21.7071 15.2929C21.5196 15.1054 21.2652 15 21 15V15Z" stroke="#DA1A5D" stroke-width="2" stroke-linejoin="round"/>
    <path d="M21 26H16C15.7348 26 15.4804 26.1054 15.2929 26.2929C15.1054 26.4804 15 26.7348 15 27V32C15 32.2652 15.1054 32.5196 15.2929 32.7071C15.4804 32.8946 15.7348 33 16 33H21C21.2652 33 21.5196 32.8946 21.7071 32.7071C21.8946 32.5196 22 32.2652 22 32V27C22 26.7348 21.8946 26.4804 21.7071 26.2929C21.5196 26.1054 21.2652 26 21 26Z" stroke="#DA1A5D" stroke-width="2" stroke-linejoin="round"/>
    <path d="M32 15H27C26.7348 15 26.4804 15.1054 26.2929 15.2929C26.1054 15.4804 26 15.7348 26 16V21C26 21.2652 26.1054 21.5196 26.2929 21.7071C26.4804 21.8946 26.7348 22 27 22H32C32.2652 22 32.5196 21.8946 32.7071 21.7071C32.8946 21.5196 33 21.2652 33 21V16C33 15.7348 32.8946 15.4804 32.7071 15.2929C32.5196 15.1054 32.2652 15 32 15V15Z" stroke="#DA1A5D" stroke-width="2" stroke-linejoin="round"/>
    <path d="M32 26H27C26.7348 26 26.4804 26.1054 26.2929 26.2929C26.1054 26.4804 26 26.7348 26 27V32C26 32.2652 26.1054 32.5196 26.2929 32.7071C26.4804 32.8946 26.7348 33 27 33H32C32.2652 33 32.5196 32.8946 32.7071 32.7071C32.8946 32.5196 33 32.2652 33 32V27C33 26.7348 32.8946 26.4804 32.7071 26.2929C32.5196 26.1054 32.2652 26 32 26Z" stroke="#DA1A5D" stroke-width="2" stroke-linejoin="round"/>
    </svg>
    

);

export default vApps;