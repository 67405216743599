import React from "react";
import ColorHelper from "../../Helpers/ColorHelper";

const HighlightIconV3_2 = () => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.66683 9.58333V36.4167H38.3335V9.58333H7.66683ZM5.75016 5.75H40.2502C40.7585 5.75 41.246 5.95193 41.6054 6.31138C41.9649 6.67082 42.1668 7.15833 42.1668 7.66667V38.3333C42.1668 38.8417 41.9649 39.3292 41.6054 39.6886C41.246 40.0481 40.7585 40.25 40.2502 40.25H5.75016C5.24183 40.25 4.75432 40.0481 4.39487 39.6886C4.03543 39.3292 3.8335 38.8417 3.8335 38.3333V7.66667C3.8335 7.15833 4.03543 6.67082 4.39487 6.31138C4.75432 5.95193 5.24183 5.75 5.75016 5.75ZM22.0418 25.875H20.1252V28.75H17.2502V25.875H11.5002V17.25H14.3752V23H17.2502V17.25H20.1252V23H22.0418V25.875ZM34.5002 28.75H31.146L27.7918 24.4375V28.75H24.9168V17.25H27.7918V21.5625L31.146 17.25H34.5002L30.1877 23L34.5002 28.75Z"
        fill={ColorHelper.BgBlue}
      />
    </svg>
  );
};

export default HighlightIconV3_2;
