import React from "react";

const NavbarIcon2 = (props) => {
  const { color, bgColor } = props;
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="24" fill={bgColor} />
      <path
        d="M24 32V22M30 32V16M18 32V28"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NavbarIcon2;
