import { Box } from "@mui/system";

// Helpers
import ColorHelper from "../../Helpers/ColorHelper";
import IconHelper from "../../Helpers/IconHelper";

import ButtunGroupStyle from './ButtonGroupStyle';

export default function HeroBtnGroup({ next, previous }) {
  return (
    <Box sx={ButtunGroupStyle.buttonGroup} id="NavBtnGroup">
      <Box
        sx={{ ...ButtunGroupStyle.BtnIconContainer, transform: 'rotate(180deg)' }}
        onClick={previous}
        aria-label="Previous"
      >
        <IconHelper.SliderBtn
          color={ColorHelper.PriamryColor}
          bgColor={ColorHelper.white}
        />
      </Box>
      <Box
        sx={{ ...ButtunGroupStyle.BtnIconContainer, transform: 'translateY(5px)' }}
        onClick={next}
        aria-label="Next"
      >
        <IconHelper.SliderBtn
          color={ColorHelper.PriamryColor}
          bgColor={ColorHelper.white}
        />
      </Box>
    </Box>
  );
}
