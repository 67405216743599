import React, { useRef, useState } from "react";
import {
  Grid,
  CardContent,
  MenuItem,
  InputLabel,
  Select,
  CardActions,
  Button,
  FormControl,
  Typography,
  IconButton,
  Modal,
  Box,
} from "@mui/material";
import { VscVmRunning } from "react-icons/vsc";
import Card from "@mui/material/Card";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "formik-material-ui";
import FilledButton from "../../Ui-Components/Buttons/FilledButton/FilledButton";
import ConstantHelper from "../../../Helpers/ConstantHelper";
import ColorHelper from "../../../Helpers/ColorHelper";
import TalkToUsListingData from "./TalkToUsListingData";

import EmailJsConfig from "../../../Helpers/EmailJsConfig";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";
import FontHelper from "../../../Helpers/FontHelper";
import ImageHelper from "../../../Helpers/ImageHelper";
import IconHelper from "../../../Helpers/IconHelper";
import AWS from "aws-sdk";
import ThankYouModal from "./ThankYouModal";

//file Upload

//Data
const initialValues = {
  firstName: "",
  lastName: "",
  servicesOrProducts: "",
  menuListing: "",
  email: "",
  PhoneNo: "",
  phone: "",
  Message: "",
  summary: "",
};

// const options = [
//   { label: "Website Development", value: "website-development" },
//   { label: "Software Development", value: "software-development" },
//   {
//     label: "Mobile Application Development",
//     value: "mobile-application-development",
//   },
//   { label: "Digital Marketing", value: "digital-marketing" },
//   { label: "Careers", value: "careers" },
// ];

//PhoneNo validation
const numericRegEx = /(?=.[0-9])/;
const lengthRegEx = /(?=.{10,})/;

//validation schema
let validationSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  // lastName: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  // PhoneNo: Yup.string()
  //   .matches(numericRegEx, "Must contain only Numbers!")
  //   .matches(lengthRegEx, "Must contain 10 Digits!")
  //   .required("Required!"),

  phone: Yup.string()
    .matches(numericRegEx, "Must contain only Numbers!")
    .matches(lengthRegEx, "Must contain 10 Digits!")
    .required("Required!"),
});

const JobForm = (props) => {
  const [fileStoreData, setFileStoreData] = useState("");
  const [fileStoreDataLoading, setFileStoreDataLoading] = useState(false);
  const [fileStoreDataName, setFileStoreDataName] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const form = useRef();

  const onSubmit = (values, formicHelpers) => {
    values = { ...values, fileStoreData, jobName: props?.jobName };

    if (fileStoreData === "") {
      return;
    }

    emailjs
      .send(
        EmailJsConfig.SERVICE_ID,
        EmailJsConfig.TEMPLATE_ID,
        values,
        EmailJsConfig.USER_ID
      )
      .then(
        (response) => {
          console.log(
            "Email sent successfully:",
            response.status,
            response.text
          );
          setOpenModal(true);
          setFileStoreData();
          setFileStoreDataName("");
        },
        (error) => {
          console.error("Error sending email:", error);
        }
      );

    formicHelpers.resetForm();
  };

  const [servicesOrProducts, setServicesOrProducts] =
    useState(TalkToUsListingData);
  const [MenuListing, setMenuListing] = useState({});
  const selectValue = (e) => {
    console.log(e);
    const selectedState = servicesOrProducts.filter(
      (v) => v.option === e.target.value
    );
    setMenuListing(selectedState[0]);
  };

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");

  const handleFileSelect = (event) => {
    setFile(event.target.files[0]);
    setFileName(event.target.files[0].name);
  };

  /* aws */
  const YOUR_ACCESS_KEY_ID = "AKIASULQMX62UGAJO5VI";
  const YOUR_SECRET_ACCESS_KEY = "978DB863KHFAQelvBf1q/wlCzbXti+WbgbTtyx/s";
  const S3_BUCKET = "indraniapp";
  const REGION = "us-east-2";

  const s3 = new AWS.S3({
    accessKeyId: YOUR_ACCESS_KEY_ID,
    secretAccessKey: YOUR_SECRET_ACCESS_KEY,
    region: REGION,
  });

  const handleFileUpload = (event) => {
    setFileStoreDataLoading(true);
    const file = event?.target?.files[0];
    const filename = file?.name;

    const params = {
      Bucket: S3_BUCKET,
      Key: filename,
      Body: file,
    };

    s3.upload(params, (error, data) => {
      if (error) {
        setFileStoreDataLoading(false);
        console.error("Error uploading file: ", error);
      } else {
        setFileStoreData(data?.Location);
        setFileStoreDataName(data?.key);
        setFileStoreDataLoading(false);
      }
    });
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ dirty, isValid, values, handleChange, handleBlur }) => {
          return (
            <Card
              sx={{
                minWidth: "100%",
                // height: "98%",
                boxShadow:
                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
              }}
            >
              <Form ref={form}>
                <CardContent>
                  <Grid item container spacing={1} justify="center">
                    <Grid item xs={12} sm={12} md={12}>
                      <Typography
                        sx={{
                          color: ColorHelper.black,
                          fontFamily: FontHelper.FontBold,
                        }}
                        fontSize={18}
                      >
                        Application
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <Typography mb={1}>Name</Typography>
                      <Field
                        // label="First Name"
                        variant="outlined"
                        fullWidth
                        name="firstName"
                        value={values.firstName}
                        component={TextField}
                        disabled={false}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <Typography mb={1}>Email Address</Typography>
                      <Field
                        // label="Email Address"
                        variant="outlined"
                        fullWidth
                        name="email"
                        value={values.email}
                        component={TextField}
                        disabled={false}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <Typography mb={1}>Phone Number</Typography>
                      <Field
                        // label="phone Number"
                        variant="outlined"
                        fullWidth
                        name="phone"
                        value={values.phone}
                        component={TextField}
                        disabled={false}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <Typography mb={1}>Summary</Typography>
                      <Field
                        // label="Summary"
                        variant="outlined"
                        fullWidth
                        name="summary"
                        value={values.summary}
                        component={TextField}
                        multiline
                        rows={4}
                        // maxRows={8}
                        disabled={false}
                      />
                    </Grid>

                    {/* <input type="file" onChange={handleFileUpload} /> */}

                    <Grid item xs={12} sm={12} md={12}>
                      <Typography mb={1}>Upload Your Resume</Typography>
                      <Card variant="outlined">
                        <CardContent>
                          {fileStoreDataLoading == false ? (
                            <Typography
                              sx={{ fontSize: 14 }}
                              color="text.secondary"
                              gutterBottom
                            >
                              <IconButton
                                color="primary"
                                sx={{
                                  display: "flex",
                                  flexWrap: "nowrap",
                                  justifyContent: "center",
                                }}
                                aria-label="upload picture"
                                component="label"
                              >
                                <input
                                  hidden
                                  type="file"
                                  onChange={handleFileUpload}
                                />
                                <IconHelper.Upload
                                  color={ColorHelper.PriamryColor}
                                />
                              </IconButton>
                            </Typography>
                          ) : null}

                          {fileStoreDataLoading == false ? (
                            <>
                              {fileStoreDataName ? (
                                <Typography variant="body2">
                                  {fileStoreDataName}
                                </Typography>
                              ) : (
                                <>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    color="text.secondary"
                                  >
                                    Drop your resume here, or&nbsp;
                                    <span
                                      color="primary"
                                      sx={{
                                        display: "flex",
                                        flexWrap: "nowrap",
                                        justifyContent: "center",
                                      }}
                                      aria-label="upload picture"
                                      component="label"
                                    >
                                      <input
                                        hidden
                                        accept="file/*"
                                        type="file"
                                      />
                                      Browse
                                    </span>
                                  </Typography>
                                  <Typography variant="body2">
                                    Please upload your resume in PDF/DOC
                                  </Typography>
                                </>
                              )}
                            </>
                          ) : (
                            <p>Loading...⏳⌛️...</p>
                          )}

                          {/* <Typography sx={{ mb: 1.5 }} color="text.secondary">
                          Drop your resume here, or&nbsp;
                          <span
                            color="primary"
                            sx={{
                              display: "flex",
                              flexWrap: "nowrap",
                              justifyContent: "center",
                            }}
                            aria-label="upload picture"
                            component="label"
                          >
                            <input hidden accept="file/*" type="file" />
                            Browse
                          </span>
                        </Typography> */}
                          {/* <Typography variant="body2">
                          Please upload your resume in PDF/DOC
                        </Typography> */}
                        </CardContent>
                      </Card>

                      {/*       <Grid item xs={12} sm={12} md={12}>
                <VscVmRunning style={{ color: "#DA1A5D" }} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} sx={{height: "80px", mt: 2}}>
               
                <span>Drop your resume here, or </span>
                <span>browse</span>
                </Grid>
                <span style={{marginBottom:"50px"}}>Please upload your resume in PDF/DOC</span>
        */}
                      {fileStoreDataName === "" && (
                        <Typography color="error">
                          Please select a file*
                        </Typography>
                      )}
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <Button
                        // disableRipple
                        // disabled={!dirty || !isValid}
                        disabled={fileStoreDataLoading == true}
                        type="Submit"
                        sx={{
                          ":hover": {
                            bgcolor: ColorHelper?.blue,
                            color: "white",
                          },
                          width: "100%",
                          bgcolor: ColorHelper.PriamryColor,
                        }}
                      >
                        <Grid sx={{ color: ColorHelper.white }}>Submit</Grid>
                      </Button>
                    </Grid>

                    {/* 
                <Grid item xs={12} sm={12} md={12}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="demo-simple-select-outlined-label">
                     You are interested in?
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      label="Services"
                      onChange={(e)=>{handleChange(e);selectValue(e)}}
                      onBlur={handleBlur}
                      value={values.services}
                      name="services"
                    >
                      {TalkToUsListingData.map((item) => (
                        <MenuItem key={item.option} value={item.option}>
                          {item.option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
               */}
                  </Grid>
                </CardContent>
              </Form>
            </Card>
          );
        }}
      </Formik>
      <ThankYouModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        message="Thank you for getting in touch with us. We will get back to you shortly."
      />
      
    </>
  );
};

export default JobForm;
