// Helpers
import ColorHelper from "../../../Helpers/ColorHelper";
import IconHelper from "../../../Helpers/IconHelper";

const HighlightServicesData = [
  {
    id: 1,
    title: "Feugiat vel molestie.",
    desc: "Sunt et culpa nulla exercitation. Irure eiusmod laborum minim labore veniam do ullamco fugiat commodo laborum cupidatat ut.",
    icon: <IconHelper.HighlightIcon1 />,
  },
  {
    id: 2,
    title: "Feugiat vel molestie.",
    desc: "Et magna eiusmod ut ad sit commodo cupidatat commodo irure elit. Commodo fugiat commodo commodo.",
    icon:  <IconHelper.HighlightIcon2 />,
  },
  {
    id: 3,
    title: "Feugiat vel molestie.",
    desc: "Ipsum proident commodo eiusmod officia qui. In cillum magna consectetur incididunt eu veniam occaecat sunt est officia. Ut sint.",
    icon:  <IconHelper.HighlightIcon3 />,
  },
  {
    id: 4,
    title: "Feugiat vel molestie.",
    desc: "Dolore et ut ullamco cillum aliqua sunt elit incididunt eiusmod aute cupidatat labore. Pariatur minim.",
    icon:  <IconHelper.HighlightIcon4 />,
  },
  {
    id: 5,
    title: "Feugiat vel molestie.",
    desc: "Ullamco irure excepteur ut et qui eiusmod commodo incididunt. Ex ut eiusmod adipisicing enim irure officia culpa. Proident nisi id ullamco consectetur.",
    icon:  <IconHelper.HighlightIcon5 />,
  },
  {
    id: 6,
    title: "Feugiat vel molestie.",
    desc: "Proident enim minim ipsum exercitation velit consequat dolor proident occaecat. Amet ex veniam officia do. Nostrud quis.",
    icon:  <IconHelper.HighlightIcon6 />,
  },
];

export default HighlightServicesData;
