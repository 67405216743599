import React from "react";

const HLTvLive04 = () => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M45.1016 5.16072C45.1016 4.01431 44.1722 3.08497 43.0258 3.08497H2.97419C1.82778 3.08497 0.898438 4.01431 0.898438 5.16072V9.74023H45.1016V5.16072Z"
        stroke="#457ABD"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M0.898438 9.74032V40.8394C0.898438 41.9857 1.82778 42.915 2.97419 42.915H43.0258C44.1722 42.915 45.1016 41.9857 45.1016 40.8394V9.74032H0.898438Z"
        stroke="#457ABD"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M39.9571 20.9608C39.9571 22.6229 39.2833 24.1287 38.1935 25.2176C37.1037 26.3074 35.5988 26.9812 33.9367 26.9812C30.6116 26.9812 27.9163 24.2859 27.9163 20.9608C27.9163 19.2987 28.5901 17.7938 29.6799 16.704C30.7688 15.6151 32.2736 14.9412 33.9367 14.9412C35.5988 14.9412 37.1037 15.6151 38.1935 16.704C39.2833 17.7938 39.9571 19.2987 39.9571 20.9608Z"
        stroke="#457ABD"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M39.9571 20.9607C39.9571 22.6228 39.2832 24.1286 38.1934 25.2175L33.9366 20.9607V14.9412C35.5988 14.9412 37.1036 15.615 38.1934 16.7039C39.2832 17.7937 39.9571 19.2986 39.9571 20.9607Z"
        stroke="#457ABD"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M27.9163 32.1074H39.9571"
        stroke="#457ABD"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.5644 32.1074H7.11108V23.6732H11.5644V32.1074Z"
        stroke="#457ABD"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.109 32.1074H15.6558V17.9335H20.109V32.1074Z"
        stroke="#457ABD"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.48047 32.1074H22.1914"
        stroke="#457ABD"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.64709 37.165H39.9571"
        stroke="#457ABD"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default HLTvLive04;
