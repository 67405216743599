import React from "react";
import ColorHelper from "../../Helpers/ColorHelper";

const HighlightIconV3_3 = () => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.7668 25.9382L33.3502 38.7167C33.457 38.859 33.522 39.0283 33.538 39.2056C33.554 39.3829 33.5203 39.5611 33.4407 39.7202C33.3611 39.8794 33.2387 40.0133 33.0873 40.1069C32.9359 40.2004 32.7615 40.25 32.5835 40.25H13.4168C13.2389 40.25 13.0644 40.2004 12.913 40.1069C12.7616 40.0133 12.6393 39.8794 12.5597 39.7202C12.4801 39.5611 12.4464 39.3829 12.4624 39.2056C12.4784 39.0283 12.5434 38.859 12.6502 38.7167L22.2335 25.9382C22.3228 25.8192 22.4385 25.7226 22.5716 25.6561C22.7047 25.5896 22.8514 25.5549 23.0002 25.5549C23.149 25.5549 23.2957 25.5896 23.4288 25.6561C23.5618 25.7226 23.6776 25.8192 23.7668 25.9382ZM23.0002 31.2992L19.1668 36.4167H26.8335L23.0002 31.2992ZM34.5002 36.4167V32.5833H38.3335V9.58333H7.66684V32.5833H11.5002V36.4167H5.73485C5.48415 36.4157 5.23611 36.3653 5.00488 36.2684C4.77366 36.1716 4.56377 36.0301 4.38722 35.8521C4.21066 35.6741 4.07089 35.4631 3.97589 35.2311C3.88088 34.9991 3.8325 34.7507 3.83351 34.5V7.66667C3.83351 6.60867 4.7056 5.75 5.73485 5.75H40.2655C41.3158 5.75 42.1668 6.60292 42.1668 7.66667V34.5C42.1668 35.558 41.2948 36.4167 40.2655 36.4167H34.5002Z"
        fill={ColorHelper.BgBlue}
      />
    </svg>
  );
};

export default HighlightIconV3_3;
