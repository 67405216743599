// Helpers
import ConstantHelper from "../../Helpers/ConstantHelper";
import ImageHelper from "../../Helpers/ImageHelper";
import IconHelper from "../../Helpers/IconHelper";

const StreamingTypeData = [
  {
    id: "stream1",
    i: 0,
    name: ConstantHelper.StreamingType1,
    bannerImg: ImageHelper.StreamingBanner1,
    url: "/products/vLive",
    Productslogo: ImageHelper.vLive_Logo,
    ProductTagLine: ConstantHelper.StreamingTagLine1,
    data: [
      {
        id: 1,
        title: "Live and On-Demand Multi-Bitrate Video/Audio Streaming",
        desc: "Adaptive bitrate streaming of video and audio content at multiple bitrate/resolution for Buffer-free content playback experience. Feature rich player on all devices.",
        icon: IconHelper.VliveIcons01,
      },
      {
        id: 2,
        title: "Feature Rich Content Management System",
        desc: "Advance content management system for OTT. Support for movies, shows, live TV, live events and music content. Advance features like multi-lingual metadata, trailers, posters, geoblocking etc.",
        icon: IconHelper.VLive02,
      },
      {
        id: 3,
        title: "Best-In-Class Video Transcoding – SD/HD/4K",
        desc: "Extremely fast high quality SD, HD and 4K transcoding with codec support for H.264, H.265/HEVC, AV1 and VP9. Supports multiple poster and trailer generation, watermarking.",
        icon: IconHelper.VLive03,
      },
      {
        id: 4,
        title: "Industry-Leading DRMs- Widewine, Fairplay, and PlayReady",
        desc: "Hollywood studio approved Digital Rights Management (DRM) for content security. Multi-DRM packaging and license service supporting DASH and HLS.",
        icon: IconHelper.VLive04,
      },
      {
        id: 5,
        title: "Advanced User, Subscription, And Device Management",
        desc: "User profile and access management. Subscriber and subscription management service. Plan, Pricing and availability management based on geolocation. Support for coupons and discounts for promotion and campaigns.",
        icon: IconHelper.VLive05,
      },
      {
        id: 6,
        title: "Monetization and Payments",
        desc: "Monetization of content through various business models – SVOD/TVOD/AVOD/Rentals. Unified payment service integrating more than 20 industry leading international and domestic payment gateways, mobile wallets and telco billing systems.",
        icon: IconHelper.VLive06,
      },
      {
        id: 7,
        title: "Real-time Analytics and Recommendation System",
        desc: "Best of breed Big Data analytics system supporting real time, frequent and batch reporting and visualisation from variety of data sources. Evolved AI based data mining. Natural language search and suggestions and deep learning based smart recommendations.",
        icon: IconHelper.VLive07,
      },
      {
        id: 8,
        title: "Multiscreen OTT Apps",
        desc: "Your choice of apps with best-in-class UI/UX on more than 15 device platforms including Web/Mobile/Tablet/TV/Console form factors.",
        icon: IconHelper.MS,
      },
    ],
    vLive: [
      {
        id: 1,
        title: "Live And On-demand Multi-bitrate Video/Audio Streaming",
        desc: "Adaptive bitrate streaming of video and audio content at multiple bitrate/resolution for buffer-free content playback experience. Feature rich player on all devices.",
        icon: IconHelper.VliveIcons01,
      },
      {
        id: 2,
        title: "Feature Rich Content Management System",
        desc: "Advance content management system for OTT. Support for movies, shows, live TV, live events and music content. Advance features like multi-lingual metadata, trailers, posters, geoblocking etc.",
        icon: IconHelper.VLive02,
      },

      {
        id: 3,
        title: "Best-In-Class Video Transcoding – SD/HD/4K",
        desc: "Extremely fast high quality SD, HD and 4K transcoding with codec support for H.264, H.265/HEVC, AV1 and VP9. Supports multiple poster and trailer generation, watermarking.",
        icon: IconHelper.VLive03,
      },
      {
        id: 4,
        title: "Industry-leading DRMs- Widewine, Fairplay, and PlayReady",
        desc: "Hollywood studio approved Digital Rights Management (DRM) for content security. Multi-DRM packaging and license service supporting DASH and HLS.",
        icon: IconHelper.VLive04,
      },
      {
        id: 5,
        title: "Advanced User, Subscription, And Device Management",
        desc: "User profile and access management. Subscriber and subscription management service. Plan, Pricing and availability management based on geolocation. Support for coupons and discounts for promotion and campaigns.",
        icon: IconHelper.VLive05,
      },
      {
        id: 6,
        title: "Monetization and Payments",
        desc: "Monetization of content through various business models – SVOD/TVOD/AVOD/Rentals. Unified payment service integrating more than 20 industry leading international and domestic payment gateways, mobile wallets and telco billing systems.",
        icon: IconHelper.VLive06,
      },
      {
        id: 7,
        title: "Real-time Analytics And Recommendation System",
        desc: "Best of breed Big Data analytics system supporting real time, frequent and batch reporting and visualisation from variety of data sources. Evolved AI based data mining. Natural language search and suggestions and deep learning based smart recommendations.",
        icon: IconHelper.VLive07,
      },
      {
        id: 8,
        title: "Multiscreen OTT Apps",
        desc: "Your choice of apps with best-in-class UI/UX on more than 15 device platforms including Web/Mobile/Tablet/TV/Console form factors.",
        icon: IconHelper.MS,
      },
    ],
  },
  {
    id: "stream2",
    i: 1,
    name: ConstantHelper.StreamingType5,
    bannerImg: ImageHelper.StreamingBanner2,
    url: "/products/vlite-mooc",
    Productslogo: ImageHelper.vLite_MOOC,
    ProductTagLine: ConstantHelper.StreamingTagLine2,
    data: [
      {
        id: 1,
        title: "Support Courses And Gamification",
        desc: "Support for courses, course tracks, and certifications. Student progress tracker and gamification.",
        icon: IconHelper.vLiteMOOC01,
      },
      {
        id: 2,
        title: "On-Demand Courses",
        desc: "Create and manage on-demand courses -   Video, Audio, HTML5 and PDF documents.",
        icon: IconHelper.vLiteMOOC02,
      },
      {
        id: 3,
        title: "Live Classes",
        desc: "Live classes - One-to-one, one-to-many, broadcast. Online classroom facility integration with Zoom/Twilio/Agora.",
        icon: IconHelper.vLiteMOOC03,
      },
      {
        id: 4,
        title: "Online And Offline Assessment",
        desc: "Online assessment with adaptive difficulty. Offline assessment facility. Q&A with the instructor. Parental connect.",
        icon: IconHelper.vLiteMOOC04,
      },
      {
        id: 5,
        title: "Option To Monetize",
        desc: "vLite MOOC provides an opportunity to monetize your passion for teaching! Subscriptions and course purchases. Multiple currencies and locations supported.",
        icon: IconHelper.vLiteMOOC05,
      },
      {
        id: 6,
        title: "Digital Rights Management (DRM)",
        desc: "Digital Rights Management (DRM) for protection of your video courses. Adaptive Bitrate (ABR) streaming for the buffer-free learning experience.",
        icon: IconHelper.DRM,
      },
      /*  {
          id: 7,
          title: "Monetization option",
          desc: "A robust online teaching and learning platform that helps you monetise your passion for teaching.",
          icon: IconHelper.StreamingTypeIcon7,
        },
        {
          id: 8,
          title: "Metadata management",
          desc: "The search engine on vLite MOOC is configured to show results determined using auto-suggest.",
          icon: IconHelper.StreamingTypeIcon8,
        }, */
    ],
    vlitemooc: [
      {
        id: 1,
        title: "Support Courses And Gamification",
        desc: "Support for courses, course tracks, and certifications. Student progress tracker and gamification.",
        icon: IconHelper.vLiteMOOC01,
      },
      {
        id: 2,
        title: "On-Demand Courses",
        desc: "Create and manage on-demand courses -   Video, Audio, HTML5 and PDF documents.",
        icon: IconHelper.vLiteMOOC02,
      },
      {
        id: 3,
        title: "Live Classes",
        desc: "Live classes - One-to-one, one-to-many, broadcast. Online classroom facility integration with Zoom/Twilio/Agora.",
        icon: IconHelper.vLiteMOOC03,
      },
      {
        id: 4,
        title: "Online And Offline assessment",
        desc: "Online assessment with adaptive difficulty. Offline assessment facility. Q&A with the instructor. Parental connect.",
        icon: IconHelper.vLiteMOOC04,
      },
      {
        id: 5,
        title: "Option To monetize",
        desc: "vLite MOOC provides an opportunity to monetize your passion for teaching! Subscriptions and course purchases. Multiple currencies and locations supported.",
        icon: IconHelper.vLiteMOOC05,
      },
      {
        id: 6,
        title: "Digital Rights Management (DRM)",
        desc: "Digital Rights Management (DRM) for protection of your video courses. Adaptive Bitrate (ABR) streaming for the buffer-free learning experience.",
        icon: IconHelper.DRM,
      },
      /*   {
           id: 7,
           title: "Secure Video Storage  ",
           desc: "Save your videos securely. We help you achieve lower storage expenses by giving you the ability to encrypt videos without compromising playing quality.",
           icon: IconHelper.StreamingTypeIcon7,
         },
         {
           id: 8,
           title: "AES Encryption ",
           desc: "Get a robust, symmetric encryption technique that guarantees end-to-end protection for your copyrighted content with vLite MOOC’s Advanced Encryption Standard.",
           icon: IconHelper.StreamingTypeIcon8,
         }, */
    ],
  },
  {
    id: "stream3",
    i: 2,
    name: ConstantHelper.StreamingType20,
    bannerImg: ImageHelper.StreamingBanner3,
    url: "/products/vApps",
    Productslogo: ImageHelper.vApps,
    ProductTagLine: ConstantHelper.StreamingTagLine3,

    data: [
      {
        id: 1,
        title: "Comprehensive App Store",
        desc: "vApps is your ideal choice whether you are an Android client or looking for a publisher portal or a provider portal with branding.",
        icon: IconHelper.vApps01,
      },
      {
        id: 2,
        title: "Monetization Options",
        desc: "Support for both paid and free applications bundled with multiple payment methods support.",
        icon: IconHelper.vApps02,
      },
      {
        id: 3,
        title: "App Management",
        desc: "Evolved app management workflow with app upload, approval/ rejection, publish, unpublish, and version update options.",
        icon: IconHelper.vApps03,
      },
      {
        id: 4,
        title: "Dashboard And Analytics",
        desc: "Detailed analytics, dashboards and reports at aggregate and app/publisher level.",
        icon: IconHelper.vApps04,
      },
      {
        id: 5,
        title: "Full Control",
        desc: "Powered by the latest technologies, we ensure that you have accurate app control with remote wipe, force install, and force update options.",
        icon: IconHelper.vApps05,
      },
      /* {
       id: 6,
       title: "Live to VOD",
       desc: "Enables on-demand users to satisfy their demands for real-time viewing.",
       icon: IconHelper.StreamingTypeIcon6,
     }, 
     {
         id: 7,
         title: "Exceptional Solutions",
         desc: "We use the agile methodology to build the best solutions.",
         icon: IconHelper.StreamingTypeIcon7,
       },
       {
         id: 8,
         title: "Support services",
         desc: "24/7 monitoring and maintenance.",
         icon: IconHelper.StreamingTypeIcon8,
       },  */
    ],
    vApps: [
      {
        id: 1,
        title: "Comprehensive App Store",
        desc: "vApps is your ideal choice whether you are an Android client or looking for a publisher portal or a provider portal with branding.",
        icon: IconHelper.vApps01,
      },
      {
        id: 2,
        title: "Monetization Options",
        desc: "Support for both paid and free applications bundled with multiple payment methods support.",
        icon: IconHelper.vApps02,
      },
      {
        id: 3,
        title: "App management",
        desc: "Evolved app management workflow with app upload, approval/rejection, publish, unpublish, and version update options.",
        icon: IconHelper.vApps03,
      },
      {
        id: 4,
        title: "Dashboard and Analytics",
        desc: "Detailed analytics, dashboards and reports at aggregate and app/publisher level.",
        icon: IconHelper.vApps04,
      },
      {
        id: 5,
        title: "Full Control",
        desc: "Powered by the latest technologies, we ensure that you have accurate app control with remote wipe, force install, and force update options.",
        icon: IconHelper.vApps05,
      },
    ],
  },
  {
    id: "stream4",
    i: 3,
    name: ConstantHelper.StreamingType21,
    bannerImg: ImageHelper.StreamingBanner4,
    url: "/products/sounds",
    Productslogo: ImageHelper.Sounds,
    ProductTagLine: ConstantHelper.StreamingTagLine4,

    data: [
      {
        id: 1,
        title: "Support For Various Types Of Content",
        desc: "Support for music, albums, podcasts, and live radio content. Configurable decking and playlist support.",
        icon: IconHelper.Sounds01,
      },
      {
        id: 2,
        title: "Multi-Device Support",
        desc: "The platform is supported across IOS, Android and Web. with Bluetooth/Airplay support. Full, mini and lockscreen audio player. Car mode support.",
        icon: IconHelper.Sounds02,
      },
      // {
      //   id: 3,
      //   title: "Content Delivery Network",
      //   desc: "Stream audio files to subscribers from the fastest server to their location and help improve stream delivery.",
      //   icon: IconHelper.StreamingTypeIcon3,
      // },
      {
        id: 4,
        title: "Industry-Leading DRMs",
        desc: "We help you develop an end-to-end protected live audio streaming application with us. Our advanced security measures efficiently prevent patent infringement and other unauthorized breaches.",
        icon: IconHelper.Sounds03,
      },
      {
        id: 5,
        title: "Monetization And Payments",
        desc: "Monetization of content through various business models – SVOD/TVOD. Unified payment service integrating industry-leading international and domestic payment gateways, mobile wallets, and telco billing systems.",
        icon: IconHelper.Sounds04,
      },
      {
        id: 6,
        title: "Advanced User, Subscription, And Device Management",
        desc: "Get detailed Metrics and Reports, including subscribers, subscriptions, purchases, orders, and payment details. We also provide Partner and affiliate management options.",
        icon: IconHelper.Sounds05,
      },
      {
        id: 7,
        title: "Interactive Audio Player",
        desc: "Feature-rich audio player. Controls for play, pause, rewind, stop, and shuffle. Audio can now be played in the background as users interact with the same device for other purposes.",
        icon: IconHelper.Sounds06,
      },
      /*    {
            id: 8,
            title: "Advanced technology",
            desc: "Uses cutting-edge technology like microservice architecture.",
            icon: IconHelper.StreamingTypeIcon8,
          },  */
    ],
    sounds: [
      {
        id: 1,
        title: "Support For Various Types Of Content",
        desc: "Support for music, albums, podcasts, and live radio content. Configurable decking and playlist support.",
        icon: IconHelper.Sounds01,
      },
      {
        id: 2,
        title: "Multi-Device Support",
        desc: "The platform is supported across IOS, Android and Web. with Bluetooth/Airplay Support. Full, mini and lockscreen audio player. Car mode support.",
        icon: IconHelper.Sounds02,
      },
      {
        id: 3,
        title: "Industry-leading DRMs",
        desc: "We help you develop an end-to-end protected live audio streaming application with us. Our advanced security measures efficiently prevent patent infringement and other unauthorized breaches.",
        icon: IconHelper.Sounds03,
      },
      {
        id: 4,
        title: "Monetization and Payments",
        desc: "Monetization of content through various business models – SVOD/TVOD. Unified payment service integrating industry-leading international and domestic payment gateways, mobile wallets, and telco billing systems.",
        icon: IconHelper.Sounds04,
      },
      {
        id: 5,
        title: "Advanced User, Subscription, And Device Management",
        desc: "Get detailed Metrics and Reports, including subscribers, subscriptions, purchases, orders, and payment details. We also provide Partner and affiliate management options.",
        icon: IconHelper.Sounds05,
      },
      {
        id: 6,
        title: "Interactive Audio Player",
        desc: "Feature-rich audio player. Controls for play, pause, rewind, stop, and shuffle. Audio can now be played in the background as users interact with the same device for other purposes.",
        icon: IconHelper.Sounds06,
      },
      /*  {
          id: 7,
          title: "Maintenance and Monitoring",
          desc: "We ensure a smooth streaming service by frequently resolving any potential concerns regarding hardware compatibility, system performance, etc.",
          icon: IconHelper.StreamingTypeIcon7,
        },
        {
          id: 8,
          title: "Adaptive Bit Rate (ABR)",
          desc: "ABR allows for the streaming of video to numerous devices and point-to-point streaming via the Internet. It is a method for dynamically altering the video quality and compression level of a stream to match available bandwidth, in Full HD and SD based.",
          icon: IconHelper.StreamingTypeIcon8,
        },*/
    ],
  },
  {
    id: "stream5",
    i: 4,
    name: ConstantHelper.StreamingType22,
    bannerImg: ImageHelper.StreamingBanner5,
    url: "/products/vDRM",
    Productslogo: ImageHelper.vDRM,
    ProductTagLine: ConstantHelper.StreamingTagLine5,

    data: [
      {
        id: 1,
        title: "Industry Leading DRMs",
        desc: "Best in class Digital Rights Management (DRM) solution for content security. Multi-DRM (Widewine, Fairplay, and PlayReady).",
        icon: IconHelper.vDRM01,
      },
      {
        id: 2,
        title: "Packaging And License Service",
        desc: "Bring your own packager or use our packaging service. DRM license management.",
        icon: IconHelper.vDRM02,
      },
      {
        id: 3,
        title: "Configurable Policy",
        desc: "Option to configure policies for security level, output protection, persistence, renewal and license duration.",
        icon: IconHelper.vDRM03,
      },
      {
        id: 4,
        title: "Comprehensive Packaging Options",
        desc: "HLS, DASH, CMAF support with multiple subtitles and audio tracks.",
        icon: IconHelper.vDRM04,
      },
      {
        id: 5,
        title: "Adaptive Bitrate Streaming",
        desc: "Connect packaged content with CDN for secure and ABR streaming.",
        icon: IconHelper.vDRM05,
      },
      /*    {
            id: 6,
            title: "High reliability",
            desc: "High performance is guaranteed with multi-region service, simple content workflow, and support.",
            icon: IconHelper.StreamingTypeIcon6,
          },
          {
            id: 7,
            title: "Usage-Based Pricing Plan ",
            desc: "The product has a pay-as-you-use business model, which ensures you give the best solution.",
            icon: IconHelper.StreamingTypeIcon7,
          },
          {
            id: 8,
            title: "Support Services",
            desc: "Provides round-the-clock monitoring, support and maintenance for the content.",
            icon: IconHelper.StreamingTypeIcon8,
          },  */
    ],
    vDRM: [
      {
        id: 1,
        title: "Industry Leading DRMs",
        desc: "Best in class Digital Rights Management (DRM) solution for content security. Multi-DRM (Widewine, Fairplay, and PlayReady).",
        icon: IconHelper.vDRM01,
      },
      {
        id: 2,
        title: "Packaging And License Service",
        desc: "Bring your own packager or use our packaging service. DRM License management.",
        icon: IconHelper.vDRM02,
      },
      {
        id: 3,
        title: "Configurable Policy",
        desc: "Option to configure policies for security level, output protection, persistence, renewal and license duration.",
        icon: IconHelper.vDRM03,
      },
      {
        id: 4,
        title: "Comprehensive Packaging Options",
        desc: "HLS, DASH, CMAF support with multiple subtitles and audio tracks.",
        icon: IconHelper.vDRM04,
      },
      {
        id: 5,
        title: "Adaptive Bitrate Streaming",
        desc: "Connect packaged content with CDN for secure and ABR streaming.",
        icon: IconHelper.vDRM05,
      },
      // {
      //   id: 6,
      //   title: "Enables user activity tracking",
      //   desc: "Backed by user analytics and activity log, you can see where and when users visit each file or video content.",
      //   icon: IconHelper.StreamingTypeIcon6,
      // },
      // {
      //   id: 7,
      //   title: "Incredibly Scalable",
      //   desc: "Are you anticipating a massive increase in your audience? As your service gains popularity, our adaptable Multi DRM licensing automatically scales to accommodate any expansion.",
      //   icon: IconHelper.StreamingTypeIcon7,
      // },
    ],
  },
  {
    id: "stream7",
    i: 6,
    name: ConstantHelper.StreamingType24,
    bannerImg: ImageHelper.StreamingBanner7,
    url: "/products/vSMS",
    Productslogo: ImageHelper.vSMS,
    ProductTagLine: ConstantHelper.StreamingTagLine6,

    data: [
      {
        id: 1,
        title: "Comprehensive User And Identity Management",
        desc: "User signup and login. Multiple profiles per account including kids profile. Email/Mobile Number/Apple ID and Social Media login support.",
        icon: IconHelper.vSMS01,
      },
      {
        id: 2,
        title: "Advance Plan And Price Management",
        desc: "Plans and purchases with country, category, tariff period and quality restrictions. Configurable trial and grace period settings. Multilanguage meta data support.",
        icon: IconHelper.vSMS02,
      },
      {
        id: 3,
        title: "Discount And Coupon Management",
        desc: "Discount management using single or multi use coupons.",
        icon: IconHelper.vSMS03,
      },
      {
        id: 4,
        title: "Device Management",
        desc: "Device registration and management including device limit setting and blacklisting. Device pairing and PIN based login for TV and console device.",
        icon: IconHelper.vSMS04,
      },
      {
        id: 5,
        title: "Business Model",
        desc: "SVOD, TVOD, AVOD subcriptions with trial support. Content purchases and rentals. Billing and invoicing. Automated and assisted renewal flow.",
        icon: IconHelper.vSMS05,
      },
      {
        id: 6,
        title: "Dashboard And Analytics",
        desc: "Detailed Metrics and reports including subscribers, devices and subscription details.",
        icon: IconHelper.vSMS06,
      },
      /*  {
          id: 7,
          title: "Content management & entitlements",
          desc: "Efficiently grants, manage and restricts access to your services.",
          icon: IconHelper.StreamingTypeIcon7,
        },
        {
          id: 8,
          title: "Product Catalog ",
          desc: "Utilizes hyper-personalized goods and promotion capabilities to attract customers. ",
          icon: IconHelper.StreamingTypeIcon8,
        }, */
      /* {
         id: 9,
         title: "Basic billing ",
         desc: "Offers multiple payment options to customers.",
         icon: IconHelper.StreamingTypeIcon8,
       }, */
    ],
    vSMS: [
      {
        id: 1,
        title: "Comprehensive User and Identity Management",
        desc: "User signup and login. Multiple profiles per account including kids profile. Email/Mobile Number/Apple ID and Social Media login support.",
        icon: IconHelper.vSMS01,
      },
      {
        id: 2,
        title: "Advance Plan and Price Management",
        desc: "Plans and purchases with country, category, tariff period and quality restrictions. Configurable trial and grace period settings. Multilanguage meta data support.",
        icon: IconHelper.vSMS02,
      },
      {
        id: 3,
        title: "Discount And Coupon Management",
        desc: "Discount management using single or multi use coupons.",
        icon: IconHelper.vSMS03,
      },
      {
        id: 4,
        title: "Device Management",
        desc: "Device registration and management including device limit setting and blacklisting. Device pairing and PIN based login for TV and console device.",
        icon: IconHelper.vSMS04,
      },
      {
        id: 5,
        title: "Business Model",
        desc: "SVOD, TVOD, AVOD subcriptions with trial support. Content purchases and rentals. Billing and invoicing. Automated and assisted renewal flow.",
        icon: IconHelper.vSMS05,
      },
      {
        id: 6,
        title: "Dashboard And Analytics",
        desc: "Detailed metrics and reports including subscribers, devices and subscription details.",
        icon: IconHelper.vSMS06,
      },
      /*  {
          id: 7,
          title: "Detailed Metrics and reports",
          desc: "We keep you updated about all the important parameters by giving you real-time analytics and metrics. This includes information about your subscribers, devices and subscription details.",
          icon: IconHelper.StreamingTypeIcon8,
        },
        {
          id: 8,
          title: "24/7 maintenance and monitoring",
          desc: "Our services do not stop at providing you with a product. We give you round-the-clock monitoring and support to streamline your business.",
          icon: IconHelper.StreamingTypeIcon8,
        }, */
    ],
  },
  {
    id: "stream6",
    i: 5,
    name: "vCMS",
    bannerImg: ImageHelper.StreamingBanner6,
    url: "/products/vCMS",
    Productslogo: ImageHelper.vCMS,
    ProductTagLine: ConstantHelper.StreamingTagLine8,

    data: [
      {
        id: 1,
        title: "Supports Various Types Of Content  ",
        desc: "Support for movie, tv shows, series, music, album, live channel and live event .  ",
        icon: IconHelper.vCMS01,
      },
      {
        id: 2,
        title: "Rich Extendable Metadata  ",
        desc: "Rich extendable metadata, including title, category, genre, trailers, cast and crew, tags and descriptions. ",
        icon: IconHelper.vCMS02,
      },
      {
        id: 3,
        title: "App CMS",
        desc: "Customize front-end applications with our App CMS. Achieve faster-time-to-market with our configurable App templates.  ",
        icon: IconHelper.vCMS03,
      },
      {
        id: 4,
        title: "Manage Content Availability  ",
        desc: "Set content availability based on geolocation, quality, and end-user device.  ",
        icon: IconHelper.vCMS04,
      },
      {
        id: 5,
        title: "Content Featuring & Promotions  ",
        desc: "Option to feature and promote existing and upcoming content. ",
        icon: IconHelper.vCMS05,
      },
      {
        id: 6,
        title: "Multi-Language Support  ",
        desc: "Multi-language metadata support and optional EPG support for live channels. ",
        icon: IconHelper.vCMS06,
      },
      {
        id: 7,
        title: "Dashboard and Analytics",
        desc: "Detailed metrics and reports, including content views, ratings, likes/dislikes by demography. ",
        icon: IconHelper.VLive07,
      },
      // {
      //   id: 8,
      //   title: "Personal User Playlists",
      //   desc: "Choose whether to add a video to an existing playlist or to create a new one",
      //   icon: IconHelper.StreamingTypeIcon8,
      // },
    ],
    vCMS: [
      {
        id: 1,
        title: "Supports Various Types Of Content  ",
        desc: "Support for movie, tv shows, series, music, album, live channel and live event .  ",
        icon: IconHelper.vCMS01,
      },
      {
        id: 2,
        title: "Rich Extendable Metadata  ",
        desc: "Rich extendable metadata, including title, category, genre, trailers, cast and crew, tags and descriptions. ",
        icon: IconHelper.vCMS02,
      },
      {
        id: 3,
        title: "App CMS",
        desc: "Customize front-end applications with our App CMS. Achieve faster-time-to-market with our configurable App templates.  ",
        icon: IconHelper.vCMS03,
      },
      {
        id: 4,
        title: "Manage Content Availability  ",
        desc: "Set content availability based on geolocation, quality, and end-user device.  ",
        icon: IconHelper.vCMS04,
      },
      {
        id: 5,
        title: "Content Featuring & Promotions  ",
        desc: "Option to feature and promote existing and upcoming content. ",
        icon: IconHelper.vCMS05,
      },
      {
        id: 6,
        title: "Multi-Language Support  ",
        desc: "Multi-language metadata support and optional EPG support for live channels. ",
        icon: IconHelper.vCMS06,
      },
      {
        id: 7,
        title: "Dashboard And Analytics",
        desc: "Detailed metrics and reports, including content views, ratings, likes/dislikes by demography. ",
        icon: IconHelper.VLive07,
      },

      // {
      //   id: 8,
      //   title: "Custom Thumbnails  ",
      //   desc: "Utilizing a vCMS software that allows thumbnails to be displayed for videos can greatly enhance the interaction between a video and its viewers.",
      //   icon: IconHelper.vCMS08,
      // },
    ],
  },
];

export default StreamingTypeData;
