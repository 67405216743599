// Helper
import ColorHelper from "../../Helpers/ColorHelper";
import FontHelper from "../../Helpers/FontHelper";

const CrossPlatormSectionStyle = {
  container: {
    px: { xs: 0, md: 19 },
    pt: 8,
    display: "none",
    justifyContent: "space-between",
    alignItems: { xs: "flex-start", md: "center" },
    flexDirection: { xs: "column", md: "row" },
    maxWidth: "100%",
    backgroundColor: ColorHelper.white2,
    maxHeight: { xs: "min-content", md: "560px" },
    overflow: "hidden",
  },
  content: {
    maxWidth: { xs: "100%", md: "60%" },
    minWidth: { xs: "100%", md: "60%" },
    px: { xs: 4, md: 0 },
    zIndex: 2,
    display: 'none',
  },
  CrossPFTitle: {
    fontSize: 36,
    fontFamily: FontHelper.FontSemiBold,
    color: ColorHelper.black,
    maxWidth: "100%",
    lineHeight: 1.3,
    mb: 3,
  },
  CrossPFCaption: {
    fontSize: 16,
    color: ColorHelper.grey,
    maxWidth: "100%",
    my: 4,
  },
  FilledButtonSpace: {
    ml: -1,
  },
  media: {
    maxWidth: "100%",
    minWidth: { xs: "100%", md: "100%" },
    position: "relative",
    minHeight: "500px",
    left: { xs: 10, md: 0, lg: 0 },
    mt: { xs: 5, md: 0 },
    zIndex: 1,
  },
  MediaShape: {
    position: "absolute",
    bottom: 0,
    left: -60,
    zIndex: 0,
    maxWidth: "100%",
  },
  MediaImage: {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 1,
    maxWidth: "100%",
  },
};

export default CrossPlatormSectionStyle;
