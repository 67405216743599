import React from "react";

const HLTvSMS03 = () => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.0508 4.31196H35.2183C40.1805 4.31169 44.2031 8.33427 44.2031 13.2963V28.0313C44.2031 32.9932 40.1806 37.0156 35.2187 37.0156H10.7794C5.81846 37.0156 1.79688 32.994 1.79688 28.0331V13.2969C1.79688 8.33499 5.81936 4.3125 10.7812 4.3125H11.8594"
        stroke="#457ABD"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M31.625 44.2031H14.375"
        stroke="#457ABD"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M35.0391 26.5352C34.2128 26.5352 33.543 27.205 33.543 28.0313C33.543 28.8575 34.2128 29.5273 35.0391 29.5273C35.8653 29.5273 36.5352 28.8575 36.5352 28.0313C36.5352 27.205 35.8653 26.5352 35.0391 26.5352ZM26.9531 26.5352C26.1269 26.5352 25.457 27.205 25.457 28.0313C25.457 28.8575 26.1269 29.5273 26.9531 29.5273C27.7794 29.5273 28.4492 28.8575 28.4492 28.0313C28.4492 27.205 27.7794 26.5352 26.9531 26.5352ZM10.7812 26.5352C9.95499 26.5352 9.28516 27.205 9.28516 28.0313C9.28516 28.8575 9.95499 29.5273 10.7812 29.5273C11.6075 29.5273 12.2773 28.8575 12.2773 28.0313C12.2773 27.205 11.6075 26.5352 10.7812 26.5352ZM18.8672 29.5273C19.6934 29.5273 20.3633 28.8575 20.3633 28.0313C20.3633 27.205 19.6934 26.5352 18.8672 26.5352C18.0409 26.5352 17.3711 27.205 17.3711 28.0313C17.3711 28.8575 18.0409 29.5273 18.8672 29.5273Z"
        fill="#457ABD"
        stroke="#457ABD"
        stroke-width="1.5"
      />
      <path
        d="M16.2617 20.9336C16.2617 17.5595 19.0229 14.8242 22.429 14.8242H23.571C26.9771 14.8242 29.7383 17.5595 29.7383 20.9336"
        stroke="#457ABD"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M26.5115 5.39063C26.5115 7.37536 24.9025 8.98438 22.9178 8.98438C20.933 8.98438 19.324 7.37536 19.324 5.39063C19.324 3.40589 20.933 1.79688 22.9178 1.79688C24.9025 1.79688 26.5115 3.40589 26.5115 5.39063Z"
        stroke="#457ABD"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default HLTvSMS03;
