import React from "react";
import { Typography, ThemeProvider } from "@mui/material";
import { Box, styled } from "@mui/system";
import { Link } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";

// Heler
import ConstantHelper from "../../../Helpers/ConstantHelper";
import IconHelper from "../../../Helpers/IconHelper";
import ColorHelper from "../../../Helpers/ColorHelper";
import FontHelper from "../../../Helpers/FontHelper";

// Custom Styles
import TopStripeStyle from "./TopStripeStyle";

const TopStripe = () => {
  return (
    <>
      <Box sx={TopStripeStyle.topStrip}>
        <Box>
          <Box sx={TopStripeStyle.flexit}>
            <Typography
              sx={TopStripeStyle.topStriptextLight}
              variant="h6"
              component="h2"
            >
              {ConstantHelper.WhatsappOrCallUs}
            </Typography>
            <Box
              onClick={() =>
                window.location.replace(`tel:${ConstantHelper.ContactNo}`)
              }
              sx={{ width: '14px', display: 'flex', alignItems: 'center', cursor: 'pointer', fontFamily: FontHelper.FontBold }}>
              <IconHelper.Call color={ColorHelper.white} />
            </Box>
            <Typography
              sx={TopStripeStyle.topStriptext}
              variant="h6"
              component="h2"
              onClick={() =>
                window.location.replace(`tel:${ConstantHelper.ContactNo}`)
              }
            >
              {ConstantHelper.ContactNo}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Box sx={TopStripeStyle.flexit}>
            <Typography
              sx={TopStripeStyle.topStriptextLight}
              variant="h6"
              component="h2"
            >
              {ConstantHelper.DropMail}
            </Typography>
            <Box
              onClick={() =>
                window.location.replace(`mailto:${ConstantHelper.mainId}`)
              }
              sx={{ width: '18px', display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
              <IconHelper.Mail color={ColorHelper.white} />
            </Box>
            <Typography
              sx={TopStripeStyle.topStriptext}
              variant="h6"
              component="h2"
              onClick={() =>
                window.location.replace(`mailto:${ConstantHelper.mainId}`)
              }
            >
              {ConstantHelper.mainId}
            </Typography>
          </Box>
        </Box>
        {/* <Box>
          <Box sx={TopStripeStyle.flexit}>
            <Typography
              sx={TopStripeStyle.topStriptextLight}
              variant="h6"
              component="h2"
            >
              {ConstantHelper.something}
            </Typography>
            <Box
              sx={{ ...TopStripeStyle.LinkStyle, width: '18px', display: 'flex', alignItems: 'center' }}>
              <IconHelper.Plus color={ColorHelper.white} />
            </Box>
          </Box>
        </Box> */}
      </Box>
    </>
  );
};

export default TopStripe;
