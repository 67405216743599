import IconHelper from "../IconHelper";

const HighlightsData = [
  {
    id: "hl1",
    hightlights: [
      {
        id: "high01",
        icon: <IconHelper.HLTvLive01 />,
        highlightTitle: "End-End OTT SaaS suite",
        highlightDescription:
          "vLive offers an End-to-end fully managed OTT platform that includes Content management, subscriber and subscription management, monetization, content processing/protection/delivery and analytics.",
      },
      {
        id: "high02",
        icon: <IconHelper.HLTvLive02 />,
        highlightTitle: "Automated content ingestion and distribution",
        highlightDescription:
          "Create exceptional user experiences with our efficient content management service. We structure, curate, promote, and enrich the content to include more information.",
      },
      {
        id: "high03",
        icon: <IconHelper.HLTvLive03 />,
        highlightTitle: "Availability across multiple devices and platforms",
        highlightDescription:
          "We enable live or on-demand audio and video content availability on a pay-per-view or subscription basis across mobile, tablet, smart TV, web console devices, etc.",
      },
      {
        id: "high04",
        icon: <IconHelper.HLTvLive04 />,
        highlightTitle: "Web portal, dashboard, and detailed analytics",
        highlightDescription:
          "Intuitive admin console to manage system functionalities. Role based access. Dashboard and analytics to track the user and usage of platform.",
      },
      {
        id: "high05",
        icon: <IconHelper.HLTvLive05 />,
        highlightTitle: "Scalable and Reliable Infrastructure fully managed",
        highlightDescription:
          "Highly scalable and secure cloud infrastructure. Fully managed service with 24x7 monitoring and support for reliable operation.",
      },
      {
        id: "high06",
        icon: <IconHelper.HLTvLive06 />,
        highlightTitle: "Professional Services",
        highlightDescription:
          "Need expert advice on how to start OTT business or how to monetize the content? Need Apps with custom UI/UX? our team of OTT experts will help you.",
      },
    ],
  },
  {
    id: "hl2",
    hightlights: [
      {
        id: "high01",
        icon: <IconHelper.HLTSounds01 />,
        highlightTitle: "White label Audio Platform",
        highlightDescription:
          "Platform is offered as white-labeled solution. Use your  logo and branding and make it your own service. Apps on Android, iOS and Web.",
      },
      {
        id: "high02",
        icon: <IconHelper.HLTSounds02 />,
        highlightTitle: "Customizable",
        highlightDescription:
          "Enhance the user experience with Sounds, an ultra-engaging audio streaming platform with specialized features and support for various business models.",
      },
      {
        id: "high03",
        icon: <IconHelper.HLTSounds03 />,
        highlightTitle: "Audio CMS",
        highlightDescription:
          "Gain control over every piece of content with a secure and integrated audio library management system. Rest assured that your audio streaming services are efficient.",
      },
      // {
      //   id: "high04",
      //   icon: <IconHelper.Sounds2 />,
      //   highlightTitle: "Content Delivery Network",
      //   highlightDescription:
      //     "Powered by a globally distributed network, Sounds streams audio files to subscribers from the fastest server to their location and helps increase stream delivery.",
      // },
      {
        id: "high05",
        icon: <IconHelper.HLTSounds04 />,
        highlightTitle: "Queuing & Playlist Plug-In",
        highlightDescription:
          "Option to provide listeners with a flexible option to create customized playlists based on choices of genre, moods, artists, bands, etc. Highlight the tracks on a various parameters in playlists. And also helps in compiling queuing precisely.  ",
      },

      /*  {
          id: "high06",
          icon: <IconHelper.HighlightIcon6  />,
          highlightTitle: "Industry-standard Multi-DRM",
          highlightDescription:
            "We provide Adaptive Bit Rate (ABR) streaming in Full HD and SD based on bandwidth and plan. You also get enhanced piracy protection with industry-standard Multi-DRM.  ",
        }, */
    ],
  },
  {
    id: "hl3",
    hightlights: [
      {
        id: "high01",
        icon: <IconHelper.HLTvLiteMOOC01 />,
        highlightTitle: "Multiple-screen platform",
        highlightDescription:
          "Let your viewer watch your content and learn on any device - Mobile phones, laptops, TV’s etc. Separate Apps for Students and Instructors.",
      },
      {
        id: "high02",
        icon: <IconHelper.HLTvLiteMOOC02 />,
        highlightTitle: "Offer multimedia support",
        highlightDescription:
          "With our eLearning solution, we offer multimedia support like adding quizzes, assignments, PDFs, media files and so much more.",
      },
      {
        id: "high03",
        icon: <IconHelper.HLTvLiteMOOC03 />,
        highlightTitle: "One-on-one coaching  or Mass audience",
        highlightDescription:
          "Create a competent video learning experience through one-on-one teaching using vLite MOOC. Personalize your training, courses, and lessons or reach to masses with broadcast and on-demand courses.",
      },
      {
        id: "high04",
        icon: <IconHelper.HLTvLiteMOOC04 />,
        highlightTitle: "Interactive Classroom",
        highlightDescription:
          "Make learning a wholesome experience. Reach out to students and meet their demands for virtual communication using tools like interactive whiteboards, breakout rooms, and polls.",
      },
      {
        id: "high05",
        icon: <IconHelper.HLTvLiteMOOC05 />,
        highlightTitle: "Mixed Learning",
        highlightDescription:
          "Universities and blended learning education institutions can explore the trend of mixed-mode environments to personalize better and take control of streaming various study resources.",
      },
      {
        id: "high06",
        icon: <IconHelper.HLTvLiteMOOC06 />,
        highlightTitle: "Scalable and Reliable Infrastructure.",
        highlightDescription:
          "Highly scalable and secure cloud infrastructure. Fully managed service with 24x7 monitoring and support for reliable operation.",
      },
      /*  {
          id: "high07",
          icon: <IconHelper.HighlightIcon6  />,
          highlightTitle: "Option to monetize",
          highlightDescription:
            "vLite MOOC provides an opportunity to monetize your passion! That’s right! This robust online teaching and learning platform allows you to monetise your passion for teaching.   " ,
        },
        {
          id: "high08",
          icon: <IconHelper.HighlightIcon6  />,
          highlightTitle: "Managing Metadata  ",
          highlightDescription:
            "Current tags and definitions have shown to function well with content. The search engine on vLite MOOC is configured to show results that have been determined using auto-suggest and are filled with names, genes, and other relevant terms. " ,
        }, */
    ],
  },
  {
    id: "hl4",
    hightlights: [
      {
        id: "high01",
        icon: <IconHelper.HLTvApps01 />,
        highlightTitle: "App store with rich features   ",
        highlightDescription:
          "vApps is an enhanced platform that helps you build and develop your ideal app.",
      },
      {
        id: "high02",
        icon: <IconHelper.HLTvApps02 />,
        highlightTitle: "Smart Recommendations",
        highlightDescription:
          "Our AI-based recommendations help your users to suggest the proper apps by considering your search history and your interests.",
      },
      {
        id: "high03",
        icon: <IconHelper.HLTvApps03 />,
        highlightTitle: "Advanced features",
        highlightDescription:
          "The platform provides accurate app control with remote wipe, force install, and force update options.",
      },
      {
        id: "high04",
        icon: <IconHelper.HLTvApps04 />,
        highlightTitle: "Scalable and Fully Managed",
        highlightDescription:
          "We provide highly Scalable and Reliable App Store built on AWS cloud and lambda, with 24*7 maintenance and monitoring.",
      },
      {
        id: "high05",
        icon: <IconHelper.HLTvApps05 />,
        highlightTitle: "Web portal & Detailed analytics",
        highlightDescription:
          "Dashboard and analytics with Detailed metering, dashboard, metrics & reports.",
      },
      /*  {
          id: "high06",
          icon: <IconHelper.HighlightIcon6  />,
          highlightTitle: "Scalability factor  ",
          highlightDescription:
            "The platform helps your app scale to meet the growing audience as it becomes popular and widens its horizons.   ",
        }, */
    ],
  },
  {
    id: "hl5",
    hightlights: [
      {
        id: "high01",
        icon: <IconHelper.HLTvDRM01 />,
        highlightTitle: "Multi DRM service",
        highlightDescription:
          "We provide multi-DRM packaging and license services that support industries leading Google Widevine, Apple FairPlay and Microsoft Playready DRMs.",
      },
      {
        id: "high02",
        icon: <IconHelper.HLTvDRM02 />,
        highlightTitle: "Security and Privacy",
        highlightDescription:
          "Bring your own S3 buckets for added security and privacy.",
      },
      {
        id: "high03",
        icon: <IconHelper.HLTvDRM03 />,
        highlightTitle: "REST API’s",
        highlightDescription:
          "Fully secure REST API’s to automate your DRM workflow.",
      },
      {
        id: "high04",
        icon: <IconHelper.HLTvDRM04 />,
        highlightTitle: "Scalable, Secure and Managed Infrastructure",
        highlightDescription:
          "Built on highly scalable and secure cloud infrastructure services, AWS cloud and lambda, with 24*7 monitoring and support.",
      },
      /* {
         id: "high05",
         icon: <IconHelper.HighlightIcon6  />,
         highlightTitle: "High reliability",
         highlightDescription:
           "It ensures high Performance and reliability through multi-region service, effortless content workflow, VOD and live stream support.",
       },
       {
         id: "high06",
         icon: <IconHelper.HighlightIcon1 />,
         highlightTitle: "Usage-Based Pricing Plan",
         highlightDescription:
           "The product follows a pay-as-you-use model, which ensures that you select the right one for your business and your customers.",
       }, */
    ],
  },
  {
    id: "hl6",
    hightlights: [
      {
        id: "high01",
        icon: <IconHelper.VCMSHighlight01 />,
        highlightTitle: "Automated content ingestion and distribution  ",
        highlightDescription:
          "Create exceptional user experiences with our efficient CMS. We structure, curate, promote, and enrich the content to include more information. ",
      },
      {
        id: "high02",
        icon: <IconHelper.VCMSHighlight02 />,
        highlightTitle: "Video CMS",
        highlightDescription:
          "Get efficient video streaming services. Gain control over every piece of content with a secure and integrated video library management system.   ",
      },
      {
        id: "high03",
        icon: <IconHelper.VCMSHighlight03 />,
        highlightTitle: "Bulk uploading   ",
        highlightDescription:
          "If you produce a lot of content, bulk uploading is essential. The bulk upload feature of vCMS saves a lot of time. ",
      },
      // {
      //   id: "high04",
      //   icon: <IconHelper.VCMSHighlight04 />,
      //   highlightTitle: "Personal User Playlists ",
      //   highlightDescription:
      //     "The user can customize his playlist with this feature. Users can choose whether to add a video to an existing playlist or to create a new one, when they click the Add to Playlist button. ",
      // },
      {
        id: "high05",
        icon: <IconHelper.VCMSHighlight04 />,
        highlightTitle: "Video security and privacy   ",
        highlightDescription:
          "It's crucial to maintain the privacy and security of your content. Mobiotics incorporates essential features like DRM for maximum content security. ",
      },
      {
        id: "high06",
        icon: <IconHelper.VCMSHighlight05 />,
        highlightTitle: "Fully-managed scalable and reliable infrastructure ",
        highlightDescription:
          "Highly scalable and secure cloud infrastructure services powered by the latest technologies of AWS cloud and Lambda, with 24*7 monitoring and support.  ",
      },
    ],
  },
  {
    id: "hl7",
    hightlights: [
      {
        id: "high01",
        icon: <IconHelper.HLTvSMS01 />,
        highlightTitle: "SAAS solution",
        highlightDescription:
          "Comprehensive User and Subscription management service offered as SAAS.",
      },
      {
        id: "high02",
        icon: <IconHelper.HLTvSMS02 />,
        highlightTitle: "Plans & Purchases",
        highlightDescription:
          "Support for both Subscription Plans and purchases with country, content category tariff period and quality  restrictions. ",
      },
      {
        id: "high03",
        icon: <IconHelper.HLTvSMS03 />,
        highlightTitle: " Multiple Login/Signup Methods",
        highlightDescription:
          "Support for multiple user registration and login methods - email-based, mobile no, social login, PIN-based login for TV devices etc.",
      },
      {
        id: "high04",
        icon: <IconHelper.HLTvSMS04 />,
        highlightTitle: "Web portal, dashboard, and detailed analytics",
        highlightDescription:
          "Intuitive admin console to manage system functionalities. Role based access. Dashboard and analytics to track the user and usage of platform.",
      },
      {
        id: "high05",
        icon: <IconHelper.HLTvSMS05 />,
        highlightTitle: "Scalable and Reliable Infrastructure – fully managed",
        highlightDescription:
          "Highly scalable and secure cloud infrastructure. Fully managed service with 24x7 monitoring and support for reliable operation. ",
      },
      //  {
      //     id: "high06",
      //     icon: <IconHelper.HighlightIcon6  />,
      //     highlightTitle: "Basic Billing ",
      //     highlightDescription:
      //       "Thanks to access controls and multiple currency support, monetization methods are simpler now. We offer numerous payment methods to our customers.",
      //   },
    ],
  },
  {
    id: "hl8",
    hightlights: [
      {
        id: "high01",
        icon: <IconHelper.HighlightIcon1 />,
        highlightTitle: "1 Feugiat ve molestie.",
        highlightDescription:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet nisl neque, nisl blandit. Sed porta eget eu, sed. Vel ornare sagittis feugiat.",
      },
      {
        id: "high02",
        icon: <IconHelper.HighlightIcon2 />,
        highlightTitle: "Feugiat vel molestie.",
        highlightDescription:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet nisl neque, nisl blandit. Sed porta eget eu, sed. Vel ornare sagittis feugiat.",
      },
      {
        id: "high03",
        icon: <IconHelper.HighlightIcon3 />,
        highlightTitle: "Feugiat vel molestie.",
        highlightDescription:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet nisl neque, nisl blandit. Sed porta eget eu, sed. Vel ornare sagittis feugiat.",
      },
      {
        id: "high04",
        icon: <IconHelper.HighlightIcon4 />,
        highlightTitle: "Feugiat vel molestie.",
        highlightDescription:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet nisl neque, nisl blandit. Sed porta eget eu, sed. Vel ornare sagittis feugiat.",
      },
      {
        id: "high05",
        icon: <IconHelper.HighlightIcon5 />,
        highlightTitle: "Feugiat vel molestie.",
        highlightDescription:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet nisl neque, nisl blandit. Sed porta eget eu, sed. Vel ornare sagittis feugiat.",
      },
      {
        id: "high06",
        icon: <IconHelper.HighlightIcon6 />,
        highlightTitle: "Feugiat vel molestie.",
        highlightDescription:
          "rem ipsum dolor sit amet, consectetur adipiscing elit. Amet nisl neque, nisl blandit. Sed porta eget eu,Lo sed. Vel ornare sagittis feugiat.",
      },
    ],
  },
  {
    id: "hl9",
    hightlights: [
      {
        id: "high01",
        icon: <IconHelper.HighlightIcon1 />,
        highlightTitle: "1 Feugiat vel molestie.",
        highlightDescription:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet nisl neque, nisl blandit. Sed porta eget eu, sed. Vel ornare sagittis feugiat.",
      },
      {
        id: "high02",
        icon: <IconHelper.HighlightIcon2 />,
        highlightTitle: "Feugiat vel molestie.",
        highlightDescription:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet nisl neque, nisl blandit. Sed porta eget eu, sed. Vel ornare sagittis feugiat.",
      },
      {
        id: "high03",
        icon: <IconHelper.HighlightIcon3 />,
        highlightTitle: "Feugiat vel molestie.",
        highlightDescription:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet nisl neque, nisl blandit. Sed porta eget eu, sed. Vel ornare sagittis feugiat.",
      },
      {
        id: "high04",
        icon: <IconHelper.HighlightIcon4 />,
        highlightTitle: "Feugiat vel molestie.",
        highlightDescription:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet nisl neque, nisl blandit. Sed porta eget eu, sed. Vel ornare sagittis feugiat.",
      },
      {
        id: "high05",
        icon: <IconHelper.HighlightIcon5 />,
        highlightTitle: "Feugiat vel molestie.",
        highlightDescription:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet nisl neque, nisl blandit. Sed porta eget eu, sed. Vel ornare sagittis feugiat.",
      },
      {
        id: "high06",
        icon: <IconHelper.HighlightIcon6 />,
        highlightTitle: "Feugiat vel molestie.",
        highlightDescription:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet nisl neque, nisl blandit. Sed porta eget eu, sed. Vel ornare sagittis feugiat.",
      },
    ],
  },
];

export default HighlightsData;
