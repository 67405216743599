import * as React from "react";
import ColorHelper from "../../Helpers/ColorHelper";

const OTTConsulting = ({ size = 46, color, ...props }) => (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="48" height="48" rx="24" fill="#FFF3F5"/>
    <path d="M28 28C28.7956 28 29.5587 28.3161 30.1213 28.8787C30.6839 29.4413 31 30.2044 31 31C31 31.7956 30.6839 32.5587 30.1213 33.1213C29.5587 33.6839 28.7956 34 28 34C27.2044 34 26.4413 33.6839 25.8787 33.1213C25.3161 32.5587 25 31.7956 25 31C25 30.2044 25.3161 29.4413 25.8787 28.8787C26.4413 28.3161 27.2044 28 28 28V28ZM18 24C20.21 24 22 25.79 22 28C22 30.21 20.21 32 18 32C15.79 32 14 30.21 14 28C14 25.79 15.79 24 18 24ZM28 30C27.7348 30 27.4804 30.1054 27.2929 30.2929C27.1054 30.4804 27 30.7348 27 31C27 31.2652 27.1054 31.5196 27.2929 31.7071C27.4804 31.8946 27.7348 32 28 32C28.2652 32 28.5196 31.8946 28.7071 31.7071C28.8946 31.5196 29 31.2652 29 31C29 30.7348 28.8946 30.4804 28.7071 30.2929C28.5196 30.1054 28.2652 30 28 30ZM18 26C17.7337 25.994 17.4688 26.0412 17.221 26.139C16.9731 26.2367 16.7473 26.383 16.5568 26.5692C16.3663 26.7554 16.2149 26.9778 16.1115 27.2233C16.0081 27.4689 15.9549 27.7326 15.9548 27.999C15.9547 28.2654 16.0079 28.5291 16.1111 28.7747C16.2144 29.0203 16.3657 29.2428 16.5561 29.4291C16.7465 29.6154 16.9723 29.7618 17.22 29.8596C17.4678 29.9575 17.7327 30.0049 17.999 29.999C18.5216 29.9874 19.0188 29.7718 19.3844 29.3982C19.7499 29.0245 19.9547 28.5227 19.9548 28C19.9549 27.4773 19.7504 26.9753 19.3851 26.6015C19.0197 26.2277 18.5226 26.0118 18 26V26ZM26.5 14C27.9587 14 29.3576 14.5795 30.3891 15.6109C31.4205 16.6424 32 18.0413 32 19.5C32 20.9587 31.4205 22.3576 30.3891 23.3891C29.3576 24.4205 27.9587 25 26.5 25C25.0413 25 23.6424 24.4205 22.6109 23.3891C21.5795 22.3576 21 20.9587 21 19.5C21 18.0413 21.5795 16.6424 22.6109 15.6109C23.6424 14.5795 25.0413 14 26.5 14V14ZM26.5 16C25.5717 16 24.6815 16.3687 24.0251 17.0251C23.3687 17.6815 23 18.5717 23 19.5C23 20.4283 23.3687 21.3185 24.0251 21.9749C24.6815 22.6313 25.5717 23 26.5 23C27.4283 23 28.3185 22.6313 28.9749 21.9749C29.6313 21.3185 30 20.4283 30 19.5C30 18.5717 29.6313 17.6815 28.9749 17.0251C28.3185 16.3687 27.4283 16 26.5 16Z" fill="#DA1A5D"/>
    </svg>
    

);

export default OTTConsulting;