import React from "react";
import ColorHelper from "../../Helpers/ColorHelper";

const Call = (props) => {
    const { color } = props;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="61" height="20" viewBox="0 0 61 60" fill="none">
            <path d="M57.8 43.9462L51.6 38.9462C49.5 37.2462 46.5 37.1452 44.3 38.7462L38.4 43.0462C37.6 43.6462 36.501 43.5462 35.8 42.8462L26.1 34.0462L17.2 24.2462C16.5 23.5462 16.4 22.4462 17 21.6462L21.3 15.7462C22.9 13.5462 22.8 10.5462 21.1 8.44616L16.1 2.24616C13.9 -0.553836 9.7 -0.753836 7.2 1.74616L1.8 7.14616C0.600001 8.34616 0 10.0462 0 11.6462C0.7 24.3462 6.5 36.4462 15 44.9462C23.5 53.4462 35.5 59.2462 48.3 59.9462C50 60.0462 51.6 59.3452 52.8 58.1452L58.2 52.7462C60.9 50.3462 60.6 46.1462 57.8 43.9462Z" fill={color} />
        </svg>
    );
};

export default Call;
