import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

// Custom Comps
import FilledButton from "../Ui-Components/Buttons/FilledButton/FilledButton";

// Helper
import ColorHelper from "../../Helpers/ColorHelper";
import ConstantHelper from "../../Helpers/ConstantHelper";
import ImageHelper from "../../Helpers/ImageHelper";

// Custom Styles
import CrossPlatormSectionStyle from "./CrossPlatormSectionStyle";
import { memo } from "react";

const CrossPlatormSection = memo(function CrossPlatormSection() {
  return (
    <Box sx={CrossPlatormSectionStyle.container}>
      <Box sx={CrossPlatormSectionStyle.content}>
        <Typography sx={CrossPlatormSectionStyle.CrossPFTitle}>
          {ConstantHelper.CrossPFTitle}
        </Typography>
        <Typography sx={CrossPlatormSectionStyle.CrossPFCaption}>
          {ConstantHelper.CrossPFCaption}
        </Typography>

        <Box sx={CrossPlatormSectionStyle.FilledButtonSpace}>
          <FilledButton
            btnText={ConstantHelper.navBtn2}
            color={ColorHelper.white}
            bgColor={ColorHelper.PriamryColor}
          />
        </Box>
      </Box>

      <Box sx={CrossPlatormSectionStyle.media}>
        <img
          src={ImageHelper.Blob}
          style={CrossPlatormSectionStyle.MediaShape}
          alt="Blob Shape"
        />
        <img
          src={ImageHelper.iPhone12Promockup}
          style={CrossPlatormSectionStyle.MediaImage}
          alt="iphone Frame"
        />
      </Box>
    </Box>
  );
});

export default CrossPlatormSection;
