import React from "react";

const Plus = (props) => {
  const { color } = props;
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2.25C6.624 2.25 2.25 6.624 2.25 12C2.25 17.376 6.624 21.75 12 21.75C17.376 21.75 21.75 17.376 21.75 12C21.75 6.624 17.376 2.25 12 2.25ZM16.5 12.75H12.75V16.5H11.25V12.75H7.5V11.25H11.25V7.5H12.75V11.25H16.5V12.75Z"
        fill={color}
      />
    </svg>
  );
};

export default Plus;
