// Helper
import ColorHelper from "../../Helpers/ColorHelper";
import FontHelper from "../../Helpers/FontHelper";

const FooterSectionStyle = {
  FooterCard: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: { xs: "column", md: "column", lg: "row" },
    mx: { xs: 4, md: 19 },
    py: 5,
    borderBottom: `1px solid ${ColorHelper.grey2}`,
    // mb: 7,
    mt: { xs: 4, md: 0 },
  },
  textContainer: {
    textAlign: { xs: "center", md: "unset" },
    mb: { xs: 0, md: 5, lg: 0 },
  },
  cardTitle: {
    color: ColorHelper.black,
    fontFamily: FontHelper.FontSemiBold,
    fontWeight: 600,
    fontSize: "26px",
    fontSize: { xs: '1rem', md: '1.5rem' },
    lineHeight: { xs: 1.2, md: 1.2 },
    mb: { xs: 3, md: 0 },
  },
  cardCaption: {
    color: ColorHelper.grey,
    fontSize: "20px",
    fontWeight: 400,
    my: { xs: 2, md: 0 },
  },
  btnContainer: {
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: {xs: 'space-around', md: 'center'},
    // flexDirection: {xs: 'column', md: 'row'},
    // minHeight: {xs : '150px', md: 'auto'}
  },
  footerMain: {
    // mx: { xs: 5, md: 19 },
    py:{xs:5,md:5},
    display: "flex",
    justifyContent: "space-between",
    backgroundColor:"#457abd",
    px: 18,
    alignItems: { xs: "center", md: "flex-start" },
    flexDirection: { xs: "column", md: "column", lg: "row" },
    textAlign: { xs: "center", md: "left" },
  },

  imageBackground:{
    backgroundColor: 'white',
    width: '50%',
    padding: '0.5rem',
    borderRadius: '10px',
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
  },
  FootershortDesc: {
    fontSize: 16,
    fontWeight: 400,
    fontFamily: FontHelper.FontRegular,
    color: ColorHelper.white,
    maxWidth: "100%",
    my: 1,
    mr: { xs: 0, md: 0, lg: 5 },
    mb: { xs: 'unset', md: 5, lg: 'unset' },
  },
  MenuListingContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    flexWrap: "wrap",
    minWidth: { xs: "65%", md: "100%", lg: "55%" },
  },
  footerHead: {
    ml: { xs: 0, md: 1 },
    mr: { xs: 0, md: 15 }
  },
  MenuListing: {
    minWidth: { xs: "100%", md: "25%", lg: "auto" },
    textAlign: { md: "left", lg: "left", xs: "left" },
    mt: { xs: 4, md: 0 },
    // ml: { xs: 0, md: 15 },
  },
  MenuTitle: {
    fontSize: 18,
    fontFamily: "Extra-Bold",
    color: ColorHelper.white,

    textAlign: { xs: 'center', md: 'left' },
    mb: 1,
  },
  Link: {
    display: 'flex',
    // justifyContent: {xs: 'center', md: 'flex-start'}, 
    alignItems: 'center',
    minWidth: 'max-content',
    textDecoration: "none",
    margin: { xs: 'auto', md: 'unset' },
    color: ColorHelper.white,
    "&hover": {
      color: ColorHelper.PriamryColor,
    },
  },
  MenuLink: {
    fontSize: 16,
    margin: { xs: 'auto', md: 'unset' },
    color: ColorHelper.white,
    fontFamily: FontHelper.FontRegular,
    my: 0.2,
    width: { xs: "auto", md: "max-content" },
    wordWrap: 'break-word',
    cursor: "pointer",
    '&:hover': {
      color: ColorHelper.PriamryColor,
    }
  },
  MenuLinkBadge: {
    display: { xs: 'none', md: 'block' },
    fontSize: 16,
    fontFamily: FontHelper.FontRegular,
    my: 1.5,
    color: ColorHelper.green,
    backgroundColor: ColorHelper.lightGreen,
    maxWidth: 'fit-content',
    padding: '0.2rem 0.6rem',
    borderRadius: '25px',
    ml: 2,
  },
  creditsContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: { xs: "column", md: "row" },
    px:4,
    // mx: { xs: 1, md: 19 },
    mb: { xs: 0, md: 0 },
    backgroundColor:"#457abd",
    display:"flex",
    justifyContent:"center",
  },
  copyrightWrapper: {
    my: { xs: 2, md: 1 },
  },
  Copyright: {
    textAlign: { xs: "center", md: "center" },
    color: ColorHelper.white,
    fontSize: "1rem",
  },
  socialIconsContainer: {
    display: "flex",
    // justifyContent: "space-between",
    alignItems: "center",
    mt: {xs:3,md:3}
  },
  FooterIcons: {
    mx: 2,
    cursor: 'pointer',
  },
};

export default FooterSectionStyle;
