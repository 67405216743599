import React from "react";
import ColorHelper from "../../Helpers/ColorHelper";

const HighlightIcon2 = () => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.83301 38.3333H3.85217"
        stroke={ColorHelper.BgBlue}
        stroke-width="3.83333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.83301 30.8584C5.67472 31.234 7.36531 32.1429 8.69441 33.472C10.0235 34.8011 10.9324 36.4917 11.308 38.3334"
        stroke={ColorHelper.BgBlue}
        stroke-width="3.83333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.83301 23.0959C7.72535 23.5289 11.3545 25.2734 14.1238 28.0426C16.8931 30.8119 18.6376 34.4411 19.0705 38.3334"
        stroke={ColorHelper.BgBlue}
        stroke-width="3.83333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.83301 15.3334V11.5001C3.83301 10.4834 4.23688 9.50839 4.95576 8.78951C5.67465 8.07062 6.64968 7.66675 7.66634 7.66675H38.333C39.3497 7.66675 40.3247 8.07062 41.0436 8.78951C41.7625 9.50839 42.1663 10.4834 42.1663 11.5001V34.5001C42.1663 35.5167 41.7625 36.4918 41.0436 37.2107C40.3247 37.9295 39.3497 38.3334 38.333 38.3334H26.833"
        stroke={ColorHelper.BgBlue}
        stroke-width="3.83333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default HighlightIcon2;
