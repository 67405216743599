import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
import { styled, alpha } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import useMediaQuery from "@mui/material/useMediaQuery";
import { NavLink, Link, Router, useNavigate } from "react-router-dom";
import CustomDropDown from "./CustomDropDown";
import Contact from "../../../Pages/Contact";

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';





// Custom Components
import BorderedButton from "../../Ui-Components/Buttons/BorderedButton/BorderedButton";
import FilledButton from "../../Ui-Components/Buttons/FilledButton/FilledButton";
import FilledButton1 from "../../Ui-Components/Buttons/FilledButton/FilledButton1";
// Custom
import "./CustomNavbarStyle.css";
import CustomNavbarStyle from "./CustomNavbarStyle";

// Helpers
import ConstantHelper from "../../../Helpers/ConstantHelper";
import ImageHelper from "../../../Helpers/ImageHelper";
import ColorHelper from "../../../Helpers/ColorHelper";
import IconHelper from "../../../Helpers/IconHelper";

// Data
import NavLinkData from "./NavLinkData";
import TalkToUsModaldrp from "../../HeroSection/TalkToUsModal/TalkToUsModaldrp";

//import MobileMenu from "./MobileMenu";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.black[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const CustomNavbarOld = (props) => {
  const { NavHeaderIdprops } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    setExpanded(false);
    setAnchorElNav(null);
    console.log(window.location.state)
  }, [window.location.pathname, window.location.search]);

  const [openCFModal, setOpenCFModal] = useState(false);  // Talk to us modal state

  const [anchorElNav, setAnchorElNav] = React.useState(null); // for HambergerMenu
  const [anchorEl, setAnchorEl] = React.useState(null); // for Submenu

  const [selected, setSelected] = useState(null); //Selected Menu from header

  let navigate = useNavigate();
  const Tablet = useMediaQuery("(min-width:768px) and (max-height: 1200px)");

  const [Menuindex, setMenuIndex] = useState();

  // HambergerMenu Functions
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  // Submenu Functions
  const open = Boolean(anchorEl);
  const handleClick = (data, event, index) => {
    setAnchorEl(event?.currentTarget);

    // setAnchorEl(data);

    // alert(event);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const setMenuPosition = (e) => {
    const menu = document.querySelector(".MobieMenu");
    // expanded === true
    //   ? menu.classList.add("set-position")
    //   : menu.classList.remove("set-position");
  };

  useEffect(() => {
    setMenuPosition();
  }, [expanded])





  const getMobileMenu = () => (
    <Box className="MobieMenu">
      {NavLinkData.map((menuData, index) => (
        <Box sx={{ minWidth: '100vw', }}>
          <Accordion expanded={expanded === `panel+${index}`} onChange={handleChange(`panel+${index}`)} sx={{
            width: '90%', borderRadius: 0,
            'a': {
              width: '100%',
              'p': {
                width: '100%',
              }
            }
          }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              sx={{ borderRadius: 0, ...(expanded && { maxHeight: '20px' }), }}
            >
              {menuData?.menuTitle}
            </AccordionSummary>
            <AccordionDetails>
              {NavLinkData[index]?.subMenu?.map((subMenuData, index) => (

                <>
                  <Box
                    sx={{ ...CustomNavbarStyle.MobileSubMenuText }}
                    className={subMenuData.subMenuClass}
                    key={index}
                  >{subMenuData.submenuLink === "#" ?
                    <Box
                      sx={{ ...CustomNavbarStyle.SubMenuText }}
                      className={`${subMenuData.subMenuClass} `}
                    >
                      <Accordion>
                        <AccordionSummary
                          // expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"

                        >
                          {/* {subMenuData.submenunIcon} */}
                          <Box
                            sx={{ ...CustomNavbarStyle.SubMenuText }}
                            className={`${subMenuData.subMenuClass}`}
                          >
                            <Typography sx={CustomNavbarStyle.subMenuTitle} className="text-Title">
                              {subMenuData.submenuTitle}
                            </Typography>
                            {/* <Typography sx={CustomNavbarStyle.subMenuCaption} className="text-Caption">
                              {subMenuData.submenuCaption}
                            </Typography> */}
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails sx={{
                          'a': {
                            width: '100%',
                            'p': {
                              width: '100%',
                            }
                          }
                        }}>
                          {subMenuData.subAttributes !== undefined && subMenuData.subAttributes?.map((value, index) => {
                            console.log(value, "valueee")
                            return <Box
                              className={subMenuData.subMenuClass}
                            >
                              {/* {value.submenunIcon} */}


                              <Link to={'/' + value.submenuLink} onClick={(e) => {
                                setAnchorEl(null);
                                setAnchorElNav(null);
                                setExpanded(false);
                              }}>
                                <Typography sx={CustomNavbarStyle.subMenuTitle}>
                                  {value.title}
                                </Typography>
                              </Link>

                            </Box>

                          })}
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                    :
                    <Link onClick={(e) => {
                      setAnchorEl(null);
                      setAnchorElNav(null);
                      setExpanded(false);
                    }} to={'/' + subMenuData.submenuLink}>
                      <Typography sx={CustomNavbarStyle.subMenuTitle} className="text-Title">
                        {subMenuData.submenuTitle}
                      </Typography>
                    </Link>

                    }
                  </Box>

                  {
                    NavLinkData[index] == 1 &&


                    <Box
                      sx={{ ...CustomNavbarStyle.SubMenuText }}
                      className={`${subMenuData.subMenuClass} `}


                    >
                      <Accordion>
                        <AccordionSummary
                          // expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"

                        >
                          {subMenuData.submenunIcon}
                          <Box
                            sx={{ ...CustomNavbarStyle.SubMenuText }}
                            className={`${subMenuData.subMenuClass}`}

                          >
                            <Typography sx={CustomNavbarStyle.subMenuTitle} className="text-Title">
                              {subMenuData.submenuTitle}
                            </Typography>
                            <Typography sx={CustomNavbarStyle.subMenuCaption} className="text-Caption">
                              {subMenuData.submenuCaption}
                            </Typography>
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                          {subMenuData.subAttributes !== undefined && subMenuData.subAttributes?.map((value, index) => {
                            console.log(value, "valueee")
                            return <Box
                              className={subMenuData.subMenuClass}>
                              {value.submenunIcon}

                              <Typography sx={CustomNavbarStyle.subMenuTitle}>

                                {value.title}
                              </Typography>

                            </Box>

                          })}
                        </AccordionDetails>
                      </Accordion>
                    </Box>

                  }



                </>




              ))
              }
            </AccordionDetails>
          </Accordion>
        </Box>
      )
      )}

      <Box
        sx={{
          display: { xs: "flex", md: "flex" },
          justifyContent: "space-around",
          py: 1,
          pt: 2,
        }}
      >
        <Link to="/company/contact" > <Box>
          <BorderedButton
            icon={<IconHelper.Headphones color={ColorHelper.grey1} />}
            btnText={Tablet == true ? "" : ConstantHelper.navBtn1}
            color={ColorHelper.grey1}
            bgColor={ColorHelper.transparent}
            bordercolor={ColorHelper.lightGrey}
          />
        </Box></Link>

        <Box onClick={() => setOpenCFModal(true)}>
          <FilledButton
            btnText={ConstantHelper.navBtn2}
            color={ColorHelper.white}
            bgColor={ColorHelper.PriamryColor}
          />
        </Box>
      </Box>

    </Box >

  )


  // const getMainMenuLinks = () => (

  //   NavLinkData.map((menuData, index) => (

  //     <MenuItem
  //       key={menuData.menuTitle}
  //       id="demo-customized-button"
  //       className="demo-customized-button"
  //       aria-controls={open ? "demo-customized-menu" : undefined}
  //       aria-haspopup="true"
  //       aria-expanded={open ? "true" : undefined}
  //       variant="contained"
  //       disableelevation="true"
  //       onMouseOver={() => handleClick(menuData, index)}
  //       endicon={<KeyboardArrowDownIcon />}
  //       sx={CustomNavbarStyle.liOfMenu}
  //       style={{ backgroundColor: "#fff" }}
  //     >
  //       <Typography
  //         textAlign="center"
  //         sx={{
  //           ...(selected == index && {
  //             color: ColorHelper.PriamryColor,
  //             fontWeight: 800,
  //           }),
  //           textAlign: 'center',
  //           width: '100%',
  //           fontSize: 16,
  //           pl: 0,
  //           ml: 0,
  //           color: ColorHelper.grey,
  //           "& hover": {
  //             color: "red",
  //             backgroundColor: ColorHelper.PriamryColor
  //           },
  //         }}
  //       // onMouseEnter={(e) =>
  //       //   (e.target.style.bgColor = "#dc0053")
  //       // }
  //       // onMouseDown={(e) => (e.target.style.backgroundColor=ColorHelper.PriamryColor,e.target.style.color=ColorHelper.white)}
  //       >
  //         {
  //           menuData.menuTitle === "Products" ? (
  //             <CustomDropDown
  //               CourseData={NavLinkData}
  //               btnText={menuData.menuTitle}
  //               MenuId={"0"}
  //               color="#fff"
  //               bgColor="#fff"
  //             />


  //           ) : menuData.menuTitle === "Solutions" ? (

  //             <CustomDropDown
  //               CourseData={NavLinkData}
  //               btnText={menuData.menuTitle}
  //               MenuId={"1"}
  //               color="#fff"
  //               bgColor="#fff"

  //             />

  //           ) : menuData.menuTitle === "Services" ? (

  //             <CustomDropDown
  //               CourseData={NavLinkData}
  //               btnText={menuData.menuTitle}
  //               MenuId={"2"}
  //               color="#fff"
  //               bgColor="#fff"

  //             />

  //           ) : menuData.menuTitle === "Company" ? (

  //             <CustomDropDown
  //               CourseData={NavLinkData}
  //               btnText={menuData.menuTitle}
  //               MenuId={"3"}
  //               color="#fff"
  //               bgColor="#fff"

  //             />

  //           ) :
  //             menuData.menuTitle === "OTT" ? (

  //               <CustomDropDown
  //                 CourseData={NavLinkData}
  //                 btnText={menuData.menuTitle}
  //                 MenuId={"4"}
  //                 color="#fff"
  //                 bgColor="#fff"

  //               />

  //             ) :


  //               (

  //                 <CustomDropDown
  //                   CourseData={NavLinkData}
  //                   MenuId={"5"}
  //                   btnText={menuData.menuTitle}
  //                   color={ColorHelper.white}
  //                   bgColor={ColorHelper.PriamryColor}
  //                 />

  //               )
  //         }


  //       </Typography>

  //     </MenuItem>
  //   ))
  // )

  // const GetSubMenus = () => (
  //   <StyledMenu
  //     id="Custom-Submenu"
  //     MenuListProps={{
  //       "aria-labelledby": "Custom-Submenu",
  //     }}
  //     anchorEl={anchorEl}
  //     open={open}
  //     onClick={handleClose}
  //   >
  //     {NavLinkData[0].subMenu.map((subMenuData) => (
  //       <MenuItem
  //         onClick={() => {
  //           navigate(`${subMenuData.submenuLink}`);
  //           setAnchorEl(null);
  //         }}
  //         disableRipple
  //         sx={CustomNavbarStyle.MenuItem}
  //         key={subMenuData.subMenuId}
  //       >
  //         {subMenuData.submenunIcon}
  //         <Box
  //           sx={{ ...CustomNavbarStyle.SubMenuText }}
  //           className={subMenuData.subMenuClass}
  //         >
  //           <Typography sx={CustomNavbarStyle.subMenuTitle}>
  //             {subMenuData.submenuTitle}
  //           </Typography>
  //           <Typography sx={CustomNavbarStyle.subMenuCaption}>
  //             {subMenuData.subMenuCaption}
  //           </Typography>
  //         </Box>
  //       </MenuItem>
  //     ))}
  //   </StyledMenu>
  // )

  return (
    <AppBar
      position="static"
      sx={{ bgcolor: ColorHelper.white, px: { xs: 3, md: 3, lg: 10 } }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Link to="/"><Box sx={CustomNavbarStyle.logoContainer} >
            <img src={ImageHelper.Logo} alt="logo" />
          </Box></Link>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "none" },
              justifyContent: "flex-end ",
            }}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onMouseOver={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon sx={{ color: ColorHelper.PriamryColor }} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >


              {/* {getMobileMenu()} */}
              {/* <MobileMenu /> */}
              {getMobileMenu()}
            </Menu>
          </Box>
          {/* <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "flex-end",
              pr: 2,
            }}
          >

            {getMainMenuLinks()}


          </Box> */}

          {/* <Box
            sx={{
              display: { xs: "none", md: "flex" },
              justifyContent: "space-around",
            }}
          >
            <Box onClick={() => navigate('/contact')}>
              <BorderedButton
                icon={<IconHelper.Headphones color={ColorHelper.grey1} />}
                btnText={Tablet == false ? "" : ConstantHelper.navBtn1}
                color={ColorHelper.grey1}
                bgColor={ColorHelper.transparent}
                bordercolor={ColorHelper.lightGrey}
              />
            </Box>

            <Box onClick={() => setOpenCFModal(true)}>
              <FilledButton
                btnText={ConstantHelper.navBtn2}
                color={ColorHelper.white}
                bgColor={ColorHelper.PriamryColor}
              />
            </Box>
          </Box> */}
        </Toolbar>
      </Container>
      <TalkToUsModaldrp open={openCFModal} onClose={setOpenCFModal} />
    </AppBar>
  );
};
export default CustomNavbarOld;
