import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import ColorHelper from "../../../Helpers/ColorHelper";
import ConstantHelper from "../../../Helpers/ConstantHelper";
import FontHelper from "../../../Helpers/FontHelper";
import HighlightSectionOfServicesStyle from "./HighlightSectionOfServicesStyle";
import HighlightServicesData from "./HighlightServicesData";

const HighlightSectionOfServices = (props) => {
  const { HighlightServicesProps, itemsInRow, bgColor } = props;

  return (
    <Box
      sx={{ ...HighlightSectionOfServicesStyle.main, backgroundColor: bgColor }}
    >
      <Box sx={HighlightSectionOfServicesStyle.headings}>
        <Typography
          sx={{
            fontSize: { xs: "0.85rem", md: "1.2rem" },
            fontWeight: 600,
            color: ColorHelper.blue,
          }}
          variant="h6"
          component="h2"
        >
          {ConstantHelper.HighLightCaption1}
          {/* {ConstantHelper.HightlightService1} */}
          &nbsp;
          {HighlightServicesProps.caption}
        </Typography>
        <Typography
          sx={{
            fontFamily: FontHelper.FontSemiBold,
            fontSize: { xs: "1rem", md: "2rem" },
            fontWeight: 800,
            color: ColorHelper.black,
          }}
          variant="h6"
          component="h2"
        >
          {/* {ConstantHelper.ServicesListTitle} */}
          {HighlightServicesProps.SectionTtitle}
        </Typography>
      </Box>

      <Box
        sx={{
          ...HighlightSectionOfServicesStyle.cardContainer,
          // ...(itemsInRow >= "22%" ? { px: { xs: 0, md: 0, lg: 29 }, } : { px: { xs: 0, md: 0, lg: 20 }, }),
        }}
      >
        {console.log(HighlightServicesProps)}
        {HighlightServicesProps?.HighlightsData?.map((item) => (
          <Box
            sx={{
              ...HighlightSectionOfServicesStyle.card,
              width: { xs: "100%", md: itemsInRow },
              maxWidth: { xs: "100%", md: itemsInRow },
              minWidth: { xs: "100%", md: itemsInRow },
            }}
          >
            {item.icon}
            <Typography sx={HighlightSectionOfServicesStyle.cardTitle}>
              {item.highlightTitle}
            </Typography>
            <Typography sx={HighlightSectionOfServicesStyle.cardDesc}>
              {item.highlightDescription}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default HighlightSectionOfServices;
