import React from "react";

const HLTSounds04 = () => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3085_81234)">
        <path
          d="M43.2994 45.0998H2.7006C1.70624 45.0998 0.900208 44.2937 0.900208 43.2994V2.70054C0.900208 1.70618 1.70624 0.900146 2.7006 0.900146H43.2994C44.2938 0.900146 45.0998 1.70618 45.0998 2.70054V43.2994C45.0998 44.2937 44.2938 45.0998 43.2994 45.0998Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.82809 11.212H5.89352C5.64497 11.212 5.44342 11.0104 5.44342 10.7619V6.8273C5.44342 6.57875 5.64497 6.3772 5.89352 6.3772H9.82809C10.0766 6.3772 10.2782 6.57875 10.2782 6.8273V10.7619C10.2782 11.0104 10.0766 11.212 9.82809 11.212Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.82809 19.7054H5.89352C5.64497 19.7054 5.44342 19.5038 5.44342 19.2553V15.3207C5.44342 15.0722 5.64497 14.8706 5.89352 14.8706H9.82809C10.0766 14.8706 10.2782 15.0722 10.2782 15.3207V19.2553C10.2782 19.5038 10.0766 19.7054 9.82809 19.7054Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.82809 28.1985H5.89352C5.64497 28.1985 5.44342 27.997 5.44342 27.7484V23.8139C5.44342 23.5653 5.64497 23.3638 5.89352 23.3638H9.82809C10.0766 23.3638 10.2782 23.5653 10.2782 23.8139V27.7484C10.2782 27.997 10.0766 28.1985 9.82809 28.1985Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.04419 34.5996V39.4345"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M5.44342 39.4345V34.5996"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17.5667 36.6272C17.8667 36.8004 17.8667 37.2336 17.5667 37.4068L15.7003 38.4843L13.834 39.5618C13.5339 39.735 13.1588 39.5185 13.1588 39.172V37.017V34.8619C13.1588 34.5154 13.5339 34.2988 13.834 34.4721L15.7003 35.5497L17.5667 36.6272Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M40.5566 37.0168H28.6335"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M25.0328 37.0168H21.7416"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M26.8333 38.8173C27.8276 38.8173 28.6337 38.0113 28.6337 37.0169C28.6337 36.0226 27.8276 35.2166 26.8333 35.2166C25.839 35.2166 25.0329 36.0226 25.0329 37.0169C25.0329 38.0113 25.839 38.8173 26.8333 38.8173Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17.6561 8.79443H40.4932"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13.3721 17.2878H40.4933"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17.6561 25.7812H40.4932"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3085_81234">
          <rect width="46" height="46" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HLTSounds04;
