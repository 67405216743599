import React from "react";

const HLTvLive03 = () => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3086_82960)">
        <path
          d="M11.7488 22.1206V19.0793"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M18.2328 3.36938H42.7682C44.0962 3.36938 45.1724 4.44575 45.1724 5.77359V28.0018C45.1724 29.3297 44.0961 30.406 42.7682 30.406H29.7032"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14.4441 22.1206V19.0793"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M29.7032 36.7929H35.481C36.26 36.7929 36.8915 36.1613 36.8915 35.3824C36.8915 34.9925 36.7334 34.6403 36.4782 34.3842C36.2231 34.1291 35.8709 33.9709 35.4809 33.9709H29.7031"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M29.7032 33.971H32.7901V30.406H29.7032"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.40255 45.3262H28.3188C29.6764 45.3262 30.8516 44.3819 31.1445 43.0558L31.229 42.6722C31.3485 42.1286 30.9343 41.6138 30.3781 41.6138H1.69911C1.14206 41.6138 0.728766 42.1286 0.848262 42.6722L0.932718 43.0558C1.22472 44.3819 2.39991 45.3262 3.75839 45.3262H6.25792"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M28.188 22.1208H3.78537C2.94845 22.1208 2.27002 22.7993 2.27002 23.6362V41.6135H29.7033V23.6363C29.7033 22.7993 29.0249 22.1208 28.188 22.1208Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M16.2436 19.0792H6.95844C5.85998 19.0792 4.96942 18.1887 4.96942 17.0901V2.66285C4.96942 1.5643 5.85989 0.673828 6.95844 0.673828H16.2437C17.3422 0.673828 18.2327 1.5643 18.2327 2.66285V17.0902C18.2327 18.1887 17.3422 19.0792 16.2436 19.0792Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13.2562 32.0745L16.1223 34.9406L19.3724 29.4363"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M25.4023 16.0516L28.2684 18.9177L31.5186 13.4133"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.50195 9.93888L11.4693 11.9062L13.7002 8.12793"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3086_82960">
          <rect width="46" height="46" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HLTvLive03;
