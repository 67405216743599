import React from "react";
import ColorHelper from "../../Helpers/ColorHelper";

const HighlightIconV3_5 = () => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.4167 24.9164H9.58333V38.333H36.4167V24.9164ZM36.4167 21.083C36.4167 17.5247 35.0031 14.1121 32.487 11.596C29.9709 9.0799 26.5583 7.66636 23 7.66636C19.4417 7.66636 16.0291 9.0799 13.513 11.596C10.9969 14.1121 9.58333 17.5247 9.58333 21.083H36.4167ZM12.2322 7.60503C15.2868 5.15757 19.0858 3.82679 23 3.83303C27.0729 3.83303 30.8162 5.2437 33.7678 7.60503L36.5527 4.82011L39.2629 7.53028L36.478 10.3152C38.9255 13.3699 40.2562 17.1688 40.25 21.083V40.2497C40.25 40.758 40.0481 41.2455 39.6886 41.605C39.3292 41.9644 38.8417 42.1664 38.3333 42.1664H7.66667C7.15834 42.1664 6.67082 41.9644 6.31138 41.605C5.95193 41.2455 5.75 40.758 5.75 40.2497V21.083C5.75 17.0101 7.16067 13.2669 9.522 10.3152L6.73708 7.5322L9.44725 4.82203L12.2322 7.60695V7.60503ZM17.25 17.2497C16.7417 17.2497 16.2542 17.0478 15.8947 16.6883C15.5353 16.3289 15.3333 15.8414 15.3333 15.333C15.3333 14.8247 15.5353 14.3372 15.8947 13.9777C16.2542 13.6183 16.7417 13.4164 17.25 13.4164C17.7583 13.4164 18.2458 13.6183 18.6053 13.9777C18.9647 14.3372 19.1667 14.8247 19.1667 15.333C19.1667 15.8414 18.9647 16.3289 18.6053 16.6883C18.2458 17.0478 17.7583 17.2497 17.25 17.2497ZM28.75 17.2497C28.2417 17.2497 27.7542 17.0478 27.3947 16.6883C27.0353 16.3289 26.8333 15.8414 26.8333 15.333C26.8333 14.8247 27.0353 14.3372 27.3947 13.9777C27.7542 13.6183 28.2417 13.4164 28.75 13.4164C29.2583 13.4164 29.7458 13.6183 30.1053 13.9777C30.4647 14.3372 30.6667 14.8247 30.6667 15.333C30.6667 15.8414 30.4647 16.3289 30.1053 16.6883C29.7458 17.0478 29.2583 17.2497 28.75 17.2497Z"
        fill={ColorHelper.BgBlue}
      />
    </svg>
  );
};

export default HighlightIconV3_5;
