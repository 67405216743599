import * as React from "react";
import ColorHelper from "../../Helpers/ColorHelper";

const Devops = ({ size = 46, color, ...props }) => (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="48" height="48" rx="24" fill="#FFF3F5"/>
    <path d="M31 14C31.2652 14 31.5196 14.1054 31.7071 14.2929C31.8946 14.4804 32 14.7348 32 15V33C32 33.2652 31.8946 33.5196 31.7071 33.7071C31.5196 33.8946 31.2652 34 31 34H17C16.7348 34 16.4804 33.8946 16.2929 33.7071C16.1054 33.5196 16 33.2652 16 33V15C16 14.7348 16.1054 14.4804 16.2929 14.2929C16.4804 14.1054 16.7348 14 17 14H31ZM30 16H18V32H30V16ZM24 19C25.088 18.9999 26.1464 19.3547 27.0145 20.0105C27.8826 20.6663 28.5131 21.5875 28.8104 22.6341C29.1076 23.6807 29.0553 24.7957 28.6615 25.81C28.2676 26.8242 27.5537 27.6823 26.628 28.254L24.5 24H27C27 23.4067 26.8241 22.8266 26.4944 22.3333C26.1648 21.8399 25.6962 21.4554 25.1481 21.2284C24.5999 21.0013 23.9967 20.9419 23.4147 21.0576C22.8328 21.1734 22.2982 21.4591 21.8787 21.8787C21.4591 22.2982 21.1734 22.8328 21.0576 23.4147C20.9419 23.9967 21.0013 24.5999 21.2284 25.1481C21.4554 25.6962 21.8399 26.1648 22.3333 26.4944C22.8266 26.8241 23.4067 27 24 27L24.955 28.909C24.2704 29.0429 23.5653 29.0321 22.8851 28.8773C22.2049 28.7224 21.5646 28.427 21.0053 28.0099C20.4461 27.5929 19.9803 27.0635 19.6379 26.4557C19.2955 25.8479 19.084 25.1752 19.0171 24.4808C18.9502 23.7864 19.0293 23.0856 19.2493 22.4236C19.4693 21.7616 19.8254 21.153 20.2947 20.6368C20.764 20.1206 21.3361 19.7084 21.9742 19.4265C22.6123 19.1446 23.3024 18.9993 24 19V19Z" fill="#DA1A5D"/>
    </svg>
    
    

);

export default Devops;