const FooterMenuData1 = [
  {
    id: 1,
    title: "vLive",
    url: "/vLive",
    IsItnew: false,
  },
  {
    id: 2,
    title: "Sounds",
    url: "/sounds",
    IsItnew: false,
  },
  {
    id: 3,
    title: "vLite MOOC",
    url: "/vlite-mooc",
    IsItnew: false,
  },
  {
    id: 4,
    title: "vApps",
    url: "/vApps",
    IsItnew: false,
  },
 
  {
    id: 5,
    title: "vDRM",
    url: "/vDRM",
    IsItnew: false,
  },
  {
    id: 6,
    title: "vCMS",
    url: "/vCMS",
    IsItnew: false,
  },
  {
    id: 7,
    title: "vSMS",
    url: "/vSMS",
    IsItnew: false,
  },
];

const FooterMenuData2 = [
  {
    id: 1,
    title: "About",
    // for Enterprise",
    url: "/company/about",
    IsItnew: false,
  },
  {
    id: 2,
    title: "Leadership Team",
    // for Enterprise",
    url: "/company/leadership",
    IsItnew: false,
  },
  {
    id: 3,
    title: "Contact Us",
    // for Enterprise",
    url: "/company/contact",
    IsItnew: false,
  },
  {
    id: 4,
    title: "Career",
    // for Enterprise",
    url: "/company/career",
    IsItnew: false,
  },
  {
    id: 4,
    title: "Privacy Policy ",
    // for Enterprise",
    url: "/company/privacy-policy",
    IsItnew: false,
  },
  // {
  //   id: 5,
  //   title: "Blogs",
  //   // for Enterprise",
  //   url: "/resources/blogs",
  //   IsItnew: false,
  // },


];

const FooterMenuData3 = [
  {
    id: 1,
    title: "OTT",
    // for Enterprise",
    url: "/OTT",
    IsItnew: false,
  },
  {
    id: 2,
    title: "Broadcast",
    url: "/broadcast",
    IsItnew: false,
  },
  {
    id: 3,
    title: "Digitization",
    url: "/digitization",
    IsItnew: false,
  },
  /*
  {
    id: 4,
    title: "Media & Entertainment",
    url: "/media&entertainment",
    IsItnew: false,
  },
  {
    id: 5,
    title: "Operators",
    url: "/operators",
    IsItnew: false,
  },
  {
    id: 6,
    title: "Education & E-Learning",
    url: "/Education&ELearning",
    IsItnew: false,
  },
  {
    id: 7,
    title: "Health & Fitness",
    url: "/Health&Fitness",
    IsItnew: false,
  },
   */
];

const FooterMenuData4 = [
  {
    id: 1,
    title: "Blogs",
    url: "/resources/blogs",
    IsItnew: false,
  },
];

const FooterMenuData5 = [
  {
    id: 1,
    title: "OTT Apps",
    url: "/ott-apps",
    IsItnew: false,
  },
  {
    id: 2,
    title: "OTT Consulting",
    url: "/ott-consulting",
    IsItnew: false,
  },
  //  {
  //     id: 3,
  //     title: "DevOps",
  //     url: "/dev-ops",
  //     IsItnew: false,
  //   }, 
  {
    id: 4,
    title: "Custom",
    title2:"Development",
    url: "/custom-development",
    IsItnew: false,
  },
  //  {
  //     id: 5,
  //     title: "QA Automation",
  //    url: "/qa-automation",
  //     IsItnew: false,
  //   }, 
];

export { FooterMenuData1, FooterMenuData2, FooterMenuData3, FooterMenuData4, FooterMenuData5 };
