import React from "react";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";

// Helpers
import ColorHelper from "../../Helpers/ColorHelper";
import ConstantHelper from "../../Helpers/ConstantHelper";
import IconHelper from "../../Helpers/IconHelper";
import ImageHelper from "../../Helpers/ImageHelper";

// Custom Styles
import HighlightCardStyle from "./HighlightCardStyle";
import { memo } from "react";

const HighlightCard = memo(function HighlightCard() {
  return (
    <Box sx={HighlightCardStyle.cardWrapper}>
      <Box sx={HighlightCardStyle.card}>
        <img
          src={ImageHelper.cardImage}
          style={HighlightCardStyle.cardImage}
          alt="Card Image"
          height=""
          width=""
        />

        <Box sx={HighlightCardStyle.content}>
          <Typography sx={HighlightCardStyle.CardTitle}>
            {ConstantHelper.CardTitle}
          </Typography>
          <Typography sx={HighlightCardStyle.CardCaption}>
            {ConstantHelper.CardCaption}
          </Typography>

          <Link to="/contact" style={HighlightCardStyle.Link}>
            {ConstantHelper.CardLink}
            <Box sx={HighlightCardStyle.arrowWrapper}>
              <IconHelper.arrowRight color={ColorHelper.white} />
            </Box>
          </Link>
        </Box>
      </Box>
    </Box>
  );
});

export default HighlightCard;
