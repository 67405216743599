// Helpers
import { fontFamily } from "@mui/system";
import ColorHelper from "../../Helpers/ColorHelper";
import FontHelper from "../../Helpers/FontHelper";

const TestimonialCardStyle = {
  TestimonialWrapper: {
    backgroundColor: ColorHelper.white1,
    paddingBottom: "80px",
    width: "100%",
    height: "auto",
    position: "relative",
  },
  TestimonialTitleWrapper: {
    textAlign: "center",
    // margin: '0 auto',
    px: { xs: 2, md: 0 },
    pt: "80px",
    pb: { xs: "20px", md: "40px" },
  },
  TestimonialTitle: {
    fontFamily: FontHelper.FontSemiBold,
    fontSize: { xs: '1.2rem', md: '2rem' },
    fontWeight: 800,
    color: ColorHelper.black,
  },
  carouselWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
    alignItems: "flex-end",
    mt: "-30px",
    px: "15px",
    height: 'max-content',
    ".carousel-container": {
      width: "100%",
      ul: {
        // display: 'inline-flex',
        // listStyleType: 'none',
      },
      overflow: "hidden",
      py: 2,
      maxWidth: [
        "100%",
        "50%",
        "50%",
        "88%",
        "88%",
        "1180px",
        "100%",
        "calc(50% + 865px)",
      ],
      mr: ["auto", null, null, null, null, null, null, "-220px"],
      ml: "auto",
      ".react-multi-carousel-item": {
        transition: "all 0.25s",
      },
      ".react-multi-carousel-item--active:nth-of-type(4n)": {
        opacity: "0.5",
        "@media screen and (max-width: 1620px)": {
          opacity: 1,
        },
      },
    },
  },
  reviewCard: {
    // minHeight: "350px",
    // maxHeight: 'fit-content',
    height: "90%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "column",
    alignSelf: 'stretch',
    flexGrow: 1,
    backgroundColor: ColorHelper.white,
    // boxShadow: '0px 0px 1px rgba(38, 78, 118, 0.35)',
    transition: "all 0.3s",
    boxShadow:
      "0px 20px 24px -4px rgba(16, 24, 40, 0.1), 0px 8px 8px -4px rgba(16, 24, 40, 0.04)",
    borderRadius: "10px",
    px: "28px",
    pt: 1,
    pb: 5,
    textAlign: "left",
    m: 2,
    "&:hover": {
      boxShadow: "0px 6px 30px rgba(38, 78, 118, 0.1)",
    },
    ".rating": {
      mb: 0,
      ul: {
        pl: 0,
        listStyle: "none",
        mb: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        width: "150px",
      },
      svg: {
        marginRight: "2px",
        "&:last-of-type": {
          marginRight: 0,
        },
      },
      ".star": {
        color: "#FEC84B",
        mr: "1px",
      },
      ".star-o": {
        color: "#ddd",
        mr: "1px",
      },
    },
    ".card-footer": {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      marginTop: 1,
      ".AvatarImage": {
        flexShrink: 0,
        mr: 7,
        display: "flex",
        img: {
          width: "max-content",
          height: "100px",
          borderRadius: "10px",
          objectFit: "cover",
          // position: "absolute",
          bottom: -7,
        },
      },
    },
  },
  title: {
    fontSize: 20,
    fontWeight: 700,
    mb: [3, null, null, "22px"],
    color: "text",
    lineHeight: 1.6,
  },
  description: {
    fontSize: 16,
    fontWeight: "normal",
    color: ColorHelper.black,
    textAlign: "center",
    llineHeight: "21px",
    minHeight: '250px',
    mt: 2,
    mb: 2,
  },
  heading: {
    fontSize: 16,
    fontWeight: 700,
    fontFamily: FontHelper.FontSemiBold,
    color: ColorHelper.black,
    lineHeight: 1.3,
    textAlign: "center",
  },
  designation: {
    color: ColorHelper.grey,
    fontWeight: "500",
    fontSize: 12,
    textAlign: "center",
    lineHeight: 1.4,
  },
  buttonGroup: {
    minWidth: "100%",
    pl: { xs: "25px", md: "110px" },
    pr: { xs: "0px", md: "80px" },
    mr: { xs: "-5px", md: 0 },
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "absolute",
    top: "50%",
  },
  BtnIconContainer: {
    bg: "transparent",
    border: "0px solid",
    fontSize: 40,
    cursor: "pointer",
    px: "2px",
    color: "#BBC7D7",
    transition: "all 0.25s",
    "&:hover": {
      color: "text",
    },
    "&:focus": {
      outline: 0,
    },
    svg: {
      width: { xs: "30px", md: "auto" },
    },
  },
};

export default TestimonialCardStyle;
