import * as React from "react";
import ColorHelper from "../../Helpers/ColorHelper";

const Sounds = ({ size = 46, color, ...props }) => (
  
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="48" height="48" rx="24" fill="#FFF3F5"/>
    <path d="M15 19V18C15 17.2044 15.3161 16.4413 15.8787 15.8787C16.4413 15.3161 17.2044 15 18 15H30C30.7956 15 31.5587 15.3161 32.1213 15.8787C32.6839 16.4413 33 17.2044 33 18V19" stroke="#DA1A5D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M28 21V27" stroke="#DA1A5D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M32 22V26" stroke="#DA1A5D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M24 19.5V28.5" stroke="#DA1A5D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M20 21V27" stroke="#DA1A5D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16 22V26" stroke="#DA1A5D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15 29V30C15 30.7956 15.3161 31.5587 15.8787 32.1213C16.4413 32.6839 17.2044 33 18 33H30C30.7956 33 31.5587 32.6839 32.1213 32.1213C32.6839 31.5587 33 30.7956 33 30V29" stroke="#DA1A5D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

);

export default Sounds;