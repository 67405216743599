import React from "react";

const CircularArrowBtnDisabled = (props) => {
  const { color } = props;
  return (
    <svg
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        opacity="0.5"
        r="15.3191"
        transform="matrix(-1 0 0 1 15.3192 15.3191)"
        fill="#F0C6CE"
      />
      <path
        d="M12.6076 14.7753L16.9429 10.4477C17.0141 10.3759 17.0988 10.3189 17.1922 10.28C17.2855 10.2411 17.3856 10.2211 17.4867 10.2211C17.5878 10.2211 17.688 10.2411 17.7813 10.28C17.8746 10.3189 17.9594 10.3759 18.0306 10.4477C18.1732 10.5912 18.2533 10.7853 18.2533 10.9877C18.2533 11.19 18.1732 11.3841 18.0306 11.5277L14.2391 15.3574L18.0306 19.1489C18.1732 19.2924 18.2533 19.4866 18.2533 19.6889C18.2533 19.8913 18.1732 20.0854 18.0306 20.2289C17.9596 20.3013 17.875 20.3589 17.7817 20.3983C17.6883 20.4378 17.5881 20.4583 17.4867 20.4587C17.3854 20.4583 17.2851 20.4378 17.1918 20.3983C17.0984 20.3589 17.0138 20.3013 16.9429 20.2289L12.6076 15.9013C12.5298 15.8295 12.4678 15.7425 12.4253 15.6456C12.3829 15.5487 12.361 15.4441 12.361 15.3383C12.361 15.2325 12.3829 15.1279 12.4253 15.031C12.4678 14.9341 12.5298 14.847 12.6076 14.7753Z"
        fill={color}
      />
    </svg>
  );
};

export default CircularArrowBtnDisabled;
