// Helpers
import ColorHelper from "../../Helpers/ColorHelper";
import FontHelper from "../../Helpers/FontHelper";

const StreamingTypeStyle = {
  wrapper: {
    backgroundColor: ColorHelper.white1,
    // minHeight: {xs : '160vmax', md: '65vmax'},
    // margin: '0 auto',
    textAlign: "center",
    p: { xs: 2, md: 5 },
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    '*::-webkit-scrollbar': {
      maxHeight: '2px',
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: ColorHelper.PriamryColor,
    }
  },
  caption: {
    fontFamily: FontHelper.FontRegular,
    fontSize: "1.2rem",
    fontWeight: 100,
    color: ColorHelper.PriamryColor,
  },
  StreamTypeSectionTitle: {
    fontFamily: FontHelper.FontRegular,
    fontSize: { xs: '1.2rem', md: '2rem' },
    fontWeight: 800,
    color: ColorHelper.black,
  },
  TabNamesWrapper: {
    // width: "100%",
    bgcolor: ColorHelper.white,
    border: `1px solid ${ColorHelper.grey2}`,
    fontFamily: FontHelper.FontRegular,
    boxShadow:
      "0px 12px 16px -4px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05)",
    borderRadius: "5px",
    width: "1216px",
    height: "80px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    my: 5,
  },
  TabNamesWrapper1: {
    // width: "100%",
    bgcolor: ColorHelper.white,
    border: `1px solid ${ColorHelper.grey2}`,
    boxShadow:
      "0px 12px 16px -4px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05)",
    borderRadius: "5px",
    width: "750px",
    height: "80px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    my: 5,
  },


  StreamTypeCoverContainer: {
    width: "100%",
    maxHeight: "172px",
    borderRadius: "20px",
    position: "relative",
    cursor: 'pointer',
    // backgroundImage: 'url("./src/Assets/Images/StreamTypeCover.png")',
  },
  StreamTypeCoverWrapper: {
    filter: "brightness(0.2)",
    overflowX: "hidden",
  },
  StreamTypeCover: {
    maxWidth: { xs: "200px", md: "100%" },
    overlay: "darken",
  },
  streamLogo: {
    maxWidth: { xs: "90px !important", md: "250px" },
    width: { xs: "90px !important", md: "250px" },
    position: "absolute",
    top: "38%",
    bottom: "50%",
    transform: 'translateY(-50%)',
    left: "43%",
    right: "55%",
    height:"12vh",
    'img': {
      width: "100%",
    }
  },
  streamContent: {
    maxWidth: { xs: "205px !important", md: "300px !important" },
    width: { xs: "190px !important", md: "250px" },
    color:"white",
    position: "absolute",
    top: "67%",
    bottom: "50%",
    borderTop:"1px solid lightGray",
    transform: 'translateY(-50%)',
    left: "38%",
    right: "55%",
    marginTop:"15px",
    paddingTop:"15px",
    width:"100vw",
  },
  btnContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  BannerImg: {
    maxHeight: '200px',
    px: { xs: 0, md: 11 },
    cursor: 'pointer',
    position: 'relative',
  },
  BannerStreamTypeCover: {
    height: '200px',
    objectFit: 'cover',
    width: '100%',
    borderRadius: 25,
    cursor: 'pointer',
  },
  overlay: {
    width: '85%',
    maxHeight: '200px',
    position: 'absolute',
    left: '50%',
    right: '50%',
    top: '50%',
    bottom: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'linear-gradient(360deg, #000000 0%, rgba(0, 0, 0, 0.48) 40.97%, rgba(0, 0, 0, 0) 68.64%)',
  },
};

export default StreamingTypeStyle;
