import React from "react";

const NavbarIcon7 = (props) => {
  const { color, bgColor } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
    >
      <rect width="48" height="48" rx="24" fill={bgColor} />
      <path
        d="M28 33V31C28 29.9391 27.5786 28.9217 26.8284 28.1716C26.0783 27.4214 25.0609 27 24 27H17C15.9391 27 14.9217 27.4214 14.1716 28.1716C13.4214 28.9217 13 29.9391 13 31V33M29 23L31 25L35 21M24.5 19C24.5 21.2091 22.7091 23 20.5 23C18.2909 23 16.5 21.2091 16.5 19C16.5 16.7909 18.2909 15 20.5 15C22.7091 15 24.5 16.7909 24.5 19Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NavbarIcon7;
