const EmailJsConfig = {
  // Tej's Gmail
    SERVICE_ID: "service_b8x8e9n",
    TEMPLATE_ID: "template_04xomgf",
    USER_ID: "-cZ01BG3X6_33Br3q",
  // for Contact us page
    TEMPLATE_ID2: "template_04xomgf",

  // //   Naresh's Gmail
  // SERVICE_ID: "service_ymz0yfa",
  // TEMPLATE_ID: "template_6de17fp",
  // USER_ID: "OZI9PfmDHifIjSIvB",
  // // for Contact us page
  // TEMPLATE_ID2: "template_23izwf7",
};

export default EmailJsConfig;
