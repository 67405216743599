import React from "react";
import ColorHelper from "../../Helpers/ColorHelper";

const HighlightIconV2_5 = () => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.2961 6.62392C40.2961 3.49592 37.7201 0.919922 34.5921 0.919922H20.0561C18.5841 0.919922 17.1121 1.47192 16.1921 2.57592L7.5441 10.6719C6.4401 11.5919 5.7041 13.2479 5.7041 14.9039V39.5599C5.7041 42.6879 8.2801 45.2639 11.4081 45.2639H34.5921C37.7201 45.2639 40.2961 42.6879 40.2961 39.5599V6.62392ZM9.3841 14.7199C9.3841 14.1679 9.7521 13.6159 10.1201 13.2479L18.5841 5.15192C18.7681 4.96792 19.3201 4.59992 20.0561 4.59992H20.2401V11.5919C20.2401 13.4319 18.7681 15.0879 16.7441 15.0879L9.3841 14.7199ZM34.2241 29.8079L27.2321 37.7199C26.8641 38.0879 26.3121 38.2719 25.9441 38.2719H25.7601C25.2081 38.2719 24.6561 37.9039 24.2881 37.5359L20.4241 31.6479L14.5361 37.9039C13.8001 38.6399 12.6961 38.6399 11.9601 37.9039C11.2241 37.1679 11.2241 36.0639 11.9601 35.3279L19.5041 27.4159C19.8721 27.0479 20.4241 26.8639 20.9761 26.8639C21.5281 26.8639 22.0801 27.2319 22.4481 27.5999L26.3121 33.4879L31.6481 27.4159C32.3841 26.6799 33.4881 26.6799 34.2241 27.2319C34.9601 27.7839 34.9601 29.0719 34.2241 29.8079ZM33.6721 18.5839H25.7601C24.6561 18.5839 23.9201 17.8479 23.9201 16.7439C23.9201 15.6399 24.6561 14.9039 25.7601 14.9039H33.6721C34.5921 14.9039 35.5121 15.6399 35.5121 16.7439C35.5121 17.8479 34.7761 18.5839 33.6721 18.5839ZM33.6721 11.2239H25.7601C24.6561 11.2239 23.9201 10.4879 23.9201 9.38392C23.9201 8.27992 24.6561 7.54392 25.7601 7.54392H33.6721C34.5921 7.54392 35.5121 8.27992 35.5121 9.38392C35.5121 10.4879 34.7761 11.2239 33.6721 11.2239Z"
        fill={ColorHelper.BgBlue}
      />
    </svg>
  );
};

export default HighlightIconV2_5;
