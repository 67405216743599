import React from "react";

const HLTvLiteMOOC03 = () => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3085_81839)">
        <path d="M0.0673828 0H0V0.0673828H0.0673828V0Z" fill="white" />
        <path
          d="M26.9106 17.2869H19.0894C18.6083 17.2869 18.2183 17.6769 18.2183 18.158V18.3506C18.2183 18.8317 18.6083 19.2217 19.0894 19.2217H26.9106C27.3917 19.2217 27.7817 18.8317 27.7817 18.3506V18.158C27.7817 17.6769 27.3917 17.2869 26.9106 17.2869Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M25.9986 21.1565H20.0015C19.5204 21.1565 19.1304 20.7664 19.1304 20.2854V20.0927C19.1304 19.6117 19.5204 19.2216 20.0015 19.2216H25.9986C26.4796 19.2216 26.8697 19.6117 26.8697 20.0927V20.2854C26.8697 20.7664 26.4796 21.1565 25.9986 21.1565Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M22.0327 21.1564V22.0936C22.0327 22.5277 22.3846 22.8796 22.8186 22.8796H23.1814C23.6155 22.8796 23.9674 22.5277 23.9674 22.0936V21.1564H22.0327Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14.0697 11.2753L12.8254 12.3064"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14.0697 4.82397L12.8254 3.79284"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13.2274 8.04956H11.7401"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M31.9302 11.2753L33.1746 12.3064"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M31.9302 4.82397L33.1746 3.79284"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M32.7726 8.04956H34.2599"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14.6569 24.9262C14.5408 24.8015 14.3517 24.7783 14.209 24.8716C12.6791 25.8711 10.6557 26.5028 8.66748 26.3599C8.46587 26.3446 8.29193 26.5055 8.29193 26.7093V29.7813C8.29193 31.7558 9.83904 33.5591 12.1791 33.5591C14.2622 33.5591 15.957 31.8644 15.957 29.7813V26.4612C15.957 26.2777 15.9649 26.3321 14.6569 24.9262Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.29192 26.9534V26.7092C8.29192 26.5055 8.46585 26.3446 8.66755 26.3598C10.6557 26.5026 12.6791 25.8709 14.2088 24.8715C14.3517 24.7782 14.5408 24.8014 14.6569 24.9261C15.9648 26.332 15.9569 26.2776 15.9569 26.4612V26.7493C15.9569 26.7526 15.9569 26.7558 15.9569 26.7592V26.9489C16.4598 26.9163 16.9347 27.0996 17.2666 27.4073V25.4947C17.2666 22.6545 14.9644 20.3524 12.1243 20.3524C9.28424 20.3524 6.98199 22.6545 6.98199 25.4947V27.4485C7.33167 27.1295 7.82778 26.9382 8.29192 26.9534Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15.9569 26.9491V29.7814C15.9569 29.8173 15.9552 29.8526 15.9542 29.8882C16.8928 29.9506 17.7322 29.2591 17.7322 28.4186C17.7322 27.5768 16.8928 26.8886 15.9569 26.9491Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.29195 29.7813V26.9534C7.45685 26.926 6.51672 27.5667 6.51672 28.4184C6.51672 29.2578 7.35452 29.9505 8.29455 29.8879C8.29356 29.8524 8.29195 29.8169 8.29195 29.7813Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14.603 34.8054C14.4316 34.739 14.3186 34.5742 14.3186 34.3902V32.8926C13.7099 33.3126 12.9729 33.5592 12.1791 33.5592C11.3484 33.5592 10.6183 33.3311 10.0212 32.9496V34.3904C10.0212 34.5742 9.90826 34.739 9.73693 34.8055L8.61639 35.2399C9.07109 35.7433 9.57116 36.2902 10.0476 36.8084C11.1654 38.0239 13.0836 38.0238 14.2014 36.8083C14.6862 36.2811 15.1954 35.7242 15.6565 35.2134L14.603 34.8054Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M20.003 36.8966L14.6031 34.8054C14.4316 34.739 14.3186 34.574 14.3186 34.3903V32.8927C13.7099 33.3125 12.9729 33.5591 12.1792 33.5591C11.3484 33.5591 10.6183 33.331 10.0211 32.9494V34.3903C10.0211 34.574 9.90828 34.739 9.73686 34.8054L4.34273 36.8966C4.34273 36.8966 2.2403 37.7609 2.2403 40.1577V44.1501C2.2403 44.7988 2.76615 45.3247 3.41482 45.3247H20.9309C21.5796 45.3247 22.1055 44.7988 22.1055 44.1501V40.1577C22.1055 37.7609 20.003 36.8966 20.003 36.8966Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M36.3112 24.9262C36.1951 24.8015 36.0061 24.7783 35.8632 24.8716C34.3334 25.8711 32.31 26.5028 30.3219 26.3599C30.1203 26.3446 29.9462 26.5055 29.9462 26.7093V29.7813C29.9462 31.7558 31.4933 33.5591 33.8335 33.5591C35.9165 33.5591 37.6113 31.8644 37.6113 29.7813V26.4612C37.6113 26.2777 37.6192 26.3321 36.3112 24.9262Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M29.9462 26.9534V26.7092C29.9462 26.5055 30.1203 26.3446 30.3219 26.3598C32.31 26.5026 34.3334 25.8709 35.8632 24.8715C36.006 24.7782 36.1952 24.8014 36.3113 24.9261C37.6192 26.332 37.6113 26.2776 37.6113 26.4612V26.7493V26.7592V26.9489C38.1141 26.9163 38.589 27.0996 38.9209 27.4073V25.4947C38.9209 22.6545 36.6188 20.3524 33.7786 20.3524C30.9386 20.3524 28.6364 22.6545 28.6364 25.4947V27.4485C28.986 27.1295 29.4821 26.9382 29.9462 26.9534Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M37.6113 26.9491V29.7814C37.6113 29.8173 37.6095 29.8526 37.6086 29.8882C38.5472 29.9506 39.3865 29.2591 39.3865 28.4186C39.3865 27.5768 38.5471 26.8886 37.6113 26.9491Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M29.9463 29.7813V26.9534C29.1112 26.926 28.171 27.5667 28.171 28.4184C28.171 29.2578 29.0088 29.9505 29.9489 29.8879C29.9479 29.8524 29.9463 29.8169 29.9463 29.7813Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M36.2573 34.8054C36.0859 34.739 35.973 34.5742 35.973 34.3902V32.8926C35.3643 33.3126 34.6272 33.5592 33.8334 33.5592C33.0027 33.5592 32.2726 33.3311 31.6755 32.9496V34.3904C31.6755 34.5742 31.5626 34.739 31.3912 34.8055L30.2707 35.2399C30.7254 35.7433 31.2254 36.2902 31.7019 36.8084C32.8196 38.0239 34.738 38.0238 35.8557 36.8083C36.3405 36.2811 36.8498 35.7242 37.3108 35.2134L36.2573 34.8054Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M23 13.1418L24.292 12.0678C24.5733 11.834 24.7359 11.4872 24.7359 11.1215V8.6387"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M21.2641 8.6387V11.1215C21.2641 11.4873 21.4268 11.834 21.708 12.0678L23.0001 13.1418"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M23 17.2869V13.1417"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M20.2405 8.63867H25.7595"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M35.363 45.3247H42.5852C43.2339 45.3247 43.7597 44.7988 43.7597 44.1501V40.1577C43.7597 37.7609 41.6574 36.8966 41.6574 36.8966L36.2573 34.8054C36.0859 34.739 35.9729 34.5741 35.9729 34.3903V32.8926C35.3643 33.3125 34.6273 33.5591 33.8334 33.5591C33.0027 33.5591 32.2727 33.3311 31.6755 32.9495V34.3904C31.6755 34.5741 31.5625 34.739 31.3912 34.8054L25.9971 36.8966C25.9971 36.8966 23.8947 37.7609 23.8947 40.1577V44.1501C23.8947 44.7988 24.4204 45.3247 25.0692 45.3247H32.2185"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M30.2613 6.4774C29.5312 3.23755 26.6909 0.796766 23.259 0.67943C19.0839 0.536758 15.5698 3.91542 15.5546 8.0928C15.546 10.442 16.6257 12.5391 18.318 13.9094C18.8923 14.3743 19.2176 15.0802 19.2176 15.8192V17.2869H26.7824V15.8408C26.7824 15.0907 27.1111 14.3734 27.6932 13.9003C29.0022 12.8362 29.9426 11.3364 30.2938 9.62194"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3085_81839">
          <rect width="46" height="45.9999" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HLTvLiteMOOC03;
