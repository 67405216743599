import IconHelper from "../IconHelper";

const HighlightsData1 = [
  {
    id: "hl1",
    hightlights: [
      {
        id: "high01",
        icon: <IconHelper.Otticon />,
        highlightTitle: "Secure content delivery",
        highlightDescription:
          "End-to-end secure delivery to protect your content against attacks.",
      },
      {
        id: "high02",
        icon: <IconHelper.Otticon />,
        highlightTitle: "Buffer-free video streaming",
        highlightDescription:
          "Quick content delivery that ensures buffer-free streaming.",
      },
      {
        id: "high03",
        icon: <IconHelper.Otticon />,
        highlightTitle: "Real-time analytics to track usage",
        highlightDescription:
          "Get real-time analytics and metrics to track performance.",
      },
      {
        id: "high04",
        icon: <IconHelper.Otticon />,
        highlightTitle: "Flexibility in monetization",
        highlightDescription: "Options to monetize while streaming content.",
      },
    ],
  },
  {
    id: "hl2",
    hightlights: [
      {
        id: "high01",
        icon: <IconHelper.Otticon />,
        highlightTitle: "On-premise hosting ",
        highlightDescription:
          "Get control over your server with on-premise hosting.",
      },
      {
        id: "high02",
        icon: <IconHelper.Otticon />,
        highlightTitle: "One-time license cost",
        highlightDescription:
          " Upgrade or downgrade subscription as per the expectations. ",
      },
      {
        id: "high03",
        icon: <IconHelper.Otticon />,
        highlightTitle: "Third-party service integration",
        highlightDescription:
          "This gives you quicker deployment and cost-effectiveness.  ",
      },
      {
        id: "high04",
        icon: <IconHelper.Otticon />,
        highlightTitle: "End-to-end development support",
        highlightDescription:
          "Get comprehensive support, ensuring a smooth transition.  ",
      },
    ],
  },
  {
    id: "hl3",
    hightlights: [
      {
        id: "high01",
        icon: <IconHelper.Otticon />,
        highlightTitle: "Virtual Classrooms",
        highlightDescription:
          "Broadcast customized health and fitness programs for your users. ",
      },
      {
        id: "high02",
        icon: <IconHelper.Otticon />,
        highlightTitle: "Great visual clarity",
        highlightDescription:
          "Stream high-quality audio and content day in and out. ",
      },
      {
        id: "high03",
        icon: <IconHelper.Otticon />,
        highlightTitle: "Advanced technology",
        highlightDescription:
          "We incorporate the latest technologies in our platform. ",
      },
      {
        id: "high04",
        icon: <IconHelper.Otticon />,
        highlightTitle: "Revenue models for fitness streaming",
        highlightDescription: "Monetize your passion for health and fitness. ",
      },
    ],
  },
  {
    id: "hl4",
    hightlights: [
      {
        id: "high01",
        icon: <IconHelper.Otticon />,
        highlightTitle: "Personalization",
        highlightDescription:
          "Broadcast customized health and fitness programs for your users. ",
      },
      {
        id: "high02",
        icon: <IconHelper.Otticon />,
        highlightTitle: "Great visual clarity",
        highlightDescription:
          "Stream high-quality audio and content day in and out. ",
      },
      {
        id: "high03",
        icon: <IconHelper.Otticon />,
        highlightTitle: "Advanced technology",
        highlightDescription:
          "We incorporate the latest technologies in our platform. ",
      },
      {
        id: "high04",
        icon: <IconHelper.Otticon />,
        highlightTitle: "Revenue models for fitness streaming",
        highlightDescription: "Monetize your passion for health and fitness. ",
      },
    ],
  },
  {
    id: "hl5",
    hightlights: [
      {
        id: "high01",
        icon: <IconHelper.OTT01 />,
        highlightTitle: "End-to-End OTT Solution",
        highlightDescription:
          // "Multi-screen OTT solutions that optimally use full-stack OTT technology. "
          "Full stack solution covering all modules for OTT  platform (backend) and Apps on Mobile, web and TV platforms",
      },

      {
        id: "high02",
        icon: <IconHelper.OTT02 />,
        highlightTitle:
          "Enterprise-Grade Platform",
        highlightDescription:
          // "This lets you and your website visitors chat in real time."
          "A highly scalable and robust platform that scales to serve tens of millions of users",
      },
      {
        id: "high03",
        icon: <IconHelper.OTT03 />,
        highlightTitle: "Multi-Screen Platform",
        highlightDescription:
          // "Stream as and when you please by preparing in advance."
          "Client Apps on all devices - mobile, web and TV platforms",
      },

      {
        id: "high04",
        icon: <IconHelper.OTT04 />,
        highlightTitle: "Managed Services",
        highlightDescription:
          // "Enables users to download content that they wish to save."
          "We provide 24x7 monitoring and support with highly scalable cloud infrastructure services.",
      },
    ],
  },
  {
    id: "hl6",
    hightlights: [
      {
        id: "high01",
        icon: <IconHelper.Broadcast01 />,
        highlightTitle: "Rich experience",
        highlightDescription:
          "Our platform makes content consumption a delightful experience with a broad set of features.",
      },
      {
        id: "high02",
        icon: <IconHelper.Broadcast02 />,
        highlightTitle: "End-to-end Broadcasting ",
        highlightDescription:
          "We offer end-to-end broadcasting solutions for cloud-based and on-prem facilities.",
      },
      {
        id: "high03",
        icon: <IconHelper.Broadcast03 />,
        highlightTitle: "Centralized Automation",
        highlightDescription:
          "Powered by automation, we incorporate the latest technologies into our platform.",
      },
      {
        id: "high04",
        icon: <IconHelper.Broadcast04 />,
        highlightTitle: "Playout, Ingest & turnkey Studio solution  ",
        highlightDescription:
          "Our platform is a single repository to store and manage your video content.",
      },
    ],
  },
  {
    id: "hl7",
    hightlights: [
      {
        id: "high01",
        icon: <IconHelper.Digitization01 />,
        highlightTitle: "On-site mass Digitization ",
        highlightDescription:
          "We combine digitization and utility by making digital content publicly accessible through mass digitization. ",
      },
      {
        id: "high02",
        icon: <IconHelper.Digitization02 />,
        highlightTitle: "Preservation of AV contents ",
        highlightDescription:
          "A digitization platform for preserving AV heritage and enrichment.",
      },
      {
        id: "high03",
        icon: <IconHelper.Digitization03 />,
        highlightTitle:
          "Quality delivery for AV, Stills, Negatives & Cinema Archival ",
        highlightDescription:
          "Our platform ensures a turnkey digitization solution for audio, video, documents, and negatives.",
      },
      {
        id: "high04",
        icon: <IconHelper.Digitization04 />,
        highlightTitle:
          "Centralized Archival, QC, and Metadata management solution with a cloud solution ",
        highlightDescription:
          "We offer a centralized archive, metadata, and QC management solution. ",
      },
    ],
  },
  // {
  //   id: "hl8",
  //   hightlights: [
  //     {
  //       id: "high01",
  //       icon: <IconHelper.HighlightIcon1 />,
  //       highlightTitle: "Feugiat vel molestie.",
  //       highlightDescription:
  //         "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet nisl neque, nisl blandit. Sed porta eget eu, sed. Vel ornare sagittis feugiat.",
  //     },
  //     {
  //       id: "high02",
  //       icon: <IconHelper.Otticon />,
  //       highlightTitle: "Feugiat vel molestie.",
  //       highlightDescription:
  //         "",
  //     },
  //     {
  //       id: "high03",
  //       icon: <IconHelper.Otticon />,
  //       highlightTitle: "Feugiat vel molestie.",
  //       highlightDescription:
  //         "",
  //     },
  //     {
  //       id: "high04",
  //       icon: <IconHelper.HighlightIcon4 />,
  //       highlightTitle: "Feugiat vel molestie.",
  //       highlightDescription:
  //         "",
  //     },
  //     {
  //       id: "high05",
  //       icon: <IconHelper.Otticon />,
  //       highlightTitle: "Feugiat vel molestie.",
  //       highlightDescription:
  //         "",
  //     },
  //     {
  //       id: "high06",
  //       icon: <IconHelper.Otticon />,
  //       highlightTitle: "Feugiat vel molestie.",
  //       highlightDescription:
  //         "",
  //     },
  //   ],
  // },
  // {
  //   id: "hl9",
  //   hightlights: [
  //     {
  //       id: "high01",
  //       icon: <IconHelper.Otticon />,
  //       highlightTitle: "Feugiat vel molestie.",
  //       highlightDescription:
  //         "",
  //     },
  //     {
  //       id: "high02",
  //       icon: <IconHelper.Otticon />,
  //       highlightTitle: "Feugiat vel molestie.",
  //       highlightDescription:
  //         "",
  //     },
  //     {
  //       id: "high03",
  //       icon: <IconHelper.Otticon />,
  //       highlightTitle: "Feugiat vel molestie.",
  //       highlightDescription:
  //         "",
  //     },
  //     {
  //       id: "high04",
  //       icon: <IconHelper.HighlightIcon4 />,
  //       highlightTitle: "Feugiat vel molestie.",
  //       highlightDescription:
  //         "",
  //     },
  //     {
  //       id: "high05",
  //       icon: <IconHelper.Otticon />,
  //       highlightTitle: "Feugiat vel molestie.",
  //       highlightDescription:
  //         "",
  //     },
  //     {
  //       id: "high06",
  //       icon: <IconHelper.Otticon />,
  //       highlightTitle: "Feugiat vel molestie.",
  //       highlightDescription:
  //         "",
  //     },
  //   ],
  // },
];

export default HighlightsData1;
