// Helper
import ColorHelper from "../../Helpers/ColorHelper";
import FontHelper from "../../Helpers/FontHelper";

const CategoriesStyle = {
  CategoriesWrapper: {
    textAlign: "center",
    py: 5,
  },
  caption: {
    fontSize: "1rem",
    fontWeight: 100,
    color: ColorHelper.PriamryColor,
    px: { xs: 5, md: 0 },
  },
  CatergoriesTitle: {
    fontFamily: FontHelper.FontSemiBold,
    fontSize: "2rem",
    fontWeight: 800,
    color: ColorHelper.black,
    mb: 4,
    px: { xs: 5, md: 0 },
  },
  categoriesContianer: {
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    // maxWidth: 'auto',
    // overflowX: 'auto',
  },
  overlay: {
    // minWidth: "16rem",
    // maxWidth: "17.2rem",
    // height: "20rem",
    // background:
    //   "linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,212,255,0) 75%, rgba(0,212,255,0) 100%)",
    position: 'absolute',
    left: '0%',
    right: '0%',
    top: '0',
    bottom: '0%',
    background: 'linear-gradient(360deg, #000000 0%, rgba(0, 0, 0, 0.48) 40.97%, rgba(0, 0, 0, 0) 68.64%)',

  },
  categoryBlock: {
    mx: 5,
    minHeight: "20rem",
    minWidth: "16rem",
    maxWidth: "17.2rem",
    backgroundRepeat: "no-repeat",
    position: "relative",
    'img': {
      width: '100%',
      height: '20rem',
      position: 'absolute',
      top: 0,
      left: 0,
      objectFit: 'cover',
    },
    "&:hover #overlay": {
      backgroundColor: "#000",
      opacity: 0.5,
    },
    "&:hover #categoryDesc": {
      display: "block",
    },
  },
  categorytexts: {
    p: 2,
    position: "absolute",
    bottom: 0,
    left: "3%",
    right: "3%",
  },
  categorytTitle: {
    fontSize: 18,
    fontWeight: 100,
    fontFamily: FontHelper.FontRegular,
    color: ColorHelper.white,
  },
  categoryDesc: {
    fontSize: 14,
    fontWeight: 100,
    color: ColorHelper.white,
    display: "none",
  },
};

export default CategoriesStyle;
