// Helper
import ColorHelper from "../../Helpers/ColorHelper";
import FontHelper from "../../Helpers/FontHelper";

const HeroSectionStyle = {
  container: {
    minHeight: { xs: 'max-content', md: "70vh", lg: "100vh" },
    minWidth: "100%",
    backgroundColor: ColorHelper.PriamryColor,
    // px: {xs : 10, md : 5},
    px: { xs: 0, md: 15 },
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: { xs: "column", md: "row" },
    pt: { xs: 'unset', md: '5%' },
  },
  contents: {
    // margin: 'auto',
    mt: { xs: 0, md: 12 },
    mb: { xs: 5, md: 0 },
    order: { xs: 2, md: 1 },
    width: { xs: '100%', md: '75%' },
  },
  title1: {
    fontFamily: FontHelper.FontSemiBold,
    fontSize: { xs: "1.5rem", md: '3vw' },
    fontWeight: 300,
    color: ColorHelper.white,
    lineHeight: { xs: 1.2, md: '4vw' },
    textAlign: { xs: "center", md: "left" },
    mt: { xs: -20, md: -10 },
    px: { xs: 2, md: 'unset' },
    display: 'none',
  },
  title2: {
    fontFamily: FontHelper.FontSemiBold,
    fontSize: { xs: "1.5rem", md: '3vw' },
    fontWeight: 600,
    color: ColorHelper.white,
    lineHeight: { xs: 1.2, md: 1.2 },
    textAlign: { xs: "center", md: "left" },
    px: { xs: 2, md: 'unset' },
    maxWidth: { xs: 'unset', md: '45vw' },
  },
  heroContent: {
    fontFamily: FontHelper.FontSemiBold,
    fontSize: { xs: "0.8rem", md: 18 },
    fontWeight: 100,
    color: ColorHelper.white,
    maxWidth: { xs: "100%", md: "45vw" },
    lineHeight: { xs: 1.2, md: '4vw' },
    textAlign: { xs: "center", md: "left" },
    my: 2,
  },
  btnContainer: {
    mt: 2,
    ml: -1,
    textAlign: { xs: "center", md: "left" },
    display: 'flex',
    justifyContent: { xs: 'center', md: 'flex-start' },
    flexWrap: 'wrap',
    alignItems: 'center',
    position: 'relative',
  },
  HeroNavbtns: {
    display: "flex",
    justifyContent: { xs: "center", md: "flex-start" },
    alignItems: "center",
  },
  NavBtns: {
    px: 0,
    justifyContent: "left",
    mt: { xs: 4, md: 10 },
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  img_container: {
    width: { xs: '100vw', md: '30%' },
    order: { xs: 1, md: 2 },
    // display: 'block',
    // justifyContent: 'center',
    // alignItems: 'center',
    mt: { xs: 6, md: 0 },
    mb: -10,
    textAlign: 'center',
    mr: { xs: 15, md: 15 },
    display: 'block',
    justifyContent: 'center',
    alignItems: 'center',
    'svg': {
      maxWidth: '80vw',
    }
  },
  image: {
    maxWidth: "100%",
  },
  sectionWrapper: {
    mt: { xs: 5, md: 7 },

    position: "relative",

    backgroundColor: ColorHelper.PriamryColor,

    pt: { xs: 1, md: 1 },

    '.react-multiple-carousel__arrow': {
      '&: before': {
        fontSize: '30px',
        fontWeight: 'bolder',
      }
    },
    '.react-multiple-carousel__arrow--left': {
      background: 'rgba(0,0,0,0.0)',
      left: 'calc(-1% + 1px)',
      backgroundColor: '#ffffff !important'
    },
    '.react-multiple-carousel__arrow--right': {
      background: 'rgba(0,0,0,0.0)',
      right: 'calc(-1% + 1px)',
    }
  }
};

export default HeroSectionStyle;
