import React, { useRef, useState } from "react";
import {
  Grid,
  CardContent,
  MenuItem,
  InputLabel,
  Select,
  CardActions,
  Button,
  FormControl,
} from "@mui/material";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "formik-material-ui";
import FilledButton from "../../Ui-Components/Buttons/FilledButton/FilledButton";
import ConstantHelper from "../../../Helpers/ConstantHelper";
import ColorHelper from "../../../Helpers/ColorHelper";
import TalkToUsListingData from "./TalkToUsListingData";
import CountryCodeData from "../../../Helpers/CountryCodeData";

import EmailJsConfig from "../../../Helpers/EmailJsConfig";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";
import ThankYouModal from "./ThankYouModal";

//Data
const initialValues = {
  from_name: "Talk To Us",
  firstName: "",
  lastName: "",
  country_code: "+91",
  servicesOrProducts: "",
  menuservices: "",
  email: "",
  PhoneNo: "",
  Message: "",
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
//props
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 80,
    },
  },
};

// const options = [
//   { label: "Website Development", value: "website-development" },
//   { label: "Software Development", value: "software-development" },
//   {
//     label: "Mobile Application Development",
//     value: "mobile-application-development",
//   },
//   { label: "Digital Marketing", value: "digital-marketing" },
//   { label: "Careers", value: "careers" },
// ];

//PhoneNo validation
const numericRegEx = /(?=.[0-9])/;
const lengthRegEx = /(?=.{10,})/;

//validation schema
let validationSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  PhoneNo: Yup.string()
    .matches(numericRegEx, "Must contain only Numbers!")
    .matches(lengthRegEx, "Must contain 10 Digits!")
    .required("Required!"),
});

const TalkTousFormdrp = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const { open, onClose, formprops } = props;

  const form = useRef();

  const onSubmit = (values, formicHelpers) => {
    emailjs
      .send(
        EmailJsConfig.SERVICE_ID,
        EmailJsConfig.TEMPLATE_ID2,
        values,
        EmailJsConfig.USER_ID
      )
      .then(
        (response) => {
          console.log(
            "Email sent successfully:",
            response.status,
            response.text
          );
          setOpenModal(true);
        },
        (error) => {
          console.error("Error sending email:", error);
        }
      );

    formicHelpers.resetForm();
  };

  const [servicesOrProducts, setServicesOrProducts] =
    useState(TalkToUsListingData);
  const [MenuListing, setMenuListing] = useState([]);
  const selectValue = (e) => {
    console.log(e);
    const selectedState = servicesOrProducts.filter(
      (v) => v.option === e.target.value
    );
    setMenuListing(selectedState[0].optionData);
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ dirty, isValid, values, handleChange, handleBlur }) => {
          return (
            <Form ref={form}>
              <CardContent sx={{ div: { width: "100%" } }}>
                <Grid item container spacing={1} justify="center">
                  <Grid item xs={12} sm={6} md={6}>
                    <Field
                      label="First Name"
                      variant="outlined"
                      fullWidth
                      name="firstName"
                      value={values.firstName}
                      component={TextField}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Field
                      label="Last Name"
                      variant="outlined"
                      fullWidth
                      name="lastName"
                      value={values.lastName}
                      component={TextField}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={12}>
                    <Field
                      label="Email"
                      variant="outlined"
                      fullWidth
                      name="email"
                      value={values.email}
                      component={TextField}
                    />
                  </Grid>

                  <Grid item xs={4} sm={2} md={2}>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.country_code}
                      name="country_code"
                      // size="small"
                      fullWidth
                      MenuProps={MenuProps}
                      sx={{ color: ColorHelper.black }}
                    >
                      {CountryCodeData.map((item) => (
                        <MenuItem key={item.code} value={item.code}>
                          {item.code}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={8} sm={10} md={10}>
                    <Field
                      label="PhoneNo"
                      variant="outlined"
                      fullWidth
                      name="PhoneNo"
                      value={values.PhoneNo}
                      // type="PhoneNo"
                      component={TextField}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id="demo-simple-select-outlined-label">
                        You are interested in?
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        label="You are interested in?"
                        onChange={(e) => {
                          handleChange(e);
                          selectValue(e);
                        }}
                        onBlur={handleBlur}
                        value={values.servicesOrProducts}
                        name="servicesOrProducts"
                      >
                        {TalkToUsListingData.map((item) => (
                          <MenuItem key={item.option} value={item.option}>
                            {item.option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id="demo-simple-select-outlined-label">
                        Category
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        label="Category"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.menuservices || ""}
                        defaultValue={values.menuservices || ""}
                        name="menuservices"
                        disabled={false}
                      >
                        {TalkToUsListingData.filter(
                          (ele) => ele.option === values.servicesOrProducts
                        )[0]?.optionData?.map((item, idx) => (
                          <MenuItem key={idx} value={item.value}>
                            {/* {menuNameForModal == values.menuservices.split("-")[0]
                            ? item
                            : "" || item} */}

                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  {/*   <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                // sx={{ mt: { xs: 0, md: 0 } }}
                >
                  <FormControl fullWidth variant="outlined">
                    {/* <InputLabel id="demo-simple-select-outlined-label">
                      Select city
                    </InputLabel> */}
                  {/*  <Field
                    label="you are Intrested In"
                    variant="outlined"
                    fullWidth
                    name="you are Intrested In"
                    value={formprops?.pageName}
                    component={TextField}
                  />
                  </FormControl>
                </Grid>  */}

                  <Grid item xs={12} sm={6} md={12}>
                    <Field
                      label="Message"
                      variant="outlined"
                      fullWidth
                      name="Message"
                      value={values.Message}
                      // type="Message"
                      component={TextField}
                      multiline={true}
                      sx={{
                        textarea: {
                          minHeight: "40px",
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions>
                <Button
                  disableRipple
                  disabled={!dirty || !isValid}
                  type="Submit"
                >
                  <FilledButton
                    btnText={ConstantHelper.navBtn2}
                    color={ColorHelper.PriamryColor}
                    bgColor={ColorHelper.white}
                  />
                </Button>
              </CardActions>
            </Form>
          );
        }}
      </Formik>
      <ThankYouModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        message="Thank you for getting in touch with us. We will get back to you shortly."
      />
    </>
  );
};

export default TalkTousFormdrp;
