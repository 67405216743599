// Helpers
import ColorHelper from "../../../../Helpers/ColorHelper";

const modalBGcolor = {
  backgroundColor: ColorHelper.white,
};

const ShowBannerModalStyle = {
  modalWrapper: {
    ...modalBGcolor,
  },
  modalHeader: {
    ...modalBGcolor,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    pl: 2,
    pt: 1,
  },
  modalContent: {
    ...modalBGcolor,
    p: 2,
  },
  main: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "fit-content",
  },
  videoContainer: {
    width: { xs: "100%", md: "100%" },
  },
  moalFooter: {
    ...modalBGcolor,
    borderTop: `2px solid ${ColorHelper.lightGrey}`,
    py: 2,
  },
};

export default ShowBannerModalStyle;
