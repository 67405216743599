import React from "react";

const HLTvDRM02 = () => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23 33.4556C24.4948 33.4556 25.7065 32.2438 25.7065 30.749C25.7065 29.2542 24.4948 28.0425 23 28.0425C21.5052 28.0425 20.2935 29.2542 20.2935 30.749C20.2935 32.2438 21.5052 33.4556 23 33.4556Z"
        stroke="#457ABD"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M23 33.4556V37.9702"
        stroke="#457ABD"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M12.1738 20.2931V12.1738C12.1738 6.19473 17.0209 1.34766 23 1.34766C28.9791 1.34766 33.8262 6.19473 33.8262 12.1738V20.2923"
        stroke="#457ABD"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M17.5869 20.2923V12.1738C17.5869 9.18428 20.0104 6.76074 23 6.76074C25.9896 6.76074 28.4131 9.18428 28.4131 12.1738V20.2923"
        stroke="#457ABD"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M36.5332 44.6523H9.46684L6.76074 41.9462V23.0005L9.46684 20.2944H36.5332L39.2393 23.0005V41.9462L36.5332 44.6523Z"
        stroke="#457ABD"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
    </svg>
  );
};

export default HLTvDRM02;
