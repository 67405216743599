const ConstantHelper = {
  siteName: "Mobiotics",

  // HOMEPAGE

  // Navbar
  MenuName1: "Products",
  MenuName2: "Solutions",
  MenuName3: "Services",
  MenuName4: "Company",
  MenuName5: "Resources",
  MenuName6: "OTT",

  WhatsappOrCallUs: "Call us ",
  ContactNo: "+91 96202 09869",
  DropMail: "Email us",

  // social links
  fb: `https://www.facebook.com/mobiotics`,
  LinkedIn: `http://www.linkedin.com/company/1840031`,
  Twitter: `https://twitter.com/mobiotics`,
  Instagram: `https://www.instagram.com/mobioticsltd/`,

  mainId: "sales@mobiotics.com",

  something: "Highlights / Events / Announcements",
  navBtn1: "Talk to Us",
  navBtn2: "Request Demo",

  navBtn3: "Know more",
  navBtn4: "Send Message",
  navBtn5: "Carrier",
  heroText1: "Live Streaming Platform for",
  heroText2: "Businesses & Individuals",
  heroText3: "Live Streaming Platform for",
  heroText4: "Businesses & Individuals",
  heroText1:
    "Launch your OTT service with Content Delivery, Engagement and Monetization solutions.",
  heroText2: "",
  heroText3: " Captivate your",
  heroText4: "audience with a robust LIVE Video broadcasting platform.",
  heroText5: "Let’s make your content",
  heroText6: "streaming service digitally sound and optimized.",
  heroContentA: "",
  heroContentB: "",
  heroContentC: "",
  heroBtn1: "Talk To Us",
  heroBtn2: "Explore our products",
  heroBtn3: "Explore more",
  CTA_btn: "Talk to Us",

  // Streaming Type
  caption: "Explore our wide range of products",
  StreamTypeSectionTitle:
    "Your go-to partner for all online video streaming services",
  StreamingType1: "vLive",
  StreamingType2: "vLite",
  StreamingType3: "Sounds",
  StreamingType4: "Health+",
  StreamingType5: "vLite MOOC",
  StreamingType6: "vAdS",
  StreamingType7: "vBOX",
  StreamingType8: "vAnalytics",
  StreamingType9: "vSuggest",

  StreamingType10: "Operators",
  StreamingType11: "BroadCaster",
  StreamingType12: "Content Creators",
  StreamingType13: "SMEs",
  StreamingType14: "Enterprises",

  StreamingType15: "Ott Apps",
  StreamingType16: "Ott Consulting",
  StreamingType17: "DevOps",
  StreamingType18: "Custom Dev",
  StreamingType19: "Qa Automation",

  StreamingType20: "vApps",
  StreamingType21: "Sounds",
  StreamingType22: "vDRM",
  StreamingType23: "vCMS",
  StreamingType24: "vSMS",


  // Streaming Tag line

  StreamingTagLine1: "End-to-End OTT SaaS Suite",
  StreamingTagLine4: "Audio Platform as a service",
  StreamingTagLine2: "eLearning Management Service",
  StreamingTagLine3: "On-Demand App Store",
  StreamingTagLine5: "Multi DRM packaging and licensing service",
  StreamingTagLine6: "Enhanced OTT Subscriber Management",
  // StreamingTagLine7 : "Accelerate Delivery of Content to Worldwide",
  StreamingTagLine8 : "Centralized Video Content Management",

  // StreamingModal
  streamingModalBtn1: "Ok",
  streamingModalBtn2: "Close",

  // HighLight Card
  CardTitle: "Experience Next Generation Content Watching Experience.",
  CardCaption:
    "Mobiotics is a pioneer in OTT, Broadcast and Digitization technologies. We use our decade-long experience to drive immersive video experience through AR, VR, Deep Learning and Context-Aware Technologies.",
  CardLink: " Let’s connect",

  // Cross Platform
  CrossPFTitle: "Widen your Audience base with Cross-Platform reach",
  CrossPFCaption: "",

  // Categories Section
  CatergoriesCaption: "",
  CatergoriesTitle: "Industries we cater to",

  // LeadingBrands
  LeadingBrandsTitle: "Our Customer Base",

  //Testimonial Section
  TestimonialTitle: "Don’t just take our word for it",

  // ShowBanner3d
  ShowBanner3dTitle: "Videos",

  // Footer
  FooterCardTitle:
    "Mobiotics is your one-stop-destination for End to End OTT, Broadcast, Digitization Solutions",
  FooterCardCaption: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  FooterCardBtn1: "Explore Products",
  FooterCardBtn2: "Get started",
  FootershortDesc:
    "New age Media Technology Company providing End-to-end OTT Platform, Broadcast and Digitization solutions to customers across the Globe.",
  Copyright: "© 2023 Mobiotics. All rights reserved.",
  FooterMenu1: "Products",
  FooterMenu2: "Company",
  FooterMenu3: "Solutions",
  FooterMenu4: "Services",
  FooterMenu5:"Resources",

  // SERVICE PAGE

  // Service List Section
  ServicesListCaption:
    "All the features that you need to grow your OTT Platform",
  ServiceListCarrierCaption: "Our Culture",
  ServicesListTitle:
    "All The Features You Need To Launch Your Audio Streaming Platform",

  ServicesListTitle0:
    "All The Features You Need To Launch and Grow your OTT Service",
  ServicesListTitle1:
    "All The Features You Need To launch and Grow Your eLearning management software",
  ServicesListTitle2:
    "All The Features You Need To Launch Your Audio Streaming Platform",
  ServicesListTitle3:
    "Features You Need to Protect Your Content with Multi DRM Service",
    ServicesListTitle4:
    "All the Features You Need to Manage Your Audio, Video and Live content ",
  ServicesListTitle5:
    "All The Features you need to Manage your Users and Grow Your OTT Revenue",
  ServicesListTitle6:
    "All the Features Required To Grow Your App Economy on Android AOSP Devices",

  HighLightCaption1: "Highlights of",
  HightlightService1: "vLive",
  HightlightService2: "Mobiotics",

  HighLightCaption2: "Industries we cater to",
  IndustiesSectionTitle: "Quis ex enim sint commodo.",

  knowMore: "Know More",

  leader: "Leadership Team",
  leadeScetion: "Meet our Leaders ",
  leaderDetails: "",

  //who-is-for-it

  CTATitle1: "Widen your Audience base with Cross-Platform reach",
  CTAbtn1: "Get Started",

  // contact from Constants
  email: "Email",
  office1: "Bangalore",
  // office2: "Kerala",
  // office3: "US",
  phone: "Phone",

  email_content1: "Write to us",
  email_content2: "sales@mobiotics.com",

  office_content1:
    "404, 22nd Cross Rd, Parangi Palaya, BDA Layout, HSR Layout, Bengaluru, Karnataka 560102",

  // office_content2:
  //   "Thapasya Annex, Near Park Centre, Infopark, Kakkanad, Kochi, Kerala 682042",

  // office_content3:
  //   "2035 Sunset Lake Road, Suite-B2, Newark, Delaware, United States - 19702",

  phone_content1: "Mon-Fri from 8am to 5pm.",
  phone_content2: "+91 96202 09869",

  PartnerCaption: "Our Partners",
  ParnterTitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  PartnerDescription:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et dignissim maecenas at neque. Enim sed fermentum ut et laoreet non ut tellus sit. Et accumsan morbi sit id eget placerat sit netus quis. Feugiat lectus lectus id.",

  AbooutBannerTitle: "Grow Your Career with Us  ",
  AboutBannerDescription:
    `<p>Are you passionate about entertainment and technology? Do you thrive in a dynamic and fast-paced environment? <br /> If so, we want to hear from you!</p>`,
  AboutBannerBtn: "Join Us",

  AboutCaption: "",
  AboutTitle:
    "New age Media Technology Company providing End-to-end OTT Platform, Broadcast and Digitization solution.",
  AboutrDescription:
    "Launch OTT platform to deliver and monetize your Entertainment or Learning content, Setup new TV channel or digitize your old video/audio assets through our state-of-the-art technology solutions.",
  MeetTheTeam: "Meet the team",

  TrustedBrandsTitle: "The world’s most trusted brands trust Mobiotics",

  ContactBannertitle:
    "Contact Our Team To Discover Your Business Benefits with Mobiotics",
  ContactBannercaption:
    "Explore how Mobiotics can help you launch and monetize your OTT platform",
  ContactBannernavBtn2: "Get in touch",
  ContactBannerdemoBtnLink: "/",

  ContactFormTitle: "Our friendly team would love to hear from you",
  ContactFormCaption: "Get in touch with us",

  //Linkdin Url
  tej: "https://www.linkedin.com/in/tppandey",
  sangram: "https://www.linkedin.com/in/sangram-keshari-rout-6b537b5/",
  praneeth: "https://www.linkedin.com/in/praneet-dixit-7a1005122",
  rajeshbatia: "https://www.linkedin.com/in/rajeshbhatiatelecomsoftware/",
  subramanya: "https://www.linkedin.com/in/vrsubramanyam/",
  sajesh: "https://www.linkedin.com/in/sajesh-nambiar-2a8ba348/",
  rajeshKm: "https://www.linkedin.com/in/rajesh-km-9491b61",
  rishavChoudhary: "https://www.linkedin.com/in/rishavcch",
  callUs: "Call Us",

  // Modal
  modalName1: "Request a demo and discover why Mobiotics is right for you!",
};

export default ConstantHelper;
