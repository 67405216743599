import ImageHelper from "../ImageHelper";
import ConstantHelper from "../ConstantHelper";

const TeamData = [
  {
    id: 1,
    teamImg: ImageHelper.Tej,
    Detail_description: "Tej Pandey is a versatile leader and entrepreneur with over 20 years of experience building software products related to OTT platforms, mobile computing and embedded technology. He is an expert in building and managing high-performance engineering teams, sales and business development, and managing enterprise customers.",
    description: "Tej Pandey is a versatile leader and entrepreneur with over 20 years of experience building software ...",
    name: "Tej Pandey ",
    Read: "Read more",
    designation: "Founder & CEO",
    socialLink1: "/",
    url: ConstantHelper.tej
  },
  {
    id: 2,
    teamImg: ImageHelper.CEO,
    Detail_description: "Sangram has over two decades of work experience in IT sectors with diverse industry expertise like Healthcare, Automotive, Retail and Supply Chain, Insurance, Travel and HR. He has been part of various reputed corporates such as Infinite, Microgenesis, Volvo IT, TVS Infotech, Hinduja Tech, Pentafour etc. He has also Co-founded MTMC Medi-Info Pvt. Ltd and Vision Next IT. He is recognized for his leadership skill in Startups Advisory, Business Consulting, Technology Advisory, Delivery Management, Quality Process Management, Organisational Strategy and Growth and more. ",
    description: "Sangram has over two decades of work experience in IT sectors with diverse industry expertise li..",
    name: "Sangram Keshari Rout",
    Read: "Read more",
    designation: "Executive Director",
    socialLink1: "/",
    url: ConstantHelper.sangram
  },
  {
    id: 3,
    teamImg: ImageHelper.praneeth,
    description: "Praneet Dixit joined Mobiotics as Head - Broadcast, OTT & New Business. He has over 20 years of ...",
    Detail_description: "Praneet Dixit joined Mobiotics as Head - Broadcast, OTT & New Business. He has over 20 years of experience in Broadcast Technology, Television, and Media and expertise in Digitization, OTT_IPTV, and Network Design.  He was associated with and is a part of Projects such as Parliament Library (Parliament of India) Robotic Camera Setup, Lok Sabha TV, News24, E24, ET NOW, Times Now, TVC Africa, Rwanda Broadcasting Agency, 9Mobile's 9TV (Nigeria OTT 9TV setup), CEC VYAS Channel - Consortium for Education Communication and many more.  He is adept at technology in creating revenue opportunities and analyzing information system needs in the process and certified in AWS Solution Architect, Blockchain, AI, and Data Science. ",
    name: "Praneet Dixit",
    Read: "Read more",
    designation: "Head - Broadcast, OTT & New Business ",
    socialLink1: "/",
    url: ConstantHelper.praneeth
  },
  {
    id: 4,
    teamImg: ImageHelper.rishav,
    Detail_description: "Rishav joined Mobiotics as VP Sales. Rishav is a seasoned Sales and Business Development professional with more than two decades of experience and a wide and vibrant international network in the Media, Technology, and Telecommunications sectors. Previously, Rishav has worked with the likes of Comcast, Nagra, Disney.",
    description: "Rishav joined Mobiotics as VP Sales. Rishav is a seasoned Sales and Business Development professional with more ...",
    name: "Rishav Choudhary",
    Read: "Read more",
    designation: "VP Sales",
    socialLink1: "/",
    url: ConstantHelper.rishavChoudhary
  },
  {
    id: 5,
    teamImg: ImageHelper.rajeshBhatia,
    Detail_description: "Rajesh has more than 20 years of experience in the Industry. He has worked in various MNCs in the past including HP for 13 years building products and solutions using Technology. He has handled various roles in his career including Head Of Product Management, Engineering Management and Lead Architect. Before joining Mobiotics, he was working on his own venture. He has 25 US Patents in various aspects of Technology that includes  Cloud Computing, Security, Mobility and Printing Domains.",
    description: "Rajesh has more than 20 years of experience in the Industry. He has worked in various MNCs in the past including HP for 13 years...",
    name: "Rajesh Bhatia",
    Read: "Read more",
    designation: "VP Technology",
    socialLink1: "/",
    url: ConstantHelper.rajeshbatia
  },

  {
    id: 6,
    teamImg: ImageHelper.subramanyam,
    Detail_description: "Nearly 20 years of software delivery and operations experience collaborating with clients to deliver large scale enterprise solutions and support services in the areas of knowledge management, Digital & E-commerce, logistics, product lifecycle management, OTT and commodity management.   Experienced in establishing the new digital practice and delivery of onsite/offshore hybrid models with remote teams ",
    description: "Nearly 20 years of software delivery and operations experience collaborating with clients to ...",
    name: "Subramanyam V R",
    Read: "Read more",
    designation: "VP - Operations",
    socialLink1: "/",
    url: ConstantHelper.subramanya
  },
  // {
  //   id: 6,
  //   teamImg: ImageHelper.sajesh,
  //   Detail_description: "Sajesh Nambiar is an experienced technology leader with over 20 years in IT and ITES sectors, closely associated with many leading organizations in domains like Aviation, Banking & Finance, Insurance, Telecom, Taxation, Education, Engineering Services, Logistics and Supply Chain for designing and implementing their IT initiatives. His proven expertise ranges from supporting organizational growth of start-ups from single/double digits resource count to three/four digits by providing suitable technology solutions, and as well in leading 100+ decentralized technical team members for developing and implementing core IT projects of large enterprises having a nationwide presence. In his current role as Technical Program Manager,  he is enhancing customer experience through delivery excellence and employee engagement. He is primarily responsible for the development and delivery of Mobile, Web and Cloud based solutions in OTT products and non-OTT Projects. He is also holding an additional responsibilities of establishing the PMO initiatives.  Sajesh holds an MCA from Annamalai University, an MBA from SMU, a PGDCA from IHRDE and a Bachelor’s degree from Calicut University.",
  //   description: "Sajesh Nambiar is an experienced technology leader with over 20 years in IT and ITES sectors, closely associated with ...",
  //   name: "K.V. Sajesh Nambiar",
  //   Read: "Read more",
  //   designation: "Technical Program Manager",
  //   socialLink1: "/",
  //   url: ConstantHelper.sajesh
  // },
  // {
  //   id: 7,
  //   teamImg: ImageHelper.rajesh,
  //   Detail_description: "Rajesh is a seasoned engineering professional having 18+ years of experience in IT Project Management, System Architecture, and Product Lifecycle Management with reputed organizations. Rajesh focuses on the development and successful delivery to customers across OTT lines. His key focus is on product management and delivery of front-end apps such as iOS, Android, web, server-side APIs, and integration with various payment gateways and analytics platforms. He currently serves as Technical Program Manager responsible for delivery and heading the Kochi team at Mobiotics. Rajesh has a proven track record of working across various domains such as Electronic Payment Systems, Mortgage, Image Processing, Turnaround systems for oil refineries, Manufacturing, Retail, and Transportation & Logistics. He has hands-on experience in the design and development of web, windows & mobile applications, and APIs, and he was responsible for system integration with SAP, MS Dynamics NAV, Harland E3, EllieMae Encompass360, etc., in various projects. He holds a Bachelor's degree in Electronics Engineering from Cochin University of Science and Technology, Kochi.",
  //   description: "Rajesh is a seasoned engineering professional having 18+ years of experience in IT Project ...",
  //   name: "Rajesh KM",
  //   Read: "Read more",
  //   designation: "Technical Program Manager",
  //   socialLink1: "/",
  //   url: ConstantHelper.rajeshKm
  // },
];

export default TeamData;
