import React from "react";

const HLTSounds02 = () => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3085_81283)">
        <path
          d="M19.6309 31.5435H1.34766"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M26.3691 31.5435H44.6523"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M26.3691 31.5435C26.3691 33.4042 24.8608 34.9126 23 34.9126C21.1392 34.9126 19.6309 33.4042 19.6309 31.5435C19.6309 29.6827 21.1392 28.1743 23 28.1743C24.8608 28.1743 26.3691 29.6827 26.3691 31.5435Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M30.6821 41.2832H1.34766"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M37.4204 41.2832H44.6524"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M37.4204 41.2832C37.4204 43.144 35.9119 44.6523 34.0513 44.6523C32.1905 44.6523 30.6821 43.144 30.6821 41.2832C30.6821 39.4224 32.1905 37.9141 34.0513 37.9141C35.9119 37.9141 37.4204 39.4224 37.4204 41.2832Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M29.4945 13.7815C29.4945 10.2542 26.5415 7.40367 22.9779 7.57213C19.8157 7.72172 17.2434 10.2744 17.071 13.4356C16.9638 15.4027 17.7776 17.1862 19.1189 18.3957C19.7768 18.9888 20.1694 19.8205 20.1694 20.7063V20.8245C20.1694 23.4455 17.0797 24.8756 15.1052 23.152C12.494 20.8728 10.8442 17.5198 10.8442 13.7815C10.8442 6.91456 16.411 1.34775 23.2781 1.34775C23.3953 1.34775 23.5123 1.34973 23.6288 1.35297L23.6241 1.34775H44.6524V16.8911H35.319C34.6839 19.3575 33.3112 21.5277 31.4501 23.1524C29.4759 24.8757 26.3867 23.4448 26.3867 20.8244V20.7059C26.3867 19.8376 26.7505 19.0052 27.4 18.4289C28.6838 17.2897 29.4945 15.6289 29.4945 13.7815Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.50126 7.5651C5.78444 7.5651 4.39258 8.95687 4.39258 10.6738C4.39258 12.3906 5.78444 13.7825 7.50126 13.7825H10.8443V13.7815"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M23.624 1.34757H23.278C23.3954 1.34757 23.5123 1.34955 23.6289 1.35278L23.624 1.34757Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M23.2781 1.34757H4.45634C2.73943 1.34757 1.34766 2.73934 1.34766 4.45626C1.34766 6.17308 2.73943 7.56494 4.45634 7.56494H12.5079"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3085_81283">
          <rect width="46" height="46" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HLTSounds02;
