import * as React from "react";
import Button from "@mui/material/Button";
import { Box } from "@mui/system";

// Helpers
import ColorHelper from "../../../../Helpers/ColorHelper";

export default function BorderedButton({
  color,
  bgColor,
  bordercolor,
  btnText,
  icon,
  sx,
  handleClick,
  ...rest
}) {
  // const { color, bgColor, bordercolor, btnText, icon, sx, handleClick,...rest } = props;
  return (
    <Button
      onClick={handleClick}
      variant="outlined"
      sx={{
        borderColor: bordercolor,
        color: color,
        backgroundColor: bgColor,
        mx: 1,
        py: 1.2,
        borderRadius: "10px",
        maxHeight: "3rem",
        minHeight: "3rem",
        minWidth: "max-content",
        textTransform: "unset",
        fontWeight: 800,
        ...sx,
        "&:hover": {
          backgroundColor: bordercolor,
          color: `${ColorHelper.BgBlue}`,
          borderColor: bgColor,
        },
      }}
    >
      {icon ? <Box sx={{ mt: 1 }}>{icon}</Box> : null}

      {btnText ? <Box sx={icon && { ml: "0.7rem" }}>{btnText}</Box> : null}
    </Button>
  );
}
