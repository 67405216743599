import React, { useRef, useState } from "react";
import {
  Grid,
  CardContent,
  MenuItem,
  InputLabel,
  Select,
  CardActions,
  Button,
  FormControl,
  Typography,
} from "@mui/material";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "formik-material-ui";
import FilledButton from "../../Components/Ui-Components/Buttons/FilledButton/FilledButton";
import ConstantHelper from "../../Helpers/ConstantHelper";
import ColorHelper from "../../Helpers/ColorHelper";
import ContactSectionStyle from "./ContactSectionStyle";

import CountryCodeData from "../../Helpers/CountryCodeData";
import { Box } from "@mui/system";

import { Theme, useTheme } from "@mui/material/styles";
import { SelectChangeEvent } from "@mui/material/Select";

import EmailJsConfig from "../../Helpers/EmailJsConfig";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";

import TalkToUsListingData from "../HeroSection/TalkToUsModal/TalkToUsListingData";
import ThankYouModal from "../HeroSection/TalkToUsModal/ThankYouModal";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 80,
    },
  },
};

//Data
const initialValues = {
  from_name: "Contact Us",
  firstName: "",
  lastName: "",
  email: "",
  country_code: "+91",
  PhoneNo: "",
  Message: "",
};

const options = [
  { label: "Website Development", value: "website-development" },
  { label: "Software Development", value: "software-development" },
  {
    label: "Mobile Application Development",
    value: "mobile-application-development",
  },
  { label: "Digital Marketing", value: "digital-marketing" },
  { label: "Careers", value: "careers" },
];

//PhoneNo validation
const numericRegEx = /(?=.[0-9])/;
const lengthRegEx = /(?=.{10,})/;

//validation schema
let validationSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  PhoneNo: Yup.string()
    .matches(numericRegEx, "Must contain only Numbers!")
    .matches(lengthRegEx, "Must contain 10 Digits!")
    .required("Required!"),
});

const TaktoUs = () => {
  const [openModal, setOpenModal] = useState(false);
  const [servicesOrProducts, setServicesOrProducts] =
    useState(TalkToUsListingData);
  const [MenuListing, setMenuListing] = useState([]);

  const form = useRef();

  const onSubmit = (values, formicHelpers) => {
    emailjs
      .send(
        EmailJsConfig.SERVICE_ID,
        EmailJsConfig.TEMPLATE_ID2,
        values,
        EmailJsConfig.USER_ID
      )
      .then(
        (response) => {
          console.log(
            "Email sent successfully:",
            response.status,
            response.text
          );
          setOpenModal(true);
        },
        (error) => {
          console.error("Error sending email:", error);
        }
      );

    formicHelpers.resetForm();
  };

  // const onSubmit = (values, formicHelpers) => {
  //   console.log(values);
  //   formicHelpers.resetForm();
  // };

  const selectValue = (e) => {
    const selectedState = servicesOrProducts.filter(
      (v) => v.option === e.target.value
    );
    setMenuListing(selectedState[0].optionData);
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ dirty, isValid, values, handleChange, handleBlur }) => {
          return (
            <Form ref={form}>
              <CardContent>
                <Grid
                  item
                  container
                  spacing={1}
                  justify="center"
                  sx={{ mt: -5 }}
                >
                  <Grid item xs={12} sm={6} md={6}>
                    <Typography sx={ContactSectionStyle.formlabels}>
                      First Name
                    </Typography>
                    <Typography sx={{ mt: 2 }}>
                      <Field
                        variant="outlined"
                        fullWidth
                        name="firstName"
                        value={values.firstName}
                        component={TextField}
                        size="small"
                        placeholder="First Name"
                      />
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Typography sx={ContactSectionStyle.formlabels}>
                      Last Name
                    </Typography>
                    <Typography sx={{ mt: 2 }}>
                      <Field
                        variant="outlined"
                        fullWidth
                        name="lastName"
                        value={values.lastName}
                        component={TextField}
                        size="small"
                        placeholder="Last Name"
                      />
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Typography sx={ContactSectionStyle.formlabels}>
                      Email Address
                    </Typography>
                    <Typography sx={{ mt: 2 }}>
                      <Field
                        variant="outlined"
                        fullWidth
                        name="email"
                        value={values.email}
                        component={TextField}
                        size="small"
                        placeholder="Email Address"
                      />
                    </Typography>
                  </Grid>
                  {/* <Grid item xs={12} sm={6} md={6}>
                  <Typography sx={ContactSectionStyle.formlabels}>Phone Number</Typography>
                  <Typography sx={{ mt: 2 }}>
                    <Field
                      variant="outlined"
                      fullWidth
                      name="PhoneNo"
                      value={values.PhoneNo}
                      type="PhoneNo"
                      component={TextField}
                      size="small"
                    />
                  </Typography>
                </Grid> */}
                  <Grid item xs={12} sm={6} md={6}>
                    <Typography sx={ContactSectionStyle.formlabels}>
                      Mobile Number
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        "ul li": {
                          maxHeight: "100px",
                        },
                      }}
                    >
                      <Grid item xs={2} sm={2} md={2}>
                        <Select
                          labelId="demo-simple-select-outlined-label1"
                          id="demo-simple-select-outlined"
                          label=""
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.country_code}
                          name="country_code"
                          size="small"
                          MenuProps={MenuProps}
                          sx={{
                            mt: 2,
                            color: ColorHelper.white,
                            backgroundColor: ColorHelper.PriamryColor,
                            zIndex: 2,
                          }}
                        >
                          {CountryCodeData.map((item) => (
                            <MenuItem key={item.code} value={item.code}>
                              {item.code}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>

                      <Grid item xs={10} sm={10} md={10}>
                        <Typography sx={{ mt: 2, pl: 4.5 }}>
                          <Field
                            variant="outlined"
                            fullWidth
                            name="PhoneNo"
                            value={values.PhoneNo}
                            type="PhoneNo"
                            component={TextField}
                            size="small"
                          />
                        </Typography>
                      </Grid>
                    </Box>
                  </Grid>

                  {/** */}
                  <Grid item xs={12} sm={6} md={6} sx={{ mt: 2, pl: 4.5 }}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                        size="small"
                      >
                        You are interested in?
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        label="You are interested in?"
                        onChange={(e) => {
                          handleChange(e);
                          selectValue(e);
                        }}
                        onBlur={handleBlur}
                        value={values?.servicesOrProducts}
                        name="servicesOrProducts"
                        size="small"
                      >
                        {TalkToUsListingData?.map((item) => (
                          <MenuItem key={item?.option} value={item?.option}>
                            {item?.option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} sx={{ mt: 2, pl: 4.5 }}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                        size="small"
                      >
                        Category
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        label="Category"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.menuservices || ""}
                        defaultValue={values.menuservices || ""}
                        name="menuservices"
                        disabled={false}
                        size="small"
                      >
                        {TalkToUsListingData?.filter(
                          (ele) => ele?.option === values?.servicesOrProducts
                        )[0]?.optionData?.map((item, idx) => (
                          <MenuItem key={idx} value={item?.value}>
                            {item?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  {/** */}

                  <Grid item xs={12} sm={6} md={12}>
                    <Typography sx={ContactSectionStyle.formlabels}>
                      Message
                    </Typography>
                    <Typography sx={{ mt: 2 }}>
                      {" "}
                      <Field
                        variant="outlined"
                        fullWidth
                        name="Message"
                        value={values.Message}
                        type="Message"
                        component={TextField}
                        multiline={true}
                        size="small"
                        rows={3}
                      />
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions>
                <Button
                  disableRipple
                  disabled={!dirty || !isValid}
                  type="Submit"
                  sx={ContactSectionStyle.submitbtn}
                >
                  <FilledButton
                    btnText={ConstantHelper.navBtn4}
                    color={ColorHelper.white}
                    bgColor={ColorHelper.PriamryColor}
                    sx={{ width: "100%" }}
                  />
                </Button>
              </CardActions>
            </Form>
          );
        }}
      </Formik>

      <ThankYouModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        message="Thank you for getting in touch with us. We will get back to you shortly."
      />
    </>
  );
};

export default TaktoUs;
