import React from "react";
import ColorHelper from "../../Helpers/ColorHelper";

const HighlightIconV2_1 = () => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.25 4.3125H8.625C6.24327 4.3125 4.3125 6.24327 4.3125 8.625V17.25C4.3125 19.6317 6.24327 21.5625 8.625 21.5625H17.25C19.6317 21.5625 21.5625 19.6317 21.5625 17.25V8.625C21.5625 6.24327 19.6317 4.3125 17.25 4.3125Z"
        fill={ColorHelper.BgBlue}
      />
      <path
        d="M37.375 4.3125H28.75C26.3683 4.3125 24.4375 6.24327 24.4375 8.625V17.25C24.4375 19.6317 26.3683 21.5625 28.75 21.5625H37.375C39.7567 21.5625 41.6875 19.6317 41.6875 17.25V8.625C41.6875 6.24327 39.7567 4.3125 37.375 4.3125Z"
        fill={ColorHelper.BgBlue}
      />
      <path
        d="M17.25 24.4375H8.625C6.24327 24.4375 4.3125 26.3683 4.3125 28.75V37.375C4.3125 39.7567 6.24327 41.6875 8.625 41.6875H17.25C19.6317 41.6875 21.5625 39.7567 21.5625 37.375V28.75C21.5625 26.3683 19.6317 24.4375 17.25 24.4375Z"
        fill={ColorHelper.BgBlue}
      />
      <path
        d="M33.0625 41.6875C37.826 41.6875 41.6875 37.826 41.6875 33.0625C41.6875 28.299 37.826 24.4375 33.0625 24.4375C28.299 24.4375 24.4375 28.299 24.4375 33.0625C24.4375 37.826 28.299 41.6875 33.0625 41.6875Z"
        fill={ColorHelper.BgBlue}
      />
    </svg>
  );
};

export default HighlightIconV2_1;
