import {
  FiBarChart,
  FiMonitor,
  FiPieChart,
  FiTrendingUp,
  FiCheckCircle,
  FiChevronDown,
  FiUserCheck,
  FiSmile,
} from "react-icons/fi";
import { FaTheaterMasks, FaHeartbeat } from "react-icons/fa";
import { MdCable, MdCastForEducation, MdNetworkLocked } from "react-icons/md";
import { VscVmRunning } from "react-icons/vsc";
import IconHelper from "./Helpers/IconHelper";

const solutionsSubMenu = [
  "media&entertainment",
  "Operators",
  "Education&Elearning",
  "Health&Fitness",
  "OTTforEnterprise",
];
const ssMenu = (arr) => {
  return arr.map((ele, idx) => ({
    name: ele,
    link: ele.toLowerCase().trim(),
    id: idx + 1 * 10,
    icon: <FiBarChart style={{ color: "#DA1A5D" }} />,
  }));
};
export const routes = [
  {
    name: "Products",
    link: "#",
    id: 1,
    dropicon: <FiChevronDown />,
    submenu: [
      {
        name: "vLive",
        icon: <FiBarChart style={{ color: "#DA1A5D" }} />,
        link: "/products/vLive",
        caption: "Experience unmatched convenience and capability with our end-to-end OTT SaaS Suite.",
        id: 11,
        submenu: [],
      },
      {
        name: "Sounds",
        icon: <IconHelper.Sound style={{ color: "#DA1A5D" }} />,
        link: "/products/sounds",
        caption: "Transform your audio strategy into a dynamic and powerful force with our audio platform as a service.",
        id: 12,
        submenu: [],
      },
      {
        name: "vLite MOOC",
        icon: <IconHelper.vLiteMooc style={{ color: "#DA1A5D" }} />,
        link: "/products/vlite-mooc",
        caption: "Simplify the complexities of online learning with our e-learning management service.",
        id: 13,
        submenu: [],
      },
      {
        name: "vApps",
        icon: <IconHelper.vApps style={{ color: "#DA1A5D" }} />,
        link: "/products/vApps",
        caption: "Get connected to a world of digital possibilities with our on-demand app store.",
        id: 14,
        submenu: [],
      },
      {
        name: "vDRM",
        icon: <FiCheckCircle style={{ color: "#DA1A5D" }} />,
        link: "/products/vDRM",
        caption: "Safeguard your digital assets with our comprehensive multi-DRM packaging and license service.",
        id: 15,
        submenu: [],
      },
      {
        name: "vCMS",
        icon: <IconHelper.vCMS style={{ color: "#DA1A5D" }} />,
        link: "/products/vCMS",
        caption: "Streamline, organize, and elevate your audio/video content with a comprehensive centralized content management system.",
        id: 16,
        submenu: [],
      },
      {
        name: "vSMS",
        icon: <IconHelper.vSMS style={{ color: "#DA1A5D" }} />,
        link: "/products/vSMS",
        caption: "Seamlessly orchestrate every aspect of the viewer journey with enhanced user and subscription management system.",
        id: 17,
        submenu: [],
      },
      {
        name: "CDN & Cloud Networking",
        icon: <IconHelper.CDN style={{ color: "#DA1A5D" }} />,
        link: "",
        caption: "Experience unrivalled content delivery speeds with our globally optimized CDN network. ",
        id: 18,
        submenu: [],
      },
    ],
  },
  {
    name: "Solutions",
    link: "#",
    id: 2,
    dropicon: <FiChevronDown />,
    submenu: [
      // {
      //     name: "OTT",
      //     icon: <IconHelper.OTT style={{ color: "#DA1A5D" }} />,
      //     link: "#",
      //     caption: "Enterprise OTT Suite",
      //     id: 21,
      //     // submenu: ssMenu(solutionsSubMenu),
      //     submenu: [
      //         {
      //             name: "Media & Entertainment",
      //             icon: <FaTheaterMasks style={{ color: "#DA1A5D" }} />,
      //             link: "/solutions/media&entertainment",
      //             caption: "Comprehensive OTT entertainment platform",
      //             id: 12,
      //             submenu: [],
      //         },
      //         {
      //             name: "Operators",
      //             icon: <MdCable style={{ color: "#DA1A5D" }} />,
      //             link: "/solutions/operators",
      //             caption: "Fully configurable end-to-end streaming",
      //             id: 13,
      //             submenu: [],
      //         },
      //         {
      //             name: "Education & E-Learning",
      //             icon: <MdCastForEducation style={{ color: "#DA1A5D" }} />,
      //             link: "/solutions/Education&ELearning",
      //             caption: "Live stream interactive educational content ",
      //             id: 13,
      //             submenu: [],
      //         },
      //         {
      //             name: "Health & Fitness",
      //             icon: <FaHeartbeat style={{ color: "#DA1A5D" }} />,
      //             link: "/solutions/Health&Fitness",
      //             caption: " Unlimited capacity to stream health content",
      //             id: 13,
      //             submenu: [],
      //         },
      //         {
      //             name: "OTT for Enterprises.",
      //             icon: <VscVmRunning style={{ color: "#DA1A5D" }} />,
      //             link: "/solutions/OTT",
      //             caption: "End-to-end video streaming and delivery platform",
      //             id: 13,
      //             submenu: [],
      //         },
      //     ],

      // },
      {
        name: "OTT",
        icon: <VscVmRunning style={{ color: "#DA1A5D" }} />,
        link: "/solutions/OTT",
        caption: "End-to-End Audio & Video Streaming platform",
        id: 21,
        submenu: [],
      },
      {
        name: "Broadcast",
        icon: <IconHelper.broadcast style={{ color: "#DA1A5D" }} />,
        link: "/solutions/broadcast",
        caption: "Robust Broadcast Platform for TV Networks",
        id: 22,
        submenu: [],
      },
      {
        name: "Digitization",
        icon: <FiPieChart style={{ color: "#DA1A5D" }} />,
        link: "/solutions/digitization",
        caption: "Robust Platform for AV, Doc Digitization",
        id: 23,
        submenu: [],
      },
    ],
  },
  {
    name: "Services",
    link: "#",
    id: 3,
    dropicon: <FiChevronDown />,
    submenu: [
      {
        name: "OTT Apps",
        icon: <IconHelper.OTTApps style={{ color: "#DA1A5D" }} />,
        link: "/services/ott-apps",
        caption: "Native and hybrid APP development",
        id: 31,
        submenu: [],
      },
      {
        name: "OTT Consulting",
        icon: <IconHelper.OTTConsulting style={{ color: "#DA1A5D" }} />,
        link: "/services/ott-consulting",
        caption: "High-end OTT consulting services ",
        id: 32,
        submenu: [],
      },
      // {
      //   name: "DevOps",
      //   icon: <IconHelper.Devops style={{ color: "#DA1A5D" }} />,
      //   link: "/services/dev-ops",
      //   caption: "DevOps that supports scalability",
      //   id: 33,
      //   submenu: [],
      // },
      {
        name: "Custom Development",
        icon: <IconHelper.CustomDevelopment style={{ color: "#DA1A5D" }} />,
        link: "/services/custom-development",
        caption: "An exclusive range of custom solutions",
        id: 34,
        submenu: [],
      },
      // {
      //   name: "QA Automation",
      //   icon: <IconHelper.QA style={{ color: "#DA1A5D" }} />,
      //   link: "/services/qa-automation",
      //   caption: "Customized automation solutions",
      //   id: 35,
      //   submenu: [],
      // },
    ],
  },
  // company Submenu is Commenting it out
  {
    name: "Company",
    link: "#",
    id: 4,
    dropicon: <FiChevronDown />,
    submenu: [
      {
        name: "About",
        icon: <IconHelper.About style={{ color: "#DA1A5D" }} />,
        link: "/company/about",
        caption: "Comprehensive OTT solutions provider",
        id: 41,
        submenu: [],
      },
      {
        name: "Leadership Team",
        icon: <IconHelper.LeaderShip style={{ color: "#DA1A5D" }} />,
        link: "/company/leadership",
        caption: "Leaders with future-oriented strategies",
        id: 42,
        submenu: [],
      },
      {
        name: "Contact Us",
        icon: <IconHelper.Contact style={{ color: "#DA1A5D" }} />,
        link: "/company/contact",
        caption: "We are a click away",
        id: 43,
        submenu: [],
      },

      {
        name: "Career",
        icon: <FiCheckCircle style={{ color: "#DA1A5D" }} />,
        link: "/company/career",
        caption: "Join and grow with us!",
        id: 44,
        submenu: [],
      },
    ],
  },
  {
    name: "Resources",
    link: "#",
    id: 5,
    dropicon: <FiChevronDown />,
    submenu: [
      {
        name: "Blogs",
        icon: <IconHelper.BlogIcon style={{ color: "#DA1A5D" }} />,
        link: "/resources/blogs",
        caption: "Read the latest industry trends",
        id: 45,
        submenu: [],
      },
      {
        name: "Case Studies",
        icon: <IconHelper.BlogIcon style={{ color: "#DA1A5D" }} />,
        link: "/resources/case-studies",
        caption: "Read the latest industry trends",
        id: 45,
        submenu: [],
      },
    ],
  },
];
