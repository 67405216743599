// Helpers
import ColorHelper from "../../../Helpers/ColorHelper";

const modalBGcolor = {
  backgroundColor: ColorHelper.white,
};

const LeaderModalstyle = {
  modalWrapper: {
    minHeight: 'max-content',
    ...modalBGcolor,
 
  },
  modalHeader: {
    ...modalBGcolor,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    float: "right",
    position: "absolute",
    pl: 2,
    pt: 1,
    pb: 1,
    right: 0
  //  borderBottom: `1px solid ${ColorHelper.grey2}`,
  },
  ModalTitle: {
    fontSize: {xs: '0.8rem', md: '1rem'},
    fontWeight: 600,
  },
  modalContent: {
    ...modalBGcolor,
    p: {xs:1, md:5},
    ml: 1,
   
  },
  main: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "fit-content",
    maxWidth: '50vw',
    overflow: 'hidden'
  },
  videoContainer: {
    width: { xs: "100%", md: "100%" },
  },
  moalFooter: {
    ...modalBGcolor,
    borderTop: `2px solid ${ColorHelper.lightGrey}`,
    py: 2,
  },
};

export default LeaderModalstyle;