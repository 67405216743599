import * as React from "react";
import Button from "@mui/material/Button";
import { Box } from "@mui/system";

export default function FilledButton(props) {
  const { color, bgColor, btnText, icon, sx, handleClick, ...rest } = props;
  return (
    <Button
      onClick={handleClick}
      variant="contained"
      sx={{
        color: color,
        backgroundColor: bgColor,
        mx: 1,
        py: 1.2,
        fontWeight: 600,
        borderRadius: "10px",
        minWidth: "max-content",
        maxHeight: "3rem",
        minHeight: "3rem",
        textTransform: "unset",
        ...sx,
        "&:hover": {
          backgroundColor: color,
          color: bgColor,
        },
      }}
    >
      {icon ? <Box sx={{ mt: 1 }}>{icon}</Box> : null}

      {btnText ? <Box sx={icon && { ml: "0.7rem" }}>{btnText}</Box> : null}
    </Button>
  );
}
