
import ColorHelper from "../../../Helpers/ColorHelper"
export default {
  normal: {

    // position: "absolute",
    width: "200px",
    height: "250px",
    transitionproperty: "height",
    transitionduration: "0.2s",
    transitiotimingfunction: "linear",


  },
  hover: {
    background: ColorHelper.PriamryColor,
    border:`2px solid ${ColorHelper.PriamryColor}`,
    
  }
}