const FontHelper = {
  FontMedium: "Nunito-Medium",
  FontBlack: "Nunito-Black !important",
  FontBlackItalic: "Nunito-BlackItalic !important",
  FontBold: "Nunito-Bold !important",
  FontBoldItalic: "Nunito-BoldItalic !important",
  FontExtraBold: "Nunito-ExtraBold !important",
  FontExtraBoldItalic: "Nunito-ExtraBoldItalic !important",
  FontExtraLight: "Nunito-ExtraLight !important",
  FontExtraLightItalic: "Nunito-ExtraLightItalic !important",
  FontItalic: "Nunito-Italic !important",
  FontLight: "Nunito-Light !important",
  FontLightItalic: "Nunito-LightItalic !important",
  FontRegular: "Nunito-Regular !important",
  FontSemiBold: "Nunito-SemiBold !important",
  FontSemiBoldItalic: "Nunito-SemiBoldItalic !important",
};

export default FontHelper;

// const FontHelper = {
//     FontSemiBold: "Poppins, sans-serif, mono-space",
//     FontBlack: "Poppins, sans-serif, mono-space",
//     FontBlackItalic: "Poppins, sans-serif, mono-space",
//     FontBold: "Poppins, sans-serif, mono-space",
//     FontBoldItalic: "Poppins, sans-serif, mono-space",
//     FontExtraBold: "Poppins, sans-serif, mono-space",
//     FontExtraBoldItalic: "Poppins, sans-serif, mono-space",
//     FontLight: "Poppins, sans-serif, mono-space",
//     FontLightItalic: "Poppins, sans-serif, mono-space",
//     FontRegular: "Poppins, sans-serif, mono-space",
//     FontSemiBold: "Poppins, sans-serif, mono-space",
//     FontSemiBoldItalic: "Poppins, sans-serif, mono-space",
// }

// export default FontHelper;
