import * as React from "react";
import ColorHelper from "../../Helpers/ColorHelper";

const Carriericon1 = ({ size = 46, color, ...props }) => (
    <svg width="19" height="22" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.5 21C9.5 21 17.5 17 17.5 11V4L9.5 1L1.5 4V11C1.5 17 9.5 21 9.5 21Z" stroke="#F2F4F7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    
    

);

export default Carriericon1;