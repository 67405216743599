// Helper
import ColorHelper from "../../Helpers/ColorHelper";

const ServiceHeroSectionStyle = {
  container: {
    minHeight: { xs: "70vh", md: "40vh", lg: "54vh" },
    minWidth: "100%",
    maxHeight: {xs: "40vh", md: "40vh", lg: "40vh"},
   // my: -20,
    // backgroundColor: ColorHelper.blue,
    // px: {xs : 10, md : 5},
    px: { xs: 3, md: 20 },
    pb: 5,
    mt: {md: 0, xs: 10},
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flexDirection: { xs: "column", md: "row" },
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "top",
    position: 'relative',
    height: "30vh",
  },
  contents: {
    // margin: 'auto',
    mt: { xs: 2, md: 7 },
    mb: { xs: 5, md: 0 },
    order: { xs: 2, md: 1 },
    zIndex: 2,
    px: {xs: 1, md: 3},
  },
  title1: {
    fontSize: { xs: "1.4rem", md: '2rem' },
    fontWeight: 600,
    color: ColorHelper.white,
    lineHeight: 1.3,
    textAlign: { xs: "center", md: "center" },
    px: {xs: 1, md: 20},
  },
  title2: {
    fontSize: { xs: "1.4rem", md: 50 },
    fontWeight: 600,
    color: ColorHelper.white,
    lineHeight: 1.3,
    textAlign: { xs: "center", md: "center" },
    px: {xs: 1, md: 20},
  },
  divider: {
    maxWidth: "60%",
    backgroundColor: ColorHelper.lightGrey,
    opacity: "0.5",
    m: "auto",
    my: 3,
  },
  heroContent: {
    fontSize: { xs: "0.8rem", md: 18 },
    fontWeight: 100,
    color: ColorHelper.white,
    maxWidth: { xs: "100%", md: "100%" },
    lineHeight: 1.5,
    textAlign: { xs: "center", md: "center" },
    m: "auto",
  },
  btnContainer: {
    mt: 2,
    ml: -1,
  //  py: -3,
    textAlign: { xs: "center", md: "left" },
    display: "flex",
    justifyContent: { xs: "center", md: "center" },
    alignItems: "center",
  },
  HeroNavbtns: {
    display: "flex",
    justifyContent: { xs: "center", md: "flex-start" },
    alignItems: "center",
  },
  NavBtns: {
    px: 0,
    // justifyContent: "left",
    mt: { xs: 4, md: 10 },
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  img_container: {
    order: { xs: 1, md: 2 },
    mt: { xs: 5, md: 0 },
  },
  image: {
    maxWidth: "100%",
  },
  overlay: {
    display: { xs: "block", md: "block" },
    background: ColorHelper.softBlue,
    mixBlendMode: 'multiply',
    opacity: '0.9',
    width: "100%",
    height: "54vh",
    position: "absolute",
    top: '0rem',
    zIndex: 1,
  },
};

export default ServiceHeroSectionStyle;
