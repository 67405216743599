import { Box, Typography } from "@mui/material";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

// Helper
import ConstantHelper from "../../Helpers/ConstantHelper";
import ImageHelper from "../../Helpers/ImageHelper";

// Custom Styles
import LeadingBrnadsCarouselStyle from "./LeadingBrnadsCarouselStyle";
import { memo } from "react";

const data = [
  {
    id: 1,
    title: "",
    caption: "",
    img: ImageHelper.BrandCover1,
  },
  {
    id: 2,
    title: "",
    caption: "",
    img: ImageHelper.BrandCover2,
  },
  {
    id: 3,
    title: "",
    caption: "",
    img: ImageHelper.BrandCover3,
  },
  {
    id: 4,
    title: "",
    caption: "",
    img: ImageHelper.BrandCover4,
  },
  {
    id: 5,
    title: "",
    caption: "",
    img: ImageHelper.BrandCover5,
  },
  {
    id: 6,
    title: "",
    caption: "",
    img: ImageHelper.BrandCover6,
  },
  {
    id: 7,
    title: "",
    caption: "",
    img: ImageHelper.BrandCover7,
  },
  {
    id: 8,
    title: "",
    caption: "",
    img: ImageHelper.BrandCover8,
  },
  {
    id: 9,
    title: "",
    caption: "",
    img: ImageHelper.BrandCover9,
  },
  // {
  //   id: 10,
  //   title: "",
  //   caption: "",
  //   img: ImageHelper.BrandCover10,
  // },
  {
    id: 11,
    title: "",
    caption: "",
    img: ImageHelper.BrandCover11,
  },
  {
    id: 12,
    title: "",
    caption: "",
    img: ImageHelper.BrandCover12,
  },
  {
    id: 13,
    title: "",
    caption: "",
    img: ImageHelper.BrandCover13,
  },
  {
    id: 14,
    title: "",
    caption: "",
    img: ImageHelper.BrandCover14,
  },
  {
    id: 15,
    title: "",
    caption: "",
    img: ImageHelper.BrandCover15,
  },
  {
    id: 16,
    title: "",
    caption: "",
    img: ImageHelper.BrandCover16,
  },
  {
    id: 17,
    title: "",
    caption: "",
    img: ImageHelper.BrandCover17,
  },
  {
    id: 18,
    title: "",
    caption: "",
    img: ImageHelper.BrandCover18,
  },
  {
    id: 19,
    title: "",
    caption: "",
    img: ImageHelper.BrandCover19,
  },
  {
    id: 20,
    title: "",
    caption: "",
    img: ImageHelper.BrandCover20,
  },
  {
    id: 21,
    title: "",
    caption: "",
    img: ImageHelper.BrandCover21,
  },
  {
    id: 22,
    title: "",
    caption: "",
    img: ImageHelper.BrandCover22,
  },
  {
    id: 23,
    title: "",
    caption: "",
    img: ImageHelper.BrandCover23,
  },
];

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1619 },
    items: 5,
    slidesToSlide: 1,
  },
  laptop: {
    breakpoint: { max: 1619, min: 1200 },
    items: 5,
    slidesToSlide: 1,
  },
  tablet2: {
    breakpoint: { max: 1199, min: 900 },
    items: 3,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 899, min: 640 },
    items: 3,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 639, min: 380 },
    items: 1,
    slidesToSlide: 1,
  },
  mobile2: {
    breakpoint: { max: 379, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const carouselParams = {
  additionalTransfrom: 0,
  arrows: false,
  autoPlay: true,
  autoPlaySpeed: 1500,
  centerMode: false,
  className: "",
  containerClass: "carousel-container",
  dotListClass: "",
  draggable: true,
  focusOnSelect: false,
  infinite: true,
  itemClass: "",
  keyBoardControl: true,
  minimumTouchDrag: 80,
  renderDotsOutside: false,
  responsive: responsive,
  showDots: false,
  sliderClass: "",
  slidesToSlide: 1,
};

const LeadingBrnadsCarousel = memo(function LeadingBrnadsCarousel() {
  return (
    <section
      id="LeadingBrands"
      style={LeadingBrnadsCarouselStyle.LeadingBrandsWrapper}
    >
      <Box sx={LeadingBrnadsCarouselStyle.LeadingBrandsTitleWrapper}>
        <Typography sx={LeadingBrnadsCarouselStyle.LeadingBrandsTitle}>
          {ConstantHelper.LeadingBrandsTitle}
        </Typography>
      </Box>
      <Box sx={LeadingBrnadsCarouselStyle.carouselWrapper}>
        <Carousel {...carouselParams}>
          {data.map((item, index) => (
            <Box sx={LeadingBrnadsCarouselStyle.card} key={item.id}>
              <div className="reviewer-info">
                <img
                  src={item.img}
                  alt={`cover${index}`}
                  style={LeadingBrnadsCarouselStyle.cardImg}
                />
                {/* <Typography sx={LeadingBrnadsCarouselStyle.title}>
                  {item.title}
                </Typography>
                <Typography as="h4" sx={LeadingBrnadsCarouselStyle.caption}>
                  {item.caption}
                </Typography> */}
              </div>
            </Box>
          ))}
        </Carousel>
      </Box>
    </section>
  );
});

export default LeadingBrnadsCarousel;
