import { Box, Button, Container, Grid, Modal, Typography } from "@mui/material";
import React from "react";
import ColorHelper from "../../../Helpers/ColorHelper";

export default function ThankYouModal({ setOpenModal, openModal, message }) {
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 350,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "10px",
  };
  const typographyStyle = {
    fontWeight: 600,
    marginTop: "10px",
    fontSize: "20px",
  };

  return (
    <Modal
      open={openModal}
      onClose={() => setOpenModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle} align="center">
        <img
          width="80px"
          height="auto"
          src="/images/others/tick.png"
          alt="Responsive Image"
        />
        <Typography id="modal-modal-title" sx={typographyStyle}>
          {message}
        </Typography>

        <Button
          type="button"
          sx={{
            ":hover": {
              bgcolor: ColorHelper?.blue,
              color: "white",
            },
            width: "20%",
            bgcolor: ColorHelper.PriamryColor,
            marginTop: "10px",
          }}
          onClick={() => setOpenModal(false)}
        >
          <Grid sx={{ color: ColorHelper.white }}>Ok</Grid>
        </Button>
      </Box>
    </Modal>
  );
}
