import React from "react";

const HLTvApps01 = () => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.72717 12.668C4.18643 12.668 1.43478 15.751 1.83575 19.269L3.76928 36.2361C4.2866 40.775 8.1276 44.2031 12.6959 44.2031H33.3181C37.8865 44.2031 41.7274 40.775 42.2446 36.2361L44.1782 19.269C44.5792 15.751 41.8275 12.668 38.2868 12.668"
        stroke="#457ABD"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.9219 12.668H31.4453V10.1523C31.4453 5.53779 27.7044 1.79688 23.0898 1.79688C18.4753 1.79688 14.7344 5.53779 14.7344 10.1523V16.8008"
        stroke="#457ABD"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.5195 26.1445C17.5195 27.385 16.5139 28.3906 15.2734 28.3906C14.033 28.3906 13.0273 27.385 13.0273 26.1445C13.0273 24.9041 14.033 23.8984 15.2734 23.8984C16.5139 23.8984 17.5195 24.9041 17.5195 26.1445Z"
        fill="#457ABD"
      />
      <path
        d="M25.2461 26.1445C25.2461 27.385 24.2405 28.3906 23 28.3906C21.7595 28.3906 20.7539 27.385 20.7539 26.1445C20.7539 24.9041 21.7595 23.8984 23 23.8984C24.2405 23.8984 25.2461 24.9041 25.2461 26.1445Z"
        fill="#457ABD"
      />
      <path
        d="M32.9727 26.1445C32.9727 27.385 31.967 28.3906 30.7266 28.3906C29.4861 28.3906 28.4805 27.385 28.4805 26.1445C28.4805 24.9041 29.4861 23.8984 30.7266 23.8984C31.967 23.8984 32.9727 24.9041 32.9727 26.1445Z"
        fill="#457ABD"
      />
      <path
        d="M17.5195 33.8711C17.5195 35.1116 16.5139 36.1172 15.2734 36.1172C14.033 36.1172 13.0273 35.1116 13.0273 33.8711C13.0273 32.6306 14.033 31.625 15.2734 31.625C16.5139 31.625 17.5195 32.6306 17.5195 33.8711Z"
        fill="#457ABD"
      />
      <path
        d="M25.2461 33.8711C25.2461 35.1116 24.2405 36.1172 23 36.1172C21.7595 36.1172 20.7539 35.1116 20.7539 33.8711C20.7539 32.6306 21.7595 31.625 23 31.625C24.2405 31.625 25.2461 32.6306 25.2461 33.8711Z"
        fill="#457ABD"
      />
      <path
        d="M32.9727 33.8711C32.9727 35.1116 31.967 36.1172 30.7266 36.1172C29.4861 36.1172 28.4805 35.1116 28.4805 33.8711C28.4805 32.6306 29.4861 31.625 30.7266 31.625C31.967 31.625 32.9727 32.6306 32.9727 33.8711Z"
        fill="#457ABD"
      />
    </svg>
  );
};

export default HLTvApps01;
