import { Avatar, Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import "./BlogDetails.css";
import { GoPrimitiveDot } from "react-icons/go";

import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";

import { TiTick } from "react-icons/ti";
import { MdArrowForwardIos } from "react-icons/md";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";
import BlogData from "../Blogs/BlogData";
import SeoComponent from "../SeoComponent/SeoComponent";
import ShareLink from "./ShareLink";
import TalkToUsModaldrp from "../HeroSection/TalkToUsModal/TalkToUsModaldrp";

import ConstantHelper from "../../Helpers/ConstantHelper";

export default function BlogDetails() {
  let { slug } = useParams();

  const [openCFModal, setOpenCFModal] = useState(false);

  let item = BlogData?.find((ele) => {
    return ele.slug == slug;
  });

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const handleCloseModal = () => setOpenCFModal(false);

  return (
    <>
      <SeoComponent
        title={item?.BlogTitle}
        description={item?.metaDescription}
        canonical={`https://www.mobiotics.com/resources/blogs/${item?.slug}`}
        keywords={item?.keywords}
      />
      {item ? (
        <Box
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          mt={18}
          className="BlogDetails_Container"
        >
          <Box className="BlogDetails_Box1">
            {/* <Box mb={1} fontSize={20} fontWeight="Bold">
              {item?.BlogTitle}
            </Box> */}
            <h1 fontSize={20}>{item?.BlogTitle}</h1>

            <Box
              display="flex"
              flexDirection="row"
              flexWrap="wrap"
              gap={1}
              color="#5B5B5B"
              alignItems="center"
              fontSize={12}
              fontWeight="Bold"
              mb={2}
            >
              <span>{item?.authorName}</span>
              <GoPrimitiveDot />
              <span>{item?.postDate}</span>
              {/* <GoPrimitiveDot />
              <span>{`${item.postViews} views`}</span> */}
            </Box>

            <Box
              display="flex"
              flexDirection="row"
              flexWrap="wrap"
              justifyContent="space-between"
              mb={1}
            >
              <Box
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                alignItems="center"
                gap={1}
                mb={1}
              >
                <Avatar alt="Remy Sharp" src={item?.authorImage} />
                <div>
                  <h5>{item?.authorName}</h5>
                  <Box
                    display="flex"
                    flexDirection="row"
                    flexWrap="wrap"
                    gap={1}
                    color="#5B5B5B"
                    alignItems="center"
                    fontSize={12}
                    fontWeight="Bold"
                  >
                    <span>{item?.postDate}</span>
                    {/* <GoPrimitiveDot />
                    <span>{`${item?.postViews} views`}</span> */}
                  </Box>
                </div>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                justifyContent="center"
                alignItems="center"
                gap={1}
              >
                <ShareLink slug={slug} />
              </Box>
            </Box>

            <Box>
              <Box className="BlogDetails_ImgContainer" mt={1} mb={1}>
                <img
                  src={item?.image}
                  alt={item?.BlogTitle}
                  title={item?.BlogTitle}
                />
              </Box>

              {item?.BlogDes ? (
                <Box
                  mb={4}
                  dangerouslySetInnerHTML={{
                    __html: item?.BlogDes,
                  }}
                ></Box>
              ) : null}

              {item?.BlogHeading1 ? (
                // <Box mb={1} fontSize={18} fontWeight="Bold">
                //   {item?.BlogHeading1}
                // </Box>
                <Box mb={1}>
                  <h3>{item?.BlogHeading1}</h3>
                </Box>
              ) : null}
 {item?.blogsubimage ? (
             <Box className="BlogDetails_ImgContainer" mt={1} mb={1}>
                <img
                  src={item?.blogsubimage}
                  alt={item?.BlogTitle}
                />
              </Box> ): null }

              {item?.BlogHeading1Des ? (
                <Box
                  mb={4}
                  dangerouslySetInnerHTML={{
                    __html: item?.BlogHeading1Des,
                  }}
                ></Box>
              ) : null}

              {item?.BlogPointsHeading1 ? (
                // <Box mb={2} fontSize={18} fontWeight="Bold">
                //   {item?.BlogPointsHeading1}
                // </Box>

                <Box mb={2}>
                  <h3>{item?.BlogPointsHeading1}</h3>
                </Box>
              ) : null}
              {item?.BlogPointsHeading1Des ? (
                <Box
                  mb={2}
                  dangerouslySetInnerHTML={{
                    __html: item?.BlogPointsHeading1Des,
                  }}
                ></Box>
              ) : null}
              {item?.blogsubimage2 ? (
             <Box className="BlogDetails_ImgContainer" mt={1} mb={1}>
                <img
                  src={item?.blogsubimage2}
                  alt={item?.BlogTitle}
                />
              </Box> ): null }
              {item?.BlogPointsHeading1Arr ? (
                <Box ml={3} mb={4}>
                  <ol>
                    {item?.BlogPointsHeading1Arr?.map((ele, index) => {
                      return (
                        <div key={index}>
                          {ele?.point ? (
                            <li>
                              {/* <Box fontWeight="bold" mb={2}>
                                {ele.point}
                              </Box> */}

                              <Box mb={2}>
                                <h4>{ele.point}</h4>
                              </Box>
                            </li>
                          ) : null}

                          {ele?.pointDes ? (
                            <Box
                              mb={2}
                              ml={3}
                              dangerouslySetInnerHTML={{
                                __html: ele?.pointDes,
                              }}
                            ></Box>
                          ) : null}
                        </div>
                      );
                    })}
                  </ol>
                </Box>
              ) : null}
              {item?.BlogPointsHeading1EndDes ? (
                <Box
                  mb={2}
                  dangerouslySetInnerHTML={{
                    __html: item?.BlogPointsHeading1EndDes,
                  }}
                ></Box>
              ) : null}

              {item?.BlogHeading2 ? (
                // <Box mb={1} fontSize={18} fontWeight="Bold">
                //   {item?.BlogHeading2}
                // </Box>

                <Box mb={1}>
                  <h3>{item?.BlogHeading2}</h3>
                </Box>
              ) : null}
              {item?.BlogHeading2Des ? (
                <Box
                  mb={4}
                  dangerouslySetInnerHTML={{
                    __html: item?.BlogHeading2Des,
                  }}
                ></Box>
              ) : null}

              {item?.BlogPointsHeading2 ? (
                // <Box mb={2} fontSize={18} fontWeight="Bold">
                //   {item?.BlogPointsHeading2}
                // </Box>

                <Box mb={2}>
                  <h3>{item?.BlogPointsHeading2}</h3>
                </Box>
              ) : null}
              {item?.BlogPointsHeading2Des ? (
                <Box
                  mb={2}
                  dangerouslySetInnerHTML={{
                    __html: item?.BlogPointsHeading2Des,
                  }}
                ></Box>
              ) : null}
              {item?.BlogPointsHeading2Arr ? (
                <Box ml={3} mb={4}>
                  <ol>
                    {item?.BlogPointsHeading2Arr?.map((ele, index) => {
                      return (
                        <div key={index}>
                          {/* {ele?.point ? (
                            <li>
                              

                              <Box mb={2}>
                                <h4>{ele.point}</h4>
                              </Box>
                            </li>
                          ) : null} */}
                          {ele?.point ? (
                            <li>

                            <Box
                              mb={2}
                              ml={3}
                              dangerouslySetInnerHTML={{
                                __html: ele?.point,
                              }}
                            ></Box>
                            </li>
                          ) : null}

                          {ele?.pointDes ? (
                            <Box
                              mb={2}
                              ml={3}
                              dangerouslySetInnerHTML={{
                                __html: ele?.pointDes,
                              }}
                            ></Box>
                          ) : null}
                        </div>
                      );
                    })}
                  </ol>
                </Box>
              ) : null}
              {item?.BlogPointsHeading2EndDes ? (
                <Box
                  mb={2}
                  dangerouslySetInnerHTML={{
                    __html: item?.BlogPointsHeading2EndDes,
                  }}
                ></Box>
              ) : null}

              {item?.BlogHeading3 ? (
                // <Box mb={1} fontSize={18} fontWeight="Bold">
                //   {item?.BlogHeading3}
                // </Box>

                <Box mb={1}>
                  <h3>{item?.BlogHeading3}</h3>
                </Box>
              ) : null}
              {item?.BlogHeading3Des ? (
                <Box
                  mb={4}
                  dangerouslySetInnerHTML={{
                    __html: item?.BlogHeading3Des,
                  }}
                ></Box>
              ) : null}

              {item?.BlogPointsHeading3 ? (
                <Box mb={2}>
                  <h3>{item?.BlogPointsHeading3}</h3>
                </Box>
              ) : null}
              {item?.BlogPointsHeading3Des ? (
                <Box
                  mb={2}
                  dangerouslySetInnerHTML={{
                    __html: item?.BlogPointsHeading3Des,
                  }}
                ></Box>
              ) : null}

              {item?.BlogPointsHeading3Arr ? (
                <Box ml={3} mb={4}>
                  <ol>
                    {item?.BlogPointsHeading3Arr?.map((ele, index) => {
                      return (
                        <div key={index}>
                          {ele?.point ? (
                            <li>
                              {/* <Box fontWeight="bold" mb={2}>
                                {ele.point}
                              </Box> */}

<Box
                              mb={2}
                              ml={3}
                              dangerouslySetInnerHTML={{
                                __html: ele?.point,
                              }}
                            ></Box>
                            </li>
                          ) : null}

                          {ele?.pointDes ? (
                            <Box
                              mb={2}
                              ml={3}
                              dangerouslySetInnerHTML={{
                                __html: ele?.pointDes,
                              }}
                            ></Box>
                          ) : null}
                        </div>
                      );
                    })}
                  </ol>
                </Box>
              ) : null}
              {item?.BlogPointsHeading3EndDes ? (
                <Box
                  mb={2}
                  dangerouslySetInnerHTML={{
                    __html: item?.BlogPointsHeading3EndDes,
                  }}
                ></Box>
              ) : null}

              {item?.BlogHeading4 ? (
                <Box mb={1} mt={2}>
                  <h3>{item?.BlogHeading4}</h3>
                </Box>
              ) : null}
              {item?.BlogHeading4Des ? (
                <Box
                  mb={4}
                  dangerouslySetInnerHTML={{
                    __html: item?.BlogHeading4Des,
                  }}
                ></Box>
              ) : null}

              {item?.BlogPointsHeading4 ? (
                // <Box mb={2} mt={2} fontSize={18} fontWeight="Bold">
                //   {item?.BlogPointsHeading4}
                // </Box>

                <Box mb={2} mt={2}>
                  <h3
                    dangerouslySetInnerHTML={{
                      __html: item?.BlogPointsHeading4,
                    }}
                  ></h3>
                </Box>
              ) : null}
              {item?.BlogPointsHeading4Des ? (
                <Box
                  mb={2}
                  dangerouslySetInnerHTML={{
                    __html: item?.BlogPointsHeading4Des,
                  }}
                ></Box>
              ) : null}
              {item?.BlogPointsSubHeading4 ? (
                <Box
                  mb={2}
                  dangerouslySetInnerHTML={{
                    __html: item?.BlogPointsSubHeading4,
                  }}
                ></Box>
              ) : null}
              {item?.BlogPointsHeading4Arr ? (
                <Box ml={3} mb={4}>
                  <ol>
                    {item?.BlogPointsHeading4Arr?.map((ele, index) => {
                      return (
                        <div key={index}>
                          {ele?.point ? (
                            <li>
                              {/* <Box fontWeight="bold" mb={2}>
                                {ele.point}
                              </Box> */}

<Box
                              mb={2}
                              ml={3}
                              dangerouslySetInnerHTML={{
                                __html: ele?.point,
                              }}
                            ></Box>
                            </li>
                          ) : null}

                          {ele?.pointDes ? (
                            <Box
                              mb={2}
                              ml={3}
                              dangerouslySetInnerHTML={{
                                __html: ele?.pointDes,
                              }}
                            ></Box>
                          ) : null}
                        </div>
                      );
                    })}
                  </ol>
                </Box>
              ) : null}

              {item?.BlogHeading5 ? (
                <Box mb={1} mt={2}>
                  <h3>{item?.BlogHeading5}</h3>
                </Box>
              ) : null}
              {item?.BlogHeading5Des ? (
                <Box
                  mb={4}
                  dangerouslySetInnerHTML={{
                    __html: item?.BlogHeading5Des,
                  }}
                ></Box>
              ) : null}

              {item?.BlogHeading6 ? (
                <Box mb={1} mt={2}>
                  <h3>{item?.BlogHeading6}</h3>
                </Box>
              ) : null}
              {item?.BlogHeading6Des ? (
                <Box
                  mb={4}
                  dangerouslySetInnerHTML={{
                    __html: item?.BlogHeading6Des,
                  }}
                ></Box>
              ) : null}

              {item?.BlogConclusionTitle ? (
                <Box mb={1} mt={2}>
                  <h3>{item?.BlogConclusionTitle}</h3>
                </Box>
              ) : null}
              {item?.BlogConclusionDes ? (
                <Box
                  mb={4}
                  dangerouslySetInnerHTML={{
                    __html: item?.BlogConclusionDes,
                  }}
                ></Box>
              ) : null}
            </Box>
          </Box>

          <Box
            className="BlogDetails_Box2"
            // display="flex"
            // flexDirection="column"
            // justifyContent="center"
          >
            {/* Search Box */}
            {/* <Box mb={2}>
              <Paper
                component="form"
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                  boxShadow: 3,
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search"
                  inputProps={{ "aria-label": "search google maps" }}
                />
                <IconButton
                  type="button"
                  sx={{ p: "10px" }}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
              </Paper>
            </Box> */}

            {/* About Author */}
            <Box mb={2}>
              <Card sx={{ maxWidth: "100%", boxShadow: 3, padding: "10px" }}>
                <CardActionArea>
                  <div
                    className="BlogDetailsImgContainer"
                    style={{ backgroundImage: `url(${item?.authorImage2})` }}
                  ></div>
                  <CardContent>
                    <Box mb={1} fontSize={16} fontWeight="Bold">
                      {item?.authorName}
                    </Box>
                    <Box fontSize={14}>{item?.authorDes}</Box>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Box>

            {/* Ads */}
            <Box mb={2}>
              <Card
                sx={{
                  maxWidth: "100%",
                  boxShadow: 3,
                  backgroundColor: "aliceblue",
                  padding: "35px",
                }}
              >
                <Box position="relative">
                  <Box>
                    <Box
                      mb={2}
                      fontSize={15}
                      fontWeight="Bold"
                      textAlign="center"
                    >
                      Launch your custom OTT platform with Mobiotics!
                    </Box>

                    {/* <Box display="flex" flexDirection="column" gap={1}>
                      <Box
                        display="flex"
                        flexDirection="row"
                        flexWrap="wrap"
                        gap={1}
                        fontSize={14}
                      >
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          borderRadius="50%"
                          backgroundColor="white"
                        >
                          <TiTick style={{ color: "#da1a5d" }} />
                        </Box>
                        OTT Platform
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="row"
                        flexWrap="wrap"
                        gap={1}
                        fontSize={14}
                      >
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          borderRadius="50%"
                          backgroundColor="white"
                        >
                          <TiTick style={{ color: "#da1a5d" }} />
                        </Box>
                        Broadcast
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="row"
                        flexWrap="wrap"
                        gap={1}
                        fontSize={14}
                      >
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          borderRadius="50%"
                          backgroundColor="white"
                        >
                          <TiTick style={{ color: "#da1a5d" }} />
                        </Box>
                        Digitization
                      </Box>
                    </Box> */}

                    <Box
                      display="flex"
                      gap={1}
                      flexDirection="row"
                      flexWrap="wrap"
                      mb={2}
                      mt={2}
                    >
                      <Button
                        variant="outlined"
                        sx={{ borderColor: "grey.500", width: "100%" }}
                        style={{ color: "#5B5B5B", backgroundColor: "white" }}
                        onClick={() =>
                          `${window.open("/files/Mobiotics_Intro_2022.pdf")}`
                        }
                      >
                        Download Brochure
                      </Button>

                      <Button
                        variant="contained"
                        sx={{ width: "100%" }}
                        style={{ backgroundColor: "#da1a5d" }}
                        onClick={() => setOpenCFModal(true)}
                      >
                        Request Demo
                      </Button>
                    </Box>
                  </Box>
                  <CardMedia
                    component="img"
                    height="480"
                    image="https://images.unsplash.com/photo-1571260899304-425eee4c7efc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dW5pdmVyc2l0eSUyMHN0dWRlbnR8ZW58MHx8MHx8&w=1000&q=80"
                    alt="green iguana"
                    className="BlogDetails_ImgAds"
                  />
                  <Box
                    position="absolute"
                    bottom="0"
                    color="white"
                    className="BlogDetails_ImgAdsGlass"
                    p={1}
                    m={1}
                  >
                    <Box fontSize="12px" mb={1}>
                      We utilize our decade-long experience in OTT and
                      Broadcasting technologies to provide next-generation
                      immersive video experiences by incorporating AR, VR, Deep
                      Learning, and context-aware technologies.
                    </Box>
                    <Box fontSize="14px" fontWeight="bold" marginBottom="2px">
                      Mobiotics
                    </Box>
                    <Box fontSize="10px" fontWeight="bold" marginBottom="2px">
                      CEO
                    </Box>
                    {/* <Box fontSize="10px" marginBottom="2px">
                      OTT Platform
                    </Box> */}

                    <Box
                      display="flex"
                      flexDirection="row"
                      flexWrap="wrap"
                      gap={1}
                      mt={2}
                    >
                      <Box
                        border="1px solid white"
                        borderRadius="50%"
                        padding="6px"
                        display="flex"
                        justifyContent="center"
                      >
                        <BsArrowLeft />
                      </Box>
                      <Box
                        border="1px solid white"
                        borderRadius="50%"
                        padding="6px"
                        display="flex"
                        justifyContent="center"
                      >
                        <BsArrowRight />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Card>
            </Box>
          </Box>
        </Box>
      ) : (
        <p>no data found</p>
      )}

      <TalkToUsModaldrp
        open={openCFModal}
        onClose={handleCloseModal}
        formName={ConstantHelper.modalName1}
      />
    </>
  );
}
