import React from "react";
import ColorHelper from "../../Helpers/ColorHelper";

const Vlive1 = (props) => {
    const { color } = props;
  return (

    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width={46} height={46}  viewBox="0 0 46 46">
  <defs>
    <style>
     
    </style>
  </defs>
  <g id="Layer_2" data-name="Layer 2">
    <path  stroke={ColorHelper.BgBlue} class="cls-1" d="M12.16,26.1c-2.56,0-4.64,2.08-4.64,4.64s2.08,4.64,4.64,4.64,4.64-2.08,4.64-4.64-2.08-4.64-4.64-4.64Zm0,7.74c-1.71,0-3.1-1.39-3.1-3.1s1.39-3.1,3.1-3.1,3.1,1.39,3.1,3.1-1.39,3.1-3.1,3.1Z"/>
    <path   stroke={ColorHelper.BgBlue} class="cls-1" d="M12.16,22.23c-6.41,0-11.61,5.2-11.61,11.61s5.2,11.61,11.61,11.61,11.61-5.2,11.61-11.61-5.2-11.61-11.61-11.61Zm-5.36,20.12c.45-2.96,3.21-4.99,6.17-4.54,2.34,.36,4.18,2.2,4.54,4.54-3.27,2.09-7.45,2.09-10.71,0Zm12.07-1.03c-1.02-3.71-4.85-5.89-8.56-4.88-2.37,.65-4.23,2.5-4.88,4.88-4.14-3.71-4.48-10.07-.77-14.21,3.71-4.14,10.07-4.48,14.21-.77,4.14,3.71,4.48,10.07,.77,14.21-.24,.27-.5,.53-.77,.77Z"/>
    <path   stroke={ColorHelper.BgBlue} class="cls-1" d="M33.84,13.71c2.56,0,4.64-2.08,4.64-4.64s-2.08-4.64-4.64-4.64-4.64,2.08-4.64,4.64,2.08,4.64,4.64,4.64Zm0-7.74c1.71,0,3.1,1.39,3.1,3.1s-1.39,3.1-3.1,3.1-3.1-1.39-3.1-3.1,1.39-3.1,3.1-3.1Z"/>
    <path   stroke={ColorHelper.BgBlue} class="cls-1" d="M33.84,23.77c6.41,0,11.61-5.2,11.61-11.61S40.25,.55,33.84,.55s-11.61,5.2-11.61,11.61,5.2,11.61,11.61,11.61Zm-5.36-3.1c.45-2.96,3.21-4.99,6.17-4.54,2.34,.36,4.18,2.2,4.54,4.54-3.27,2.09-7.45,2.09-10.71,0ZM33.84,2.1c5.56,.01,10.05,4.53,10.04,10.09,0,2.84-1.21,5.54-3.32,7.45-1.02-3.71-4.85-5.89-8.56-4.88-2.37,.65-4.23,2.5-4.88,4.88-4.13-3.72-4.45-10.09-.73-14.21,1.9-2.11,4.61-3.31,7.45-3.32Z"/>
    <path   stroke={ColorHelper.BgBlue} class="cls-1" d="M4.55,21.88c.24,.35,.71,.45,1.07,.22l2.32-1.55c.36-.23,.47-.71,.24-1.07-.12-.18-.3-.31-.51-.35l-1.2-.24c1.53-6.1,6.29-10.86,12.38-12.38l.23,1.43c.07,.42,.47,.71,.89,.64,.2-.03,.38-.14,.49-.3l1.66-2.3c.25-.34,.17-.82-.17-1.08l-2.26-1.62c-.34-.26-.83-.19-1.08,.15-.13,.18-.18,.4-.14,.62l.15,.92c-6.75,1.63-12.02,6.89-13.68,13.63l-1.15-.25c-.42-.07-.82,.22-.89,.64-.03,.19,0,.38,.11,.54l1.52,2.34Z"/>
    <path   stroke={ColorHelper.BgBlue} class="cls-1" d="M41.45,24.12c-.24-.35-.71-.45-1.07-.22l-2.32,1.55c-.36,.23-.47,.71-.24,1.07,.12,.18,.3,.31,.51,.35l1.2,.24c-1.53,6.1-6.29,10.86-12.38,12.38l-.27-1.42c-.07-.42-.47-.71-.89-.64-.2,.03-.38,.14-.49,.3l-1.63,2.28c-.25,.34-.17,.82,.17,1.08l2.26,1.64c.34,.26,.83,.19,1.08-.15,.13-.18,.18-.4,.14-.62l-.15-.92c6.75-1.63,12.03-6.9,13.68-13.65l1.15,.23c.42,.07,.82-.22,.89-.64,.03-.19,0-.38-.11-.54l-1.52-2.32Z"/>
  </g>
</svg>
  );
};

export default Vlive1;
