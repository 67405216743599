import React from "react";

const StreamingTypeIcon1 = (props) => {
  const { color } = props;
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38.3334 13.4167H7.66671C5.54962 13.4167 3.83337 15.1329 3.83337 17.25V38.3334C3.83337 40.4504 5.54962 42.1667 7.66671 42.1667H38.3334C40.4505 42.1667 42.1667 40.4504 42.1667 38.3334V17.25C42.1667 15.1329 40.4505 13.4167 38.3334 13.4167Z"
        stroke={color}
        strokeWidth="3.83333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.5833 3.83331L23 13.4166L13.4166 3.83331"
        stroke={color}
        strokeWidth="3.83333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default StreamingTypeIcon1;
