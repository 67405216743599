import * as React from "react";
import ColorHelper from "../../Helpers/ColorHelper";

const LeaderShip = ({ size = 46, color, ...props }) => (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="48" height="48" rx="24" fill="#FFF3F5"/>
    <path d="M15.783 14.826L24 13L32.217 14.826C32.4391 14.8754 32.6377 14.999 32.78 15.1764C32.9224 15.3538 33 15.5745 33 15.802V25.789C32.9999 26.7767 32.756 27.7492 32.2899 28.62C31.8238 29.4908 31.1499 30.2331 30.328 30.781L24 35L17.672 30.781C16.8503 30.2332 16.1765 29.4911 15.7103 28.6205C15.2442 27.7498 15.0002 26.7776 15 25.79V15.802C15 15.5745 15.0776 15.3538 15.22 15.1764C15.3623 14.999 15.5609 14.8754 15.783 14.826V14.826ZM17 16.604V25.789C17 26.4475 17.1626 27.0957 17.4733 27.6763C17.7839 28.2568 18.2332 28.7517 18.781 29.117L24 32.597L29.219 29.117C29.7667 28.7518 30.2158 28.2571 30.5265 27.6767C30.8372 27.0964 30.9998 26.4483 31 25.79V16.604L24 15.05L17 16.604Z" fill="#DA1A5D"/>
    </svg>
    

);

export default LeaderShip;