import React from "react";

const CircularArrowBtn = (props) => {
  const { color } = props;

  return (
    <svg
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15.6808" cy="15.3191" r="15.3191" fill="white" />
      <path
        d="M18.3924 14.7753L14.0571 10.4477C13.9859 10.3759 13.9012 10.3189 13.8078 10.28C13.7145 10.2411 13.6144 10.2211 13.5133 10.2211C13.4122 10.2211 13.312 10.2411 13.2187 10.28C13.1254 10.3189 13.0406 10.3759 12.9694 10.4477C12.8268 10.5912 12.7467 10.7853 12.7467 10.9877C12.7467 11.19 12.8268 11.3841 12.9694 11.5277L16.7609 15.3574L12.9694 19.1489C12.8268 19.2924 12.7467 19.4866 12.7467 19.6889C12.7467 19.8913 12.8268 20.0854 12.9694 20.2289C13.0404 20.3013 13.125 20.3589 13.2183 20.3983C13.3117 20.4378 13.4119 20.4583 13.5133 20.4587C13.6146 20.4583 13.7149 20.4378 13.8082 20.3983C13.9016 20.3589 13.9862 20.3013 14.0571 20.2289L18.3924 15.9013C18.4702 15.8295 18.5322 15.7425 18.5747 15.6456C18.6171 15.5487 18.639 15.4441 18.639 15.3383C18.639 15.2325 18.6171 15.1279 18.5747 15.031C18.5322 14.9341 18.4702 14.847 18.3924 14.7753Z"
        fill={color}
      />
    </svg>
  );
};

export default CircularArrowBtn;
