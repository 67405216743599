import React from "react";
import FooterSection from "../FooterSection/FooterSection";
import Header from "../Header/Header";
import CaseStudiesDetails from "./CaseStudiesDetails";

export default function BlogDetailsContainer() {
  return (
    <>
      <Header />
      <CaseStudiesDetails />
      <FooterSection footerCardShow={true} />
    </>
  );
}
