import React from "react";

const StreamingTypeIcon4 = (props) => {
  const { color } = props;
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.6667 17.4166H35.5834C37.6167 17.4166 39.5668 18.2244 41.0046 19.6621C42.4423 21.0999 43.2501 23.05 43.2501 25.0833C43.2501 27.1166 42.4423 29.0667 41.0046 30.5044C39.5668 31.9422 37.6167 32.75 35.5834 32.75H33.6667"
        stroke={color}
        strokeWidth="3.83333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 17.4166H33.6667V34.6666C33.6667 36.7 32.8589 38.65 31.4211 40.0878C29.9834 41.5256 28.0333 42.3333 26 42.3333H10.6667C8.63334 42.3333 6.68329 41.5256 5.24551 40.0878C3.80774 38.65 3 36.7 3 34.6666V17.4166Z"
        stroke={color}
        strokeWidth="3.83333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6667 4V9.75"
        stroke={color}
        strokeWidth="3.83333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3335 4V9.75"
        stroke={color}
        strokeWidth="3.83333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26 4V9.75"
        stroke={color}
        strokeWidth="3.83333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default StreamingTypeIcon4;
