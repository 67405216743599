import * as React from "react";
import ColorHelper from "../../Helpers/ColorHelper";

const vLiteMooc = ({ size = 46, color, ...props }) => (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="48" height="48" rx="24" fill="#FFF3F5"/>
    <path d="M16 32V17C16 15.343 17.433 14 19.2 14H32V30H19.2C16.681 30 16 30.342 16 32Z" stroke="#DA1A5D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M18 34H32V30H18C17.4696 30 16.9609 30.2107 16.5858 30.5858C16.2107 30.9609 16 31.4696 16 32C16 32.5304 16.2107 33.0391 16.5858 33.4142C16.9609 33.7893 17.4696 34 18 34V34Z" stroke="#DA1A5D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg> 
);

export default vLiteMooc;