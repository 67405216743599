import React from "react";

const NavbarIcon6 = (props) => {
  const { color, bgColor } = props;
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="24" fill={bgColor} />
      <path
        d="M34 23.08V24C33.9988 26.1564 33.3005 28.2547 32.0093 29.9818C30.7182 31.709 28.9033 32.9725 26.8354 33.5839C24.7674 34.1953 22.5573 34.1219 20.5345 33.3746C18.5117 32.6273 16.7847 31.2461 15.611 29.4371C14.4373 27.628 13.8798 25.4881 14.0217 23.3363C14.1636 21.1846 14.9972 19.1363 16.3983 17.4971C17.7994 15.8578 19.6928 14.7154 21.7962 14.2401C23.8996 13.7649 26.1003 13.9823 28.07 14.86M34 16L24 26.01L21 23.01"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NavbarIcon6;
