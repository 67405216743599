import React from "react";

const HLTvDRM03 = () => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3086_82554)">
        <path
          d="M20.2935 6.98535V5.79283e-07"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
        />
        <path
          d="M25.7065 6.98535V5.79283e-07"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
        />
        <path
          d="M20.2935 46V39.0146"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
        />
        <path
          d="M25.7065 46V39.0146"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
        />
        <path
          d="M39.2393 23H46"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
        />
        <path
          d="M0 23H6.76074"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
        />
        <path
          d="M0 14.8804H8.93379"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
        />
        <path
          d="M0 31.1196H8.93379"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
        />
        <path
          d="M37.0662 14.8804H46"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
        />
        <path
          d="M37.0662 31.1196H46"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
        />
        <path
          d="M6.76074 4.0542C6.76074 5.54902 5.54902 6.76074 4.0542 6.76074C2.55938 6.76074 1.34766 5.54902 1.34766 4.0542C1.34766 2.55938 2.55938 1.34766 4.0542 1.34766C5.54902 1.34766 6.76074 2.55938 6.76074 4.0542Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
        />
        <path
          d="M6.76074 4.05418H12.1738L14.9419 8.89819"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
        />
        <path
          d="M39.2393 4.0542C39.2393 5.54902 40.451 6.76074 41.9458 6.76074C43.4406 6.76074 44.6523 5.54902 44.6523 4.0542C44.6523 2.55938 43.4406 1.34766 41.9458 1.34766C40.451 1.34766 39.2393 2.55938 39.2393 4.0542Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
        />
        <path
          d="M39.2393 4.05418H33.8262L31.0581 8.89819"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
        />
        <path
          d="M6.76074 41.9458C6.76074 40.451 5.54902 39.2393 4.0542 39.2393C2.55938 39.2393 1.34766 40.451 1.34766 41.9458C1.34766 43.4406 2.55938 44.6523 4.0542 44.6523C5.54902 44.6523 6.76074 43.4406 6.76074 41.9458Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
        />
        <path
          d="M6.76074 41.9458H12.1738L14.9419 37.1018"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
        />
        <path
          d="M39.2393 41.9458C39.2393 40.451 40.451 39.2393 41.9458 39.2393C43.4406 39.2393 44.6523 40.451 44.6523 41.9458C44.6523 43.4406 43.4406 44.6523 41.9458 44.6523C40.451 44.6523 39.2393 43.4406 39.2393 41.9458Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
        />
        <path
          d="M39.2393 41.9458H33.8262L31.0581 37.1018"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
        />
        <path
          d="M23 28.4124V18.9351H25.7043C27.2003 18.9351 28.4131 20.1478 28.4131 21.6439C28.4131 23.1399 27.2003 24.3526 25.7043 24.3526H23"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
        />
        <path
          d="M12.7129 28.4125V21.6417C12.7129 20.1469 13.9246 18.9352 15.4194 18.9352C16.9143 18.9352 18.126 20.1469 18.126 21.6417V28.4131"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
        />
        <path
          d="M32.8702 17.5869V28.4131"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
        />
        <path
          d="M18.126 24.3528H12.7129"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
        />
        <path
          d="M39.2393 23C39.2393 31.9687 31.9687 39.2393 23 39.2393C14.0313 39.2393 6.76074 31.9687 6.76074 23C6.76074 14.0313 14.0313 6.76074 23 6.76074C31.9687 6.76074 39.2393 14.0313 39.2393 23Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_3086_82554">
          <rect width="46" height="46" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HLTvDRM03;
