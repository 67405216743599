import React, { useState, useRef, useEffect } from "react";
import { NavLink, useNavigate, Link, Navigate } from "react-router-dom";
import Logo from "../../../Assets/Images/MobioticsLogo.svg";
import { routes } from "../../../routes";
import {
  BsFillArrowDownCircleFill,
  BsFillArrowUpCircleFill,
} from "react-icons/bs";

import { VscTriangleUp } from "react-icons/vsc";
import { useLocation } from "react-router-dom";

import {
  FiBarChart,
  FiHeadphones,
  FiMonitor,
  FiPieChart,
  FiTrendingUp,
} from "react-icons/fi";
import { Box } from "@mui/material";
import TalkToUsModaldrp from "../../HeroSection/TalkToUsModal/TalkToUsModaldrp";
import IconHelper from "../../../Helpers/IconHelper";
import ColorHelper from "../../../Helpers/ColorHelper";
import FontHelper from "../../../Helpers/FontHelper";
import ConstantHelper from "../../../Helpers/ConstantHelper";

const EachMenuItem = ({
  name,
  link,
  caption,
  icon,
  submenu,
  idx,
  subMenuIdx,
  handleClick,
}) => {
  const navigate = useNavigate();
  // console.log("subMenuIdx",link);
  return (
    <div>
      {link === "#" ? (
        <div>
          <div className="mobiotics-link-box" onClick={handleClick}>
            <div className="mobiotics-link-icon">{icon}</div>
            <div className="mobiotics-FlexIt">
              <div className="mobiotics-link-texts">
                <label className="mobiotics-link-texts-name">{name}</label>
                <label className="mobiotics-link-texts-caption">
                  {caption}
                </label>
              </div>
              {/* {name == "OTT" && (
                <Box
                  id="OTTMenu"
                  sx={{
                    transform: "rotate(180deg)",
                  }}
                >
                  {subMenuIdx === idx ? (
                    <BsFillArrowUpCircleFill style={{ color: "#DA1A5D" }} />
                  ) : (
                    <BsFillArrowDownCircleFill style={{ color: "#DA1A5D" }} />
                  )}
                </Box>
              )} */}
            </div>
          </div>
        </div>
      ) : (
        <NavLink
          // onClick={(e) => window.location.href = link}
          to={link}
        >
          <div className="mobiotics-link-box" onClick={handleClick}>
            <div className="mobiotics-link-icon">{icon}</div>
            <div className="mobiotics-FlexIt">
              <div className="mobiotics-link-texts">
                <label className="mobiotics-link-texts-name">{name}</label>
                <label className="mobiotics-link-texts-caption">
                  {caption}
                </label>
              </div>
              {/* {name == "OTT" && (
                <Box
                  id="OTTMenu"
                  sx={{
                    transform: "rotate(180deg)",
                  }}
                >
                  {subMenuIdx === idx ? (
                    <BsFillArrowDownCircleFill style={{ color: "#DA1A5D" }} />
                  ) : (
                    <BsFillArrowUpCircleFill style={{ color: "#DA1A5D" }} />
                  )}
                </Box>
              )} */}
            </div>
          </div>
        </NavLink>
      )}

      {submenu.length > 0 && subMenuIdx === idx && (
        <div
          style={{ height: subMenuIdx === idx ? "inherit" : 0 }}
          className="mobiotics-link-subbox"
        >
          {submenu.map((ele, idx) => (
            <NavLink to={ele.link}>
              <div className="mobiotics-link-box">
                <div className="mobiotics-link-icon">{ele.icon}</div>
                <div className="mobiotics-link-texts">
                  <label className="mobiotics-link-texts-name">
                    {ele.name}
                  </label>
                </div>
              </div>
            </NavLink>
          ))}
        </div>
      )}
    </div>
  );
};

function CustomNavbar() {
  const location = useLocation();
  const history = useNavigate();
  const subRouteRef = useRef(null);
  const routeRef = useRef(null);
  const [navIndex, setNavIndex] = useState(0);
  const [subIndexNav, setSubNavIndex] = useState(-1);
  const [currentPath, setCurrentPath] = useState("");

  const [openCFModal, setOpenCFModal] = useState(false);

  function handleHover(event, idx) {
    if (
      (subRouteRef.current && subRouteRef.current.contains(event.target)) ||
      (routeRef.current && routeRef.current.contains(event.target))
    ) {
      setNavIndex(idx);
    }
  }
  const handleClicked = (event, idx) => {
    if (
      (subRouteRef.current && !subRouteRef.current.contains(event.target)) ||
      (routeRef.current && routeRef.current.contains(event.target))
    ) {
      navIndex === idx ? setNavIndex(-1) : setNavIndex(idx);
    }
  };

  const handleSubmenuClicked = (event, idx, parentIndex) => {
    if (
      (subRouteRef.current && !subRouteRef.current.contains(event.target)) ||
      (routeRef.current && routeRef.current.contains(event.target))
    ) {
      setNavIndex(parentIndex);
    }
    if (subIndexNav === idx || location.pathname.split("/")[2]) {
      console.log("heelo");
      setNavIndex(idx);
    }

    subIndexNav === idx ? setSubNavIndex(-1) : setSubNavIndex(idx);
  };

  useEffect(() => {
    console.log(":::: su nava index ::::", subIndexNav);
    setSubNavIndex(-1);
    setNavIndex(-1);
    const paths = window.location.pathname.split("/");
    if (paths[1] !== "") {
      setCurrentPath(paths[1]);
      // window.location.reload();
    }
  }, [window.location.pathname]);

  const handleCloseModal = () => setOpenCFModal(false);

  return (
    <header>
      <nav className="header-container">
        <Link to="/">
          {" "}
          <div className="mobiotics-logo">
            <img src={Logo} alt="mobiotics-logo" />
          </div>
        </Link>
        <div className="d-flex content-between align-items-center">
          {routes.map((route, idx) => (
            <div
              ref={routeRef}
              key={route.id}
              className="pos-rel"
              onClick={(e) => handleClicked(e, route.id)}
              onMouseEnter={(e) => {
                setNavIndex(route.id);
                // console.log("gggggggggggg",route)
                // handleHover(e, route.id);
              }}
              onMouseLeave={() => setNavIndex(0)}
            >
              <div
                className={`mobiotics-link-title d-flex content-between align-items-center
                     ${
                       currentPath === route?.submenu[0]?.link?.split("/")[1]
                         ? "mobiotics-activelink"
                         : ""
                     }
                `}
              >
                <span
                  style={{
                    fontFamily: FontHelper.FontBold,
                    fontWeight: 700,
                    cursor: "pointer",
                  }}
                >
                  {route.name}
                </span>
                &nbsp;&nbsp;
                {route.dropicon}
              </div>
              {navIndex === route.id && (
                <div className="mobiotics-sublink-box d-flex" ref={subRouteRef}>
                  <div>
                    {route.submenu.length > 4
                      ? route.submenu.slice(0, 4).map((subroute, i) => (
                          <>
                            <EachMenuItem
                              key={subroute.id}
                              name={subroute.name}
                              link={subroute.link}
                              caption={subroute.caption}
                              icon={subroute.icon}
                              submenu={subroute.submenu}
                              idx={subroute.id}
                              subMenuIdx={subIndexNav}
                              handleClick={(e) =>
                                handleSubmenuClicked(e, subroute.id, route.id)
                              }
                            />
                          </>
                        ))
                      : route.submenu.map((subroute, i) => (
                          <EachMenuItem
                            key={subroute.id}
                            name={subroute.name}
                            link={subroute.link}
                            caption={subroute.caption}
                            icon={subroute.icon}
                            submenu={subroute.submenu}
                            idx={subroute.id}
                            subMenuIdx={subIndexNav}
                            handleClick={(e) =>
                              handleSubmenuClicked(e, subroute.id, route.id)
                            }
                          />
                        ))}
                  </div>
                  <div>
                    {route.submenu
                      .slice(4, route.submenu.length)
                      .map((subroute, i) => (
                        <EachMenuItem
                          key={subroute.id}
                          name={subroute.name}
                          link={subroute.link}
                          caption={subroute.caption}
                          icon={subroute.icon}
                          submenu={subroute.submenu}
                          idx={subroute.id}
                          subMenuIdx={subIndexNav}
                          handleClick={(e) =>
                            handleSubmenuClicked(e, subroute.id, route.id)
                          }
                        />
                      ))}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="d-flex content-between align-items-center">
          <Link to="/company/contact">
            <button className="button button-secondary">
              <FiHeadphones />
              &nbsp;Talk to Us
            </button>
          </Link>
          &nbsp;&nbsp;
          <Box
            className="button button-primary"
            onClick={() => {
              setOpenCFModal(true);
            }}
          >
            Request Demo
          </Box>
        </div>
      </nav>
      {/* Talk to Us Modal */}

      <TalkToUsModaldrp
        open={openCFModal}
        onClose={handleCloseModal}
        formName={ConstantHelper.modalName1}
      />
    </header>
  );
}

export default CustomNavbar;
