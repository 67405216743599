import * as React from "react";
import ColorHelper from "../../Helpers/ColorHelper";

const Broadcast = ({ size = 46, color, ...props }) => (
  
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="48" height="48" rx="24" fill="#FFF3F5"/>
    <path d="M16.929 14.93L18.343 16.344C17.5992 17.0859 17.0092 17.9675 16.6072 18.9382C16.2051 19.9088 15.9987 20.9493 16 22C16 24.21 16.895 26.21 18.343 27.657L16.93 29.07C16 28.1426 15.2624 27.0406 14.7596 25.8273C14.2568 24.614 13.9987 23.3133 14 22C13.9985 20.6867 14.2564 19.386 14.7591 18.1727C15.2617 16.9594 15.9992 15.8574 16.929 14.93V14.93ZM31.071 14.93C32.0009 15.8573 32.7384 16.9593 33.2411 18.1726C33.7437 19.386 34.0017 20.6866 34 22C34.0019 23.3136 33.7441 24.6146 33.2414 25.8283C32.7387 27.042 32.0011 28.1443 31.071 29.072L29.657 27.658C30.4012 26.9158 30.9913 26.0339 31.3934 25.0629C31.7955 24.0919 32.0017 23.0509 32 22C32 19.79 31.105 17.79 29.657 16.343L31.07 14.93L31.071 14.929V14.93ZM19.757 17.758L21.172 19.173C20.8001 19.5438 20.5051 19.9844 20.304 20.4695C20.1028 20.9547 19.9995 21.4748 20 22C20 23.105 20.448 24.105 21.172 24.828L19.757 26.243C19.199 25.6864 18.7565 25.025 18.455 24.2968C18.1534 23.5687 17.9988 22.7881 18 22C18 20.343 18.672 18.843 19.757 17.757V17.758ZM28.243 17.758C28.8008 18.3145 29.2432 18.9757 29.5447 19.7037C29.8462 20.4316 30.001 21.212 30 22C30.0011 22.7881 29.8464 23.5686 29.5449 24.2968C29.2434 25.0249 28.8009 25.6863 28.243 26.243L26.828 24.828C27.2 24.457 27.4951 24.0162 27.6962 23.5309C27.8974 23.0456 28.0006 22.5253 28 22C28.0007 21.4746 27.8975 20.9543 27.6963 20.469C27.4952 19.9836 27.2001 19.5428 26.828 19.172L28.243 17.757V17.758ZM24 24C23.4696 24 22.9609 23.7892 22.5858 23.4142C22.2107 23.0391 22 22.5304 22 22C22 21.4695 22.2107 20.9608 22.5858 20.5857C22.9609 20.2107 23.4696 20 24 20C24.5304 20 25.0391 20.2107 25.4142 20.5857C25.7893 20.9608 26 21.4695 26 22C26 22.5304 25.7893 23.0391 25.4142 23.4142C25.0391 23.7892 24.5304 24 24 24ZM24 26C24.58 26 25.077 26.413 25.184 26.983L26.5 34H21.5L22.816 26.983C22.923 26.413 23.42 26 24 26Z" fill="#DA1A5D"/>
    </svg>
);

export default Broadcast;