import React from "react";
import ColorHelper from "../../Helpers/ColorHelper";

const HighlightIcon6 = () => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_655_53389)">
        <path
          d="M43.2021 12.3051C42.9744 11.3955 42.5107 10.562 41.8579 9.88894C41.205 9.21588 40.3861 8.72702 39.4838 8.47175C36.1871 7.66675 23.0004 7.66675 23.0004 7.66675C23.0004 7.66675 9.81378 7.66675 6.51711 8.54841C5.61483 8.80369 4.7959 9.29254 4.14303 9.96561C3.49015 10.6387 3.02646 11.4721 2.79878 12.3817C2.19544 15.7274 1.90031 19.1213 1.91711 22.5209C1.8956 25.9461 2.19075 29.3659 2.79878 32.7367C3.04978 33.6181 3.52387 34.4199 4.17522 35.0645C4.82658 35.7091 5.63318 36.1749 6.51711 36.4167C9.81378 37.2984 23.0004 37.2984 23.0004 37.2984C23.0004 37.2984 36.1871 37.2984 39.4838 36.4167C40.3861 36.1615 41.205 35.6726 41.8579 34.9996C42.5107 34.3265 42.9744 33.493 43.2021 32.5834C43.8008 29.263 44.0959 25.8949 44.0838 22.5209C44.1053 19.0957 43.8101 15.6759 43.2021 12.3051V12.3051Z"
          stroke={ColorHelper.BgBlue}
          stroke-width="3.83333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M18.6875 28.7884L29.7083 22.5209L18.6875 16.2534V28.7884Z"
          stroke={ColorHelper.BgBlue}
          stroke-width="3.83333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_655_53389">
          <rect width="46" height="46" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HighlightIcon6;
