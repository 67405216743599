// Helper
import ColorHelper from "../../Helpers/ColorHelper";
import FontHelper from "../../Helpers/FontHelper";

const ContactSectionStyle = {
  FooterCard: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    flexDirection: { xs: "column", md: "column", lg: "row" },
    mx: { xs: 4, md: 5 },
    py: 5,
    px: { xs: 3, md: 12 },
    borderBottom: `1px solid ${ColorHelper.grey2}`,
    mb: 7,
    mt: { xs: 4, md: 0 },
    pb: {xs: 0, md: 3},
  },

  getinTouch: {
    // width: "591px",
    // height: "27px",
    fontfamily: FontHelper.FontRegular,
    fontstyle: "normal",
    fontweight: "500",
    fontsize: "18px",
    lineheight: "27px",
    color: "#457ABD",
    flex: "none",
    order: 0,
    flexgrow: 0,
    maxWidth: '100vw',
  },
  friendlytxt: {
    fontFamily: FontHelper.FontSemiBold,
    fontstyle: "normal",
    fontweight: "600",
    fontsize: { xs: '20 !important', md: '24 !important' },
    lineheight: "36px",
    color: "#1D2939",
    flex: "none",
    order: 1,
    flexgrow: 0
  },
  formsection: {
    boxsizing: "borderbox",
    display: "flex",
    flexdirection: "column",
    justifycontent: "center",
    alignitems: "center",
    padding: "60px 112px 0px",
    // width: "1440px",
    // height: "1114px",
    background: "#FCFCFD",
    borderbottom: "1px solid #E4E7EC",
    flex: "none",
    order: 0,
    flexgrow: 0
  },
  emailicon: {
    position: "absolute",
    left: "8.33%",
    right: "8.33%",
    top: "16.67%",
    bottom: "16.67%",
    border: "2px solid #457ABD"
  },
  textContainer: {
    textAlign: { xs: "center", md: "unset" },
    mb: { xs: 5, md: 5, lg: 0 },
    maxWidth: { xs: '100%', md: '30%' },
  },
  cardTitle: {
    color: ColorHelper.black,
    fontFamily: FontHelper.FontSemiBold,
    fontSize: "2.5rem",
    fontWeight: 700,
    lineHeight: { xs: 1.2, md: 2 },
  },
  cardCaption: {
    color: ColorHelper.grey,
    fontSize: "1.45rem",
    fontWeight: 500,
    my: { xs: 2, md: 0 },
  },
  emailicon: {
    position: "absolute",
    left: "8.33%",
    right: "8.33%",
    top: "16.67%",
    bottom: "16.67%",
    border: "2px solid #457ABD"
  },
  phoneicon: {
    position: "absolute",
    left: "8.8%",
    right: "8.33%",
    top: "8.33%",
    bottom: "8.63%",
    border: "2px solid #457ABD"
  },
  phone: {

    // width: "384px",
    // height: "27px",
    fontfamily: FontHelper.FontRegular,
    fontstyle: "normal",
    fontweight: "500",
    fontsize: "18px",
    lineheight: "27px",
    color: "#101828",
    flex: "none",
    order: 0,
    alignself: "stretch",
    flexgrow: 0,
    margin: { xs: 'auto', md: 'unset' }
  },

  phone_content1: {
    // width: "384px",
    // height: "24px",
    fontfamily: FontHelper.FontRegular,
    fontstyle: "normal",
    fontweight: "400",
    fontsize: "16px",
    lineheight: "24px",
    color: "#667085",
    flex: "none",
    order: 1,
    alignself: "stretch",
    flexgrow: 0,
  },

  phone_content2: {

    // width: "384px",
    // height: "24px",
    fontfamily: FontHelper.FontRegular,
    fontstyle: "normal",
    fontweight: "400",
    fontsize: "16px",
    lineheight: "24px",
    color: "#DA1A5D",
    flex: "none",
    order: 1,
    alignself: "stretch",
    flexgrow: 0
  },
  mappingicon: {
    boxsizing: "border-box",
    border: "1px solid #E4E7EC",
    borderradius: "5px",
    flex: "none",
    order: 1,
    alignself: "stretch",
    flexgrow: 1,
    maxWidth: '100%',
  },
  mapImageContainer: {
    display: 'flex',
    justifyContent: "center",
    maxWidth: { xs: '100%', md: '50%' },
    maxHeight: '400px',
    objectFit: 'cover',
    mt: {xs: 0, md: 10},
    cursor: 'pointer',
  },
  mapicon: {
    position: "absolute",
    left: "12.5%",
    right: "12.5%",
    top: "4.17%",
    bottom: "4.17%",
    border: "2px solid #457ABD"
  },

  office: {
    fontfamily: FontHelper.FontRegular,
    fontstyle: "normal",
    fontweight: "500",
    fontsize: "18px",
    lineheight: "27px",
    color: "#101828",
    flex: "none",
    order: 0,
    alignself: "stretch",
    flexgrow: 0
  },
  office_content1: {
    fontfamily: FontHelper.FontRegular,
    fontstyle: "normal",
    fontweight: "400",
    fontsize: "16px",
    lineheight: "24px",
    color: "#667085",
    flex: "none",
    order: 1,
    alignself: "stretch",
    flexgrow: 0
  },

  office_content2: {
    fontfamily: FontHelper.FontRegular,
    fontstyle: "normal",
    fontweight: "500",
    fontsize: "16px",
    lineheight: "24px",
    color: ColorHelper.PriamryColor,
    flex: "none",
    order: 1,
    alignself: "stretch",
    flexgrow: 0,
    textAlign: 'left',
  },


  emailName: {
    fontfamily: FontHelper.FontRegular,
    fontstyle: "normal",
    fontweight: "500",
    fontsize: "18px",
    lineheight: "27px",
    color: "#101828",
    flex: "none",
    order: 0,
    alignself: "stretch",
    flexgrow: 0,
  },
  email_content1: {
    // width: "384px",
    // height: "24px",
    fontfamily: FontHelper.FontRegular,
    fontstyle: "normal",
    fontweight: "400",
    fontsize: "16px",
    lineheight: "24px",
    color: "#667085",
    flex: "none",
    order: 1,
    alignself: "stretch",
    flexgrow: 0
  },
  email_content2: {
    // width: "384px",
    // height: "24px",
    fontfamily: FontHelper.FontRegular,
    fontstyle: "normal",
    fontweight: "400",
    fontsize: "16px",
    lineheight: "24px",
    color: "#DA1A5D",
    flex: "none",
    order: 1,
    alignself: "stretch",
    flexgrow: 0
  },
  formlabels: {
    marginBottom: '-0.8rem',
    fontFamily: FontHelper.FontSemiBold,
    fontstyle: "normal",
    fontweight: "600",
    fontsize: "12px",
    lineheight: "18px",
    color: ColorHelper.grey4,
    flex: "none",
    order: 0,
    alignself: "stretch",
    flexgrow: 0,
    mt: 3,
  },

  submitbtn: {
    width: "100%"
  },
  formlabels1: {
    marginBottom: '-0.8rem',
    fontfamily: FontHelper.FontRegular,
    fontstyle: "normal",
    fontweight: "600",
    fontsize: "12px",
    lineheight: "18px",
    color: ColorHelper.grey4,
    flex: "none",
    order: 0,
    alignself: "stretch",
    flexgrow: 0,
    mt: 4

  },
  inputfield: {
    display: "flex",
    flexdirection: "row",
    alignitems: "flexstart",
    padding: "8px 16px",
    gap: "8px",
    // width: "283.5px",
    // height: "40px",
    background: "#FCFCFD",
    border: "1px solid #457ABD",
    borderradius: "5px",
    flex: "none",
    order: 1,
    alignself: "stretch",
    flexgrow: 0
  },



  btnContainer: {
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: {xs: 'space-around', md: 'center'},
    // flexDirection: {xs: 'column', md: 'row'},
    // minHeight: {xs : '150px', md: 'auto'}
  },
  SectionContainer: {
    maxWidth: "100vw",
  },
  sectionWrapper: {
    mx: { xs: 5, md: 19 },
    display: "flex",
    justifyContent: "space-between",
    alignItems: { xs: "center", md: "flex-start" },
    flexDirection: { xs: "column", md: "column", lg: "row" },
    textAlign: "left",
    mt: {xs: 10, md: 20},
    maxWidth: '100vw',
  },
  FootershortDesc: {
    fontSize: 20,
    fontFamily: FontHelper.FontRegular,
    color: ColorHelper.grey,
    maxWidth: "100%",
    my: 5,
    mr: { xs: 0, md: 0, lg: 5 },
  },
  MenuListingContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    minWidth: { xs: "65%", md: "100%", lg: "65%" },
  },
  MenuListing: {
    minWidth: { xs: "50%", md: "25%", lg: "auto" },
    textAlign: 'left',
    mt: { xs: 4, md: 0 },
    margin: { xs: 'auto', md: 'unset' },
  },
  MenuTitle: {
    fontSize: 16,
    fontFamily: FontHelper.FontRegular,
    color: ColorHelper.grey3,
  },
  Link: {
    display: 'flex',
    // justifyContent: {xs: 'center', md: 'flex-start'}, 
    alignItems: 'center',
    minWidth: 'max-content',
    textDecoration: "none",
    color: ColorHelper.grey,
    "&hover": {
      color: ColorHelper.PriamryColor,
    },
  },
  MenuLink: {
    fontSize: 16,
    fontFamily: FontHelper.FontRegular,
    my: 1.5,
    minWidth: 'max-content',
  },
  MenuLinkBadge: {
    display: { xs: 'none', md: 'block' },
    fontSize: 16,
    fontFamily: FontHelper.FontRegular,
    my: 1.5,
    color: ColorHelper.green,
    backgroundColor: ColorHelper.lightGreen,
    maxWidth: 'fit-content',
    padding: '0.2rem 0.6rem',
    borderRadius: '25px',
    ml: 2,
  },
  creditsContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: { xs: "column", md: "row" },
    py: 3,
    mx: { xs: 1, md: 19 },
    mb: { xs: 0, md: 3 },
  },
  copyrightWrapper: {
    my: { xs: 2, md: 1 },
  },
  Copyright: {
    textAlign: { xs: "center", md: "left" },
    color: ColorHelper.grey3,
    fontSize: "1rem",
  },
  socialIconsContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  FooterIcons: {
    mx: 1,
  },
  divider: {
    maxWidth: "100%",
    backgroundColor: ColorHelper.grey2,
    opacity: "0.5",
    m: "auto",
    my: 2,
    mr: 6.8,
  },
};



export default ContactSectionStyle;
