import React from "react";
import ColorHelper from "../../Helpers/ColorHelper";

const HighlightIcon5 = () => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23 5.75H36.4167C37.4333 5.75 38.4084 6.15387 39.1272 6.87276C39.8461 7.59165 40.25 8.56667 40.25 9.58333V36.4167C40.25 37.4333 39.8461 38.4084 39.1272 39.1272C38.4084 39.8461 37.4333 40.25 36.4167 40.25H23M23 5.75H9.58333C8.56667 5.75 7.59165 6.15387 6.87276 6.87276C6.15387 7.59165 5.75 8.56667 5.75 9.58333V36.4167C5.75 37.4333 6.15387 38.4084 6.87276 39.1272C7.59165 39.8461 8.56667 40.25 9.58333 40.25H23M23 5.75V40.25"
        stroke={ColorHelper.BgBlue}
        stroke-width="3.83333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default HighlightIcon5;
