import React from "react";

export default function ShareLink({slug}) {
  return (
     <>
      <a
        target="_blank"
        href={`https://www.facebook.com/sharer/sharer.php?u=https://www.mobiotics.com/resources/blogs/${slug}`}
      >
        <img src="/images/socialmedia/Facebook.svg" />
      </a>
      <a
        target="_blank"
        href={`https://twitter.com/share?url=https://www.mobiotics.com/resources/blogs/${slug}`}
      >
        <img src="/images/socialmedia/Twitter.svg" />
      </a>

      <a
        target="_blank"
        href={`https://www.linkedin.com/uas/login?session_redirect=https://www.linkedin.com/shareArticle?url=https://www.mobiotics.com/resources/blogs/${slug}`}
      >
        <img src="/images/socialmedia/Linkedin.svg" />
      </a>
 </>
  );
}
