// Helper
import ColorHelper from "../../../Helpers/ColorHelper";
import ConstantHelper from "../../../Helpers/ConstantHelper";
import IconHelper from "../../../Helpers/IconHelper";

const NavLinkData = [
  {
    menuTitle: ConstantHelper.MenuName1,
    subMenu: [
      {
        subMenuId: 1,
        submenuTitle: "vLive",
        submenuCaption: "Nibh eu in diam cursus.",
        submenuLink: "products/vLive",
        subMenuClass: "itemA",
        submenunIcon: (
          <IconHelper.NavbarIcon2
            color={ColorHelper.PriamryColor}
            bgColor={ColorHelper.white2}
          />
        ),
      },
      {
        subMenuId: 2,
        submenuTitle: "Sounds",
        submenuCaption: "Tincidunt sem aliquet nam.",
        submenuLink: "products/sounds",
        subMenuClass: "itemB",
        submenunIcon: (
          <IconHelper.NavbarIcon6
            color={ColorHelper.PriamryColor}
            bgColor={ColorHelper.white2}
          />
        ),
      },
      {
        subMenuId: 3,
        submenuTitle: "vLite MOOC",
        submenuCaption: "Turpis viverra viverra.",
        submenuLink: "products/vlite-mooc",
        subMenuClass: "itemC",
        submenunIcon: (
          <IconHelper.NavbarIcon3
            color={ColorHelper.PriamryColor}
            bgColor={ColorHelper.white2}
          />
        ),
      },
      {
        subMenuId: 4,
        submenuTitle: "vApps",
        submenuCaption: "Fames aliquam quis senectus.",
        submenuLink: "products/vApps",
        subMenuClass: "itemE",
        submenunIcon: (
          <IconHelper.NavbarIcon7
            color={ColorHelper.PriamryColor}
            bgColor={ColorHelper.white2}
          />
        ),
      },
      {
        subMenuId: 5,
        submenuTitle: "vDRM",
        submenuCaption: "Fames aliquam quis senectus.",
        submenuLink: "products/vDRM",
        subMenuClass: "itemF",
        submenunIcon: (
          <IconHelper.NavbarIcon4
            color={ColorHelper.PriamryColor}
            bgColor={ColorHelper.white2}
          />
        ),
      },
      {
        subMenuId: 6,
        submenuTitle: "vSMS",
        submenuCaption: "Consectetur turpis fames.",
        submenuLink: "products/vSMS",
        subMenuClass: "itemH",
        submenunIcon: (
          <IconHelper.NavbarIcon5
            color={ColorHelper.PriamryColor}
            bgColor={ColorHelper.white2}
          />
        ),
      },
      {
        subMenuId: 7,
        submenuTitle: "vCMS",
        submenuCaption: "Consectetur turpis fames.",
        submenuLink: "products/vCMS",
        subMenuClass: "itemG",
        submenunIcon: (
          <IconHelper.NavbarIcon8
            color={ColorHelper.PriamryColor}
            bgColor={ColorHelper.white2}
          />
        ),
      },

    ],
  },
  {
    menuTitle: ConstantHelper.MenuName2,
    subMenu: [
      // {
      //   subMenuId: 1,
      //   submenuTitle: "Operators",
      //   submenuCaption: "Nibh eu in diam cursus.",
      //   submenuLink: "/operators",
      //   subMenuClass: "itemA",
      //   submenunIcon: (
      //     <IconHelper.NavbarIcon1
      //       color={ColorHelper.PriamryColor}
      //       bgColor={ColorHelper.white2}
      //     />
      //   ),
      // },
      // {
      //   subMenuId: 1,
      //   submenuTitle: "OTT",
      //   submenuCaption: "Nibh eu in diam cursus.",
      //   submenuLink: "#",
      //   subMenuClass: "itemA",
      //   submenunIcon: (
      //     <IconHelper.NavbarIcon1
      //       color={ColorHelper.PriamryColor}
      //       bgColor={ColorHelper.white2}
      //     />
      //   ),
      //   subAttributes: [{
      //     title: "Media & Entertainment",
      //     submenuLink: "Solutions/media&entertainment",
      //     submenunIcon: (
      //       <IconHelper.NavbarIcon2
      //         color={ColorHelper.PriamryColor}
      //         bgColor={ColorHelper.white}
      //       />

      //     ),
      //   },
      //   {
      //     title: "Operators",
      //     submenuLink: "Solutions/operators",
      //     submenunIcon: (
      //       <IconHelper.NavbarIcon2
      //         color={ColorHelper.PriamryColor}
      //         bgColor={ColorHelper.white}
      //       />
      //     ),
      //   },
      //   {
      //     title: "Education & E-Learning",
      //     submenuLink: "Solutions/Education&ELearning",
      //     submenunIcon: (
      //       <IconHelper.NavbarIcon2
      //         color={ColorHelper.PriamryColor}
      //         bgColor={ColorHelper.white}
      //       />
      //     ),
      //   },
      //   {
      //     title: "Health & Fitness",
      //     submenuLink: "Solutions/Health&Fitness",
      //     submenunIcon: (
      //       <IconHelper.NavbarIcon2
      //         color={ColorHelper.PriamryColor}
      //         bgColor={ColorHelper.white}
      //       />
      //     ),
      //   },
      //   {
      //     title: "OTT for Enterprise",
      //     submenuLink: "Solutions/OTTforenterprises",
      //     submenunIcon: (
      //       <IconHelper.NavbarIcon2
      //         color={ColorHelper.PriamryColor}
      //         bgColor={ColorHelper.white}
      //       />
      //     ),
      //   }
      //   ]
      // },
      {
        subMenuId: 1,
        submenuTitle: "OTT",
        submenuCaption: "End-to-End Audio & Video Streaming platform",
        submenuLink: "solutions/OTT",
        subMenuClass: "itemB",
        submenunIcon: (
          <IconHelper.NavbarIcon2
            color={ColorHelper.PriamryColor}
            bgColor={ColorHelper.white2}
          />
        ),
        subAttributes: []
      },
      {
        subMenuId: 2,
        submenuTitle: "Broadcast",
        submenuCaption: "Nibh eu in diam cursus.",
        submenuLink: "solutions/broadcast",
        subMenuClass: "itemB",
        submenunIcon: (
          <IconHelper.NavbarIcon3
            color={ColorHelper.PriamryColor}
            bgColor={ColorHelper.white2}
          />
        ),
        subAttributes: []
      },
      {
        subMenuId: 3,
        submenuTitle: "Digitization",
        submenuCaption: "Nibh eu in diam cursus.",
        submenuLink: "solutions/digitization",
        subMenuClass: "itemC",
        submenunIcon: (
          <IconHelper.NavbarIcon4
            color={ColorHelper.PriamryColor}
            bgColor={ColorHelper.white2}
          />
        ),
        subAttributes: []
      },
    ],
  },
  {
    menuTitle: ConstantHelper.MenuName3,
    subMenu: [
      {
        subMenuId: 1,
        submenuTitle: "OTT Apps",
        submenuCaption: "Nibh eu in diam cursus.",
        submenuLink: "services/ott-apps",
        subMenuClass: "itemA",
        submenunIcon: (
          <IconHelper.NavbarIcon1
            color={ColorHelper.PriamryColor}
            bgColor={ColorHelper.white2}
          />
        ),
      },
      {
        subMenuId: 2,
        submenuTitle: "OTT Consulting",
        submenuCaption: "Nibh eu in diam cursus.",
        submenuLink: "services/ott-consulting",
        subMenuClass: "itemB",
        submenunIcon: (
          <IconHelper.NavbarIcon2
            color={ColorHelper.PriamryColor}
            bgColor={ColorHelper.white2}
          />
        ),
      },
      // {
      //   subMenuId: 3,
      //   submenuTitle: "DevOps",
      //   submenuCaption: "Nibh eu in diam cursus.",
      //   submenuLink: "Services/dev-ops",
      //   subMenuClass: "itemC",
      //   submenunIcon: (
      //     <IconHelper.NavbarIcon3
      //       color={ColorHelper.PriamryColor}
      //       bgColor={ColorHelper.white2}
      //     />
      //   ),
      // },
      {
        subMenuId: 4,
        submenuTitle: "Custom Development",
        submenuCaption: "Nibh eu in diam cursus.",
        submenuLink: "services/custom-development",
        subMenuClass: "itemD",
        submenunIcon: (
          <IconHelper.NavbarIcon4
            color={ColorHelper.PriamryColor}
            bgColor={ColorHelper.white2}
          />
        ),
      },
      // {
      //   subMenuId: 5,
      //   submenuTitle: "QA Automation",
      //   submenuCaption: "Nibh eu in diam cursus.",
      //   submenuLink: "Services/qa-automation",
      //   subMenuClass: "itemE",
      //   submenunIcon: (
      //     <IconHelper.NavbarIcon5
      //       color={ColorHelper.PriamryColor}
      //       bgColor={ColorHelper.white2}
      //     />
      //   ),
      // },
    ],
  },
  {
    menuTitle: ConstantHelper.MenuName4,
    subMenu: [
      {
        subMenuId: 1,
        submenuTitle: "About",
        submenuCaption: "Nibh eu in diam cursus.",
        submenuLink: "company/about",
        subMenuClass: "itemA",
        submenunIcon: (
          <IconHelper.About
            color={ColorHelper.PriamryColor}
            bgColor={ColorHelper.white2}
          />
        ),
      },
      {
        subMenuId: 2,
        submenuTitle: "Leadership Team",
        submenuCaption: "Nibh eu in diam cursus.",
        submenuLink: "company/leadership",
        subMenuClass: "itemB",
        submenunIcon: (
          <IconHelper.NavbarIcon2
            color={ColorHelper.PriamryColor}
            bgColor={ColorHelper.white2}
          />
        ),
      },
      {
        subMenuId: 3,
        submenuTitle: "Contact Us",
        submenuCaption: "Nibh eu in diam cursus.",
        submenuLink: "company/contact",
        subMenuClass: "itemC",
        submenunIcon: (
          <IconHelper.NavbarIcon3
            color={ColorHelper.PriamryColor}
            bgColor={ColorHelper.white2}
          />
        ),
      },
      {
        subMenuId: 4,
        submenuTitle: "Careers",
        submenuCaption: "Nibh eu in diam cursus.",
        submenuLink: "company/career",
        subMenuClass: "itemD",
        submenunIcon: (
          <IconHelper.NavbarIcon4
            color={ColorHelper.PriamryColor}
            bgColor={ColorHelper.white2}
          />
        ),
      },

    ],
  },
  //OTT Pages 
  // {
  //   menuTitle: ConstantHelper.MenuName6,
  //   subMenu: [
  //     {
  //       subMenuId: 1,
  //       submenuTitle: "Media & Entertainment",
  //       submenuCaption: "Nibh eu in diam cursus.",
  //       submenuLink: "/Media&Entertainment",
  //       subMenuClass: "itemA",
  //       submenunIcon: (
  //         <IconHelper.NavbarIcon1
  //           color={ColorHelper.PriamryColor}
  //           bgColor={ColorHelper.white2}
  //         />
  //       ),
  //     },
  //     {
  //       subMenuId: 2,
  //       submenuTitle: "Operators",
  //       submenuCaption: "Nibh eu in diam cursus.",
  //       submenuLink: "/operators",
  //       subMenuClass: "itemB",
  //       submenunIcon: (
  //         <IconHelper.NavbarIcon2
  //           color={ColorHelper.PriamryColor}
  //           bgColor={ColorHelper.white2}
  //         />
  //       ),
  //     },
  //     {
  //       subMenuId: 3,
  //       submenuTitle: "Education & E-Learning",
  //       submenuCaption: "Nibh eu in diam cursus.",
  //       submenuLink: "/education&Elearning",
  //       subMenuClass: "itemC",
  //       submenunIcon: (
  //         <IconHelper.NavbarIcon3
  //           color={ColorHelper.PriamryColor}
  //           bgColor={ColorHelper.white2}
  //         />
  //       ),
  //     },
  //     {
  //       subMenuId: 4,
  //       submenuTitle: "Health & Fitness",
  //       submenuCaption: "Nibh eu in diam cursus.",
  //       submenuLink: "/Health&Fitness",
  //       subMenuClass: "itemD",
  //       submenunIcon: (
  //         <IconHelper.NavbarIcon4
  //           color={ColorHelper.PriamryColor}
  //           bgColor={ColorHelper.white2}
  //         />
  //       ),
  //     },
  //     {
  //       subMenuId: 5,
  //       submenuTitle: "OTT for Enterprises ",
  //       submenuCaption: "Nibh eu in diam cursus.",
  //       submenuLink: "/OTTforEnterprise",
  //       subMenuClass: "OTT&Enterprise",
  //       submenunIcon: (
  //         <IconHelper.NavbarIcon5
  //           color={ColorHelper.PriamryColor}
  //           bgColor={ColorHelper.white2}
  //         />
  //       ),
  //     },
  //     {
  //       subMenuId: 6,
  //       submenuTitle: "Broadcast",
  //       submenuCaption: "Nibh eu in diam cursus.",
  //       submenuLink: "/Broadcast",
  //       subMenuClass: "Broadcast",
  //       submenunIcon: (
  //         <IconHelper.NavbarIcon5
  //           color={ColorHelper.PriamryColor}
  //           bgColor={ColorHelper.white2}
  //         />
  //       ),
  //     },
  //     {
  //       subMenuId: 7,
  //       submenuTitle: "Digilization",
  //       submenuCaption: "Nibh eu in diam cursus.",
  //       submenuLink: "/digitalization",
  //       subMenuClass: "Broadcast",
  //       submenunIcon: (
  //         <IconHelper.NavbarIcon5
  //           color={ColorHelper.PriamryColor}
  //           bgColor={ColorHelper.white2}
  //         />
  //       ),
  //     },
  //   ],
  // },
  // {
  //   menuTitle: ConstantHelper.MenuName4,
  //   subMenu: [
  //     {
  //       subMenuId: 1,
  //       submenuTitle: "About",
  //       submenuCaption: "Nibh eu in diam cursus.",
  //       submenuLink: "/about",
  //       subMenuClass: "itemA",
  //       submenunIcon: (
  //         <IconHelper.NavbarIcon1
  //           color={ColorHelper.PriamryColor}
  //           bgColor={ColorHelper.white2}
  //         />
  //       ),
  //     },
  //     {
  //       subMenuId: 2,
  //       submenuTitle: "Leadership Team",
  //       submenuCaption: "Nibh eu in diam cursus.",
  //       submenuLink: "/leadership-team",
  //       subMenuClass: "itemB",
  //       submenunIcon: (
  //         <IconHelper.NavbarIcon1
  //           color={ColorHelper.PriamryColor}
  //           bgColor={ColorHelper.white2}
  //         />
  //       ),
  //     },
  //     {
  //       subMenuId: 3,
  //       submenuTitle: "Partners",
  //       submenuCaption: "Nibh eu in diam cursus.",
  //       submenuLink: "/partners",
  //       subMenuClass: "itemC",
  //       submenunIcon: (
  //         <IconHelper.NavbarIcon1
  //           color={ColorHelper.PriamryColor}
  //           bgColor={ColorHelper.white2}
  //         />
  //       ),
  //     },
  //     {
  //       subMenuId: 4,
  //       submenuTitle: "Career",
  //       submenuCaption: "Nibh eu in diam cursus.",
  //       submenuLink: "/career",
  //       subMenuClass: "itemD",
  //       submenunIcon: (
  //         <IconHelper.NavbarIcon1
  //           color={ColorHelper.PriamryColor}
  //           bgColor={ColorHelper.white2}
  //         />
  //       ),
  //     },
  //   ],
  // },
  {
    menuTitle: ConstantHelper.MenuName5,
    subMenu: [
      {
        subMenuId: 1,
        submenuTitle: "Blogs",
        submenuCaption: "Nibh eu in diam cursus.",
        submenuLink: "resources/blogs",
        subMenuClass: "itemA",
        submenunIcon: (
          <IconHelper.NavbarIcon1
            color={ColorHelper.PriamryColor}
            bgColor={ColorHelper.white2}
          />
        ),
      },
  //     {
  //       subMenuId: 2,
  //       submenuTitle: "Sucess Stories",
  //       submenuCaption: "Nibh eu in diam cursus.",
  //       submenuLink: "/sucess-stories",
  //       subMenuClass: "itemB",
  //       submenunIcon: (
  //         <IconHelper.NavbarIcon1
  //           color={ColorHelper.PriamryColor}
  //           bgColor={ColorHelper.white2}
  //         />
  //       ),
  //     },
  //     {
  //       subMenuId: 3,
  //       submenuTitle: "ContentHub",
  //       submenuCaption: "Nibh eu in diam cursus.",
  //       submenuLink: "/content-hub",
  //       subMenuClass: "itemC",
  //       submenunIcon: (
  //         <IconHelper.NavbarIcon1
  //           color={ColorHelper.PriamryColor}
  //           bgColor={ColorHelper.white2}
  //         />
  //       ),
  //     },
  //     {
  //       subMenuId: 4,
  //       submenuTitle: "News Room",
  //       submenuCaption: "Nibh eu in diam cursus.",
  //       submenuLink: "/news-room",
  //       subMenuClass: "itemD",
  //       submenunIcon: (
  //         <IconHelper.NavbarIcon1
  //           color={ColorHelper.PriamryColor}
  //           bgColor={ColorHelper.white2}
  //         />
  //       ),
  //     },
    ],
  },
];

export default NavLinkData;
