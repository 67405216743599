import React from "react";
import ColorHelper from "../../Helpers/ColorHelper";

const HighlightIconV2_3 = () => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_690_54038)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M23 0C35.7026 0 46 10.2974 46 23C46 35.7026 35.7026 46 23 46C10.2974 46 0 35.7026 0 23C0 10.2974 10.2974 0 23 0ZM28.8955 17.1027C28.4982 16.7046 27.8512 16.7046 27.4539 17.1027L24.2218 20.3337C23.8487 20.1618 23.4363 20.0655 22.9999 20.0655C21.3808 20.0655 20.0657 21.3816 20.0657 23.0001C20.0657 24.6176 21.3808 25.9343 22.9999 25.9343C24.616 25.9343 25.9341 24.6176 25.9341 23.0001C25.9341 22.5637 25.8378 22.1496 25.6663 21.777L28.8954 18.5461C29.2957 18.1476 29.2957 17.5012 28.8955 17.1027ZM15.8593 30.7358L15.2348 30.0464C13.7249 28.3792 12.7852 26.2569 12.5627 24.0201H7.72656L7.73186 24.0935C7.98343 27.594 9.44411 30.9204 11.8448 33.4796L12.5938 34.2781L15.8593 30.7358ZM33.4068 34.2775L34.1552 33.4796C36.556 30.9203 38.0133 27.5942 38.2681 24.0945L38.2735 24.0201H33.4345C33.2147 26.2579 32.2747 28.3794 30.7629 30.0457L30.1367 30.7358L33.4068 34.2775ZM34.5165 12.9262L31.0974 16.3453C32.4205 17.9524 33.2355 19.9067 33.4346 21.9796H38.2733L38.2682 21.907C38.0333 18.6032 36.7327 15.4827 34.5641 12.981L34.5165 12.9262ZM24.0202 7.72674V12.5637C26.0939 12.7647 28.0459 13.5798 29.6541 14.9029L33.0729 11.4841L33.0175 11.436C30.5173 9.2663 27.3953 7.96662 24.0922 7.73186L24.0202 7.72674ZM12.9256 11.4827L16.3457 14.9028C17.952 13.5789 19.9073 12.7646 21.9797 12.5637V7.72674L21.9076 7.73186C18.6019 7.96662 15.4831 9.26595 12.9798 11.4356L12.9256 11.4827ZM7.72665 21.9796H12.5624C12.7645 19.9069 13.5794 17.9526 14.9026 16.3453L11.4835 12.9262L11.4364 12.9804C9.26505 15.4814 7.9668 18.6023 7.73177 21.907L7.72665 21.9796Z"
          fill={ColorHelper.BgBlue}
        />
      </g>
      <defs>
        <clipPath id="clip0_690_54038">
          <rect width="46" height="46" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HighlightIconV2_3;
