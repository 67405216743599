import React from "react";

const HLTvLiteMOOC05 = () => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3085_82095)">
        <path
          d="M23 8.01703V37.982C23 41.6657 20.0549 44.6523 16.4225 44.6523C13.4613 44.6523 10.9573 42.6679 10.1335 39.9382C10.0382 39.9436 9.94211 39.9454 9.84598 39.9454C6.21359 39.9454 3.26852 36.9588 3.26852 33.2752C3.26852 32.7577 3.32691 32.2528 3.43742 31.769C2.15176 30.5527 1.34766 28.8179 1.34766 26.8936C1.34766 24.1876 2.93699 21.8569 5.22082 20.8119C4.20918 19.6393 3.59555 18.1022 3.59555 16.4212C3.59555 12.7722 6.48492 9.80735 10.0715 9.75272C9.92414 9.19964 9.84598 8.61746 9.84598 8.01703C9.84598 4.33335 12.7902 1.34766 16.4225 1.34766C20.0549 1.34766 23 4.33335 23 8.01703Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.98263 20.3091C8.63853 20.2526 8.28544 20.2234 7.92517 20.2234C6.96114 20.2234 6.04653 20.4339 5.22087 20.812H5.21997"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M3.43738 31.769C4.61253 32.8824 6.19109 33.563 7.92507 33.563C9.2898 33.563 10.6608 33.0482 11.712 32.3257"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10.0715 9.75271C10.4615 11.226 11.3365 12.497 12.5117 13.3799"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.9888 28.3696C11.4569 28.9236 11.1299 29.6798 11.1299 30.5135C11.1299 32.2127 12.4883 33.5903 14.1639 33.5903C14.2106 33.5903 14.2573 33.5894 14.3032 33.5876"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17.6516 14.3384V14.3375C18.0739 11.7272 20.3074 9.73541 23 9.73541"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M23 26.8562C21.0234 26.8562 19.3838 25.3942 19.074 23.478V23.4774"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M23 38.749C21.0234 38.749 19.3838 37.287 19.074 35.3708V35.3702"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M27.924 43.97H23V32.7223C23.3206 32.7549 23.645 32.7717 23.9743 32.7717C29.2958 32.7717 33.6099 28.3966 33.6099 23C33.6099 17.6024 29.2958 13.2274 23.9743 13.2274C23.645 13.2274 23.3206 13.2442 23 13.2768V2.03001H27.924L28.3079 7.92367C29.7352 8.3436 31.0768 8.96801 32.3004 9.76142L36.4917 6.03749L40.6997 10.3058L37.0277 14.5553C37.9893 16.0809 38.6976 17.7862 39.0931 19.6111L44.6523 19.9826V26.0175L39.0931 26.3889C38.6976 28.2129 37.9893 29.9182 37.0277 31.4438L40.6997 35.6943L36.4917 39.9615L32.3004 36.2386C31.0768 37.0321 29.7352 37.6555 28.3079 38.0764L27.924 43.97Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3085_82095">
          <rect width="46" height="46" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HLTvLiteMOOC05;
