import * as React from "react";
import ColorHelper from "../../Helpers/ColorHelper";

const Upload = ({ size = 46, color, ...props }) => (
    <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 1.91737e-07C13.7351 -0.000392242 15.4165 0.601633 16.7571 1.70325C18.0976 2.80486 19.0141 4.33772 19.35 6.04C21.95 6.22 24 8.36 24 11C24 13.76 21.76 16 19 16H6C2.69 16 0 13.31 0 10C0 6.91 2.34 4.36 5.35 4.04C5.98257 2.82249 6.93716 1.80196 8.10975 1.08958C9.28235 0.377207 10.628 0.000324676 12 1.91737e-07ZM14 13V9H17L12 4L7 9H10V13H14Z" fill="#DA1A5D"/>
    </svg>
    

);

export default Upload;