import React, { useState, useEffect } from "react";
import { Button, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
// import { useTheme } from '@mui/material/styles';
import useMediaQuery from "@mui/material/useMediaQuery";

// Custom Components
import FilledButton from "../Ui-Components/Buttons/FilledButton/FilledButton";
import BorderedButton from "../Ui-Components/Buttons/BorderedButton/BorderedButton";

// Helpers
import ConstantHelper from "../../Helpers/ConstantHelper";
import ColorHelper from "../../Helpers/ColorHelper";
import ImageHelper from "../../Helpers/ImageHelper";

// Custom Style
import StreamingTypeStyle from "./StreamingTypeSectionStyle";
import "./StreamingTypeStyle.css";

// Data
import StreamingTypeData from "./StreamingTypeData";

import StreamTypeItemData from "./StreamTypeItemData";
import ServicesListing from "../Elements/ServicesListing/ServicesListing";
import StreamingTypeData2 from "./StreamTypeData2";

import { Link, useNavigate } from "react-router-dom";
import TalkToUsModal from "../HeroSection/TalkToUsModal/TalkToUsModal";
import TalkToUsModaldrp from "../HeroSection/TalkToUsModal/TalkToUsModaldrp";
import { memo } from "react";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function a11yPropsService(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StreamingType =  memo(function  StreamingType() {
  const [value, setValue] = React.useState(0);
  const [value1, setValue1] = React.useState(0);
  const [service, setService] = React.useState(0);

  const [openCFModal, setOpenCFModal] = useState(false); // Talk to us modal state

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCall = (event, newValue) => {
    setService(newValue);
  };

  const navigate = useNavigate();

  const handleProductsPage = (url) => {
    navigate(`${url}`, { state: { url: url } });
  };

  const handleServicePage = (url, productsIndex) => {
    console.log("productsIndex::", productsIndex);
    navigate(`${url}`, { state: { url: url } });
  };

  // const theme = useTheme();
  // const aboveMobile = useMediaQuery(theme.breakpoints.up('sm'));
  const GalaxyFold = useMediaQuery("(min-width:280px) and (max-width: 359px)");
  const GalaxyS8 = useMediaQuery("(min-width:360px) and (max-width: 374px)");
  const iphoneSE = useMediaQuery("(min-width:375px) and (max-width: 389px)");
  const iphone12Pro = useMediaQuery("(min-width:390px) and (max-width: 392px)");
  const Pixel5 = useMediaQuery("(min-width:393px) and (max-width: 411px)");
  const GalaxyS20 = useMediaQuery("(min-width:412px) and (max-width: 413px)");
  const iPhoneXR = useMediaQuery("(min-width:414px) and (max-width: 539px)");
  const surfaceDuo = useMediaQuery("(min-width:540px) and (max-width: 767px)");
  const iPadMini = useMediaQuery("(min-width:768px) and (max-width: 819px)");
  const iPadAir = useMediaQuery("(min-width:820px) and (max-width: 911px)");
  const surfacePro7 = useMediaQuery(
    "(min-width:912px) and (max-width: 1023px)"
  );
  const NestHub = useMediaQuery("(width:1024px) and (max-height: 600px)");
  const NestHub2 = useMediaQuery("(width:1280px) and (max-height: 800px)");
  const Desktop = useMediaQuery("(min-width:1300px) and (max-width: 2000px)");

  const [wrapperHeight, setWrapperHeight] = useState("160vmax");

  useEffect(() => {
    if (GalaxyS8 === true) {
      setWrapperHeight("265vmax");
      // alert(`GalaxyS8::${wrapperHeight}`);
    } else if (iphoneSE === true) {
      setWrapperHeight("290vmax");
      // alert(`iphoneSE::${wrapperHeight}`);
    } else if (iphone12Pro === true) {
      setWrapperHeight("220vmax");
      // alert(`iphone12Pro::${wrapperHeight}`);
    } else if (Pixel5 === true) {
      setWrapperHeight("215vmax");
      // alert(`Pixel5::${wrapperHeight}`);
    } else if (GalaxyS20 === true) {
      setWrapperHeight("200vmax");
      // alert(`GalaxyS20::${wrapperHeight}`);
    } else if (iPhoneXR === true) {
      setWrapperHeight("200vmax");
      // alert(`iPhoneXR::${wrapperHeight}`);
    } else if (iPadAir === true) {
      setWrapperHeight("140vmax");
      // alert(`iPadAir::${wrapperHeight}`);
    } else if (iPadMini === true) {
      setWrapperHeight("165vmax");
      // alert(`iPadMini::${wrapperHeight}`);
    } else if (surfacePro7 === true) {
      setWrapperHeight("105vmax");
      // alert(`surfacePro7::${wrapperHeight}`);
      // alert(`surfacePro7::${innerContainerHeight}`);
    } else if (surfaceDuo === true) {
      setWrapperHeight("250vmax");
      // alert(`surfaceDuo::${wrapperHeight}`);
    } else if (GalaxyFold === true) {
      setWrapperHeight("310vmax");
      // alert(`GalaxyFold::${wrapperHeight}`);
    } else if (NestHub === true) {
      setWrapperHeight("130vmax");
      // alert(`NestHub::${wrapperHeight}`);
    } else if (NestHub2 === true) {
      setWrapperHeight("95vmax");
      // alert(`NestHub::${wrapperHeight}`);
    } else if (Desktop === true) {
      setWrapperHeight("72vmax");
      // alert(`Desktop::${wrapperHeight}`);
    }
  }, [
    GalaxyS8,
    iphoneSE,
    iphone12Pro,
    Pixel5,
    GalaxyS20,
    iPhoneXR,
    iPadAir,
    iPadMini,
    surfacePro7,
    surfaceDuo,
    GalaxyFold,
    NestHub,
    NestHub2,
    Desktop,
  ]);

  const handleCloseModal = () => setOpenCFModal(false);
  return (
    <div className="divheight ">
      <Box sx={{ ...StreamingTypeStyle.wrapper }}>
        {/* <Box sx={{ ...StreamingTypeStyle.wrapper, minHeight: wrapperHeight }}> */}
        <Typography sx={StreamingTypeStyle.caption} variant="h6" component="h2">
          {ConstantHelper.caption}
        </Typography>
        <Typography
          sx={StreamingTypeStyle.StreamTypeSectionTitle}
          variant="h6"
          component="h2"
        >
          {ConstantHelper.StreamTypeSectionTitle}
        </Typography>

        <Box sx={StreamingTypeStyle.TabNamesWrapper} id="TabNamesWrapper">
          <Tabs value={value} sx={{
    '& .MuiTabs-flexContainer': {
      flexWrap: 'wrap',
    },
  }} onChange={handleChange} centered>
            {StreamingTypeData.map((item, id) =>
              item.name != "VCMS" ? (
                <Tab
                  key={id}
                  label={item.name}
                  sx={{ color: ColorHelper.grey1, mb: 1 }}
                  {...a11yProps(id)}
                />
              ) : null
            )}
          </Tabs>
        </Box>

        {/* servicepage elem */}

        <div className="boxheight">
          {StreamingTypeData.map((item2, index) => (
            <TabPanel
              value={value}
              key={index}
              index={index}
              className="boxheight-tab"
            >
              <Box sx={StreamingTypeStyle.StreamTypeCoverContainer}>
                <Box sx={StreamingTypeStyle.BannerImg}>
                  <img
                    src={item2.bannerImg}
                    alt="Banner Image"
                    style={StreamingTypeStyle.BannerStreamTypeCover}
                    onClick={() => handleProductsPage(item2.url)}
                  />
                </Box>
                <Box id="overlay" sx={StreamingTypeStyle.overlay}></Box>

                <img
                  src={item2.Productslogo}
                  alt="Products Logo"
                  style={StreamingTypeStyle.streamLogo}
                  id="logoImage"
                  onClick={() => handleProductsPage(item2.url)}
                />
                <Box sx={StreamingTypeStyle.streamContent}>{item2.ProductTagLine}</Box>
              </Box>
              {/* adding header StreamItem Data */}
              <ServicesListing
                ListData={item2.data}
                iconColor={ColorHelper.PriamryColor}
              />
              {console.log(item2.data)}

              <Box sx={StreamingTypeStyle.btnContainer}>
                {/* <Box onClick={() => setOpenCFModal(true)}>
                  <FilledButton
                    btnText={ConstantHelper.navBtn2}
                    color={ColorHelper.white}
                    bgColor={ColorHelper.PriamryColor}
                  />
                </Box> */}
                <Box onClick={() => handleProductsPage(item2.url)}>
                  <BorderedButton
                    btnText={ConstantHelper.navBtn3}
                    color={ColorHelper.grey1}
                    bgColor={ColorHelper.transparent}
                    bordercolor={ColorHelper.lightGrey}
                  />
                </Box>
              </Box>
              {/* </Box> */}
              {/* <TalkToUsModaldrp
                open={openCFModal} onClose={handleCloseModal} formName={ConstantHelper.modalName1}
              /> */}

              {openCFModal && (
                <TalkToUsModaldrp
                  open={openCFModal}
                  onClose={() => setOpenCFModal(false)}
                  // modalprops={modalprops}
                  menuNameForModal="Products"
                  pageNameForModal={item2.name}
                />
              )}
            </TabPanel>
          ))}
        </div>
      </Box>
    </div>
  );
});

export default StreamingType;
