import React from "react";

const Linkedin = (props) => {
  const { color } = props;
  return (
     <svg width="20" height="20" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.28457 1.69215C4.28457 2.6267 3.47453 3.3843 2.47529 3.3843C1.47606 3.3843 0.666016 2.6267 0.666016 1.69215C0.666016 0.757601 1.47606 0 2.47529 0C3.47453 0 4.28457 0.757601 4.28457 1.69215Z" fill="white"/>
<path d="M0.913438 4.6281H4.00622V14H0.913438V4.6281Z" fill="white"/>
<path d="M8.9856 4.6281H5.89282V14H8.9856C8.9856 14 8.9856 11.0496 8.9856 9.20486C8.9856 8.09762 9.36367 6.98554 10.8722 6.98554C12.577 6.98554 12.5668 8.43453 12.5588 9.55709C12.5484 11.0244 12.5732 12.5219 12.5732 14H15.666V9.05372C15.6398 5.89538 14.8168 4.44008 12.1093 4.44008C10.5014 4.44008 9.50473 5.17006 8.9856 5.83049V4.6281Z" fill="white"/>
</svg>

  );
};

export default Linkedin;