import React from "react";

const HLTvApps02 = () => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3086_82228)">
        <path
          d="M23 15.7852L17.2672 18.7595L18.3266 12.3884L13.727 7.85725L20.1125 6.89646L23 1.34762L25.8875 6.89646L32.2731 7.85725L27.6734 12.3884L28.7329 18.7595L23 15.7852Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15.4479 28.0923L18.6273 24.9609L14.2144 24.2961L12.2188 20.3048L10.2231 24.2961L5.7204 24.9609L8.98963 28.0923L8.25812 32.4944L12.2188 30.4384L16.1796 32.4944L15.4479 28.0923Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M37.0104 28.0923L40.1898 24.9609L35.7769 24.2961L33.7813 20.3048L31.7856 24.2961L27.3727 24.9609L30.5521 28.0923L29.8206 32.4944L33.7813 30.4384L37.7421 32.4944L37.0104 28.0923Z"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M18.1542 12.2188H4.04303C2.55441 12.2188 1.34772 13.4254 1.34772 14.9141V36.4766C1.34772 37.9651 2.55441 39.1719 4.04303 39.1719H19.6309L23.0001 44.6523L26.3692 39.1719H41.9571C43.4456 39.1719 44.6524 37.9651 44.6524 36.4766V14.9141C44.6524 13.4254 43.4456 12.2188 41.9571 12.2188H27.8458"
          stroke="#457ABD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3086_82228">
          <rect width="46" height="46" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HLTvApps02;
