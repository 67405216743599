import React from "react";
import ColorHelper from "../../Helpers/ColorHelper";

const Vlive2 = (props) => {
    const { color } = props;
  return (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width={46} height={46}  viewBox="0 0 46 46">
    <defs>
      <style>
      
      </style>
    </defs>
    <path  stroke={ColorHelper.BgBlue}  class="cls-1" d="M23,11.67c-.48,0-.87,.39-.87,.87s.39,.87,.87,.87,.87-.39,.87-.87-.39-.87-.87-.87Z"/>
    <path  stroke={ColorHelper.BgBlue} class="cls-1" d="M8.53,27.36c.48,0,.87-.39,.87-.87s-.39-.87-.87-.87-.87,.39-.87,.87,.39,.87,.87,.87Z"/>
    <path  stroke={ColorHelper.BgBlue} class="cls-1" d="M29.97,2.95c-7.85,0-14.29,5.41-14.79,12.23C7.71,15.55,.68,20.94,.68,28.23c0,3.05,1.21,6,3.4,8.34,.43,1.79-.09,3.69-1.4,5-.25,.25-.32,.62-.19,.95,.13,.33,.45,.54,.81,.54,2.49,0,4.88-.98,6.66-2.69,1.73,.59,4,.95,6.07,.95,7.85,0,14.29-5.41,14.79-12.23,1.83-.08,3.72-.42,5.23-.93,1.78,1.72,4.18,2.69,6.66,2.69,.35,0,.67-.21,.81-.54,.13-.33,.06-.7-.19-.95-1.31-1.31-1.84-3.21-1.4-5,2.19-2.34,3.4-5.29,3.4-8.34,0-7.58-7.57-13.08-15.34-13.08Zm-13.95,36.62c-2.06,0-4.4-.4-5.97-1.03-.34-.13-.72-.05-.97,.22-1.05,1.15-2.42,1.95-3.91,2.32,.82-1.57,1.03-3.43,.53-5.19-.04-.14-.11-.27-.21-.37-1.98-2.04-3.07-4.63-3.07-7.29,0-6.14,6.23-11.33,13.6-11.33,6.96,0,13.08,4.84,13.08,11.33s-5.87,11.33-13.08,11.33Zm24.48-16.25c-.1,.1-.17,.23-.21,.37-.5,1.76-.29,3.61,.53,5.19-1.49-.37-2.86-1.17-3.91-2.32-.24-.27-.63-.36-.97-.22-1.36,.54-3.3,.92-5.14,1.01-.25-3.3-1.9-6.4-4.71-8.7h11.37c.48,0,.87-.39,.87-.87s-.39-.87-.87-.87h-14.05c-1.98-1.01-4.2-1.59-6.49-1.72,.5-5.86,6.16-10.49,13.04-10.49,7.37,0,13.6,5.19,13.6,11.33,0,2.66-1.09,5.25-3.07,7.29Z"/>
    <path  stroke={ColorHelper.BgBlue} class="cls-1" d="M23,25.62H12.01c-.48,0-.87,.39-.87,.87s.39,.87,.87,.87h10.99c.48,0,.87-.39,.87-.87s-.39-.87-.87-.87Z"/>
    <path  stroke={ColorHelper.BgBlue} class="cls-1" d="M23,30.85H8.53c-.48,0-.87,.39-.87,.87s.39,.87,.87,.87h14.47c.48,0,.87-.39,.87-.87s-.39-.87-.87-.87Z"/>
    <path  stroke={ColorHelper.BgBlue} class="cls-1" d="M37.47,11.67h-10.99c-.48,0-.87,.39-.87,.87s.39,.87,.87,.87h10.99c.48,0,.87-.39,.87-.87s-.39-.87-.87-.87Z"/>
  </svg>
  );
};

export default Vlive2;