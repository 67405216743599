import React from "react";
import { Helmet } from "react-helmet-async";

const SeoComponent = ({title, description, name, canonical, keywords}) => {
  return (
    <Helmet>
      {/* Standard metadata tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={canonical} /> 
      {/* End standard metadata tags */}
      {/* Facebook tags */}
      {/* <meta property="og:type" content={type} /> */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {/* End Facebook tags */}
      {/* Twitter tags */}
      <meta name="twitter:creator" content={name} />
      <meta name="twitter:description" content={description} />
      {/* End Twitter tags */}
      <meta
          name="keywords"
          content={keywords}
        />
    </Helmet>
  );
};

export default SeoComponent;
