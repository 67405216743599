import React from "react";

const StreamingTypeIcon5 = (props) => {
  const { color } = props;
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.5 5.75C32.975 5.75 31.5125 6.3558 30.4341 7.43414C29.3558 8.51247 28.75 9.97501 28.75 11.5V34.5C28.75 36.025 29.3558 37.4875 30.4341 38.5659C31.5125 39.6442 32.975 40.25 34.5 40.25C36.025 40.25 37.4875 39.6442 38.5659 38.5659C39.6442 37.4875 40.25 36.025 40.25 34.5C40.25 32.975 39.6442 31.5125 38.5659 30.4341C37.4875 29.3558 36.025 28.75 34.5 28.75H11.5C9.97501 28.75 8.51247 29.3558 7.43414 30.4341C6.3558 31.5125 5.75 32.975 5.75 34.5C5.75 36.025 6.3558 37.4875 7.43414 38.5659C8.51247 39.6442 9.97501 40.25 11.5 40.25C13.025 40.25 14.4875 39.6442 15.5659 38.5659C16.6442 37.4875 17.25 36.025 17.25 34.5V11.5C17.25 9.97501 16.6442 8.51247 15.5659 7.43414C14.4875 6.3558 13.025 5.75 11.5 5.75C9.97501 5.75 8.51247 6.3558 7.43414 7.43414C6.3558 8.51247 5.75 9.97501 5.75 11.5C5.75 13.025 6.3558 14.4875 7.43414 15.5659C8.51247 16.6442 9.97501 17.25 11.5 17.25H34.5C36.025 17.25 37.4875 16.6442 38.5659 15.5659C39.6442 14.4875 40.25 13.025 40.25 11.5C40.25 9.97501 39.6442 8.51247 38.5659 7.43414C37.4875 6.3558 36.025 5.75 34.5 5.75Z"
        stroke={color}
        strokeWidth="3.83333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default StreamingTypeIcon5;
