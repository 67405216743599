import React, { useState, useEffect } from "react";
import { Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";

// Helpers
import ConstantHelper from "../../Helpers/ConstantHelper";
import ColorHelper from "../../Helpers/ColorHelper";

// Custom Components
import FilledButton from "../Ui-Components/Buttons/FilledButton/FilledButton";
import StickyCTABtn from "../StickyCTABtn/StickyCTABtn";

// Custoom Styles
import ServiceHeroSectionStyle from "./ServiceHeroSectionStyle";
import ImageHelper from "../../Helpers/ImageHelper";
import BorderedButton from "../Ui-Components/Buttons/BorderedButton/BorderedButton";
import TalkToUsModal from "../HeroSection/TalkToUsModal/TalkToUsModal";

import { useNavigate } from "react-router-dom";
import SeoComponent from "../SeoComponent/SeoComponent";

const ServiceHeroSection = ({
  ServiceHeroProps,
  pageNameForModal,
  menuNameForModal,
  handleClick,
  ...rest
}) => {
  // Talk to us modal state

  const navigate = useNavigate();


  return (
    <>
      <SeoComponent
        title={ServiceHeroProps?.metaTitle}
        description={ServiceHeroProps?.description}
        canonical={ServiceHeroProps?.canonical}
        keywords={ServiceHeroProps?.keywords}
      />
      <Box
        sx={{
          ...ServiceHeroSectionStyle.container,
          backgroundImage: `url(${ServiceHeroProps?.img})`,
        }}
        id="HeroSecrtionContainer"
      >
        <Box sx={ServiceHeroSectionStyle.overlay} id="heroOverlay"></Box>
        <Box sx={ServiceHeroSectionStyle.contents}>
          <Typography
            sx={ServiceHeroSectionStyle.title1}
            variant="h6"
            component="h2"
          >
            {/* {ConstantHelper.heroText1} */}
            {ServiceHeroProps?.title}
          </Typography>
          <Typography
            sx={ServiceHeroSectionStyle.title1}
            variant="h6"
            component="h2"
          >
            {/* {ConstantHelper.heroText1} */}
            {ServiceHeroProps?.title1}
          </Typography>
          {/* <Typography
          sx={ServiceHeroSectionStyle.title2}
          variant="h6"
          component="h2"
        >
          {ConstantHelper.heroText2}
        </Typography> */}
          <Divider sx={ServiceHeroSectionStyle.divider} />
          <Typography
            sx={ServiceHeroSectionStyle.heroContent}
            variant="h6"
            component="h2"

            dangerouslySetInnerHTML={{
              __html: ServiceHeroProps?.caption,
            }}
          >
            {/* {ServiceHeroProps?.caption} */}
          </Typography>
          <Box sx={ServiceHeroSectionStyle.btnContainer}>
            <Box
              onClick={() => {
                if (ServiceHeroProps?.gotoContactPage == true) {
                  navigate("/contact");
                } else {
                  // setOpenCFModal(true);
                }
              }}
            >
              <BorderedButton
                handleClick={handleClick}
                btnText={ServiceHeroProps?.btnName}
                color={ColorHelper.white}
                bgColor={ColorHelper.whiteOp}
                bordercolor={ColorHelper.white}
                sx={{
                  backdropFilter: "blur(4px)",
                }}
              />
            </Box>
          </Box>
        </Box>
        <StickyCTABtn />
      </Box>
    </>
  );
};

export default ServiceHeroSection;
