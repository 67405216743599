import React from "react";
import ColorHelper from "../../Helpers/ColorHelper";

const HighlightIconV3_8 = () => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.9515 20.4738C19.7499 23.6335 22.3665 26.2501 25.5262 28.0485L27.2205 25.6757C27.493 25.2941 27.8958 25.0257 28.3529 24.9211C28.8099 24.8166 29.2894 24.8832 29.7007 25.1083C32.4114 26.5898 35.4051 27.4807 38.4847 27.7227C38.9654 27.7608 39.414 27.9786 39.7412 28.3328C40.0684 28.6869 40.2501 29.1514 40.25 29.6336V38.1857C40.2501 38.6603 40.0742 39.118 39.7562 39.4703C39.4383 39.8226 39.0009 40.0444 38.5288 40.0928C37.5087 40.1981 36.4839 40.2506 35.4583 40.25C19.0517 40.25 5.75 26.9483 5.75 10.5417C5.75 9.5105 5.80175 8.487 5.90717 7.47117C5.95557 6.99909 6.17739 6.56175 6.52968 6.2438C6.88197 5.92585 7.33969 5.7499 7.81425 5.75H16.3664C16.8486 5.74994 17.3131 5.93161 17.6672 6.25878C18.0214 6.58595 18.2392 7.0346 18.2773 7.51525C18.5193 10.5949 19.4102 13.5886 20.8917 16.2993C21.1168 16.7106 21.1834 17.1901 21.0789 17.6471C20.9743 18.1042 20.7059 18.507 20.3243 18.7795L17.9515 20.4738ZM13.1177 19.2146L16.7593 16.6137C15.7258 14.3828 15.0178 12.0153 14.6567 9.58333H9.6025C9.591 9.9015 9.58525 10.2216 9.58525 10.5417C9.58333 24.8323 21.1677 36.4167 35.4583 36.4167C35.7784 36.4167 36.0985 36.4109 36.4167 36.3975V31.3433C33.9847 30.9822 31.6172 30.2741 29.3863 29.2407L26.7854 32.8823C25.7383 32.4754 24.7212 31.9951 23.7418 31.4448L23.6306 31.3816C19.8712 29.242 16.758 26.1288 14.6184 22.3694L14.5552 22.2582C14.0049 21.2788 13.5246 20.2617 13.1177 19.2146Z"
        fill={ColorHelper.BgBlue}
      />
    </svg>
  );
};

export default HighlightIconV3_8;
