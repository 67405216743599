import * as React from "react";
import ColorHelper from "../../Helpers/ColorHelper";

const vCMS = ({ size = 46, color, ...props }) => (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="48" height="48" rx="24" fill="#FFF3F5"/>
    <path d="M24 17L31 21V29L24 33L17 29V21L24 17Z" stroke="#DA1A5D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M24 14V17" stroke="#DA1A5D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M17 21L24 25L31 21" stroke="#DA1A5D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M31 29L34 30.5" stroke="#DA1A5D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M14 30.5L17 29" stroke="#DA1A5D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M24 25V33" stroke="#DA1A5D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
);

export default vCMS;