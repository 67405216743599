import React from "react";
import ColorHelper from "../../Helpers/ColorHelper";

const Vlive5 = (props) => {
    const { color } = props;
  return (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width={46} height={46}  viewBox="0 0 46 46">
    <defs>
      <style>
     
      </style>
    </defs>
    <path stroke={ColorHelper.BgBlue}  class="cls-1" d="M9.13,35.02c-2.55,0-4.62-2.07-4.62-4.62v-1.85c0-2.55,2.07-4.62,4.62-4.62s4.62,2.07,4.62,4.62v1.85c0,2.55-2.07,4.62-4.62,4.62Zm0-9.25c-1.53,0-2.77,1.24-2.77,2.77v1.85c0,1.53,1.24,2.77,2.77,2.77s2.77-1.24,2.77-2.77v-1.85c0-1.53-1.24-2.77-2.77-2.77Z"/>
    <path stroke={ColorHelper.BgBlue}  class="cls-1" d="M6.36,44.27c-3.12,0-5.19-2.03-5.28-2.12-.17-.17-.27-.41-.27-.65v-3.2c0-.62,.31-1.2,.82-1.54l5.14-3.42c.42-.28,1-.17,1.28,.26,.28,.43,.17,1-.26,1.28l-5.14,3.42v2.78c.55,.44,1.92,1.35,3.7,1.35,.51,0,.92,.41,.92,.92s-.41,.92-.92,.92Z"/>
    <path stroke={ColorHelper.BgBlue}  class="cls-1" d="M16.01,38.56l-5.55-3.7c-.26-.17-.41-.46-.41-.77v-.92h1.85v.43l5.14,3.42-1.03,1.54Z"/>
    <path stroke={ColorHelper.BgBlue}  class="cls-1" d="M23,35.02c-2.55,0-4.62-2.07-4.62-4.62v-1.85c0-2.55,2.07-4.62,4.62-4.62s4.62,2.07,4.62,4.62v1.85c0,2.55-2.07,4.62-4.62,4.62Zm0-9.25c-1.53,0-2.77,1.24-2.77,2.77v1.85c0,1.53,1.24,2.77,2.77,2.77s2.77-1.24,2.77-2.77v-1.85c0-1.53-1.24-2.77-2.77-2.77Z"/>
    <path stroke={ColorHelper.BgBlue}  class="cls-1" d="M15.6,40.57c-.51,0-.92-.41-.92-.92v-1.35c0-.62,.31-1.2,.82-1.54l5.14-3.42c.42-.28,1-.17,1.28,.26,.28,.43,.17,1-.26,1.28l-5.14,3.42v1.35c0,.51-.41,.92-.92,.92Z"/>
    <path stroke={ColorHelper.BgBlue}  class="cls-1" d="M30.4,40.57c-.51,0-.92-.41-.92-.92v-1.35l-5.14-3.42c-.42-.28-.54-.86-.26-1.28,.28-.43,.86-.54,1.28-.26l5.14,3.42c.52,.34,.82,.92,.82,1.54v1.35c0,.51-.41,.92-.92,.92Z"/>
    <path stroke={ColorHelper.BgBlue}  class="cls-1" d="M36.87,35.02c-2.55,0-4.62-2.07-4.62-4.62v-1.85c0-2.55,2.07-4.62,4.62-4.62s4.62,2.07,4.62,4.62v1.85c0,2.55-2.07,4.62-4.62,4.62Zm0-9.25c-1.53,0-2.77,1.24-2.77,2.77v1.85c0,1.53,1.24,2.77,2.77,2.77s2.77-1.24,2.77-2.77v-1.85c0-1.53-1.24-2.77-2.77-2.77Z"/>
    <path stroke={ColorHelper.BgBlue}  class="cls-1" d="M29.99,38.56l-1.03-1.54,5.14-3.42v-.43h1.85v.92c0,.31-.15,.6-.41,.77l-5.55,3.7Z"/>
    <path stroke={ColorHelper.BgBlue}  class="cls-1" d="M39.64,44.27c-.51,0-.92-.41-.92-.92s.41-.92,.92-.92c1.8,0,3.15-.9,3.7-1.34v-2.79l-5.14-3.42c-.43-.28-.54-.86-.26-1.28,.28-.43,.86-.54,1.28-.26l5.14,3.42c.52,.34,.82,.92,.82,1.54v3.2c0,.25-.1,.48-.27,.65-.09,.09-2.16,2.12-5.28,2.12Zm4.62-2.77h0Z"/>
    <path stroke={ColorHelper.BgBlue}  class="cls-1" d="M35.94,44.27H6.36c-.51,0-.92-.41-.92-.92s.41-.92,.92-.92h29.59c.51,0,.92,.41,.92,.92s-.41,.92-.92,.92Z"/>
    <path stroke={ColorHelper.BgBlue}  class="cls-1" d="M40.57,22.08c-.51,0-.92-.41-.92-.92v-1.33l-2.59-1.55c-.23-.14-.38-.36-.43-.62-.12-.65-.3-1.31-.54-2-.09-.25-.06-.52,.06-.75l1.47-2.65-.4-.7-3.02-.05c-.26,0-.51-.12-.68-.32-.45-.52-.94-1.02-1.46-1.46-.2-.17-.32-.42-.32-.69l-.05-3.02-.7-.4-2.65,1.47c-.23,.13-.51,.15-.75,.07-.67-.24-1.35-.42-2-.54-.26-.05-.49-.21-.62-.43l-1.55-2.59h-.8l-1.55,2.59c-.14,.23-.36,.38-.62,.43-.66,.12-1.31,.3-2,.54-.25,.09-.52,.06-.75-.07l-2.65-1.47-.69,.4-.05,3.03c0,.26-.12,.51-.32,.68-.52,.45-1.01,.94-1.46,1.46-.17,.2-.42,.32-.68,.32l-3.03,.05-.4,.69,1.47,2.65c.13,.23,.15,.5,.06,.75-.24,.68-.42,1.34-.54,2-.05,.26-.21,.49-.43,.62l-2.59,1.55v1.33c0,.51-.41,.92-.92,.92s-.92-.41-.92-.92v-1.85c0-.32,.17-.63,.45-.79l2.69-1.61c.11-.48,.24-.97,.39-1.46l-1.53-2.75c-.16-.28-.16-.63,0-.91l.92-1.6c.16-.28,.46-.46,.79-.46l3.14-.05c.34-.37,.69-.73,1.06-1.06l.05-3.14c0-.32,.18-.62,.46-.78l1.6-.92c.28-.16,.63-.17,.91,0l2.75,1.53c.49-.16,.98-.29,1.46-.39l1.61-2.69c.17-.28,.47-.45,.79-.45h1.85c.33,0,.63,.17,.79,.45l1.61,2.69c.48,.11,.97,.24,1.46,.39l2.75-1.53c.28-.16,.63-.16,.91,0l1.6,.92c.28,.16,.46,.46,.46,.79l.05,3.14c.37,.34,.73,.69,1.06,1.06l3.14,.05c.33,0,.62,.18,.79,.46l.92,1.6c.16,.28,.17,.63,0,.91l-1.52,2.75c.16,.49,.29,.98,.39,1.46l2.69,1.61c.28,.17,.45,.47,.45,.79v1.85c0,.51-.41,.92-.92,.92Z"/>
    <path stroke={ColorHelper.BgBlue}  class="cls-1" d="M31.32,22.08c-.51,0-.92-.41-.92-.92v-.92c0-4.08-3.32-7.4-7.4-7.4s-7.4,3.32-7.4,7.4v.92c0,.51-.41,.92-.92,.92s-.92-.41-.92-.92v-.92c0-5.1,4.15-9.25,9.25-9.25s9.25,4.15,9.25,9.25v.92c0,.51-.41,.92-.92,.92Z"/>
    <path stroke={ColorHelper.BgBlue}  class="cls-1" d="M22.08,7.28h1.85v1.85h-1.85v-1.85Z"/>
    <path stroke={ColorHelper.BgBlue}  class="cls-1" d="M10.06,20.23h1.85v1.85h-1.85v-1.85Z"/>
    <path stroke={ColorHelper.BgBlue}  class="cls-1" d="M34.1,20.23h1.85v1.85h-1.85v-1.85Z"/>
    <path stroke={ColorHelper.BgBlue}  class="cls-1" d="M27.62,22.08c-.51,0-.92-.41-.92-.92v-.92c0-2.04-1.66-3.7-3.7-3.7-1.16,0-2.26,.55-2.96,1.48-.31,.41-.89,.49-1.29,.18-.41-.31-.49-.89-.18-1.29,1.04-1.39,2.7-2.22,4.44-2.22,3.06,0,5.55,2.49,5.55,5.55v.92c0,.51-.41,.92-.92,.92Z"/>
  </svg>
  );
};

export default Vlive5;