import React from "react";
import ColorHelper from "../../Helpers/ColorHelper";

const HighlightIconV2_2 = () => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_690_54030)">
        <path
          d="M17.7158 12.0481V23.859L29.5267 17.9535L17.7158 12.0481Z"
          fill={ColorHelper.BgBlue}
        />
        <path
          d="M41.0586 0H4.94141C3.6313 0.00142687 2.37526 0.522496 1.44888 1.44888C0.522496 2.37526 0.00142687 3.6313 0 4.94141L0 41.0586C0.00142687 42.3687 0.522496 43.6247 1.44888 44.5511C2.37526 45.4775 3.6313 45.9986 4.94141 46H41.0586C42.3687 45.9986 43.6247 45.4775 44.5511 44.5511C45.4775 43.6247 45.9986 42.3687 46 41.0586V4.94141C45.9986 3.6313 45.4775 2.37526 44.5511 1.44888C43.6247 0.522496 42.3687 0.00142687 41.0586 0ZM15.0201 11.3203C15.0201 10.9375 15.118 10.561 15.3044 10.2266C15.4909 9.89219 15.7597 9.61101 16.0853 9.40974C16.411 9.20846 16.7827 9.09378 17.1652 9.07657C17.5477 9.05936 17.9282 9.14019 18.2706 9.31141L31.5352 15.9446C31.9082 16.1311 32.222 16.4179 32.4412 16.7727C32.6605 17.1275 32.7766 17.5364 32.7766 17.9535C32.7766 18.3706 32.6605 18.7794 32.4412 19.1343C32.222 19.4891 31.9082 19.7758 31.5352 19.9624L18.2706 26.5938C17.9282 26.765 17.5477 26.8458 17.1652 26.8286C16.7827 26.8114 16.411 26.6967 16.0853 26.4954C15.7597 26.2941 15.4909 26.013 15.3044 25.6786C15.118 25.3442 15.0201 24.9677 15.0201 24.5848V11.3203ZM40.5195 35.1289H12.668V36.4766C12.668 36.834 12.526 37.1768 12.2732 37.4295C12.0205 37.6822 11.6777 37.8242 11.3203 37.8242C10.9629 37.8242 10.6201 37.6822 10.3674 37.4295C10.1146 37.1768 9.97266 36.834 9.97266 36.4766V35.1289H6.82812C6.4707 35.1289 6.12792 34.9869 5.87519 34.7342C5.62245 34.4815 5.48047 34.1387 5.48047 33.7812C5.48047 33.4238 5.62245 33.081 5.87519 32.8283C6.12792 32.5756 6.4707 32.4336 6.82812 32.4336H9.97266V31.0859C9.97266 30.7285 10.1146 30.3857 10.3674 30.133C10.6201 29.8803 10.9629 29.7383 11.3203 29.7383C11.6777 29.7383 12.0205 29.8803 12.2732 30.133C12.526 30.3857 12.668 30.7285 12.668 31.0859V32.4336H40.5195C40.877 32.4336 41.2197 32.5756 41.4725 32.8283C41.7252 33.081 41.8672 33.4238 41.8672 33.7812C41.8672 34.1387 41.7252 34.4815 41.4725 34.7342C41.2197 34.9869 40.877 35.1289 40.5195 35.1289Z"
          fill={ColorHelper.BgBlue}
        />
      </g>
      <defs>
        <clipPath id="clip0_690_54030">
          <rect width="46" height="46" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HighlightIconV2_2;
