import React from "react";

const StreamingTypeIcon6 = (props) => {
  const { color } = props;
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.66671 5.75H38.3334C39.35 5.75 40.3251 6.15387 41.0439 6.87276C41.7628 7.59165 42.1667 8.56667 42.1667 9.58333V21.0833C42.1667 26.1666 40.1474 31.0418 36.5529 34.6362C32.9585 38.2307 28.0834 40.25 23 40.25C20.483 40.25 17.9907 39.7542 15.6653 38.791C13.3399 37.8278 11.227 36.416 9.44716 34.6362C5.85271 31.0418 3.83337 26.1666 3.83337 21.0833V9.58333C3.83337 8.56667 4.23724 7.59165 4.95613 6.87276C5.67502 6.15387 6.65004 5.75 7.66671 5.75V5.75Z"
        stroke={color}
        strokeWidth="3.83333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.3334 19.1667L23 26.8334L30.6667 19.1667"
        stroke={color}
        strokeWidth="3.83333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default StreamingTypeIcon6;
