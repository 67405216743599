// Helper
import ColorHelper from "../../Helpers/ColorHelper";
import FontHelper from "../../Helpers/FontHelper";

const HighlightCardStyle = {
  cardWrapper: {
    backgroundColor: ColorHelper.white2,
    px: { xs: 2, md: 19 },
    py: { xs: 6, md: 8 },
    display: "flex",
    justifyCotent: "center",
    alignItems: "center",
  },
  card: {
    backgroundColor: ColorHelper.PriamryColor,
    borderRadius: 8,
    width: "100vw",
    display: "flex",
    justifyCotent: "center",
    alignItems: "center",
    px: { xs: 4, md: 10 },
    py: { xs: 4, md: 7 },
    display: "flex",
    justifyCotent: "Space-between",
    alignItems: "center",
    flexDirection: { xs: "column", md: "row" },
    // mx: {xs: 2, md: 0},
    flexDirection: { xs: "column", md: "column", lg: "row" },
  },
  cardImage: {
    filter: "drop-shadow(rgb(156, 0, 55) 16px 30px 30px)",
    borderRadius: "10px",
    width: "100%",
    overlay: "rgba(0,0,0,0.8)",
  },
  content: {
    mx: { xs: 1, md: 1, lg: 9 },
    mt: { xs: 4, md: 4, lg: 0 },
  },
  CardTitle: {
    fontSize: { xs: 16, md: 28 },
    fontFamily: FontHelper.FontSemiBold,
    color: ColorHelper.white,
    maxWidth: "100%",
  },
  CardCaption: {
    fontSize: { xs: 12, md: 16 },
    color: ColorHelper.grey2,
    fontFamily: FontHelper.FontRegular,
    maxWidth: "100%",
    fontWeight: 400,
    my: 3,
  },
  Link: {
    textDecoration: "none",
    color: ColorHelper.white,
    fontFamily: FontHelper.FontRegular,
    display: "flex",
    justifyCotent: "center",
    alignItems: "center",
    pt: 2.5,
    transition: "0.3s ease-in-out 0s",
    "&:hover": {
      fontWeight: 800,
      transition: "0.3s ease-in-out 0s",
    },
  },
  arrowWrapper: {
    pt: 0.7,
    ml: 1.2,
    transition: "0.3s ease-in-out 0s",
    "&:hover": {
      ml: 2,
      transition: "0.3s ease-in-out 0s",
    },
  },
  BtnIconContainer: {
    svg: {
      width: { xs: "30px", md: "auto" },
    },
  },
};

export default HighlightCardStyle;
