// Helper
import ColorHelper from "../../Helpers/ColorHelper";
const BrandSectionStyle = {
  container: {
    backgroundColor: ColorHelper.white,
    py: 1,
    pt: { xs: 0, md: 2 },
    // px: { xs: 2, md: 25 },
    ".react-multi-carousel-item": {
      'div': {
        mt: '10px',
      }
    },
  },
  brandLogoImgs: {
    textAlign: "center",
    mt: { xs: 5, md: 0 },
    'img': {
      height: { xs: 'auto', md: "100px" },
      maxWidth: { xs: '80px', md: "200px" },
    }

  },
  img: {
    // height: {xs: 0, md: "100vh"},
    minWidth: { xs: 0, md: "200px" },
    objectFit:"contain",

  }
};

export default BrandSectionStyle;
