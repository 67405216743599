import React from "react";
import ColorHelper from "../../Helpers/ColorHelper";

const Vlive4 = (props) => {
    const { color } = props;
  return (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width={46} height={46}  viewBox="0 0 46 46">
    <defs>
      <style>
       
      </style>
    </defs>
    <path stroke={ColorHelper.BgBlue}  class="cls-1" d="M3.29,29.27c.49,0,.88-.4,.88-.89s-.4-.88-.88-.88-.88,.4-.88,.88h0c0,.49,.4,.89,.88,.89Z"/>
    <path stroke={ColorHelper.BgBlue}  class="cls-1" d="M42.23,31.57l-5.09-2.75v-9.28c0-1.74-1.23-3.16-2.75-3.16h-.81v-2c0-3.68-1.44-7.14-4.06-9.76C26.91,2.01,23.45,.57,19.77,.57s-7.14,1.44-9.75,4.06c-2.62,2.62-4.06,6.08-4.06,9.76v2h-.81c-1.51,0-2.75,1.42-2.75,3.16v4.9c0,.49,.4,.88,.88,.88s.88-.4,.88-.88v-4.9c0-.74,.46-1.39,.98-1.39h29.24c.52,0,.98,.65,.98,1.39v9.28l-5.09,2.75c-.84,.45-1.35,.79-1.36,2.11-.02,2.08,.58,4.31,1.72,6.44,.07,.13,.15,.27,.22,.4H5.16c-.52,0-.98-.65-.98-1.39v-6.8c0-.49-.4-.88-.88-.88s-.88,.4-.88,.88v6.8c0,1.74,1.23,3.16,2.75,3.16h26.86c.91,1.21,1.93,2.16,3.03,2.82,.48,.29,.84,.43,1.21,.43,.37,0,.74-.14,1.21-.43,1.69-1.01,3.18-2.69,4.4-4.98,1.14-2.13,1.74-4.35,1.72-6.44-.01-1.32-.53-1.66-1.36-2.11h0Zm-14.62-15.18H11.94v-2c0-4.32,3.51-7.84,7.83-7.84s7.83,3.52,7.83,7.84v2h0Zm4.21,0h-2.44v-2c0-2.55-1-4.96-2.82-6.78-1.82-1.82-4.23-2.82-6.78-2.82s-4.96,1-6.78,2.82c-1.82,1.82-2.82,4.23-2.82,6.78v2h-2.44v-2c0-6.64,5.4-12.05,12.04-12.05s12.04,5.41,12.04,12.05v2Zm8.5,22.89c-1.06,1.98-2.36,3.47-3.75,4.3-.17,.1-.26,.15-.3,.17-.05-.02-.13-.06-.3-.17,0,0,0,0,0,0-1-.6-1.92-1.49-2.75-2.67,0,0,0-.01-.01-.02-.34-.49-.67-1.02-.99-1.61-1-1.87-1.52-3.8-1.51-5.59,0-.2,.02-.29,.02-.33,.07-.05,.25-.15,.41-.23l5.14-2.77,5.11,2.76h.02c.16,.1,.34,.19,.41,.25,0,.04,.02,.14,.02,.33,.02,1.79-.51,3.72-1.51,5.59h0Z"/>
    <path stroke={ColorHelper.BgBlue}  class="cls-1" d="M21.92,25.78h7.83c.49,0,.88-.4,.88-.88s-.4-.88-.88-.88h-7.83c-.49,0-.88,.4-.88,.88s.4,.88,.88,.88h0Z"/>
    <path stroke={ColorHelper.BgBlue}  class="cls-1" d="M21.92,30.52h5.4c.49,0,.88-.4,.88-.88s-.4-.88-.88-.88h-5.4c-.49,0-.88,.4-.88,.88s.4,.88,.88,.88h0Z"/>
    <path stroke={ColorHelper.BgBlue}  class="cls-1" d="M7.05,35.04c0,1.24,1.01,2.26,2.26,2.26h8.38c1.25,0,2.26-1.01,2.26-2.26,0-1.64-.73-3.28-1.99-4.51-.48-.47-1.02-.85-1.59-1.16,1-.83,1.64-2.08,1.64-3.48,0-2.49-2.02-4.51-4.51-4.51s-4.51,2.02-4.51,4.51c0,1.4,.64,2.65,1.64,3.48-.57,.3-1.11,.69-1.59,1.16-1.27,1.23-1.99,2.87-1.99,4.51h0Zm6.45-11.9c1.51,0,2.74,1.23,2.74,2.74s-1.23,2.74-2.74,2.74-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74Zm0,7.26c2.41,0,4.68,2.25,4.68,4.64,0,.27-.22,.49-.49,.49H9.31c-.26,0-.49-.22-.49-.49,0-2.38,2.27-4.64,4.68-4.64h0Z"/>
    <path stroke={ColorHelper.BgBlue}  class="cls-1" d="M38.17,34.78l-2.53,2.59-.88-.82c-.36-.33-.92-.32-1.25,.04-.33,.36-.32,.92,.04,1.25l1.51,1.41c.17,.16,.39,.24,.6,.24,.23,0,.46-.09,.63-.27l3.13-3.21c.34-.35,.33-.91-.02-1.25-.35-.34-.91-.33-1.25,.02h0Z"/>
  </svg>
  );
};

export default Vlive4;