import React from "react";
import FooterSection from "../FooterSection/FooterSection";
import Header from "../Header/Header";
import BlogDetails from "./BlogDetails";

export default function BlogDetailsContainer() {
  return (
    <>
      <Header />
      <BlogDetails />
      <FooterSection footerCardShow={true} />
    </>
  );
}
