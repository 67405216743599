// Helpers
import IconHelper from "../../Helpers/IconHelper";

const StreamingTypeData = [
  {
    id: 1,
    title: "Multi-screen OTT solutions",
    desc: "Offers white labelled full stack OTT technology platform as a managed service. ",
    icon: IconHelper.StreamingTypeIcon1,
  },
  {
    id: 2,
    title: "Easy deployment",
    desc: "Can be deployed from the cloud or on-premise, depending on the requirement. ",
    icon: IconHelper.StreamingTypeIcon2,
  },
  {
    id: 3,
    title: "Customization",
    desc: "Personalized solution that provides best-in-class user experience across devices. ",
    icon: IconHelper.StreamingTypeIcon3,
  },
  {
    id: 4,
    title: "Increased engagement",
    desc: "Helps to keep customers and employees engaged with your brand. ",
    icon: IconHelper.StreamingTypeIcon4,
  },
  {
    id: 5,
    title: "Enhanced communication",
    desc: "This solution helps to communicate more effectively across the organization.  ",
    icon: IconHelper.StreamingTypeIcon5,
  },
  {
    id: 6,
    title: "Support services",
    desc: "Comprehensive onboarding and training with vLive Enterprise experts. ",
    icon: IconHelper.StreamingTypeIcon6,
  },
  {
    id: 7,
    title: "Scalability",
    desc: "Helps to produce reliable virtual events at any scale- small or big.",
    icon: IconHelper.StreamingTypeIcon7,
  },
  {
    id: 8,
    title: "Security",
    desc: "Helps to keep your content secure at all times without any interference.  ",
    icon: IconHelper.StreamingTypeIcon8,
  },
];

export default StreamingTypeData;
