import React from "react";

const StreamingTypeIcon8 = (props) => {
  const { color } = props;
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.9999 3.83331C17.9166 3.83331 13.0415 5.85265 9.44704 9.4471C5.85259 13.0415 3.83325 17.9167 3.83325 23C3.83325 28.0833 5.85259 32.9584 9.44704 36.5529C13.0415 40.1473 17.9166 42.1666 22.9999 42.1666C28.0832 42.1666 32.9584 40.1473 36.5528 36.5529C40.1472 32.9584 42.1666 28.0833 42.1666 23C42.1666 17.9167 40.1472 13.0415 36.5528 9.4471C32.9584 5.85265 28.0832 3.83331 22.9999 3.83331V3.83331Z"
        stroke={color}
        strokeWidth="3.83333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.1667 15.3333L30.6667 23L19.1667 30.6666V15.3333Z"
        stroke={color}
        strokeWidth="3.83333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default StreamingTypeIcon8;
