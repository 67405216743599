// Helper
import ColorHelper from "../../../Helpers/ColorHelper";
import FontHelper from "../../../Helpers/FontHelper";
const TopStripeStyle = {
  topStrip: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    py: "0.5rem",
    px: { xs: 0, md: 5, lg: 14 },
    backgroundColor: ColorHelper.blue,
  },
  flexit: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: { xs: "column", md: "column", lg: "row" },
    minWidth: "fit-content",
    px: { xs: 5, md: 0 },
  },
  topStriptext: {
    display: { xs: "none", md: "block", lg: "block" },
    fontFamily: FontHelper.FontBold,
    fontSize: 14,
    mx: 2,
    color: ColorHelper.white,
    textDecoration: "none",
    cursor: "pointer",
  },
  topStriptextLight: {
    display: { xs: "none", md: "none", lg: "block" },
    fontSize: 14,
    fontFamily: FontHelper.FontBold,
    mx: 2,
    color: ColorHelper.white,
    fontWeight: 100,
  },
  LinkStyle: {
    textDecoration: "none",
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
};

export default TopStripeStyle;
